import {createElement} from "react";
import {Grid, Link, Typography} from "@mui/material";
import {Item} from "../../data/types";
import {LabeledData} from "../../components/LabeledData";
import {ImageWithPopover} from "../../components/ImageWithPopover";
import {useLocalization} from "../../i18n";
import {useNavigate} from "react-router-dom";

interface CompactItemDisplayProps {
    item: Item;
}

export function CompactItemDisplay({item}: CompactItemDisplayProps) {
    const navigate = useNavigate();

    const showImage = item.images.length > 0;

	const {t} = useLocalization();
    return (
        <Grid container spacing={3}>
            <Grid item xs={showImage ? 8: 12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <LabeledData label={t('items.singular')}>
                            <Link style={{cursor: 'pointer'}} onClick={() => navigate(`/items/${item.uuid}`)}>
                                <Typography variant="h5">{item.name}</Typography>
                            </Link>
                        </LabeledData>
                    </Grid>
                    {item.description &&
                    <Grid item xs={12}>
                        <LabeledData label={t('common.description')}>
                            {item.description}
                        </LabeledData>
                    </Grid>
                    }
                </Grid>
            </Grid>
            {showImage &&
            <Grid item xs={4}>
                <ImageWithPopover src={item.images[0]} />
            </Grid>
            }
        </Grid>
    );
}
