import {createElement} from "react";
import {Box, Grid, Tooltip} from "@mui/material";
import {Utilization} from "../../data/types";
import {useLocalization} from "../../i18n";

export function CubeUtilizationComponent({utilization}: { utilization: Utilization }) {
    const occupied = utilization.occupied / utilization.total * 100;
    const disabled = utilization.disabled / utilization.total * 100;
    const title = `${utilization.occupied} / ${utilization.total}`;
    return (
        <Tooltip title={<CubeUtilizationTooltipContent utilization={utilization} />}>
            <Box
				component="div"
				sx={{
					width: '100%',
					display: 'flex',
					height: '1rem',
					overflow: 'hidden',
					fontSize: '0.75rem',
					backgroundColor: '#e9ecef',
					borderColor: 'e9ecef'
				}}
			>
                <Box
					component="div"
					style={{width: `${occupied}%`}}
					sx={{
						justifyContent: 'center',
						textAlign: 'center',
						color: '#fff',
						backgroundColor: '#8C8838',
						whiteSpace: 'nowrap',
					}}
				/>
                <Box
					component="div"
					style={{width: `${disabled}%`}}
					sx={{
						justifyContent: 'center',
						textAlign: 'center',
						color: '#fff',
						backgroundColor: '#f44336',
						whiteSpace: 'nowrap',
					}}
				/>
            </Box>
        </Tooltip>
    );
}

function CubeUtilizationTooltipContent({utilization}: { utilization: Utilization }) {
    const {total, occupied, available, disabled} = utilization;
	const {t} = useLocalization();
    return(
        <Grid container spacing={3}>
            <Grid item>
                <label>{t('cubes.utilization.total')}:</label> {total}
            </Grid>
            <Grid item>
                <IndicatorBox color="#8C8838" />
                <label>{t('cubes.utilization.occupied')}:</label> {occupied}
            </Grid>
            <Grid item>
                <IndicatorBox color="#f44336" />
                <label>{t('cubes.utilization.disabled')}:</label> {disabled}
            </Grid>
            <Grid item>
                <IndicatorBox color="#e9ecef" />
                <label>{t('cubes.utilization.available')}:</label> {available}
            </Grid>
        </Grid>
    );
}

interface IndicatorBoxProps {
    color: string;
}

function IndicatorBox(props: IndicatorBoxProps) {
    return (
		<Box
			sx={{
				display: 'inline-block',
				width: '1em', height: '1em',
				marginTop: '0.3em',
				marginRight: '1em'
			}}
			style={{backgroundColor: props.color}}
		/>
	);
}
