import {Page, Paging} from "@variocube/app-ui";
import {Api} from "../Api";
import {CostCenter, CostCenterDetails} from "./types";

export function listCostCenters(tenantId: string, paging?: Paging) {
    return Api.GET<Page<CostCenter>>(`tenants/${tenantId}/cost-centers` + (paging ? paging.toQueryString() : '?unpaged=1'), { 'Accept': 'application/vnd.costcenters.paged+json' });
}

export function createCostCenter(tenantId: string, details: CostCenterDetails) {
    return Api.POST<CostCenter>(`tenants/${tenantId}/cost-centers`, details);
}

export function getCostCenter(tenantId: string, uuid: string) {
    return Api.GET<CostCenter>(`tenants/${tenantId}/cost-centers/${uuid}`);
}

export function putCostCenter(tenantId: string, uuid: string, details: CostCenterDetails) {
    return Api.PUT<CostCenter>(`tenants/${tenantId}/cost-centers/${uuid}`, details);
}

export function deleteCostCenter(tenantId: string, uuid: string) {
    return Api.DELETE<void>(`tenants/${tenantId}/cost-centers/${uuid}`);
}
