import {createContext, RefObject} from "react";

interface IWizardContext {
	step: number;
	setStep: (step: number) => void;
	loading: boolean;
	setLoading: (loading: boolean) => void;
	nextBtnRef: RefObject<HTMLButtonElement>|null;
}

export const WizardContext = createContext<IWizardContext>({
	step: 0,
	setStep: _ => {
		throw new Error('WizardContext was not initiated properly.')
	},
	loading: false,
	setLoading: _ => {
		throw new Error('WizardContext was not initiated properly.')
	},
	nextBtnRef: null
})
