import {Chip, Grid, TableCell, TableRow} from "@mui/material";
import {ColumnType, ContentTable, Page, PagingSettings, TemporalRangeFormat} from "@variocube/app-ui";
import {createElement, Fragment, useEffect, useState} from "react";
import {DateComponent} from "../../components/DateComponent";
import {RentalFilterData} from "../../data/items";
import {rentalsPaging} from "../../data/pagings";
import {Rental} from "../../data/types";
import {useLocalization} from "../../i18n";
import {convertInstantString, convertPlainDateTime} from "../../tools";
import {RentalBorrowerName} from "./RentalBorrowerName";
import {RentalFilter} from "./RentalFilter";
import {RentalItemName} from "./RentalItemName";
import {RentalStateComponent} from "./RentalStateComponent";
import {useNavigate} from "react-router-dom";
import {DateTime} from "luxon";

type RentalTableProps = {
	page: Page<Rental>;
	pageable: PagingSettings<any>;
	onPagingChange: (pageSettings: PagingSettings<any>) => void;
	inProgress?: boolean;
};

export const RentalTable = ({page, pageable, onPagingChange, inProgress}: RentalTableProps) => {
	const navigate = useNavigate();
	const {t} = useLocalization();

	useEffect(() => {
		setColumns({
			"state": {show: true, name: t("common.state")},
			"borrower.name": {show: true, name: t("rentals.borrower.label")},
			"itemName": {show: true, name: t("items.singular")},
			"from": {show: true, name: t("common.timeframe")},
			"foreignId": {show: true, name: t("rentals.foreignId")},
			"created": {show: true, name: t("common.created")},
		});
	}, [t]);

	const [columns, setColumns] = useState<ColumnType>({});
	const [filterDialog, setFilterDialog] = useState<boolean>(false);
	const [filter, setFilter] = useState<RentalFilterData>({});

	const handleFilter = (newFilter: RentalFilterData) => {
		setFilter(newFilter);
		const settings = rentalsPaging.getSettings();
		settings.filters = {};
		if (newFilter.needle) {
			settings.filters["needle"] = newFilter.needle;
		}
		if (newFilter.rentalState) {
			if ((newFilter.rentalState as any) === "ALL") {
				delete settings.filters["rentalState"];
			} else {
				settings.filters["rentalState"] = newFilter.rentalState;
			}
		}
		if (newFilter.categoryUuid) {
			settings.filters["categoryUuid"] = newFilter.categoryUuid;
		} else {
			delete settings.filters["categoryUuid"];
		}
		if (newFilter.modelUuid) {
			settings.filters["modelUuid"] = newFilter.modelUuid;
		} else  {
			delete settings.filters["modelUuid"];
		}
		settings.filters["from"] = convertPlainDateTime(newFilter.from);
		settings.filters["until"] = convertPlainDateTime(newFilter.until);
		rentalsPaging.updateSettings({
			...settings,
		});
		onPagingChange(settings);
		setFilterDialog(false);
	};

	useEffect(() => {
		const filters = rentalsPaging.getSettings().filters;
		if (filters) {
			filter.needle = filters["needle"];
			filter.rentalState = filters["rentalState"];
			filter.from = convertInstantString(filters["from"]);
			filter.until = convertInstantString(filters["until"]);
			setFilter(filter);
		}
	}, []);

	const handleResetFilter = () => {
		setFilter({});
		rentalsPaging.updateSettings({
			...rentalsPaging.getSettings(),
			filters: {},
		});
		onPagingChange(rentalsPaging.getSettings());
		setFilterDialog(false);
	};

	const filterIsFiltering = () => {
		return Boolean(
			(filter.needle && filter.needle.length > 0)
				|| filter.rentalState
				|| filter.from
				|| filter.until
				|| filter.categoryUuid
				|| filter.modelUuid,
		);
	};

	const handleResetSingleFilter = (filterProp: string) => {
		const settings = rentalsPaging.getSettings();
		if (settings.filters) {
			switch (filterProp) {
				case "needle":
					filter.needle = undefined;
					settings.filters["needle"] = undefined;
					break;
				case "rentalState":
					filter.rentalState = undefined;
					settings.filters["rentalState"] = undefined;
					break;
				case "from":
					filter.from = null;
					settings.filters["from"] = null;
					break;
				case "until":
					filter.until = null;
					settings.filters["until"] = null;
					break;
				case "categoryUuid":
					filter.categoryUuid = null;
					settings.filters["categoryUuid"] = null;
					break;
				case "modelUuid":
					filter.modelUuid = null;
					settings.filters["modelUuid"] = null;
					break;
			}
			setFilter(filter);
		}
		rentalsPaging.updateSettings({
			...settings,
		});
		onPagingChange(rentalsPaging.getSettings());
	};

	const filterOptions = (
		<Grid container spacing={1}>
			{!filterIsFiltering()
				&& (
					<Grid item>
						<Chip variant="outlined" label={t("noFilter")} onClick={() => setFilterDialog(true)} />
					</Grid>
				)}
			{(filter.needle && filter.needle.length > 0)
				&& (
					<Grid item>
						<Chip
							label={`${t("fulltextSearch")}: ${filter.needle}`}
							onDelete={() => handleResetSingleFilter("needle")}
						/>
					</Grid>
				)}

			{filter.categoryUuid
				&& (
					<Grid item>
						<Chip
							label={`${t("filterCategory")}: ${filter.category?.name}`}
							onDelete={() => handleResetSingleFilter("categoryUuid")}
						/>
					</Grid>
				)}

			{filter.modelUuid
				&& (
					<Grid item>
						<Chip
							label={`${t("filterModel")}: ${filter.model?.name}`}
							onDelete={() => handleResetSingleFilter("modelUuid")}
						/>
					</Grid>
				)}
			{filter.rentalState
				&& (
					<Grid item>
						<Chip
							label={`${t("filterStates")}: ${filter.rentalState}`}
							onDelete={() => handleResetSingleFilter("rentalState")}
						/>
					</Grid>
				)}
			{filter.from
				&& (
					<Grid item>
						<Chip
							label={`${t("filterTimeframeFrom")}: ${convertPlainDateTime(filter.from)}`}
							onDelete={() => handleResetSingleFilter("from")}
						/>
					</Grid>
				)}
			{filter.until
				&& (
					<Grid item>
						<Chip
							label={`${t("filterTimeframeUntil")}: ${convertPlainDateTime(filter.until)}`}
							onDelete={() => handleResetSingleFilter("until")}
						/>
					</Grid>
				)}
		</Grid>
	);

	const handleCancelFilterDialog = () => {
		setFilterDialog(false);
		onPagingChange(rentalsPaging.getSettings());
	};

	const showCell = (column: keyof typeof columns) => columns[column] && columns[column].show;

	return (
		<Fragment>
			<ContentTable
				page={page}
				pageable={pageable}
				onPageableChange={onPagingChange}
				inProgress={inProgress}
				columns={columns}
				onColumnsChange={c => setColumns(c as any)}
				onFilterClick={() => setFilterDialog(true)}
				renderFilterOptions={filterOptions}
				renderTableBody={
					<Fragment>
						{page.content.map(r => (
							<TableRow
								key={"rental-" + r.uuid}
								onClick={() => navigate("/rentals/" + r.uuid)}
							>
								{showCell("state") && (
									<TableCell>
										<RentalStateComponent rental={r} size="small" />
									</TableCell>
								)}
								{showCell("borrower.name") && (
									<TableCell>
										<RentalBorrowerName borrower={r.borrower} />
									</TableCell>
								)}
								{showCell("itemName") && (
									<TableCell>
										<RentalItemName itemName={r.itemName} serviceItem={r.serviceItem} />
									</TableCell>
								)}
								{showCell("from") && (
									<TableCell>
										<TemporalRangeFormat from={r.from.toJSDate()} until={r.until.toJSDate()} dateStyle="short" timeStyle="short" />
									</TableCell>
								)}
								{showCell("foreignId") && <TableCell>{r.foreignId || ""}</TableCell>}
								{showCell("created") && (
									<TableCell align="right">
										<DateComponent date={r.created} />
									</TableCell>
								)}
							</TableRow>
						))}
					</Fragment>
				}
			/>

			<RentalFilter
				filterDialog={filterDialog}
				setFilterDialog={setFilterDialog}
				handleFilter={handleFilter}
				handleResetFilter={handleResetFilter}
				handleCancelFilterDialog={handleCancelFilterDialog}
				filter={filter}
				setFilter={setFilter}
				showState
			/>
		</Fragment>
	);
};
