import {createElement} from "react";
import {Chip} from "@mui/material"
import {User} from "../../data/types";
import {green, red} from "@mui/material/colors";
import {useLocalization} from "../../i18n";

export function UserStateDisplay({user}: { user: User }) {
	const {t} = useLocalization();
    return (
        user.active ?
            <Chip label={t('common.enabled')} style={{ color: '#fff', background: green[500] }}/> :
            <Chip label={t('common.disabled')} style={{ color: '#fff', background: red[500] }}/>
    );
}
