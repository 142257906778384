import { Badge, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Fragment, createElement, useEffect, useState } from "react";
import { Cube, Box as CubeBox } from "../../data/types";
import { tenantUserStore } from "../../store/TenantUserStore";
import { ApiError } from "@variocube/app-ui";
import { listCubesBoxes } from "../../data/cubes";
import { FormattedAlert } from "../../components/FormattedAlert";

export type BoxSizeListProps = {
    cube: Cube;
    onSelectType: any;
}

export type BoxSizeType = {
    type: string;
    boxes: CubeBox[];
}

export const BoxSizeList = (props: BoxSizeListProps) => {

    const [boxes, setBoxes] = useState<CubeBox[] | undefined>();
    const [error, setError] = useState<ApiError | undefined>(undefined);
    const [boxTypes, setBoxTypes] = useState<BoxSizeType[]>([]);
    const [selectedType, setSelectedType] = useState<string | null>('');

    useEffect(() => {
        const tenant = tenantUserStore.tenant;
        if (!tenant) {
            throw new Error("No tenant selected");
        }
        console.debug('listCubesBoxes', props.cube.cubeId);
        listCubesBoxes(tenant.centerId, props.cube.cubeId)
            .then(boxes=>setBoxes(boxes.filter(b=>!b.disabled && (b.occupancies==undefined || b.occupancies.length==0))))
            .catch(toggleError);
    }, [props.cube]);

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    useEffect(() => {
        var foundBoxTypes: BoxSizeType[] = [];
        console.debug('foundBoxTypes', boxes);
        boxes?.filter(b => !b.occupancies || b.occupancies.length <= 0).forEach(b => {
            b.types.forEach(typ => {
                var found = (foundBoxTypes.find(bt => bt.type === typ));
                if (!found) {
                    found = { type: typ, boxes: [] }
                    foundBoxTypes.push(found);
                }
                found.boxes.push(b);
            })

        });
        setBoxTypes(foundBoxTypes);
    }, [boxes]);

    const handleSelectedType = (
        event: React.MouseEvent<HTMLElement>,
        newType: string | null,
    ) => {
        setSelectedType(newType);
        var firstBoxOfType = boxTypes.find(bt => bt.type == newType)?.boxes[0];

        props.onSelectType(newType, firstBoxOfType);
    };

    return (<Fragment><ToggleButtonGroup value={selectedType}
        exclusive
        onChange={handleSelectedType}>{boxTypes && boxTypes.map(b => (
            <ToggleButton key={b.type} value={b.type}>
                <Badge badgeContent={b.boxes.length} color="secondary" >
                    {b.type}&nbsp;&nbsp;
                </Badge>
            </ToggleButton >
        ))}</ToggleButtonGroup>
        {error && (
            <FormattedAlert title={`${error.status}`} info={error.message} />
        )}
    </Fragment>
    );
}
