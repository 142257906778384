import { createElement, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
    Box,
    Button,
    Grid,
    Paper,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Alert, AlertTitle
} from "@mui/material";
import { ApiError } from "../../Api";
import { tenantUserStore } from "../../store/TenantUserStore";
import { getItem } from "../../data/items";
import { getRental } from "../../data/rentals";
import { getBox, openBox, setBoxState } from "../../data/cubes";
import { Box as CubeBox, Occupancy, Rental, Item } from "../../data/types";
import { Loading } from "../../components/Loading";
import { LabeledData } from "../../components/LabeledData";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { FormattedAlert } from "../../components/FormattedAlert";
import { RentalBorrowerName } from "../rentals";
import { CubeBoxLockStateComponent } from "./CubeBoxLockStateComponent";
import { CubeBoxAvailableComponent } from "./CubeBoxAvailableComponent";
import { CubeBoxActionsComponent } from "./CubeBoxActionsComponent";
import { useLocalization } from "../../i18n";
import { Breadcrumbs, BreadcrumbItem, TemporalRangeFormat } from "@variocube/app-ui";
import { CrumbLink } from "../../components/CrumbLink";
export type BoxOperationsParams = {
    cubeId: string,
    boxNumber: string,
    showBreadcrumb?: boolean
}

export const BoxOperations = (params: BoxOperationsParams) => {
    const {
        cubeId = '',
        boxNumber = '',
        showBreadcrumb = true
    } = params;
    const [box, setBox] = useState<CubeBox | undefined>(undefined);
    const [error, setError] = useState<ApiError | undefined>(undefined);
    const [openBoxMode, setOpenBoxMode] = useState<boolean>(false);
    const [enableBoxMode, setEnableBoxMode] = useState<boolean>(false);
    const [disableBoxMode, setDisableBoxMode] = useState<boolean>(false);

    useEffect(() => {
        fetchBox(cubeId, boxNumber);
    }, [setBox]);

    const getTenantId = () => {
        const tenant = tenantUserStore.tenant;
        if (!tenant) {
            throw new Error('No tenant selected');
        }
        return tenant.centerId;
    };

    const fetchBox = (cubeId: string, boxNumber: string) => {
        getBox(getTenantId(), cubeId, boxNumber)
            .then(setBox)
            .catch(toggleError);
    };


    const toggleOpenBox = () => {
        setOpenBoxMode(!openBoxMode);
    };

    const toggleEnableBox = () => {
        setEnableBoxMode(!enableBoxMode);
    };

    const toggleDisableBox = () => {
        setDisableBoxMode(!disableBoxMode);
    };

    const handleOpenBox = () => {
        if (box) {
            openBox(getTenantId(), cubeId, box.number)
                .then(() => fetchBox(cubeId, box.number))
                .catch(toggleError);
            toggleOpenBox();
        }
    };

    const enableBox = () => {
        if (box) {
            setBoxState(getTenantId(), cubeId, box.number, { disabled: false })
                .then(() => fetchBox(cubeId, box.number))
                .catch(toggleError);
            toggleEnableBox();
        }
    };

    const disableBox = () => {
        if (box) {
            setBoxState(getTenantId(), cubeId, box.number, { disabled: true })
                .then(() => fetchBox(cubeId, box.number))
                .catch(toggleError);
            toggleDisableBox();
        }
    };

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    const { t } = useLocalization();
    return (
        <span>
            {!box && (
                <Box p={5}>
                    <Loading />
                </Box>
            )}
            {box && (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item style={{ flexGrow: 1 }}>
                                    {showBreadcrumb && <Breadcrumbs>
                                        <CrumbLink href='/cubes'>{t('cubes.plural')}</CrumbLink>
                                        <CrumbLink href={'/cubes/' + cubeId}>{cubeId}</CrumbLink>
                                        <BreadcrumbItem>{box.description} {box.number}</BreadcrumbItem>
                                    </Breadcrumbs>
                                    }
                                    <Box my={1} />
                                    <Typography variant="h4">
                                        {t('boxes.singular')}: {box.description} {box.number}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <CubeBoxActionsComponent disabledBox={box.disabled}
                                        onOpenBox={toggleOpenBox}
                                        onDisableBox={toggleDisableBox}
                                        onEnableBox={toggleEnableBox} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                {box.disabled && (
                                    <Grid item xs={12}>
                                        <Alert severity="warning"
                                            action={<Button size="small" variant="contained" color="primary" onClick={toggleEnableBox}>{t('boxes.enableBox')}</Button>}>
                                            <AlertTitle>{t('boxes.disabled')}</AlertTitle>
                                            {t('boxes.disabledInfo')}
                                        </Alert>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <BoxHeader box={box} />
                                </Grid>
                                <Grid item xs={12}>
                                    {box.occupancies && box.occupancies.length > 0 ?
                                        <OccupancyTable occupancies={box.occupancies} /> :
                                        <Alert severity="info">{t('boxes.noOccupancies')}</Alert>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {openBoxMode && (
                        <ConfirmDialog
                            title={t('boxes.openBox')}
                            message={(box.occupancies && box.occupancies.length > 0) ? t('boxes.openBoxInfoOccupied', { boxDescription: box.description }) : t('boxes.openBoxInfoEmpty', { boxDescription: box.description })}
                            confirmLabel={t('boxes.openBox')}
                            resolve={handleOpenBox}
                            reject={toggleOpenBox}
                        />
                    )}
                    {enableBoxMode && (
                        <ConfirmDialog
                            title={t('boxes.enableBox')}
                            message={t('boxes.enableBoxInfo', { boxDescription: box.description })}
                            confirmLabel={t('boxes.enableBox')}
                            resolve={enableBox}
                            reject={toggleEnableBox}
                        />
                    )}
                    {disableBoxMode && (
                        <ConfirmDialog
                            title={t('boxes.disableBox')}
                            message={t('boxes.disableBoxInfo', { boxDescription: box.description })}
                            confirmLabel={t('boxes.disableBox')}
                            resolve={disableBox}
                            reject={toggleDisableBox}
                        />
                    )}
                </div>
            )}
            {error && (
                <FormattedAlert title={`${error.code}`} info={error.message} />
            )}
        </span>
    );
};

const BoxHeader = ({ box }: { box: CubeBox }) => {
    const { t } = useLocalization();
    return (
        <Paper>
            <Box p={3}>
                <Grid container spacing={3}>
                    <Grid item>
                        <LabeledData label={t('boxes.lockState.label')}>
                            <CubeBoxLockStateComponent lockState={box.lockStatus} />
                        </LabeledData>
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }}>
                        <LabeledData label={t('boxes.available')}>
                            <CubeBoxAvailableComponent available={!box.disabled} />
                        </LabeledData>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

const OccupancyTable = (props: { occupancies: Occupancy[] }) => {
    const { t } = useLocalization();
    return (
        <Paper>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('items.singular')}</TableCell>
                        <TableCell>{t('boxes.occupancy')}</TableCell>
                        <TableCell>{t('rentals.borrower.label')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.occupancies.map((occupancy) => (
                        <OccupancyRow key={occupancy.uuid} occupancy={occupancy} />
                    ))}
                </TableBody>
            </Table>
        </Paper>
    )
};

const OccupancyRow = ({ occupancy }: { occupancy: Occupancy }) => {
    const { t } = useLocalization();
    const colSpan = 3;

    const [item, setItem] = useState<Item | undefined>();
    const [rental, setRental] = useState<Rental | undefined>();

    const [loading, setLoading] = useState<boolean>(false);

    const getTenantId = () => {
        const tenant = tenantUserStore.tenant;
        if (!tenant) {
            throw new Error('No tenant selected');
        }
        return tenant.centerId;
    };

    useEffect(() => {
        setLoading(true);
        if (occupancy.itemUuid) {
            getItem(getTenantId(), occupancy.itemUuid).then(setItem).finally(() => setLoading(false));
        }
        if (occupancy.rentalUuid) {
            getRental(getTenantId(), occupancy.rentalUuid).then(setRental);
        }

    }, [occupancy]);

    const itemLabel = useMemo<string>(() => {
        if (!item) {
            return '...';
        }
        if (item.foreignId) {
            return `${item.name}: ${item.foreignId}`;
        }
        return item.name;
    }, [item]);

    if (loading) {
        return (
            <TableRow>
                <TableCell colSpan={colSpan}>{t('loading')}</TableCell>
            </TableRow>
        );
    }

    if (!item && !rental) {
        return (
            <TableRow>
                <TableCell colSpan={colSpan}>{occupancy.uuid}</TableCell>
            </TableRow>
        );
    }

    return (
        <TableRow>
            <TableCell>
                {item && <Link to={`/items/${item.uuid}`}>{itemLabel}</Link>}
                {!item && <span>{occupancy.itemUuid}</span>}
            </TableCell>
            <TableCell>
                {rental && <Link to={`/rentals/${rental.uuid}`}><TemporalRangeFormat from={rental.from?.toJSDate()} until={rental.until?.toJSDate()} dateStyle="short" timeStyle="short" /></Link>}
            </TableCell>
            <TableCell>
                {rental && <RentalBorrowerName borrower={rental.borrower} />}
            </TableCell>
        </TableRow>
    );


};
