import { createElement } from "react";
import { Rental } from "../../data/types";
import { RentalBorrowerName } from "../rentals/RentalBorrowerName";
import { TemporalRangeFormat } from "@variocube/app-ui";
import { tryParseDateTime } from "../../utils/luxonLocalizer";

export const TicketRentalDisplay = (props: { rental?: Rental }) => {
    const { rental } = props;
    if (!rental) {
        return <span>--</span>;
    }
    return (
        <div>
            <RentalBorrowerName borrower={rental.borrower} /> | <TemporalRangeFormat from={tryParseDateTime(rental.from)} until={tryParseDateTime(rental.until)} dateStyle="short" timeStyle="short" />
        </div>
    )
};
 