import {createElement} from "react";
import {useLocalization} from "../i18n";
import {CircularProgress} from "@mui/material";

export const Loading = (props: { message?: string }) => {
    const {t} = useLocalization();
    return (
        <div>
            <SmallLoading />
            <p style={{ textAlign: 'center' }}>{ props.message || t('loading') }</p>
        </div>
    );
};

interface SmallLoading {
    size?: number;
}

export function SmallLoading(props: SmallLoading) {
    const size = props.size || 40;
    return <CircularProgress size={size} style={{ display: 'block', margin: 'auto' }} />;
}
