import {Alert, Box, Button, Grid, Paper, Typography} from "@mui/material";
import {
	Accessibility,
	BreadcrumbItem,
	Breadcrumbs,
	Checkbox,
	ConfirmDialog,
	SiteAccessibility,
	Tabs, useFlag
} from "@variocube/app-ui";
import {createElement, FormEvent, useCallback, useEffect, useState} from "react";
import {useParams} from "react-router";
import {ApiError} from "../../Api";
import {PushButtonWithMenu} from "../../components/buttons/PushButtonWithMenu";
import {CrumbLink} from "../../components/CrumbLink";
import {ErrorDialog} from "../../components/ErrorDialog";
import {LabeledData} from "../../components/LabeledData";
import {Loading} from "../../components/Loading";
import {CubeStorageItem, getCube, getStorageItem, openAllBoxes, writeStorageItem} from "../../data/cubes";
import {Cube, CubeConfiguration} from "../../data/types";
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer";
import {tenantUserStore} from "../../store/TenantUserStore";
import {BoxesList} from "./BoxesList";
import {CubeConnectedComponent} from "./CubeConnectedComponent";
import {CubeUtilizationComponent} from "./CubeUtilizationComponent";
import {useAsync, useAsyncCallback} from "react-async-hook";
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';

export const CubeDetails = () => {
	const {t} = useLocalization();

	const {cubeId = ""} = useParams<{ cubeId: string }>();
	const [cube, setCube] = useState<Cube>();
	const [openAll, setOpenAll] = useState<boolean>(false);
	const [error, setError] = useState<ApiError>();

	const [tabValue, setTabValue] = useState(0);

	const fetchCube = useCallback(() => {
		getCube(tenantUserStore.getTenantId(), cubeId)
			.then(setCube)
			.catch(setError);
	}, [cubeId]);

	const askConfirmOpenAll = useCallback(() => {
		if (!cube || !cube.connected) {
			alert(t("cubes.openAll.offline"));
		} else {
			setOpenAll(true);
		}
	}, [t, cube]);

	const handleOpenAll = useCallback(async () => {
		return openAllBoxes(tenantUserStore.getTenantId(), cubeId)
			.then(() => {
				setOpenAll(false);
			});
	}, [cubeId]);

	useEffect(() => {
		fetchCube();
	}, []);

	return (
		<RentalAppContainer title={cube?.description ?? t("cubes.singular")}>
			{!cube && (
				<Box p={5}>
					<Loading />
				</Box>
			)}
			{cube && (
				<div>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Breadcrumbs>
								<CrumbLink href="/cubes">{t("cubes.plural")}</CrumbLink>
								<BreadcrumbItem>{cube.description}</BreadcrumbItem>
							</Breadcrumbs>
							<Box my={1} />
							<Grid container spacing={3}>
								<Grid item style={{flexGrow: 1}}>
									<Typography variant="h4">
										{t("cubes.singular")}: {cube.description}
									</Typography>
								</Grid>
								<Grid item>
									<PushButtonWithMenu
										label={t("cubes.openAll.title")}
										onClick={askConfirmOpenAll}
									/>
									<ConfirmDialog
										open={Boolean(openAll && cube)}
										onClose={() => setOpenAll(false)}
										onConfirm={handleOpenAll}
										title={t("cubes.openAll.title")}
										cancel={t("actions.cancel")}
									>
										{t("cubes.openAll.info")}
									</ConfirmDialog>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<CubeHeader cube={cube} />
								</Grid>
								<Grid item xs={12}>
									<Tabs
										value={tabValue}
										onChange={(e, value) => setTabValue(value)}
										items={[
											{label: t("cubes.tab.boxlist")},
											{label: t("cubes.tab.accessibility")},
											{label: t("cubes.tab.configuration")},
										]}
									/>
									{tabValue === 0 && <BoxesList cube={cube} />}
									{tabValue === 1 && <CubeAccessibility cube={cube} />}
									{tabValue === 2 && <CubeConfiguration cubeId={cube.cubeId} />}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			)}
			{error && <ErrorDialog error={error} onClose={() => setError(undefined)} />}
		</RentalAppContainer>
	);
};

function CubeHeader({cube}: { cube: Cube }) {
	const {t} = useLocalization();
	return (
		<Paper>
			<Box p={3}>
				<Grid container spacing={3}>
					<Grid item>
						<LabeledData label={t("cubes.connectionState")}>
							<CubeConnectedComponent connected={cube.connected} />
						</LabeledData>
					</Grid>
					<Grid item style={{flexGrow: 1}}>
						<LabeledData label={t("cubes.utilization.label")}>
							<CubeUtilizationComponent utilization={cube.utilization} />
						</LabeledData>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
}

function CubeAccessibility({cube}: { cube: Cube }) {
	const defaultAccessibility: SiteAccessibility = {
		alwaysAccessible: true,
		weekdayAccessibleHours: [],
		accessDescription: "",
		externalAccess: "None",
	};
	const {t} = useLocalization();
	const [loading, setLoading] = useState(false);
	const [accesibility, setAccessibility] = useState<SiteAccessibility>(defaultAccessibility);

	const onSave = (cubeAccessibility: SiteAccessibility) => {
		setAccessibility(cubeAccessibility);

		const itemToStore: CubeStorageItem = {
			cubeId: cube.cubeId,
			key: "accessibility",
			value: JSON.stringify(cubeAccessibility),
		};

		setLoading(true);
		writeStorageItem(tenantId, cube.cubeId, itemToStore)
			.then(() => {
			}).finally(
				() => {
					setLoading(false);
				},
			);
	};

	const tenantId = tenantUserStore.getTenantId();

	useEffect(() => {
		setLoading(true);
		getStorageItem(tenantId, cube.cubeId, "accessibility")
			.then((item) => {
				setAccessibility(JSON.parse(item.value));
			}).finally(
				() => {
					setLoading(false);
				},
			);
	}, [cube]);

	return (
		<Paper>
			<Accessibility result={accesibility} enabled={true} loading={loading} onSave={onSave} t={t} />
		</Paper>
	);
}

const CUBE_CONFIG_KEY = "configuration";

function CubeConfiguration({cubeId}: { cubeId: string }) {
	const {t} = useLocalization();
	const {result: config} = useAsync(() => getStorageItem(tenantUserStore.getTenantId(), cubeId, CUBE_CONFIG_KEY), [cubeId]);
	const {execute, loading, error} = useAsyncCallback((item: CubeStorageItem) => writeStorageItem(tenantUserStore.getTenantId(), cubeId, item));

	const [saved, setSaved, clearSaved] = useFlag(false);

	const [headless, setHeadless] = useState(false);

	useEffect(() => {
		if (config !== undefined) {
			try {
				const {headless} = JSON.parse(config.value) as CubeConfiguration;
				setHeadless(headless);
			} catch (err) {
				console.error(err);
			}
		}
	}, [config]);

	function handleSubmit(ev: FormEvent) {
		ev.preventDefault();

		execute({
			cubeId,
			key: CUBE_CONFIG_KEY,
			value: JSON.stringify({
				headless
			})
		})
			.catch(console.error);

		setSaved();
	}

	return (
		<Paper>
			<Box
				component="form"
				onChange={clearSaved}
				onSubmit={handleSubmit}
				sx={{
					p: 2,
					width: '100%',
					overflow: 'hidden',
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Checkbox label="Headless Locker" value={headless} onChange={setHeadless}/>
					</Grid>
					{error && (
						<Grid item xs={12}>
							<Alert severity="error">{error.message ?? "Error"}</Alert>
						</Grid>
					)}
					<Grid item xs={12}>
						<Button
							type="submit"
							variant="outlined"
							color={saved ? 'success' : 'primary'}
							startIcon={saved ? <CheckIcon/> : <SaveIcon/>}
							disabled={loading}
						>
							{t('actions.save')}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	)
}
