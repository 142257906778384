import {createElement, useCallback, useEffect, useMemo, useState} from "react";
import {DateTime} from "luxon";
import {
    Box, Breadcrumbs, Button,
    Container,
    Grid,
    Hidden, LinearProgress,
    MobileStepper,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
import {tenantUserStore} from "../../store/TenantUserStore";
import {ApiError} from "../../Api";
import {luxonDate} from "../../tools";
import {
    CreateRentalBorrower,
    CreateRentalRequest, CreateRentalServiceItem,
    Item,
    RentalAlternativeTimeframe,
    RentalAvailabilityCheckResponse, UserItemAccessRequest
} from "../../data/types";
import {createRental, verifyItemUserAccess} from "../../data/rentals";
import {getItem, listItems} from "../../data/items";
import {itemsPaging} from "../../data/pagings";
import {DateComponent, Display} from "../../components/DateComponent";
import {ErrorDialog} from "../../components/ErrorDialog";
import {CreateWizardBorrower} from "./CreateWizardBorrower";
import {CreateWizardDateRange} from "./CreateWizardDateRange";
import {CreateWizardItemSelector} from "./CreateWizardItemSelector";
import {CreateWizardLenderAndServiceItem} from "./CreateWizardLenderAndServiceItem";
import {CreateWizardSummary} from "./CreateWizardSummary";
import {useLocalization} from "../../i18n";
import {KeyboardArrowLeftIcon, KeyboardArrowRightIcon} from "../../theme";
import {RentalAppContainer} from "../../RentalAppContainer";
import {BreadcrumbItem} from "@variocube/app-ui";
import { CrumbLink } from "../../components/CrumbLink";
import {useNavigate} from "react-router-dom";

enum WizardStep {
    DateRange,
    Item,
    Borrower,
    LenderAndServiceItem,
    Summary
}

const steps = 5;

export const RentalCreateByUserWizard = () => {
	const {t} = useLocalization();
	const navigate = useNavigate();

    const [item, setItem] = useState<Item>();
    const [itemAccess, setItemAccess] = useState<boolean | undefined>();
    const [rentalRequest, setRentalRequest] = useState<CreateRentalRequest>();
    const [costCenterUuid, setCostCenterUuid] = useState<string|null>(null);
    const [availability, setAvailability] = useState<RentalAvailabilityCheckResponse>();
    const [activeStep, setActiveStep] = useState(0);
    const [canCreateRental, setCanCreateRental] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<ApiError>();

    useEffect(() => {
        const request: CreateRentalRequest = {
            from: DateTime.local().startOf('day').plus({hours: 8}),
            until: DateTime.local().startOf('day').plus({hours: 22}),
            timezone: '',
            itemUuid: '',
            foreignId: '',
            borrower: {
                name: '',
                email: '',
                phone: '',
                accessKey: '',
				nfcToken: '',
                rentalEndsAfterReturn: tenantUserStore.tenant ? tenantUserStore.tenant.rentalEndsAfterReturn : true,
                notifyOnCreation: true
            },
            serviceItem: null,
            borrowerUuid: null,
            costCenterUuid: null
        };

		const params = new URLSearchParams(location.search);

		let from = params.get('from');
		let until = params.get('until');
        if (from) request.from = luxonDate(from);
        if (until) request.until = luxonDate(until);

		console.log('request', request)

		setRentalRequest(request);

		let itemUuid = params.get('itemUuid');
		if(itemUuid) {
            getItem(tenantUserStore.getTenantId(), itemUuid)
                .then(handleItemSelection)
                .catch(setError);
        }

        listItems(tenantUserStore.getTenantId(), itemsPaging)
            .then(items => setCanCreateRental(items.content.length > 0))
            .catch(setError);
    }, []);

    const handleItemSelection = useCallback((item?: Item) => {
		setItem(item);
		setItemAccess(undefined);
		const tenantId = tenantUserStore.getTenantId();
		if(item && tenantId) {
			if(rentalRequest && rentalRequest.borrowerUuid) {
				const accessRequest: UserItemAccessRequest = {
					itemUuid: item.uuid,
					borrowerUuid: rentalRequest.borrowerUuid
				};
				verifyItemUserAccess(tenantId, accessRequest)
					.then((result) => {
						setItemAccess(!result.blacklisted);
					});
			}else {
				setItemAccess(true);
			}
		}
	}, [rentalRequest]);

    const handleDateTimeChange = (from: DateTime, until: DateTime) => {
        if (rentalRequest) {
            setRentalRequest({
                ...rentalRequest,
                from,
                until
            });
        }
    };

    const handleAvailabilityChange = (availability?: RentalAvailabilityCheckResponse) => {
        setAvailability(availability);
    };

    const handleTimeframeChange = (timeframe: RentalAlternativeTimeframe) => {
        if (rentalRequest) {
            setRentalRequest({
                ...rentalRequest,
                from: timeframe.from,
                until: timeframe.until
            });
        }
    };

    const handleBorrowerChange = (borrower: CreateRentalBorrower, borrowerUuid: string|null) => {
        if (rentalRequest) {
            setRentalRequest({
                ...rentalRequest,
                borrower,
                borrowerUuid
            });
        }
    };

    const handleServiceItemChange = (serviceItem: CreateRentalServiceItem|null) => {
        if (rentalRequest) {
            setRentalRequest({
                ...rentalRequest,
                serviceItem
            });
        }
    };

    const handleCostCenterUuidChange = (costCenterUuid: string|null) => {
        setCostCenterUuid(costCenterUuid);
    };

    const generateStepLabel = (activeStep: number) => {
        switch (activeStep) {
            case WizardStep.Item:
                return t('items.singular');
            case WizardStep.DateRange:
                return t('rentals.create.dateRange');
            case WizardStep.Borrower:
                return t('rentals.borrower.label');
            case WizardStep.LenderAndServiceItem:
                return t('rentals.serviceItem.name');
            case WizardStep.Summary:
                return t('rentals.create.summary');
        }
        throw new Error(`Invalid step ${activeStep}`);
    };

    const allowNext = useMemo(() => {
        if (!rentalRequest) {
            return false;
        }
        if (activeStep >= steps-1) {
            return false;
        }
        switch (activeStep) {
            case WizardStep.Item:
                return item != undefined;
            case WizardStep.DateRange:
                return item === undefined || (rentalRequest.from < rentalRequest.until && availability?.available === true);
            case WizardStep.Borrower:
                const tenant = tenantUserStore.tenant;
                return (tenant && tenant.enabledCostCenter) ? costCenterUuid != null : true;
            case WizardStep.LenderAndServiceItem:
                return true;
            case WizardStep.Summary:
                return true;
        }
        throw new Error(`Invalid step ${activeStep}`);
    }, [rentalRequest, costCenterUuid, activeStep, steps, availability, item])

    const renderStep = () => {
        if (!rentalRequest) {
            return <div/>;
        }
        switch (activeStep) {
            case WizardStep.Item:
                return <CreateWizardItemSelector canCreateRental={canCreateRental}
                                                 item={item}
                                                 from={rentalRequest.from} until={rentalRequest.until}
                                                 onItemSelect={handleItemSelection} />;
            case WizardStep.DateRange:
                return <CreateWizardDateRange item={item as any} from={rentalRequest.from} until={rentalRequest.until}
                                                        onDateTimeChange={handleDateTimeChange} onAvailabilityChange={handleAvailabilityChange}
                                                        onAlternativeItemSelect={handleItemSelection} onAlternativeTimeframeChange={handleTimeframeChange} />;
            case WizardStep.Borrower:
                return (item) && <CreateWizardBorrower item={item} borrower={rentalRequest.borrower} onBorrowerChange={handleBorrowerChange}
                                                       borrowerUuid={rentalRequest.borrowerUuid} costCenterUuid={costCenterUuid}
                                                       onCostCenterUuidChange={handleCostCenterUuidChange}
                />;
            case WizardStep.LenderAndServiceItem:
                return <CreateWizardLenderAndServiceItem serviceItem={rentalRequest.serviceItem} onServiceItemChange={handleServiceItemChange}/>;
            case WizardStep.Summary:
                return (item && itemAccess != undefined) && <CreateWizardSummary item={item} itemAccess={itemAccess} rentalRequest={rentalRequest}/>;
        }
        throw new Error(`Invalid step ${activeStep}`);
    };

    const create = () => {
        if (rentalRequest && item) {
            setSubmitting(true);
            createRental(tenantUserStore.getTenantId(), {
                ...rentalRequest,
                costCenterUuid,
                itemUuid: item.uuid
            })
                .then(rental => navigate(`/rentals/${rental.uuid}`))
                .catch(e => {
                    setSubmitting(false);
                    setError(e);
                });
        }
    };

    return (
        <RentalAppContainer title={t('rentals.create.title')}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
					<Breadcrumbs>
						<CrumbLink href='/rentals'>{t('rentals.plural')}</CrumbLink>
						<BreadcrumbItem>{t('rentals.create.title')}</BreadcrumbItem>
					</Breadcrumbs>
					<Box my={1} />
                    <Typography variant="h4" gutterBottom>{t('rentals.create.title')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {rentalRequest && (
                        <Paper>
                            <Hidden smDown>
								<Box pt={3} />
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    <Step key="dateRange">
                                        <StepLabel>
                                            <div>
                                                <label>{t('rentals.create.dateRange')}</label>
                                            </div>
                                                <div style={{fontWeight: 'lighter'}}>
                                                    <small>
                                                        <DateComponent date={rentalRequest.from} display={Display.Timestamp} />
                                                        {rentalRequest.until.hasSame(rentalRequest.from, 'day') ? '-' : <br />}
                                                        <DateComponent date={rentalRequest.until} display={rentalRequest.until.hasSame(rentalRequest.from, 'day') ? Display.Time : Display.Timestamp} />
                                                    </small>
                                                </div>
                                        </StepLabel>
                                    </Step>

                                    <Step key="item">
                                        <StepLabel>
                                            <div>
                                                <label>{t('items.singular')}</label>
                                            </div>
                                            {(item && activeStep > 0) && (
                                                <div style={{fontWeight: 'lighter'}}><small>{item.name}</small></div>
                                            )}
                                        </StepLabel>
                                    </Step>

                                    <Step key="borrower">
                                        <StepLabel>
                                            <div>
                                                <label>{t('rentals.borrower.label')}</label>
                                            </div>
                                            {(rentalRequest.borrower.name && activeStep > 2) && (
                                                <div style={{fontWeight: 'lighter'}}><small>{rentalRequest.borrower.name}</small></div>
                                            )}
                                        </StepLabel>
                                    </Step>
                                    <Step key="lenderAndServiceItem">
                                        <StepLabel>
                                            <div>
                                                <label>{t('rentals.serviceItem.name')}</label>
                                            </div>
                                            {(rentalRequest.serviceItem && rentalRequest.serviceItem.name && activeStep > 3) && (
                                                <div style={{fontWeight: 'lighter'}}><small>{rentalRequest.serviceItem.name}</small></div>
                                            )}
                                        </StepLabel>
                                    </Step>
                                    <Step key="summary">
                                        <StepLabel>{t('rentals.create.summary')}</StepLabel>
                                    </Step>
                                </Stepper>
                            </Hidden>
                            <Hidden mdUp>
                                <Box p={2}>
                                    <Typography align="center">{generateStepLabel(activeStep)}</Typography>
                                </Box>
                            </Hidden>
                            <Container maxWidth="md" style={{minHeight: 450}}>
                                <Box p={2} />
                                {renderStep()}
                                <Box p={4} />
                            </Container>
                            <MobileStepper
                                steps={steps}
                                position="static"
                                variant="text"
                                activeStep={activeStep}
                                nextButton={
                                    activeStep != steps -1 ?
                                        <Button size="small" disabled={!allowNext} onClick={() => setActiveStep(activeStep+1)}>
                                            {t('actions.next')}
                                            <KeyboardArrowRightIcon />
                                        </Button> :
                                        <Button size="small" variant="contained" color="primary" onClick={() => create()} disabled={submitting}>
                                            {t('actions.create')}
                                            <KeyboardArrowRightIcon />
                                        </Button>
                                }
                                backButton={
                                    <Button size="small" disabled={activeStep == 0 || submitting} onClick={() => setActiveStep(activeStep-1)}>
                                        <KeyboardArrowLeftIcon />
                                        {t('actions.back')}
                                    </Button>
                                }
                            />
                            {submitting && <LinearProgress variant="indeterminate" />}
                        </Paper>
                    )}
                </Grid>
            </Grid>
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    );
}
