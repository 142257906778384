import {apiV2} from "./api";
import {createQueryString, Page, SpringPageable} from "@variocube/app-ui";

export interface CustomField {
	id: number;
	name: string;
	type: CustomFieldType;
	slug: string;
	required: boolean;
	centerId: string;
	categoryUuid?: string;
	categoryName?: string;
}

export enum CustomFieldType {
	STRING = 'STRING',
	MILEAGE = 'MILEAGE',
}

export type CustomFieldRequest = Omit<CustomField, 'id'|'centerId'|'categoryName'>;

export const customFieldApi = {
	list(centerId: string, pageable: SpringPageable) {
		return apiV2.get<Page<CustomField>>(`/tenants/${centerId}/custom-fields?${createQueryString(pageable)}`)
	},
	async create(centerId: string, request: CustomFieldRequest) {
		return apiV2.post(`/tenants/${centerId}/custom-fields`, request)
			.then(async r => await r.json() as CustomField)
	},
	async update(centerId: string, id: number, request: CustomFieldRequest) {
		await apiV2.put(`/tenants/${centerId}/custom-fields/${id}`, request)
	},
	async del(centerId: string, id: number) {
		await apiV2.del(`/tenants/${centerId}/custom-fields/${id}`);
	}
}
