import {DateTime} from "luxon";
import {Instant, Now, PlainDate, PlainDateTime} from "@variocube/app-ui";

export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone; 

export function luxonDate(date: any): DateTime {
    if(!date) {
        return DateTime.local();
    } else if(typeof date === "string") {
        return DateTime.fromISO(date);
    } else if(date instanceof DateTime) {
        return date;
    } else if(typeof date === "number") {
        return DateTime.fromMillis(date);
    } else if(date instanceof Date) {
        return DateTime.fromJSDate(date);
    } else if (date instanceof PlainDateTime) {
		return DateTime.fromISO(date.round('minutes').toZonedDateTime(TIMEZONE).toInstant().toString());
	} else if (date instanceof PlainDate) {
		return DateTime.fromISO(date.toString());
	}
    throw Error('Date was in a not supported type')
}

export function instantToPlainDatetime(datetime: any) {
	return Instant.from(luxonDate(datetime).toJSDate().toISOString()).toZonedDateTimeISO(TIMEZONE).toPlainDateTime();
}

export function plainDateTimeNow() {
	return Now.plainDateTimeISO(TIMEZONE);
}

export function convertPlainDateTime(plainDateTime: PlainDateTime | null | undefined) {
	if (plainDateTime) {
		return plainDateTime.round('minutes').toZonedDateTime(TIMEZONE).toInstant().toString();
	}
}

export function convertInstantString(instantString: string | undefined) {
	if (instantString) {
		try {
			return Instant.from(instantString).toZonedDateTimeISO(TIMEZONE).toPlainDateTime();
		}
		catch (error) {
			console.error("Failed to read instant string: " + instantString, error);
		}
	}
	return null;
}

export function pad(num: number, size: number) {
    var s = "000000000" + num;
    return s.substr(s.length-size);
}

export function initials(name: string, length?: number) {
    if(!length) {
        length = 2;
    }
    return name.toUpperCase().split(" ").map((n) => n[0]).join("").substr(0, length);
}

export function maxLen(value: string, maxLen: number, end?: string): string {
    if(!value) {
        return '';
    }
    if(value.length <= maxLen) {
        return value;
    }
    if(!end) { end = ' ...' ; }
    const keep = value.substr(0, maxLen - end.length);
    return `${keep}${end}`;
}

export function getQueryValue(name: string) {
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function isHttpUrl(url: string): boolean {
    const re = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    return re.test(url);
}

export function isImgUrl(url: string): boolean {
    return url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gmi) != null;
}
