import {useLocalization} from "../i18n";
import {useAsync} from "react-async-hook";
import {tenantUserStore} from "../store/TenantUserStore";
import {Selector} from "@variocube/app-ui";
import {createElement, useMemo} from "react";
import {modelApi} from "../data/models";

interface ModelSelectionProps {
	value: string;
	onChange: (value: string) => void;
}

export function ModelSelection({value, onChange}: ModelSelectionProps) {
	const {t} = useLocalization();

	const {result} = useAsync(() => modelApi.listOptions(tenantUserStore.getTenantId()), [])

	return (
		<Selector
			label={t("models.singular")}
			value={value}
			onChange={onChange}
			options={[
				{label: t("common.none"), value: ""},
				...(result ?? []).map(m => ({label: m.name, value: m.uuid})),
			]}
		/>
	)
}
