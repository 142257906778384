// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` 
    .card-media {
        object-fit: contain;
        padding: 0.5em;
        height: 16em;
    }
    
    .card-title {
        padding-top: 0.15em;
    }
    
    .card-content {
        //background-color: theme.palette.background.default
    }
    
    .product-description { 
        mask-image: linear-gradient(to bottom, red 50%, transparent 100%);
        overflow-y: hidden;
        height: 8em; 
    } `, "",{"version":3,"sources":["webpack://./src/main/typescript/assets/css/model.css"],"names":[],"mappings":";IACI;QACI,mBAAmB;QACnB,cAAc;QACd,YAAY;IAChB;;IAEA;QACI,mBAAmB;IACvB;;IAEA;QACI;IACJ;;IAEA;QACI,iEAAiE;QACjE,kBAAkB;QAClB,WAAW;IACf","sourcesContent":[" \r\n    .card-media {\r\n        object-fit: contain;\r\n        padding: 0.5em;\r\n        height: 16em;\r\n    }\r\n    \r\n    .card-title {\r\n        padding-top: 0.15em;\r\n    }\r\n    \r\n    .card-content {\r\n        //background-color: theme.palette.background.default\r\n    }\r\n    \r\n    .product-description { \r\n        mask-image: linear-gradient(to bottom, red 50%, transparent 100%);\r\n        overflow-y: hidden;\r\n        height: 8em; \r\n    } "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
