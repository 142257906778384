import {DateTime} from "luxon";

const route = '/rentals/create';

export function getRentalTriggerUrlWithItemUuid(itemUuid: string) {
    return `${route}?itemUuid=${itemUuid}`;
}

export function getRentalTriggerUrlWithDateRange(from: DateTime, until: DateTime) {
    return `${route}?from=${from.toJSDate().toISOString()}&until=${until.toJSDate().toISOString()}`;
}

export function getRentalTriggerUrlWithDateRangeAndItem(from: DateTime, until: DateTime, itemUuid: string) {
    return `${route}?from=${from.toJSDate().toISOString()}&until=${until.toJSDate().toISOString()}&itemUuid=${itemUuid}`
}

export function getRentalTriggerUrl() {
    return route;
}