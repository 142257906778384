import {MenuItem} from "@mui/material";
import {createElement} from "react";
import {PushButtonWithMenu} from "../../components/buttons/PushButtonWithMenu";
import {useLocalization} from "../../i18n";

type CubeBoxActionsComponentProps = {
	disabledBox: boolean;
	onOpenBox: () => void;
	onEnableBox: () => void;
	onDisableBox: () => void;
};

export const CubeBoxActionsComponent = (props: CubeBoxActionsComponentProps) => {
	const {t} = useLocalization();
	return (
		<PushButtonWithMenu label={t("boxes.openBox")} onClick={props.onOpenBox}>
			{props.disabledBox
				? <MenuItem key="enableBox" onClick={props.onEnableBox}>{t("boxes.enableBox")}</MenuItem>
				: <MenuItem key="disableBox" onClick={props.onDisableBox}>{t("boxes.disableBox")}</MenuItem>}
		</PushButtonWithMenu>
	);
};
