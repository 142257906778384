import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
} from "@mui/material";
import {PlainDateTime, PlainDateTimePicker, render, Selector} from "@variocube/app-ui";
import {ChangeEvent, createElement, Fragment, useEffect, useState} from "react";
import {RentalFilterData} from "../../data/items";
import {listModels} from "../../data/models";
import {CategoryTree, Model, RentalState} from "../../data/types";
import {useLocalization} from "../../i18n";
import {tenantUserStore} from "../../store/TenantUserStore";
import {CategorySelector} from "../categories";
import { getCategory, listCategoryTrees } from "../../data/categories";

type RentalFilterProps = {
	filterDialog: boolean;
	setFilterDialog: any;
	handleFilter: any;
	handleResetFilter: any;
	handleCancelFilterDialog: any;
	filter: RentalFilterData;
	setFilter: any;
	showState?: boolean;
	allowNotRentedSearch?: boolean;
};

export const RentalFilter = (
	{
		filterDialog,
		setFilterDialog,
		handleFilter,
		handleResetFilter,
		handleCancelFilterDialog,
		filter,
		setFilter,
		showState,
		allowNotRentedSearch,
	}: RentalFilterProps,
) => {
	const {t, e} = useLocalization();
	const [rentalState, setRentalState] = useState<RentalState>(filter.rentalState as any);
	const [needle, setNeedle] = useState<string>(filter.needle as any);
	const [from, setFrom] = useState<PlainDateTime | null | undefined>(filter.from as any);
	const [until, setUntil] = useState<PlainDateTime | null | undefined>(filter.until as any);
	const [dateType, setDateType] = useState<string>();
	const [categoryUuid, setCategoryUuid] = useState<string | undefined>();
	const [category, setCategory] = useState<CategoryTree | undefined>();
	const [modelUuid, setModelUuid] = useState<string | null | undefined>();
	const [model, setModel] = useState<Model| undefined>();
	const [models, setModels] = useState<Model[]>([]);

	const [trees, setTrees] = useState<CategoryTree[]>([]); 
    useEffect(() => {
        listCategoryTrees(tenantUserStore.getTenantId())
            .then(setTrees);
    }, []);
	const handleResetFilterIntern = () => {
		filter = {};
		setRentalState(null as any);
		setNeedle("");
		setFrom(null);
		setUntil(null);
		setCategoryUuid(undefined);
		setModelUuid(null);
		handleResetFilter();
	};
	const handleFilterIntern = () => {
		filter = {rentalState, needle, categoryUuid, category, modelUuid, model};
		if (dateType == "rented") {
			filter = {rentalState, needle, categoryUuid, category, modelUuid, model, from, until};
		} else if (dateType == "available") {
			filter = {rentalState, needle, categoryUuid, category, modelUuid, model, availableFrom: from, availableUntil: until};
		}
		setFilter(filter);
		handleFilter(filter);
	};

	const handleDateType = (event: ChangeEvent<HTMLInputElement>, value: string) => {
		setDateType(value);
	};

	const handleCategoryUuid = (value: string) => {
		setCategoryUuid(value);
		//find category 
		getCategory(tenantUserStore.getTenantId(), value).then(
			cat=>	setCategory(cat)
		);
	};

	const handleModelUuid = (value: string) => {
		setModelUuid(value);
		setModel(models.find(m=>m.uuid == value));
	};

	useEffect(() => {
	}, [filter]);

	useEffect(() => {
		listModels(tenantUserStore.getTenantId())
			.then(page => {
				setModels(page.content);
			});
	}, []);

	return (
		<Dialog open={filterDialog} fullWidth maxWidth="sm" onClose={() => setFilterDialog(false)}>
			<DialogTitle>{t("actions.filter")}</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} sx={{paddingTop: "0.5em"}}>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							fullWidth
							label={t("actions.search")}
							value={needle || ""}
							onChange={v => {
								setNeedle(v.target.value);
							}}
						/>
					</Grid>

					<Grid item md={12} xs={12}>
						<CategorySelector value={categoryUuid} onChange={handleCategoryUuid} />
					</Grid>
					<Grid item md={12} xs={12}>
						<Selector
							label={t("models.singular")}
							value={modelUuid}
							onChange={handleModelUuid}
							options={[
								{label: t("common.none"), value: ""},
								...models.map(m => ({label: m.name, value: m.uuid})),
							]}
						/>
					</Grid>
					{showState
						&& (
							<Grid item xs={12}>
								<Selector
									label={t("common.state")}
									value={rentalState || "ALL"}
									onChange={val => {
										setRentalState(val as any);
									}}
									options={[
										{value: "ALL", label: t("common.all")},
										...Object.values(RentalState).map(val => ({
											value: val,
											label: e("rentals.states", val),
										})),
									]}
								/>
							</Grid>
						)}
					{allowNotRentedSearch
						&& (
							<Grid item xs={12}>
								<RadioGroup row value={dateType} onChange={handleDateType}>
									<FormControlLabel value="all" control={<Radio />} label={t("common.all")} />
									<FormControlLabel
										value="available"
										control={<Radio />}
										label={t("common.available_in_timeframe")}
									/>
									<FormControlLabel
										value="rented"
										control={<Radio />}
										label={t("common.rented_in_timeframe")}
									/>
								</RadioGroup>
							</Grid>
						)}
					<Grid item xs={6}>
						<PlainDateTimePicker
							label={t("common.from")}
							fullWidth
							value={from ? from : null}
							onChange={date => {
								setFrom(date);
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<PlainDateTimePicker
							label={t("common.until")}
							fullWidth
							value={until ? until : null}
							onChange={date => {
								setUntil(date);
							}}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleCancelFilterDialog()}>
					{t("actions.cancel")}
				</Button>
				<Box mx={2} />
				<Button variant="contained" color="primary" onClick={handleResetFilterIntern}>
					{t("actions.reset")}
				</Button>
				<Button variant="contained" color="primary" onClick={handleFilterIntern}>
					{t("actions.filter")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
