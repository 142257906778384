import {AccessibilityTimeframe, SiteAccessibility} from "@variocube/app-ui";
import {createElement, Fragment, useEffect, useMemo, useState} from "react";
import {getStorageItem} from "../../data/cubes";
import {useLocalization} from "../../i18n";
import {tenantUserStore} from "../../store/TenantUserStore";
import {DateTime} from "luxon";
import {Grid, Typography} from "@mui/material";
import {CubeDescriptionDisplay} from "../../components/CubeDescriptionDisplay";

export interface CubeAccessibilityComponentProps {
	cubeId: string;
	onAccessibilityLoaded?: (siteAccessibility: SiteAccessibility) => void;
}

export function CubeAccessibilityComponent(props: CubeAccessibilityComponentProps) {
	const {t} = useLocalization();
	const tenantId = tenantUserStore.getTenantId();
	const [accessibility, setAccessibility] = useState<SiteAccessibility>();
	useEffect(() => {
		getStorageItem(tenantId, props.cubeId, "accessibility")
			.then((item) => {
				if (item.value) {
					setAccessibility(JSON.parse(item.value));
				}
			}).finally(() => {});
	}, [props.cubeId]);

	useEffect(() => {
		if (props.onAccessibilityLoaded && accessibility) {
			props.onAccessibilityLoaded(accessibility);
		}
	}, [accessibility]);

	const isGeneralOpeningTimesSet = useMemo( () => {
		if(accessibility){
			return accessibility.accessibleFrom != undefined && accessibility.accessibleUntil != undefined;
		}
	},[accessibility]);

	const isWeekdayAccessibleHoursSet = useMemo( () => {
		if(accessibility){
			for(const wda of accessibility.weekdayAccessibleHours){
				if(wda.accessibleFrom != undefined || wda.accessibleUntil != undefined) return true;
			}
			return false;
		}
	},[accessibility])

	function getTimeframe(wda: AccessibilityTimeframe, access: SiteAccessibility) {
		const from = wda.accessibleFrom ?? access.accessibleFrom;
		const until = wda.accessibleUntil ?? access.accessibleUntil;
		return [from, until]
			.filter(v => v !== undefined)
			.map(v => {
				const time = (v as string).split('.');
				return DateTime.fromFormat(time[0], 'HH:mm:ss').toFormat('HH:mm')
			})
			.join(' - ');
	}

	function getHeader(){
		return(
			<Grid item xs={12}>
				<Typography variant="body1">
					{t("sites.accessibility.accessibleHours")}{" "}
					<CubeDescriptionDisplay cubeId={props.cubeId} />:
				</Typography>
			</Grid>
		);
	}

	if (accessibility) {
		return (
			<Fragment>
				{accessibility.alwaysAccessible && (
					<div>
						{getHeader()}
						<p>24/7</p>
					</div>
				)}

				{(!accessibility.alwaysAccessible && (isGeneralOpeningTimesSet || isWeekdayAccessibleHoursSet)) && (
					<div>
						{getHeader()}
						<p>
							{accessibility.weekdayAccessibleHours.map((wda) => (
								<div>
									{t(`weekdays.${wda.weekday}` as any) + ": " + getTimeframe(wda, accessibility)}
								</div>
							))}
						</p>
					</div>
				)}
			</Fragment>
		);
	}
	return <Fragment />;
}
