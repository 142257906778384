import {createElement, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {
	Alert,
	AlertTitle,
	Box,
	Button, Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle, Divider,
	Grid,
	Link,
	List,
	ListItem,
	ListItemText,
	MenuItem,
	Paper,
	TextField,
	Typography
} from "@mui/material";
import {Item, Rental, RentalState, Ticket, TicketCreateRequest, TicketType} from "../../data/types";
import {ApiError} from "../../Api";
import {deleteRental, endRental, getRental, getRentalTicket, simulateRentalEvent} from "../../data/rentals";
import {tenantUserStore} from "../../store/TenantUserStore";
import {getItem, setItemState} from "../../data/items";
import {createTicket} from "../../data/tickets";
import {Loading} from "../../components/Loading";
import {LabeledData} from "../../components/LabeledData";
import {ErrorDialog} from "../../components/ErrorDialog";
import {EmailDisplay} from "../../components/EmailDisplay";
import {PhoneDisplay} from "../../components/PhoneDisplay";
import {DateComponent} from "../../components/DateComponent";
import {ConfirmDialog} from "../../components/ConfirmDialog";
import {BooleanDisplay} from "../../components/BooleanDisplay";
import {FormattedAlert} from "../../components/FormattedAlert";
import {CompactTicketDisplay} from "../tickets";
import {CompactItemDisplay} from "../items";
import {RentalActions} from "./RentalActions";
import {ProlongRentalDialog} from "./ProlongRentalDialog";
import {RentalStateComponent} from "./RentalStateComponent";
import {CompactCallbackDisplay} from "./CompactCallbackDisplay";
import {useLocalization} from "../../i18n";
import {AssistantIcon, BrokenImageIcon, BuildIcon, HelpIcon} from "../../theme";
import {RentalAppContainer} from "../../RentalAppContainer";
import {BreadcrumbItem, Breadcrumbs, TemporalRangeFormat} from "@variocube/app-ui";
import {CrumbLink} from "../../components/CrumbLink";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";

enum SimulatedEvent {
    LateReturn = "LateReturn",
    Overdue = "Overdue",
}

export const RentalView = () => {
	const navigate = useNavigate();
	const {uuid = ''} = useParams<{ uuid: string }>();

    const [rental, setRental] = useState<Rental>();
    const [item, setItem] = useState<Item>();
    const [deleteMode, setDeleteMode] = useState(false);
    const [endMode, setEndMode] = useState(false);
    const [disableItemMode, setDisableItemMode] = useState(false);
    const [ticket, setTicket] = useState<Ticket>();
    const [ticketForm, setTicketForm] = useState<TicketCreateRequest>();
    const [reportMode, setReportMode] = useState(false);
    const [prolongRental, setProlongRental] = useState(false);
    const [simulateEvent, setSimulateEvent] = useState(false);
    const [simulatedEvent, setSimulatedEvent] = useState<SimulatedEvent>(SimulatedEvent.LateReturn);
    const [error, setError] = useState<ApiError>();

    const isOverdue = useMemo(() => {
        return rental && (rental.state !== RentalState.Created && rental.state !== RentalState.BorrowerReturned) && rental.until.toJSDate().getTime() < new Date().getTime()
    }, [rental])

    const fetch = useCallback(() => {
        if (uuid) {
            getRental(tenantUserStore.getTenantId(), uuid)
                .then(rental => {
                    setRental(rental);
                    getItem(tenantUserStore.getTenantId(), rental.itemUuid)
                        .then(item => {
                            setItem(item);
                            getRentalTicket(tenantUserStore.getTenantId(), uuid)
                                .then(t => {
                                    if (t === null) {
                                        setTicketForm({
                                            type: TicketType.Defective,
                                            itemUuid: item.uuid,
                                            rentalUuid: rental.uuid,
                                            comment: ''
                                        });
                                    } else {
                                        setTicket(t);
                                    }
                                })
                                .catch(setError);
                        })
                        .catch(setError);
                })
                .catch(setError);
        }
    }, [uuid, setRental, setItem, setTicketForm, setTicket]);

    useEffect(() => {
        fetch();
    }, []);

    const toggleProlongRental = () => {
        setProlongRental(!prolongRental);
    };

    const toggleDeleteMode = () => {
        setDeleteMode(!deleteMode);
    };

    const toggleEndMode = () => {
        setEndMode(!endMode);
    }

    const handleDeleteRental = () => {
        if (rental) {
            deleteRental(tenantUserStore.getTenantId(), rental.uuid)
                .then(() => navigate(`/rentals`))
                .catch(setError);
        }
    };

    function handleProlongCancel() {
        toggleProlongRental();
        fetch();
    }

    const handleEndRental = async () => {
        if (rental) {
            try {
                await endRental(tenantUserStore.getTenantId(), rental.uuid);
            } catch (error) {
                setError(error as any);
            }
			fetch();
            toggleEndMode();
        }
    }

    const toggleDisableItemMode = () => {
        setDisableItemMode(!disableItemMode);
    };

    const disableItem = () => {
        if (item) {
            setItemState(tenantUserStore.getTenantId(), item.uuid, { disabled: true })
                .then(i => {
                    setItem(i);
                    toggleDisableItemMode();
                })
                .catch(setError);
        }
    };

    const toggleReportMode = () => {
        setReportMode(!reportMode);
    };

    const onReportFormChange = (event: any) => {
        if (ticketForm) {
            const {name, value} = event.target;
            setTicketForm({
                ...ticketForm,
                [name]: value
            });
        }
    };

    const handleCreateTicket = () => {
        if (ticketForm) {
            createTicket(tenantUserStore.getTenantId(), ticketForm)
                .then(t => navigate('/tickets/' + t.uuid, { replace: true }))
                .catch(setError);
        }
    };

    const changeSimulatedEvent = useCallback((simulatedEvent: any) => {
        if(simulatedEvent) {
            setSimulatedEvent(simulatedEvent);
        }
    }, [setSimulatedEvent]);

    const handleSimulateEvent = useCallback(() => {
        if(rental) {
            simulateRentalEvent(tenantUserStore.getTenantId(), rental.uuid, simulatedEvent)
                .then(() => {
                    setSimulateEvent(false);
                });
        }
    }, [rental, simulatedEvent]);

    const crumb = (rental: Rental) => {
        if(rental.borrower && rental.borrower.name) {
            return `${rental.borrower.name} - ${rental.itemName}`;
        }
        return rental.itemName;
    };

    const canViewAccessCode = !!rental?.borrower.accessCode && (tenantUserStore.staff || tenantUserStore.user?.uuid === rental?.user?.uuid);
    const canControl = !!rental && tenantUserStore.canControlRental(rental);
	const canDelete = rental && (rental.state === RentalState.Created || rental.state === RentalState.RequireApproval);

	const {t, e} = useLocalization();
    return (
        <RentalAppContainer title={rental ? crumb(rental) : t('rentals.singular')}>
            {!rental && (
                <Box p={5}>
                    <Loading/>
                </Box>
            )}
            {rental && (
                <Fragment>
                    <Grid container spacing={3}>
						<Grid item xs={12}>
                            <Grid container>
                                <Grid item style={{flexGrow: 1}}>
									<Breadcrumbs>
										<CrumbLink href='/rentals'>{t('rentals.plural')}</CrumbLink>
										<BreadcrumbItem>{crumb(rental)}</BreadcrumbItem>
									</Breadcrumbs>
									<Box my={1} />
                                    <Typography variant="h4">{
                                        `${t('rentals.singular')}: ${rental.itemName}`
                                    }</Typography>
                                </Grid>
                                {tenantUserStore.staff && (
                                    <Grid item>
                                        <RentalActions
                                            rental={rental} item={item}
                                            onCreateTicket={() => toggleReportMode()}
                                            onDeactivateItem={() => toggleDisableItemMode()}
                                            onProlongRental={() => toggleProlongRental()}
                                            onDeleteRental={() => toggleDeleteMode()}
                                            onEndRental={() => toggleEndMode()}
                                            onSimulateEvent={() => setSimulateEvent(true)}
                                        />
                                    </Grid>
                                )}
                                {(tenantUserStore.borrower && canControl) && (
                                    <Grid item>
                                        <Button sx={{mr:2}} variant="outlined" disabled={!canDelete} onClick={() => setDeleteMode(true)}>{t('rentals.delete.title')}</Button>
                                        <Button variant="outlined" onClick={() => toggleProlongRental()}>{t('rentals.prolong.title')}</Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        {isOverdue && (
                            <Grid item xs={12}>
                                <Alert severity="warning">
                                    <AlertTitle>{t('rentals.overdueTitle')}</AlertTitle>
                                    {t('rentals.overdueMessage')}
                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={2}>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <LabeledData label={t('common.state')}>
                                                <RentalStateComponent rental={rental} />
                                            </LabeledData>
                                        </Grid>
                                        <Grid item>
                                            <LabeledData label={t('rentals.borrower.label')}>
                                                {rental.borrower.name || rental.borrower.email}
                                            </LabeledData>
                                        </Grid>
                                        <Grid item>
                                            <LabeledData label={t('items.singular')}>
                                                <Link style={{cursor: 'pointer'}} onClick={() => navigate(`/items/${rental.itemUuid}`)}>{rental.itemName}</Link>
                                            </LabeledData>
                                        </Grid>
                                        <Grid item>
                                            <LabeledData label={t('rentals.borrower.rentalEndsAfterReturn')}>
                                                <BooleanDisplay value={Boolean(rental.borrower.rentalEndsAfterReturn)} />
                                            </LabeledData>
                                        </Grid>
                                        {rental.borrower.rentalEndsAutomaticallyAfterReturn != undefined &&
                                        <Grid item>
                                            <LabeledData label={t('rentals.borrower.rentalEndsAutomaticallyAfterReturn')}>
                                                {rental.borrower.rentalEndsAutomaticallyAfterReturn}
                                            </LabeledData>
                                        </Grid>
                                        }
                                        {rental.costCenter &&
                                        <Grid item>
                                            <LabeledData label={t('costCenters.singular')}>
                                                {`${rental.costCenter.name}: ${rental.costCenter.description}`}
                                            </LabeledData>
                                        </Grid>
                                        }
                                        <Grid item style={{flexGrow: 1}}>
                                            <LabeledData label={t('common.timeframe')}>
                                                <TemporalRangeFormat from={rental.from?.toJSDate()} until={rental.until?.toJSDate()} dateStyle="short" timeStyle="short" />
                                            </LabeledData>
                                        </Grid>
                                        {(canViewAccessCode) &&
                                        <Grid item>
                                            <LabeledData label={t('rentals.borrower.accessCode')}>
                                                <Typography variant="h5" align="center">{rental.borrower.accessCode}</Typography>
                                            </LabeledData>
                                        </Grid>
                                        }
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{t('rentals.borrower.label')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={2}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={3}>
                                                        {rental.borrower.name &&
                                                        <Grid item>
                                                            <LabeledData label={t('common.name')}>
                                                                {rental.borrower.name}
                                                            </LabeledData>
                                                        </Grid>
                                                        }
                                                        {rental.borrower.email &&
                                                        <Grid item>
                                                            <LabeledData label={t('common.email')}>
                                                                <EmailDisplay email={rental.borrower.email} />
                                                            </LabeledData>
                                                        </Grid>
                                                        }
                                                        {rental.borrower.phone &&
                                                        <Grid item>
                                                            <LabeledData label={t('common.phone')}>
                                                                <PhoneDisplay phone={rental.borrower.phone} />
                                                            </LabeledData>
                                                        </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {rental.borrower && !rental.borrower.canAccess &&
                                                    <FormattedAlert severity="warning" title={t('rentals.borrower.canNotAccess.title')} info={t('rentals.borrower.canNotAccess.info')} />
                                                    }
                                                </Grid>
                                                {canViewAccessCode &&
                                                <Grid item xs={12}>
                                                    <LabeledData label={t('rentals.borrower.accessCode')}>
                                                        <Typography variant="h5">{rental.borrower.accessCode}</Typography>
                                                    </LabeledData>
                                                </Grid>
                                                }
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                                {rental.serviceItem && (
									<Fragment>
										<Grid item xs={12}>
											<Typography variant="h5">{t('rentals.lender.label')}</Typography>
										</Grid>
										<Grid item xs={12}>
											<Paper>
												<Box p={2}>
													<Grid container spacing={3}>
														<Grid item xs={12}>
															{rental.lender && !rental.lender.canAccess &&
																<FormattedAlert severity="warning" title={t('rentals.lender.canNotAccess.title')} info={t('rentals.lender.canNotAccess.info')} />
															}
														</Grid>
														{rental.lender.accessCode &&
															<Grid item xs={12}>
																<LabeledData label={t('rentals.lender.accessCode')}>
																	<Typography variant="h5">{rental.lender.accessCode}</Typography>
																</LabeledData>
															</Grid>
														}
													</Grid>
												</Box>
											</Paper>
										</Grid>
									</Fragment>
								)}
								{rental.customFieldInputs.length > 0 && (
									<Fragment>
										<Grid item xs={12}>
											<Typography variant="h5">{t('settings.fieldsTitle')}</Typography>
										</Grid>
										<Grid item xs={12}>
											<Paper>
												<List>
													{rental.customFieldInputs.map((input, i) => (
														<Fragment>
															{i !== 0 && <Divider />}
															<ListItem key={input.slug}>
																<ListItemText
																	primary={(
																		<Typography variant="overline" color="textSecondary">{input.name} <Chip size="small" label={input.type} /></Typography>
																	)}
																	secondary={(
																		<Typography>{input.value ?? '-'}</Typography>
																	)}

																/>
															</ListItem>
														</Fragment>
													))}
												</List>
											</Paper>
										</Grid>
									</Fragment>
								)}
                            </Grid>
                        </Grid>
                        {item &&
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{t('items.singular')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={2}>
                                            <CompactItemDisplay item={item} />
                                        </Box>
                                    </Paper>
                                </Grid>
                                {rental.serviceItem &&
                                <Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">{t('rentals.serviceItem.name')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper>
                                            <Box p={2}>
                                                <Typography variant="body1">{rental.serviceItem.name}</Typography>
                                                <Typography variant="body2">{rental.serviceItem.description}</Typography>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                </Fragment>
                                }
                                {rental.logs &&
                                <Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">{t('rentals.logs.title')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper>
                                            <List>
                                                {rental.logs.map(log =>
                                                    <ListItem key={log.createdAt.toString()}>
                                                        <ListItemText primary={e('rentals.logs.logType', log.type)} secondary={<DateComponent date={log.createdAt} />} />
                                                    </ListItem>
                                                )}
                                            </List>
                                        </Paper>
                                    </Grid>
                                </Fragment>
                                }
                            </Grid>
                        </Grid>
                        }
                        {ticket &&
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{t('tickets.singular')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={2}>
                                            <CompactTicketDisplay ticket={ticket} />
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                        {(rental.foreignId || rental.callback) &&
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{t('rentals.callback')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={2}>
                                            <CompactCallbackDisplay rental={rental} />
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                    </Grid>
                    <ProlongRentalDialog open={prolongRental} rental={rental} onClose={handleProlongCancel} />
                    {deleteMode && (
                        <ConfirmDialog
                            title={t('rentals.delete.title')}
                            message={t('rentals.delete.body', { itemName: rental.itemName })}
                            resolve={handleDeleteRental}
                            reject={toggleDeleteMode}
                        />
                    )}
                    {endMode && (
                        <ConfirmDialog title={t('rentals.end.title')}
                                       message={t('rentals.end.body', { itemName: rental.itemName })}
                                       resolve={handleEndRental}
                                       reject={toggleEndMode}
                        />
                    )}
                    {disableItemMode && (
                        <ConfirmDialog
                            title={t('items.disable.title')}
                            message={t('items.disable.hint')}
                            resolve={disableItem}
                            reject={toggleDisableItemMode}
                        />
                    )}
                    {ticketForm && reportMode && (
                        <Dialog open={true} onClose={toggleReportMode} fullWidth maxWidth="sm">
                            <DialogTitle>{t('tickets.warningReport')}</DialogTitle>
                            <DialogContent>
                                <TextField
									variant="outlined" fullWidth label={t('common.type')}
                                    name="type" select
                                    value={ticketForm.type}
                                    onChange={onReportFormChange}
                                >
                                    <MenuItem value={TicketType.Defective}>
                                        <BrokenImageIcon fontSize="inherit" style={{ marginRight: 10, top: 2, position: 'relative' }}/>
                                        {t('tickets.types.Defective')}
                                    </MenuItem>
                                    <MenuItem value={TicketType.Lost}>
                                        <HelpIcon fontSize="inherit" style={{ marginRight: 10, top: 2, position: 'relative' }}/>
                                        {t('tickets.types.Lost')}
                                    </MenuItem>
                                    <MenuItem value={TicketType.Maintenance}>
                                        <BuildIcon fontSize="inherit" style={{ marginRight: 10, top: 2, position: 'relative' }}/>
                                        {t('tickets.types.Maintenance')}
                                    </MenuItem>
                                    <MenuItem value={TicketType.Misc}>
                                        <AssistantIcon fontSize="inherit" style={{ marginRight: 10, top: 2, position: 'relative' }}/>
                                        {t('tickets.types.Misc')}
                                    </MenuItem>
                                </TextField>
                                <Box my={2}/>
                                <TextField variant="outlined" fullWidth label={t('tickets.comment')}
                                           name="comment" multiline
                                           minRows={4} maxRows={10}
                                           defaultValue={ticketForm.comment}
                                           onBlur={onReportFormChange}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" onClick={toggleReportMode}>{t('actions.cancel')}</Button>
                                <Button color="primary" variant="contained" onClick={handleCreateTicket}>{t('actions.confirm')}</Button>
                            </DialogActions>
                        </Dialog>
                    )}
                    {ticket && reportMode && (
                        <Dialog open={true} onClose={toggleReportMode} fullWidth maxWidth="sm">
                            <DialogTitle>{t('rentals.ticketExisted.title')}</DialogTitle>
                            <DialogContent>
                                <Typography>{t('rentals.ticketExisted.message')}</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="primary" onClick={toggleReportMode}>OK</Button>
                            </DialogActions>
                        </Dialog>
                    )}
                    <Dialog open={simulateEvent} onClose={() => setSimulateEvent(false)}>
                        <DialogTitle>{t('rentals.simulateEvent.title')}</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">{t('rentals.simulateEvent.message')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={t('rentals.simulateEvent.event')} variant="outlined" fullWidth select
                                               value={simulatedEvent}
                                               onChange={(e) => changeSimulatedEvent(e.target.value)}>
                                        {Object.keys(SimulatedEvent).map((event) =>
                                            <MenuItem key={event} value={event}>{e('rentals.simulateEvent.events', event)}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={() => setSimulateEvent(false)}>{t('actions.cancel')}</Button>
                            <Button variant="contained" color="primary" onClick={() => handleSimulateEvent()}>{t('rentals.simulateEvent.title')}</Button>
                        </DialogActions>
                    </Dialog>
                </Fragment>
            )}
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}
