import {createElement, useMemo, useState} from "react";
import {
    Box, Button,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    Paper,
    Table, TableBody, TableCell,
    TableHead,
    TableRow,
    TextField, useTheme
} from "@mui/material";
import {FileInput} from "./input/FileInput";
import {Loading} from "./Loading";
import Papa from "papaparse";
import {ImportRequest} from "../data/types";
import {useLocalization} from "../i18n";

type CSVImportFormProps = {
    onImportRequest: (request: ImportRequest) => void,
	onClose: () => void,
    onError?: (err: Error) => void,
    inProgress?: boolean,
    allowModel?: boolean,
    allowForeignId?: boolean,
    allowCategory?: boolean
}

export const CSVImportForm = ({onImportRequest, onClose, onError, inProgress, allowModel, allowCategory, allowForeignId}: CSVImportFormProps) => {
    const [columnPos, setColumnPos] = useState<{ [name: string]: number }>({});
    const [file, setFile] = useState<File>();
    const [hasHeader, setHasHeader] = useState(false);
    const [encoding, setEncoding] = useState('UTF-8');
    const [preview, setPreview] = useState<string[][]>();
    const [error, setError] = useState(false);

    const canSubmit = useMemo(() => {
        return (!inProgress && columnPos['name'] !== undefined)
    }, [inProgress, columnPos])

    function handleFileChange(file: File) {
        setFile(file);
        parseCSV(file, encoding, hasHeader);
    }

    function handleHasHeaderChange(value: boolean) {
        setHasHeader(value);
        if (file) {
            parseCSV(file, encoding, value);
        }
    }

    function handleEncodingChange(value: string) {
        setEncoding(value);
        if (file) {
            parseCSV(file, value, hasHeader);
        }
    }

    function parseCSV(file: File, encoding: string, hasHeader: boolean) {
        setColumnPos({});
        setPreview(undefined);
        const offsetAdjustor = hasHeader ? 1 : 0;
        Papa.parse(file, {
            encoding,
            complete: results => {
                console.log('result', results);
                setPreview(results.data.slice(0 + offsetAdjustor, 5 + offsetAdjustor) as any);
            },
            error: error => {
                console.error('error', error);
                if (onError) onError(error);
            }
        });
    }

    function handleFieldPositionChange(name: string, pos: number) {
        const updatedPos = { ...columnPos };
        const currentPos = Object.entries(updatedPos).find(([key, value]) => value === pos);
        if (currentPos) {
            delete updatedPos[currentPos[0]];
        }
        if (name) {
            updatedPos[name] = pos;
        }
        setColumnPos(updatedPos);
    }

    function handleImport() {
        if (file && canSubmit) {
            onImportRequest({
                csvFile: file,
                headerAvailable: hasHeader,
                characterEncoding: encoding,
                columnMapping: columnPos
            });
        }
    }

	const {t} = useLocalization();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper>
                    <Box p={3}>
                        <FileInput label={t('file.select')} onChange={handleFileChange} disabled={inProgress} />
                        <Box my={2} />
                        <FormControlLabel
                            disabled={inProgress}
                            control={<Checkbox checked={hasHeader} onChange={ev => handleHasHeaderChange(ev.target.checked)} />}
                            label={t('csvImport.hasHeader')}
                        />
                        <Box my={2} />
                        <Grid container spacing={3}>
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    select fullWidth
                                    variant="outlined"
                                    label="Encoding"
                                    value={encoding}
                                    onChange={ev => handleEncodingChange(ev.target.value)}
                                    disabled={inProgress}
                                >
                                    <MenuItem value="UTF-8">UTF-8</MenuItem>
                                    <MenuItem value="ISO-8859-1">ISO-8859-1</MenuItem>
                                    <MenuItem value="MacRoman">MacRoman</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
                <Box my={3} />
                <Paper style={{ overflowX: 'auto' }}>
                    {((file && !preview) || inProgress) && (
                        <Box p={2}><Loading message={inProgress ? t('inProgress') : undefined} /></Box>
                    )}
                    {!inProgress && preview && preview.length > 0 && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {preview[0].map((c, i) => (
                                        <TableCell key={'head-' + i}>
                                            <FieldPosSelector
                                                columnPos={columnPos}
                                                position={i}
                                                onChange={handleFieldPositionChange}
                                                allowModel={allowModel}
                                                allowCategory={allowCategory}
                                                allowForeignId={allowForeignId}
                                            />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {preview.map((r, rI) => (
                                    <TableRow key={'row-' + rI}>
                                        {r.map((c, cI) => (
                                            <TableCell key={'col-' + rI + '-' + cI}>{c}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Paper>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Button variant="outlined" disabled={inProgress} onClick={onClose}>{t('actions.cancel')}</Button>
                <Box mx={1} />
                <Button variant="contained" color="primary" disabled={!canSubmit} onClick={handleImport}>{t('actions.import')}</Button>
            </Grid>
        </Grid>
    )
}

type FieldPosSelectorProps = {
    position: number,
    columnPos: { [name: string]: number },
    onChange: (name: string, pos: number) => void,
    allowModel?: boolean,
    allowCategory?: boolean,
    allowForeignId?: boolean
}

const FieldPosSelector = ({position, columnPos, onChange, allowModel, allowCategory, allowForeignId}: FieldPosSelectorProps ) => {
    const [name, setName] = useState('');
    const isNameDisabled = columnPos.hasOwnProperty('name') && columnPos['name'] !== undefined;
    const isDescriptionDisabled = columnPos.hasOwnProperty('description') && columnPos['description'] !== undefined;
    const isModelDisabled = columnPos.hasOwnProperty('model') && columnPos['model'] !== undefined;
    const isCategoryDisabled = columnPos.hasOwnProperty('category') && columnPos['category'] !== undefined;
    const isForeignIdDisabled = columnPos.hasOwnProperty('foreignId') && columnPos['foreignId'] !== undefined;

    function handleChange(name: string) {
        setName(name);
        onChange(name, position);
    }

	const theme = useTheme();
	const {t} = useLocalization();
    return (
        <TextField
            select fullWidth
            label={t('common.field')}
            variant="outlined" size="small"
            value={name}
            onChange={ev => handleChange(ev.target.value)}
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
        >
            <MenuItem value="" style={{ color: theme.palette.text.secondary }}><i>{t('csvImport.notImport')}</i></MenuItem>
            <MenuItem value="name" disabled={isNameDisabled}>{t('common.name')}</MenuItem>
            <MenuItem value="description" disabled={isDescriptionDisabled}>{t('common.description')}</MenuItem>
            {allowModel && <MenuItem value="model" disabled={isModelDisabled}>{t('models.singular')}</MenuItem>}
            {allowCategory && <MenuItem value="category" disabled={isCategoryDisabled}>{t('categories.singular')}</MenuItem>}
            {allowForeignId && <MenuItem value="foreignId" disabled={isForeignIdDisabled}>{t('items.foreignId')}</MenuItem>}
        </TextField>
    )
}
