import {createElement} from "react";
import {Grid} from "@mui/material";
import {Rental} from "../../data/types";
import {LabeledData} from "../../components/LabeledData";
import {useLocalization} from "../../i18n";

export function CompactCallbackDisplay({rental}: { rental: Rental }) {
    const {t} = useLocalization();
    return (
        <Grid container spacing={3}>
            {rental.foreignId &&
            <Grid item>
                <LabeledData label={t('rentals.foreignId')}>
                    {rental.foreignId}
                </LabeledData>
            </Grid>
            }
            {rental.callback && rental.callback.url &&
            <Grid item>
                <LabeledData label={t('rentals.callback')}>
                    <UrlWithMethod method={rental.callback.method} url={rental.callback.url} />
                </LabeledData>
            </Grid>
            }
        </Grid>
    );
}

function UrlWithMethod(props: {method?: string, url: string}) {
    const {method, url} = props;
    if(method) {
        return <span>{`${method.toUpperCase()}: ${url}`}</span>;
    }
    return <span>{url}</span>;
}
