import {createElement, useEffect, useState} from "react";
import {Grid, MenuItem, TextField} from "@mui/material";
import {CostCenter} from "../../data/types";
import {tenantUserStore} from "../../store/TenantUserStore";
import {useLocalization} from "../../i18n";

export const CreateWizardCostCenter = ({onCostCenterChange}: { onCostCenterChange: (costCenter: CostCenter) => void }) => {
    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);

    useEffect(() => {
        const user = tenantUserStore.user;
        if (user) {
            if (user.costCenters.length > 0) {
                setCostCenters(user.costCenters);
            }
        }
    }, [onCostCenterChange]);

    const costCenterChange = (event: any) => {
        const costCenter = costCenters.find(cc => cc.uuid === event.target.value);
        if (costCenter) {
			onCostCenterChange(costCenter);
        }
    };

	const {t} = useLocalization();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField variant="outlined" fullWidth select label={t('costCenters.singular')}
                           size="small"
                           onChange={costCenterChange}>
                    {costCenters.map(cc => (
                        <MenuItem key={cc.uuid} value={cc.uuid}>{[cc.name, cc.description].filter(t => !!t).join(': ')}</MenuItem>
                    ))}
                </TextField>
            </Grid>

        </Grid>
    );
};
