import {createElement} from "react";
import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select} from "@mui/material";
import {CostCenter} from "../data/types";
import {useLocalization} from "../i18n";

interface CostCenterSelectInputProps {
    costCenters: CostCenter[];
    value: string[];
    onChange: (costCenters: []) => void;
}

export function CostCenterSelectInput({costCenters, value, onChange}: CostCenterSelectInputProps) {
    function label(costCenter: CostCenter): string {
		if(costCenter.name && costCenter.description) {
			return `${costCenter.name}: ${costCenter.description}`;
		}
		if(costCenter.name) {
			return costCenter.name;
		}
		return costCenter.uuid;
	}

	const {t} = useLocalization();
    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel>{t('costCenters.plural')}</InputLabel>
            <Select
				multiple label={t('costCenters.plural')}
				value={value}
				onChange={({target}) => onChange(target.value as any)}
				renderValue={(v: any) => v.length + ' ' + t('costCenters.selectCount')}
            >
                {costCenters.map(cc => (
                    <MenuItem key={cc.uuid} value={cc.uuid}>
                        <Checkbox checked={value.indexOf(cc.uuid) > -1} />
                        <ListItemText primary={label(cc)} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
