import {createElement} from "react";
import {Box, Button, Divider, Grid, Hidden, Paper, Typography, Alert} from "@mui/material";
import {Item} from "../../data/types";
import {FittingImageComponent} from "../../components/FittingImageComponent";
import {useLocalization} from "../../i18n";

interface RentalItemSummaryProps {
    item: Item;
    itemAccess: boolean;
    onAction?: () => void;
    actionLabel?: string;
    small?: boolean;
}

export function RentalItemSummary({item, itemAccess, onAction, actionLabel, small}: RentalItemSummaryProps) {
	const {t} = useLocalization();
    return (
        <Paper>
            <Grid container>
                {item.images && item.images.length > 0 && (
                    <Hidden smDown>
                        <Grid item sm={small? 2 : 4}>
                            <Box p={3}>
                                <FittingImageComponent src={item.images[0]} />
                            </Box>
                        </Grid>
                    </Hidden>
                )}
                <Grid item xs={12} sm={small ? 10 : 8}>
                    <Box p={2}>
                        <Grid container spacing={3}>
                            <Grid item style={{flexGrow: 1}}>
                                <Typography variant="h6">{item.name}</Typography>
                                <Typography variant="body1">{item.description}</Typography>
                            </Grid>
                            {onAction && actionLabel &&
                                <Grid item>
                                    <Button variant="outlined" onClick={() => onAction()}>{actionLabel}</Button>
                                </Grid>
                            }
                        </Grid>
                        {!small && item.images && item.images.length > 0 && (
                            <Hidden mdUp>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box p={2}>
                                            <Divider />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <img src={item.images[0]} style={{display: 'block', maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto'}} alt=""/>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        )}
                        <Box my={1} />
                        {!itemAccess && <Alert severity="warning">{t('rentals.borrower.noAccess')}</Alert>}
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
}
