import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {Box, Grid, Paper, TableCell, TableRow, Typography} from "@mui/material";
import {Breadcrumbs, BreadcrumbItem, ColumnType, ContentTable, Page, PagingSettings} from "@variocube/app-ui";
import {ApiError} from "../../Api";
import {tenantUserStore} from "../../store/TenantUserStore";
import {CostCenter} from "../../data/types";
import {costCentersPaging} from "../../data/pagings";
import {listCostCenters} from "../../data/costcenters";
import {AddFab} from "../../components/AddFab";
import {ErrorDialog} from "../../components/ErrorDialog";
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer";
import {useNavigate} from "react-router-dom";

export const CostCenterList = () => {
    const {t} = useLocalization();
	const navigate = useNavigate();

    useEffect(() => {
        setColumns({
            'name': { show: true, name: t('costCenters.singular') },
            'description': { show: true, name: t('common.description'), unsortable: true },
            'manager': { show: true, name: t('costCenters.managers'), unsortable: true }
        })
    }, [t])

    const [page, setPage] = useState<Page<CostCenter>>();
    const [inProgress, setInProgress] = useState(true);
    const [columns, setColumns] = useState<ColumnType>({});
    const [error, setError] = useState<ApiError>();

    const loadPage = useCallback(async () => {
        setInProgress(true);
        try {
            const page = await listCostCenters(tenantUserStore.getTenantId(), costCentersPaging);
            setPage(page);
        } catch (error) {
            costCentersPaging.resetSettings();
            setError(error as any);
        }
        setInProgress(false);
    }, [setPage, setError, setInProgress]);

    useEffect(() => {
        loadPage().then();
    }, []);

    const showCell = (column: keyof typeof columns) => columns[column] && columns[column].show;

    const handlePageChange = (settings: PagingSettings<any>) => {
        costCentersPaging.updateSettings({
            ...costCentersPaging.getSettings(),
            ...settings
        });
        loadPage().then();
    }

    return (
        <RentalAppContainer title={t('costCenters.plural')}>
            <Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{ flexGrow: 1 }}>
                    <Breadcrumbs>
                        <BreadcrumbItem>{t('costCenters.plural')}</BreadcrumbItem>
                    </Breadcrumbs>
                    <Box my={1} />
                    <Typography variant="h4">{t('costCenters.plural')}</Typography>
                </Grid>
            </Grid>
            <Box my={3} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        <ContentTable
                            page={page} pageable={costCentersPaging.getSettings()}
                            onPageableChange={handlePageChange}
                            inProgress={inProgress} columns={columns}
                            onColumnsChange={c => setColumns(c)}
                            renderTableBody={(
                                <Fragment>
                                    {page && page.content.map(i => (
                                        <TableRow key={'item-' + i.uuid} onClick={() => navigate('/cost-centers/' + i.uuid)}>
                                            {showCell('name') && (<TableCell>{i.name}</TableCell>)}
                                            {showCell('description') && (<TableCell>{i.description}</TableCell>)}
                                            {showCell('manager') && (<TableCell>{i.managers ? i.managers.length : 0}</TableCell>)}
                                        </TableRow>
                                    ))}
                                </Fragment>
                            )}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <AddFab onClick={() => navigate('/cost-centers/create')}/>
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}
