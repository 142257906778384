import {createElement, Fragment, useEffect, useState} from "react";
import {
	Box,
	Button, Chip,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Typography,
	Alert, TextField, IconButton, LinearProgress
} from "@mui/material";
import {BreadcrumbItem,  Breadcrumbs, Selector} from "@variocube/app-ui";
import {ApiError} from "../../Api";
import {tenantUserStore} from "../../store/TenantUserStore";
import {listCostCenters} from "../../data/costcenters";
import {CostCenter, User, UserDetails, UserLocale, UserRole} from "../../data/types";
import {approveUser, deleteUser, getUser, updateUser, updateUserAccessCode, UserWrapper} from "../../data/users";
import {CategoryMultiSelector, getCategoryUrl} from "../categories";
import {Loading} from "../../components/Loading";
import {LabeledData} from "../../components/LabeledData";
import {ErrorDialog} from "../../components/ErrorDialog";
import {EmailDisplay} from "../../components/EmailDisplay";
import {ConfirmDialog} from "../../components/ConfirmDialog";
import {BooleanDisplay} from "../../components/BooleanDisplay";
import {CostCenterSelectInput} from "../../components/CostCenterSelectInput";
import {SimpleCheckBox} from "../../components/input/SimpleCheckBox";
import {UserRoleDisplay} from "./UserRoleDisplay";
import {UserStateDisplay} from "./UserStateDisplay";
import {UserLanguageDisplay} from "./UserLanguageDisplay";
import {useLocalization} from "../../i18n";
import {AccountBalanceIcon, CheckCircleIcon, EditIcon} from "../../theme";
import {RentalAppContainer} from "../../RentalAppContainer";
import { CrumbLink } from "../../components/CrumbLink";
import {Replay} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";

export const UserView = () => {
	const navigate = useNavigate();
	const {uuid = ''} = useParams<{ uuid: string }>();

    const [user, setUser] = useState<User>();
    const [userDetails, setUserDetails] = useState<UserDetails>();
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [approveMode, setApproveMode] = useState(false);
    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
	const [refreshingCode, setRefreshingCode] = useState(false);
    const [error, setError] = useState<ApiError>();

    const applyDetails = (user: User) => {
        return JSON.parse(JSON.stringify({
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            costCenterUuids: user.costCenters.map(cc => cc.uuid),
            locale: user.locale,
            whitelists: user.whitelists.map(c => c.uuid),
            blacklists: user.blacklists.map(c => c.uuid),
            accessKey: user.accessKey,
			nfcToken: user.nfcToken,
            inspector: user.inspector,
            receiveEmails: user.receiveEmails
        }));
    };

    useEffect(() => {
        getUser(tenantUserStore.getTenantId(), uuid)
            .then(u => {
                setUser(u);
                setUserDetails(applyDetails(u));
            })
            .catch(setError);
        listCostCenters(tenantUserStore.getTenantId())
            .then(p => setCostCenters(p.content.sort((c1, c2) => c1.name.localeCompare(c2.name))))
            .catch(setError);
    }, [])

    const toggleEditMode = () => {
        setEditMode(!editMode);
        if (user) {
            setUserDetails(applyDetails(user));
        }
    };

    const toggleDeleteMode = () => {
        setDeleteMode(!deleteMode);
    };

    const toggleApproveMode = () => {
        setApproveMode(!approveMode);
    };

    const onInputChange = (value: any, name?: string) => {
        if (name && userDetails) {
			setUserDetails({
                ...userDetails,
                [name]: value
            });
        }
    };

    const save = () => {
        if (userDetails) {
            updateUser(tenantUserStore.getTenantId(), uuid, userDetails)
                .then(u => {
                    setUser(u);
                    toggleEditMode();
                })
                .catch(setError);
        }
    };

    const approve = () => {
        if (user && !user.active) {
            approveUser(tenantUserStore.getTenantId(), user.uuid)
                .then(u => {
                    setUser(u);
                    toggleApproveMode();
                })
                .catch(setError);
        }
    }

    const handleDelete = () => {
        if (user) {
            deleteUser(tenantUserStore.getTenantId(), user.uuid)
                .then(() => navigate('/users', { replace: true }))
                .catch(setError);
        }
    }

	const handleRefreshAccessCode = () => {
		if(user) {
			setRefreshingCode(true);
			updateUserAccessCode(tenantUserStore.getTenantId(), user.uuid)
				.then(u => setUser(u))
				.catch(setError)
				.finally(() => setRefreshingCode(false));
		}
	}

	const {t, e} = useLocalization();
    return (
        <RentalAppContainer title={user ? new UserWrapper(user).displayName : t('users.singular')}>
            {!user && (
                <Box p={5}>
                    <Loading />
                </Box>
            )}
            {user && (
                <Fragment>
					<Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                        <Grid item style={{ flexGrow: 1 }}>
							<Breadcrumbs>
								<CrumbLink href="/users">{t('users.plural')}</CrumbLink>
								<BreadcrumbItem>{new UserWrapper(user).displayName}</BreadcrumbItem>
							</Breadcrumbs>
							<Box my={1} />
                            <Typography variant="h4">{
                                `${t('users.singular')}: ${new UserWrapper(user).displayName}`
                            }</Typography>
                        </Grid>
                        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                            <Button variant="outlined"
                                    startIcon={<EditIcon/>}
                                    onClick={toggleEditMode}>{t('users.edit.title')}</Button>
                            {!user.active && (
                                <Fragment>
                                    <Box mx={1}/>
                                    <Button variant="outlined"
                                            startIcon={<CheckCircleIcon/>}
                                            onClick={toggleApproveMode}>{t('users.approval.title')}</Button>
                                </Fragment>
                            )}
                        </Grid>
                    </Grid>
					<Box my={3} />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {!editMode && (
                                <div>
                                    <Paper>
                                        <Box p={2}>
                                            <Grid container spacing={3}>
                                                <Grid item>
                                                    <LabeledData label={t('common.state')}>
                                                        <UserStateDisplay user={user}/>
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={t('users.role')}>
                                                        <UserRoleDisplay size="medium" role={user.role} /> {user.inspector && (<Chip label={t('users.inspector')} />)}
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={t('users.receiveEmails')}>
                                                        <BooleanDisplay value={user.receiveEmails} />
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item  style={{ flexGrow: 1 }}>
                                                    <LabeledData label="ID">
                                                        {user.username}
                                                    </LabeledData>
                                                </Grid>
                                                {user.accessKey && (
													<Grid item>
														<LabeledData label={t('users.accessKey')}>
															{user.accessKey}
														</LabeledData>
													</Grid>
												)}
                                                {user.nfcToken && (
													<Grid item>
														<LabeledData label="NFC-Token">
															{user.nfcToken}
														</LabeledData>
													</Grid>
												)}
                                                <Grid item>
                                                    <LabeledData label={t('users.accessCode')}>
														<Typography variant="h5" align="center">{user.accessCode}</Typography>
														{refreshingCode && <LinearProgress variant="indeterminate" />}
                                                    </LabeledData>
                                                </Grid>
												<Grid item>
													<Box paddingTop={2}>
														<IconButton onClick={handleRefreshAccessCode} size="small">
															<Replay />
														</IconButton>
													</Box>
												</Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                    <Box my={3}/>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} sm={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5">{t('users.data')}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Paper>
                                                        <Box p={2}>
                                                            <Grid container spacing={3}>
                                                                <Grid item>
                                                                    <LabeledData label={t('common.lastName')}>
                                                                        {user.lastName ?? '-'}
                                                                    </LabeledData>
                                                                </Grid>
                                                                <Grid item>
                                                                    <LabeledData label={t('common.firstName')}>
                                                                        {user.firstName ?? '-'}
                                                                    </LabeledData>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={3}>
                                                                <Grid item>
                                                                    <LabeledData label={t('common.email')}>
                                                                        <EmailDisplay email={user.email} label={user.email} />
                                                                    </LabeledData>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={3}>
                                                                <Grid item>
                                                                    <LabeledData label={t('users.language')}>
                                                                        <UserLanguageDisplay user={user}/>
                                                                    </LabeledData>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                            <Box my={3} />
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5">{t('users.whitelists')}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Paper>
                                                        <List dense>
                                                            {user.whitelists.map(wl => (
                                                                <ListItem key={wl.uuid} button style={{ cursor: 'pointer' }} onClick={() => navigate(getCategoryUrl(wl))}>
                                                                    <ListItemText primary={wl.name} />
                                                                </ListItem>
                                                            ))}
                                                            {user.whitelists.length == 0 && (
                                                                <Box p={3}>
                                                                    <Typography variant="body2" align="center">
                                                                        {t('users.noWhitelist')}
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                        </List>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                            <Box my={3} />
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5">{t('users.blacklists')}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Paper>
                                                        <List dense>
                                                            {user.blacklists.map(bl => (
                                                                <ListItem key={bl.uuid} button style={{ cursor: 'pointer' }} onClick={() => navigate(getCategoryUrl(bl))}>
                                                                    <ListItemText primary={bl.name} />
                                                                </ListItem>
                                                            ))}
                                                            {user.blacklists.length == 0 && (
                                                                <Box p={3}>
                                                                    <Typography variant="body2" align="center">
                                                                        {t('users.noBlacklist')}
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                        </List>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5">{t('costCenters.plural')}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Paper>
                                                        {user.costCenters.length > 0 && (
                                                            <List dense>
                                                                {user.costCenters.map(cc => (
                                                                    <ListItem key={cc.uuid} button style={{ cursor: 'pointer' }} onClick={() => navigate('/cost-centers/' + cc.uuid)}>
                                                                        <ListItemIcon>
                                                                            <AccountBalanceIcon />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={cc.name} secondary={cc.description} />
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                        )}
                                                        {user.costCenters.length == 0 && (
                                                            <Box p={3}>
                                                                <Typography variant="body2" align="center">
                                                                    {t('users.noCostCenters')}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                            {editMode && userDetails && (
                                <div>
                                    <Paper>
                                        <Box p={3}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Selector label={t('users.role')}
                                                              value={userDetails.role} onChange={v => onInputChange(v, 'role')}
                                                              options={Object.values(UserRole).map(r => ({ label: e('users.roles', r), value: r }))}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={3}>
                                                        <Grid item md={6} sm={12}>
                                                            <TextField variant="outlined" fullWidth
																	   label={t('common.lastName')} name="lastName"
                                                                       value={userDetails.lastName} onChange={v => onInputChange(v.target.value, 'lastName')}
                                                            />
                                                        </Grid>
                                                        <Grid item md={6} sm={12}>
                                                            <TextField variant="outlined" fullWidth
																	   label={t('common.firstName')} name="firstName"
                                                                       value={userDetails.firstName} onChange={v => onInputChange(v.target.value, 'firstName')}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Selector label={t('users.language')}
                                                              value={userDetails.locale} onChange={v => onInputChange(v, 'locale')}
                                                              options={[
                                                                  { label: 'English', value: UserLocale.English },
                                                                  { label: 'Deutsch', value: UserLocale.German }
                                                              ]}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <CostCenterSelectInput costCenters={costCenters} value={userDetails.costCenterUuids} onChange={(value) => onInputChange(value, 'costCenterUuids')} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <CategoryMultiSelector label={t('users.whitelists')}
                                                                           helperText={t('users.whitelistsHint')}
                                                                           value={userDetails.whitelists} onChange={uuids => onInputChange(uuids, 'whitelists')} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <CategoryMultiSelector label={t('users.blacklists')}
                                                                           helperText={t('users.blacklistsHint')}
                                                                           value={userDetails.blacklists} onChange={uuids => onInputChange(uuids, 'blacklists')} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField variant="outlined" fullWidth
															   label={t('users.accessKey')} name="accessKey"
                                                               value={userDetails.accessKey} onChange={v => onInputChange(v.target.value, 'accessKey')}                                                 />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
														variant="outlined" fullWidth
														label="NFC-Token" name="nfcToken"
														value={userDetails.nfcToken} onChange={v => onInputChange(v.target.value, 'nfcToken')}
													/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={3}>
                                                        <Grid item>
                                                            <SimpleCheckBox label={t('users.receiveEmails')} checked={userDetails.receiveEmails}
                                                                            onChange={(checked) => onInputChange(checked, 'receiveEmails')} />
                                                        </Grid>
                                                        <Grid item>
                                                            <SimpleCheckBox label={t('users.inspector')} checked={userDetails.inspector}
                                                                            onChange={(checked) => onInputChange(checked, 'inspector')} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Box my={3}/>
                                            <Box display="flex" justifyContent="flex-end">
                                                <Button variant="contained" onClick={toggleEditMode}>{t('actions.cancel')}</Button>
                                                <Box mx={1}/>
                                                <Button variant="contained" color="primary" onClick={save}>{t('actions.save')}</Button>
                                            </Box>
                                        </Box>
                                    </Paper>
                                    <Box my={3}/>
                                    <Paper>
                                        <Alert severity="error">
                                            <Typography variant="subtitle1">{t('users.delete.title')}</Typography>
                                            <Typography variant="body1">{t('users.delete.hint')}</Typography>
                                            <Box my={1}/>
                                            <Button variant="outlined" onClick={toggleDeleteMode}>{t('actions.delete')}</Button>
                                        </Alert>
                                    </Paper>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Fragment>
            )}
            {deleteMode && (
                <ConfirmDialog title={t('users.delete.title')}
                               message={t('users.delete.prompt')}
                               reject={toggleDeleteMode}
                               resolve={handleDelete}/>
            )}
            {approveMode && (
                <ConfirmDialog title={t('users.approval.title')}
                               message={t('users.approval.prompt')}
                               reject={toggleApproveMode}
                               resolve={approve}/>
            )}
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}
