import {
	Alert, Box, Button, DialogActions, DialogContent, FormControl, Grid, InputLabel,
	MenuItem, Select, SelectChangeEvent, Typography
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import {createElement, useEffect, useMemo, useState} from "react";
import { useLocalization } from "../../i18n";
import {Item, Box as CubeBox, ItemStoreRequest, Cube} from "../../data/types";
import { tenantUserStore } from "../../store/TenantUserStore";
import { listCubes, storeItem } from "../../data/cubes";
import { BoxSizeList } from "./BoxSizeList";
import { BoxOperations } from "../cubes/BoxOperations";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {Loading} from "../../components/Loading";

export type ItemStoreDialogProps = {
    item: Item;
    handleClose: () => void;
    handleStore: () => void;
}

export const ItemStoreDialog = ({item, handleClose, handleStore}: ItemStoreDialogProps) => {
	const {t} = useLocalization();

	const [cubeId, setCubeId] = useState('');
	const [boxType, setBoxType] = useState<string>();

	const {loading, error, execute: store} = useAsyncCallback(async () => {
		console.debug('handleStore', item, cube, boxType);
		const {centerId} = tenantUserStore.tenant ?? {};
		if (centerId && cube && boxType) {
			const request: ItemStoreRequest = {
				itemUuid: item.uuid,
				cubeId: cube.cubeId,
				boxType
			};
			await storeItem(centerId, cube.cubeId, request)
			handleStore();
		}
	})

	const {result: cubes} = useAsync(async () => listCubes(tenantUserStore.getTenantId()).then(p => p.content), []);
	const cube = useMemo(() => {
		if (cubes && cubeId) return cubes.find(c => c.cubeId === cubeId);
	}, [cubes, cubeId]);

	useEffect(() => {
		if (cubes && cubes.length === 1) setCubeId((cubes[0] as Cube).cubeId)
	}, [cubes]);

    function handleCubeSelect(event: SelectChangeEvent) {
		console.debug('handleCubeSelect', event);
        setCubeId(event.target.value);
    }

    function handleSelectType(boxType: string, firstBox: CubeBox) {
        console.debug('handleSelectType', boxType, firstBox);
		setBoxType(boxType);
    }

    return (
		<Dialog open={true}>
			<DialogTitle>{t('items.store.title')}</DialogTitle>
			<DialogContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography>{t('items.store.message', { itemName: item.name })}</Typography>
					</Grid>
					<Grid item xs={12}>
						<div
							dangerouslySetInnerHTML={{ __html: t('items.store.instructions') }}
						/>
						<Box my={1} />
						<Alert severity="info">
							<Typography>{t('items.store.info')}</Typography>
						</Alert>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth>
							<InputLabel id="cubeSelectLabelId">{t('items.store.selectcube')}</InputLabel>
							<Select
								labelId="cubeSelectLabelId"
								id="cubeSelectId"
								value={cubeId}
								label="Cube"
								onChange={handleCubeSelect}
							>
								{cubes && cubes.filter(c => c).map(cube => (
									<MenuItem key={cube?.cubeId} value={cube?.cubeId}>{cube?.description}</MenuItem>))}
							</Select>
						</FormControl>
					</Grid>
					<hr />
					<Grid item xs={12}>
						{t('items.store.selectboxsize')}
						{cube && <BoxSizeList cube={cube} onSelectType={handleSelectType} />}
					</Grid>
					<Grid item xs={12}>
						<Button
							disabled={!boxType} variant="contained" color="primary"
							onClick={store}
						>
							{t('items.store.title')}
						</Button>
					</Grid>
					<Grid item xs={12}>
						{item && item.stored?.boxNumber && <BoxOperations cubeId={cubeId} boxNumber={item.stored?.boxNumber} showBreadcrumb={false}></BoxOperations>}
					</Grid>
				</Grid>
				<Grid item xs={12}>
				    {error && <Alert severity="error">{error.message ?? 'Failed to store.'}</Alert>}
				    {loading && <Loading />}
				</Grid>

			</DialogContent>

			<DialogActions>
				<Button variant="outlined" onClick={handleClose}>{t('actions.close')}</Button>
			</DialogActions>
		</Dialog>
	)
}
