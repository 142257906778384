import {Box, Chip, Grid, Paper, TableCell, TableRow, Typography} from "@mui/material";
import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {RentalItemName} from "./RentalItemName";
import {DateComponent} from "../../components/DateComponent";
import {
	BreadcrumbItem,
	
	Breadcrumbs,
	ColumnType,
	ContentTable,
	Page,
	PagingSettings,
    TemporalRangeFormat
} from "@variocube/app-ui";
import {RentalRequest, RentalRequestStatus, User} from "../../data/types";
import {rentalRequestsPaging} from "../../data/pagings";
import {listRentalRequests} from "../../data/rentals";
import {tenantUserStore} from "../../store/TenantUserStore";
import {ApiError} from "../../Api";
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer";
import { CrumbLink } from "../../components/CrumbLink";
import {useNavigate} from "react-router-dom";

export const RentalRequestList = () => {
	const navigate = useNavigate();
	const {t, e} = useLocalization();

	const baseColumns = {
		'status': { show: true, name: t('rentals.requests.status') },
		'borrower.name': { show: true, name: t('rentals.borrower.label') },
		'itemName': { show: true, name: t('items.singular') },
		'from': { show: true, name: t('common.timeframe') },
		'foreignId': { show: true, name: t('rentals.foreignId') },
		'created': { show: true, name: t('common.created') },
	}

    const [page, setPage] = useState<Page<RentalRequest>>();
    const [columns, setColumns] = useState<ColumnType>(baseColumns);
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState<ApiError>();

    const loadPage = useCallback((status?: RentalRequestStatus) => {
        setInProgress(true);
        listRentalRequests(tenantUserStore.getTenantId(), rentalRequestsPaging, status)
            .then(setPage)
            .catch(setError)
            .finally(() => setInProgress(false));
    }, [])

    useEffect(() => {
        loadPage();
    }, [])

    const handlePageChange = (settings: PagingSettings<any>) => {
        rentalRequestsPaging.updateSettings({
            ...rentalRequestsPaging.getSettings(),
            ...settings
        });
        loadPage();
    }

    const showCell = (column: keyof typeof columns) => columns[column] && columns[column].show;

    const renderStatus = (status: RentalRequestStatus) => {
        let color;
        switch (status) {
            case RentalRequestStatus.Approved:
                color = { backgroundColor: '#0F5102', color: '#FFFFFF' };
                break;
            case RentalRequestStatus.Rejected:
                color = { backgroundColor: '#c53636', color: '#FFFFFF' };
                break;
            default:
                color = {};
        }
        const label = e('rentals.requests.statuses', status);
        return <Chip style={color} size="small" label={label} />
    }

    const renderBorrowerName = (borrower: User) => {
        if (!borrower.lastName && !borrower.firstName) return borrower.email;
        return [borrower.lastName, borrower.firstName].filter(s => !!s).join(' ');
    }

    return (
        <RentalAppContainer title={t('rentals.requests.plural')}>
            <Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{ flexGrow: 1 }}>
					<Breadcrumbs>
						<CrumbLink href='/rentals'>{t('rentals.plural')}</CrumbLink>
						<BreadcrumbItem>{t('rentals.requests.plural')}</BreadcrumbItem>
					</Breadcrumbs>
					<Box my={1} />
                    <Typography variant="h4" gutterBottom>{t('rentals.requests.plural')}</Typography>
                </Grid>
            </Grid>
			<Box my={3} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        <ContentTable page={page} pageable={rentalRequestsPaging.getSettings()}
                                      onPageableChange={handlePageChange}
                                      inProgress={inProgress}
                                      columns={columns}
                                      onColumnsChange={c => setColumns(c as any)}
                                      renderTableBody={(
                                          <Fragment>
                                              {page && page.content.map(r => (
                                                  <TableRow key={'rental-' + r.uuid} onClick={() => navigate('/requests/' + r.uuid)}>
                                                      {showCell('status') && (<TableCell>{renderStatus(r.status)}</TableCell>)}
                                                      {showCell('borrower.name') && (<TableCell>{renderBorrowerName(r.borrower)}</TableCell>)}
                                                      {showCell('itemName') && (<TableCell><RentalItemName itemName={r.item.name} serviceItem={r.serviceItem} /></TableCell>)}
                                                      {showCell('from') && (<TableCell><TemporalRangeFormat from={r.from?.toJSDate()} until={r.until?.toJSDate()} dateStyle="short" timeStyle="short" /></TableCell>)}
                                                      {showCell('foreignId') && (<TableCell>{r.foreignId || ''}</TableCell>)}
                                                      {showCell('created') && (<TableCell align="right"><DateComponent date={r.created} /></TableCell>)}
                                                  </TableRow>
                                              ))}
                                          </Fragment>
                                      )}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </RentalAppContainer>
    )
}
