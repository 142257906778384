import {createElement, Fragment, useState} from "react";
import {ImagePopover} from "./ImagePopover";

export function ImageWithPopover({src, alt}: { src: string, alt?: string }) {
	const [open, setOpen] = useState(false);
	return (
		<Fragment>
			<img
				src={src} alt={alt} width="100%" style={{cursor: 'pointer'}}
				onClick={() => setOpen(true)}
			/>
			<ImagePopover open={open} onClose={() => setOpen(false)} src={src} alt={alt} />
		</Fragment>
	)
}
