import {createElement, Fragment, useState} from "react";
import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
	Alert
} from "@mui/material";
import {tenantUserStore} from "../../store/TenantUserStore";
import {importCategories} from "../../data/categories";
import {ImportRequest, ImportResult} from "../../data/types";
import {CSVImportForm} from "../../components/CSVImportForm";
import {useLocalization} from "../../i18n";
import {CheckCircleIcon} from "../../theme";
import {RentalAppContainer} from "../../RentalAppContainer";
import {Breadcrumbs, BreadcrumbItem} from "@variocube/app-ui";
import {CrumbLink} from "../../components/CrumbLink";
import {useNavigate} from "react-router-dom";

export const CategoryImport = () => {
    const navigate = useNavigate();
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState(false);
    const [importResult, setImportResult] = useState<ImportResult>();

    async function handleImport(request: ImportRequest) {
        setError(false);
        setInProgress(true);
        try {
            const result = await importCategories(tenantUserStore.getTenantId(), request)
            setImportResult(result);
        } catch (error) {
            console.error('failed to import data', error)
            setError(true);
        }
        setInProgress(false);
    }

	const {t} = useLocalization();
    return (
        <RentalAppContainer title={t('categories.import.title')}>
            <Grid container spacing={3}>
                <Grid item style={{ flexGrow: 1 }}>
					<Breadcrumbs>
						<CrumbLink href='/categories'>{t('categories.plural')}</CrumbLink>
						<BreadcrumbItem>{t('categories.import.title')}</BreadcrumbItem>
					</Breadcrumbs>
					<Box my={1} />
                    <Typography variant="h4">{t('categories.import.title')}</Typography>
                </Grid>
            </Grid>
			<Box my={3} />
            {!importResult && (
                <Fragment>
                    <CSVImportForm inProgress={inProgress}
								   onClose={() => navigate('/categories')}
                                   onImportRequest={handleImport} />
                    {error && (
                        <Alert severity="error">
                            {t('categories.import.error')}
                        </Alert>
                    )}
                </Fragment>
            )}
            {importResult && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper><Box p={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography style={{ fontSize: '2rem' }}><CheckCircleIcon fontSize="inherit" style={{ color: 'green' }}/></Typography>
                            <Typography>{t('categories.import.success')}</Typography>
                            <Typography>{t('common.total')}: {importResult.totalItems}</Typography>
                            <Typography>{t('common.imported')}: {importResult.importedItems}</Typography>
                            <Typography>{t('common.skipped')}: {importResult.skippedItems}</Typography>
                            <Typography>{t('common.failed')}: {importResult.failedItems.length}</Typography>
                            <Box my={1} />
                            <Button variant="outlined" onClick={() => navigate('/categories')}>{t('actions.close')}</Button>
                        </Box></Paper>
                    </Grid>
                </Grid>
            )}
        </RentalAppContainer>
    )
}
