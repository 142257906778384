import {createElement} from "react";
import {Link} from "@mui/material";

interface EmailDisplayProps {
    email: string;
    label?: string;
}

export function EmailDisplay(props: EmailDisplayProps) {
    const {email} = props;
    const label = props.label || email;
    return (<Link href={`mailto:${email}`}>{label}</Link>);
}
