import {createElement, useEffect, useState} from "react"; 
import {ApiError} from "../../Api";
import {tenantUserStore} from "../../store/TenantUserStore"; 
import {getBox} from "../../data/cubes";
import {Box as CubeBox} from "../../data/types"; 
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer"; 
import {useParams} from "react-router";
import { BoxOperations } from "./BoxOperations";

export const BoxDetails = () => {
    const {
		cubeId = '',
		boxNumber = ''
	} = useParams<{ cubeId: string, boxNumber: string }>();
    const [box, setBox] = useState<CubeBox|undefined>(undefined);
    const [error, setError] = useState<ApiError|undefined>(undefined); 

    useEffect(() => {
        fetchBox(cubeId, boxNumber);
    }, [setBox]);

    const getTenantId = () => {
        const tenant = tenantUserStore.tenant;
        if(!tenant) {
            throw new Error('No tenant selected');
        }
        return tenant.centerId;
    };

    const fetchBox = (cubeId: string, boxNumber: string) => {
        getBox(getTenantId(), cubeId, boxNumber)
            .then(setBox)
            .catch(toggleError);
    };
 
    const toggleError = (error?: ApiError) => {
        setError(error);
    };

	const {t} = useLocalization();
    return (
        <RentalAppContainer title={box?.description ?? t('boxes.singular')}>
             <BoxOperations cubeId={cubeId} boxNumber={boxNumber}></BoxOperations>
        </RentalAppContainer>
    );
};
    