import {User} from "./types";
import {SignUpRequest} from "../pages/signup/types";

export function signup(request: SignUpRequest): Promise<User> {
    return fetch('/api/v1/registration', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    }).then(async (response) => {
        if (!response.ok) {
            const error = await response.json();
            console.error(error);
            throw new Error(error.message);
        }
        return response.json();
    }).catch(error => {
        console.error(error);
        throw new Error(error);
    });
}
