import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {
	Box, Breadcrumbs,
	Button,
	Dialog, DialogActions, DialogContent,
	DialogTitle,
	Divider,
	Grid, MenuItem,
	Paper,
	TextField,
	Typography
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {ApiError} from "../../Api";
import {Comment, CommentCreateRequest, Ticket, TicketSetStateRequest, TicketState} from "../../data/types";
import {deleteTicket, getTicket, getTicketComments, postTicketComment, setTicketState} from "../../data/tickets";
import {tenantUserStore} from "../../store/TenantUserStore";
import {Loading} from "../../components/Loading";
import {LabeledData} from "../../components/LabeledData";
import {ErrorDialog} from "../../components/ErrorDialog";
import {DateComponent} from "../../components/DateComponent";
import {ConfirmDialog} from "../../components/ConfirmDialog";
import {TicketActions} from "./TicketActions";
import {TicketTypeDisplay} from "./TicketTypeDisplay";
import {TicketStateDisplay} from "./TicketStateDisplay";
import {TicketRentalDisplay} from "./TicketRentalDisplay";
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer";
import {BreadcrumbItem} from "@variocube/app-ui";
import {CrumbLink} from "../../components/CrumbLink";
import {useParams} from "react-router";

export const TicketView = () => {
	const navigate = useNavigate();
	const {uuid = ''} = useParams<{ uuid: string }>();

    const [ticket, setTicket] = useState<Ticket>();
    const [comments, setComments] = useState<Comment[]>([]);
    const [stateForm, setStateForm] = useState<TicketSetStateRequest>();
    const [commentForm, setCommentForm] = useState<CommentCreateRequest>({ comment: '' });
    const [deleteMode, setDeleteMode] = useState(false);
    const [stateMode, setStateMode] = useState(false);
    const [error, setError] = useState<ApiError>();

    const fetch = useCallback((uuid: string) => {
        getTicket(tenantUserStore.getTenantId(), uuid)
            .then(t => {
                setTicket(t);
                setStateForm({
                    comment: '',
                    state: t.state
                })
                getTicketComments(tenantUserStore.getTenantId(), uuid)
                    .then(setComments)
                    .catch(setError);
            })
            .catch(setError)
    }, [setTicket, setStateForm, setComments]);

    useEffect(() => fetch(uuid), []);

    const toggleDeleteMode = () => {
        setDeleteMode(!deleteMode);
    };

    const toggleStateMode = () => {
        setStateMode(!stateMode);
    };

    const crumb = (ticket: Ticket) => {
        if(ticket.rental && ticket.rental.borrower && ticket.rental.borrower.name) {
            return `${ticket.rental.borrower.name} - ${ticket.rental.itemName}`;
        }
        return ticket.item.name;
    }

    const onFormChange = (event: any) => {
        const {name, value} = event.target;
        setCommentForm({
            ...commentForm,
            [name]: value
        });
    }

    const onStateFormChange = (event: any) => {
        if (stateForm) {
            const {name, value} = event.target;
            setStateForm({
                ...stateForm,
                [name]: value
            });
        }
    }

    const reply = () => {
        if (ticket && commentForm.comment.length > 0) {
            postTicketComment(tenantUserStore.getTenantId(), ticket.uuid, commentForm)
                .then(c => {
                    setComments(comments.concat(c));
                    setCommentForm({
                        comment: ''
                    });
                })
                .catch(setError);
        }
    };

    const setState = async () => {
        if (ticket && stateForm && stateForm.comment.length > 0) {
            try {
                const t = await setTicketState(tenantUserStore.getTenantId(), ticket.uuid, stateForm);
                fetch(t.uuid);

            } catch (error) {
                setError(error as any);
            }
            toggleStateMode();
        }
    }

    const handleDeleteTicket = () => {
        if (ticket) {
            deleteTicket(tenantUserStore.getTenantId(), ticket.uuid)
                .then(() => navigate('/tickets', { replace: true }))
                .catch(setState);
        }
    }

	const {t} = useLocalization();
    return (
        <RentalAppContainer title={t('tickets.singular')}>
            {!ticket && (
                <Box p={5}>
                    <Loading/>
                </Box>
            )}
            {ticket && (
                <Fragment>
                    <Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                        <Grid item style={{ flexGrow: 1 }}>
							<Breadcrumbs>
								<CrumbLink href="/tickets">{t('tickets.plural')}</CrumbLink>
								<BreadcrumbItem>{crumb(ticket)}</BreadcrumbItem>
							</Breadcrumbs>
							<Box my={1} />
                            <Typography variant="h4">
                                {t('tickets.singular')}: {ticket.item.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {tenantUserStore.admin && (
                                <TicketActions onSetState={toggleStateMode} onDeleteTicket={toggleDeleteMode} />
                            )}
                        </Grid>
                    </Grid>
					<Box my={3} />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <LabeledData label={t('common.type')}>
                                                <TicketTypeDisplay type={ticket.type} />
                                            </LabeledData>
                                        </Grid>
                                        <Grid item>
                                            <LabeledData label={t('common.state')}>
                                                <TicketStateDisplay state={ticket.state} />
                                            </LabeledData>
                                        </Grid>
                                        <Grid item>
                                            <LabeledData label={t('common.created')}>
                                                <DateComponent date={ticket.createdAt} />
                                            </LabeledData>
                                        </Grid>
                                        <Grid item>
                                            <LabeledData label={t('items.singular')}>
                                                <Link to={'/items/' + ticket.item.uuid}>{ticket.item.name}</Link>
                                            </LabeledData>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}>
                                            <LabeledData label={t('rentals.singular')}>
                                                <TicketRentalDisplay rental={ticket.rental}/>
                                            </LabeledData>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Box my={3}/>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">{t('tickets.comment')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper>
                                            <Box p={2}>
                                                {comments.map(c => (
                                                    <Box mb={2} key={c.uuid}>
                                                        <Paper variant="outlined">
                                                            <Box p={1}>
                                                                <Typography variant="overline">{new Date(c.createdAt).toLocaleString()}</Typography>
                                                                <Typography variant="subtitle1"><strong>{c.user ? c.user.email : 'Anonymous'}</strong></Typography>
                                                                <Typography variant="body1">{c.comment}</Typography>
                                                            </Box>
                                                        </Paper>
                                                    </Box>
                                                ))}
                                                {ticket.state === TicketState.Open && (
                                                    <div>
                                                        <Box my={3}><Divider/></Box>
                                                        <TextField label={t('tickets.comment')} variant="outlined" fullWidth multiline rows={2} value={commentForm.comment} onChange={onFormChange} name="comment" required/>
                                                        <Box my={1}/>
                                                        <Button variant="contained" color="primary" onClick={reply} disabled={commentForm.comment.length === 0}>{t('tickets.reply')}</Button>
                                                    </div>
                                                )}
                                            </Box>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {deleteMode && (
                        <ConfirmDialog
                            title={t('tickets.delete.title')}
                            message={t('tickets.delete.prompt')}
                            resolve={handleDeleteTicket}
                            reject={toggleDeleteMode}
                        />
                    )}
                    {stateForm && stateMode && (
                        <Dialog open={true} onClose={toggleStateMode} fullWidth maxWidth="sm">
                            <DialogTitle>{t('tickets.setState.title')}</DialogTitle>
                            <DialogContent>
                                <TextField variant="outlined" fullWidth label={t('common.state')}
                                           name="state" select
                                           value={stateForm.state}
                                           onChange={onStateFormChange}
                                >
                                    <MenuItem value={TicketState.Open}>{t('tickets.states.Open')}</MenuItem>
                                    <MenuItem value={TicketState.Closed}>{t('tickets.states.Closed')}</MenuItem>
                                </TextField>
                                <Box my={2}/>
                                <TextField variant="outlined" fullWidth label={t('tickets.setState.message')}
                                           name="comment" multiline required
                                           minRows={4} maxRows={10}
                                           value={stateForm.comment}
                                           onChange={onStateFormChange}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" onClick={toggleStateMode}>{t('actions.cancel')}</Button>
                                <Button color="primary" variant="contained" disabled={stateForm.comment.length === 0} onClick={setState}>{t('actions.confirm')}</Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Fragment>
            )}
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
		</RentalAppContainer>
    )
}
