import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {Box, Grid, Paper, TableCell, TableRow, Typography} from "@mui/material";
import {Breadcrumbs, BreadcrumbItem, ColumnType, ContentTable, Page, PagingSettings} from "@variocube/app-ui";
import {Cube} from "../../data/types";
import {ApiError} from "../../Api";
import {listCubes} from "../../data/cubes";
import {cubesPaging} from "../../data/pagings";
import {tenantUserStore} from "../../store/TenantUserStore";
import {ErrorDialog} from "../../components/ErrorDialog";
import {CubeConnectedComponent} from "./CubeConnectedComponent";
import {CubeUtilizationComponent} from "./CubeUtilizationComponent";
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer";
import {useNavigate} from "react-router-dom";

export const CubeList = () => {
    const {t} = useLocalization();
	const navigate = useNavigate();

    useEffect(() => {
        setColumns({
            'description': { show: true, unsortable: true, name: t('common.name') },
            'cubeId': { show: true, unsortable: true, name: t('items.foreignId') },
            'connected': { show: true, unsortable: true, align: 'center', name: t('models.singular') },
            'utilization' : { show: true, unsortable: true, name: `${t('cubes.singular')} / ${t('boxes.singular')}` }
        })
    }, [t])

    const [page, setPage] = useState<Page<Cube>>();
    const [error, setError] = useState<ApiError>();
    const [inProgress, setInProgress] = useState(true);
    const [columns, setColumns] = useState<ColumnType>({});

    const loadPage = useCallback(async () => {
        setInProgress(true);
        try {
            const page = await listCubes(tenantUserStore.getTenantId(), cubesPaging);
            setPage(page);
        } catch (error) {
            cubesPaging.resetSettings();
            setError(error as any);
        }
        setInProgress(false);
    }, [setPage, setInProgress, setError]);

    useEffect(() => {
        loadPage().then();
    }, []);

    const showCell = (column: keyof typeof columns) => columns[column] && columns[column].show;

    const handlePageChange = (settings: PagingSettings<any>) => {
        cubesPaging.updateSettings({
            ...cubesPaging.getSettings(),
            ...settings
        });
        loadPage().then();
    }

    return (
        <RentalAppContainer title={t('cubes.plural')}>
            <Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{ flexGrow: 1 }}>
                    <Breadcrumbs>
                        <BreadcrumbItem>{t('cubes.plural')}</BreadcrumbItem>
                    </Breadcrumbs>
                    <Box my={1} />
                    <Typography variant="h4">{t('cubes.plural')}</Typography>
                </Grid>
            </Grid>
            <Box my={3} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        <ContentTable
                            page={page} pageable={cubesPaging.getSettings()}
                            onPageableChange={handlePageChange}
                            inProgress={inProgress} columns={columns}
                            onColumnsChange={setColumns}
                            renderTableBody={(
                                <Fragment>
                                    {page && page.content.map(c => (
                                        <TableRow key={'cube-' + c.cubeId} onClick={() => navigate('/cubes/' + c.cubeId)}>
                                            {showCell('description') && (<TableCell>{c.description}</TableCell>)}
                                            {showCell('cubeId') && (<TableCell>{c.cubeId}</TableCell>)}
                                            {showCell('connected') && (<TableCell align="center"><CubeConnectedComponent connected={c.connected} /></TableCell>)}
                                            {showCell('utilization') && (<TableCell><CubeUtilizationComponent utilization={c.utilization} /></TableCell>)}
                                        </TableRow>
                                    ))}
                                </Fragment>
                            )}
                        />
                    </Paper>
                </Grid>
            </Grid>
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}
