import {createElement} from "react";
import {Chip} from "@mui/material";
import {useLocalization} from "../../i18n";
import {CloudIcon, CloudOffIcon} from "../../theme";

export function CubeConnectedComponent({connected}: { connected: boolean }) {
    const {t} = useLocalization();
    const icon = connected ? <CloudIcon /> : <CloudOffIcon />;
    const label = connected ? t('cubes.connected'): t('cubes.notConnected');
    const color = connected ? '#4caf50' : '#f44336';
    return (
        <Chip label={label} style={{color: '#ffffff', backgroundColor: color}} icon={icon} />
    );
}
