import {tenantUserStore} from "../store/TenantUserStore";
import {createElement, useEffect, useState} from "react";
import {isTenantSignupDomain} from "../data/tenants";
import {Tenant} from "../data/types";
import {Loading} from "../components/Loading";
import {WelcomeScreen} from "./WelcomeScreen";
import {AuthLoginPath, authStorage} from "../Auth";

export function IndexRedirection() {
	const tenant = tenantUserStore.tenant;

	const [checking, setChecking] = useState(true);
	const [signupTenant, setSignupTenant] = useState<Tenant | undefined>();

	useEffect(() => {
		isTenantSignupDomain(window.location.hostname)
			.then((t) => {
				setSignupTenant(t);
			})
			.catch(() => {
				if (!authStorage.authenticated) {
					setTimeout(() => {
						location.href = AuthLoginPath;
					}, 500);
				}
			})
			.finally(() => setChecking(false));
	}, []);

	if (checking) {
		return (<Loading />);
	}

	if (signupTenant) {
		return (<WelcomeScreen tenant={signupTenant} />);
	}

	if (tenant) {
		if (tenant.reducedMenuOptions) {
			location.href = '/items';
		} else {
			location.href = '/rentals';
		}
	} else {
		location.href = '/tenants';
	}

	return (<Loading />);

}
