import {Button, MenuItem} from "@mui/material";
import {createElement, Fragment} from "react";
import {PushButtonWithMenu} from "../../components/buttons/PushButtonWithMenu";
import {Item} from "../../data/types";
import {useLocalization} from "../../i18n";
import {tenantUserStore} from "../../store/TenantUserStore";

type ItemActionsProps = {
	item: Item;
	onCreateRental: () => void;
	onEditItem: () => void;
	onDeleteItem: () => void;
	onStoreItem?: () => void;
	onRemoveItem?: () => void;
};

export const ItemActions = (
	{item, onCreateRental, onEditItem, onDeleteItem, onStoreItem, onRemoveItem}: ItemActionsProps,
) => {
	const {t} = useLocalization();
	return (
		<Fragment>
			{tenantUserStore.staff
				&& (
					<PushButtonWithMenu
						label={t("rentals.create.title")}
						disabled={item.disabled}
						onClick={onCreateRental}
					>
						<MenuItem key="editItem" onClick={onEditItem}>{t("items.edit.title")}</MenuItem>
						<MenuItem key="deleteItem" onClick={onDeleteItem}>{t("items.delete.title")}</MenuItem>
						{onStoreItem && (
							<MenuItem
								key="storeItem"
								disabled={item.disabled || item.stored?.boxNumber != undefined}
								onClick={onStoreItem}
							>
								{t("items.store.title")}
							</MenuItem>
						)}
						{onRemoveItem && (
							<MenuItem key="removeItem" disabled={!item.stored} onClick={onRemoveItem}>
								{t("items.remove.title")}
							</MenuItem>
						)}
					</PushButtonWithMenu>
				)}
			{!tenantUserStore.staff && (
				<Button disabled={item.disabled} onClick={onCreateRental}>{t("rentals.create.title")}</Button>
			)}
		</Fragment>
	);
};
