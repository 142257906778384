import {Box, Hidden, Step, StepLabel, Stepper, Typography} from "@mui/material";
import {createElement, useContext} from "react";
import {useLocalization} from "../../i18n";
import {WizardContext} from "./context";

export function SignupStepper() {
	const {t} = useLocalization();
	const {step} = useContext(WizardContext);

	return (
		<Box sx={{ width: '100%' }}>
			<Hidden smUp>
				<Typography variant="h5" align="center">
					{step === 0 && t('signup.email')}
					{step === 1 && t('signup.phone')}
					{step === 2 && t('signup.details')}
					{step === 3 && t('signup.password')}
				</Typography>
			</Hidden>
			<Hidden smDown>
				<Stepper
					activeStep={step}
					alternativeLabel
				>
					<Step>
						<StepLabel>{t('signup.email')}</StepLabel>
					</Step>
					<Step>
						<StepLabel>{t('signup.phone')}</StepLabel>
					</Step>
					<Step>
						<StepLabel>{t('signup.details')}</StepLabel>
					</Step>
					<Step>
						<StepLabel>{t('signup.password')}</StepLabel>
					</Step>
				</Stepper>
			</Hidden>
		</Box>
	)
}
