import {useLocalization} from "../i18n";
import {useAsync} from "react-async-hook";
import {tenantUserStore} from "../store/TenantUserStore";
import {categoryApi, CategoryOption} from "../data/categories";
import {createElement, useMemo} from "react";
import {MenuItem, TextField} from "@mui/material";

interface CategorySelectionProps {
	value: string;
	onChange: (value: string) => void;
}

type NestedOption = { label: string, value: string, pad: number };

export function CategorySelection({value, onChange}: CategorySelectionProps) {
	const {t} = useLocalization();

	const {result} = useAsync(() => categoryApi.listOptions(tenantUserStore.getTenantId()), [])

	function flatMapOptions(category: CategoryOption, pad: number): NestedOption[] {
		let o = { label: category.name, value: category.uuid, pad };
		let children: NestedOption[] = []
		for (let c of category.children) {
			children.push(...flatMapOptions(c, pad + 1))
		}
		return [
			o,
			...children
		]
	}

	const selectOptions = useMemo(() => {
		let options: NestedOption[] = [];
		if (!!result) {
			for (let c of result) {
				options.push(...flatMapOptions(c, 0))
			}
		}
		return options;
	}, [result])

	return (
		<TextField
			fullWidth
			select
			label={t("categories.singular")}
			value={value ?? ''}
			onChange={ev => onChange(ev.target.value)}
		>
			<MenuItem value="">{t("common.none")}</MenuItem>
			{selectOptions.map(o => (
				<MenuItem
					key={o.value}
					value={o.value}
				>
					<span style={{ paddingLeft: `${o.pad}rem` }}>
						{o.label}
					</span>
				</MenuItem>
			))}
		</TextField>
	)
}
