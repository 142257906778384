import {createElement, useMemo} from "react";
import {
    Accordion, AccordionDetails,
    AccordionSummary, Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography, useTheme
} from "@mui/material";
import {ApiError} from "../Api";
import {useLocalization} from "../i18n";
import {ExpandIcon} from "../theme";
import {LabeledData} from "./LabeledData";

type ErrorDialogProps = {
    error: ApiError,
    onClose: () => void,
    title?: string,
    message?: string
}

export const ErrorDialog = ({error, onClose, title: pTitle, message: m}: ErrorDialogProps) => {
	const theme = useTheme();
	const {t} = useLocalization();

    const title = useMemo(() => {
        if (pTitle) return pTitle;
        switch (error.code) {
            case 404:
                return t('error.notFound.title');
            case 400:
                return t('error.badRequest.title');
            default:
                return t('error.generic.title');
        }
    }, [error]);

    const message = useMemo(() => {
        if (m) return m;
        switch (error.code) {
            case 404:
                return t('error.notFound.message');
            case 400:
                return t('error.badRequest.message');
            default:
                return t('error.generic.message');
        }
    }, [error]);

    return (
        <Dialog open={true} fullWidth maxWidth="md">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography>{message}</Typography>
                <Box my={2}/>
                <Accordion
					sx={{
						margin: '0 !important',
						background: theme.palette.grey[200]
					}}
				>
                    <AccordionSummary
                        expandIcon={<ExpandIcon />}
                    >
                        <Typography>{t('error.details')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails
						sx={{
							display: 'flex',
							flexDirection: 'column',
							maxHeight: 200,
							overflowX: 'scroll'
						}}
					>
                        {error.path && <LabeledData label="Pathname">{error.path}</LabeledData>}
                        {!error.path && <LabeledData label="Current path">{location.pathname}</LabeledData>}
                        <Box my={1}/>
                        <LabeledData label="Stacktrace">
                            <code
								style={{
									fontFamily: 'monospace',
									fontSize: '0.75rem',
									lineHeight: '1.5em',
									display: 'block'
								}}
							>
								{error.message}
							</code>
                        </LabeledData>
                    </AccordionDetails>
                </Accordion>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={onClose}>{t('actions.close')}</Button>
            </DialogActions>
        </Dialog>
    )
}
