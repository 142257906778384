import {createElement, useEffect, useState} from "react";
import {
    Box,
    Button,
    Grid,
    LinearProgress,
    Paper,
    Typography
} from "@mui/material";
import {ItemDetails} from "../../data/types";
import {ApiError} from "../../Api";
import {tenantUserStore} from "../../store/TenantUserStore";
import {createItem} from "../../data/items";
import {ItemForm} from "./ItemForm";
import {ErrorDialog} from "../../components/ErrorDialog";
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer";
import {Breadcrumbs, BreadcrumbItem} from "@variocube/app-ui";
import {CrumbLink} from "../../components/CrumbLink";
import {useNavigate} from "react-router-dom";

const qs = require('qs');

export const ItemCreate = () => {
	const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [details, setDetails] = useState<ItemDetails>();
    const [modelUuid, setModelUuid] = useState<string>();
    const [error, setError] = useState<ApiError>();

    useEffect(() => {
        if (!loaded) {
            const parsed = qs.parse(location.search, {ignoreQueryPrefix: true});
            if (parsed.modelUuid) {
                setModelUuid(parsed.modelUuid);
            }
            setLoaded(true);
        }

    }, []);

    const create = async () => {
        if (details) {
            setProcessing(true);
            try {
                const item = await createItem(tenantUserStore.getTenantId(), details);
                navigate(`/items/${item.uuid}`)
                return;
            } catch (error) {
                setError(error as any);
            }
            setProcessing(false);
        }
    };

	const {t} = useLocalization();
    return (
        <RentalAppContainer title={t('items.create.title')}>
			<Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{flexGrow: 1}}>
					<Breadcrumbs>
						<CrumbLink href='/items'>{t('items.plural')}</CrumbLink>
						<BreadcrumbItem>{t('items.create.title')}</BreadcrumbItem>
					</Breadcrumbs>
					<Box my={1} />
                    <Typography variant="h4">
                        {details && details.name ? details.name : t('items.create.title')}
                    </Typography>
                </Grid>
            </Grid>
			<Box my={3} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        <Box p={3}>
                            <ItemForm modelUuid={modelUuid} onChange={setDetails} />
                            <Box my={3}/>
                            <Box display="flex" justifyContent="flex-end">
                                <Button variant="contained" onClick={() => navigate("/items")}>{t('actions.cancel')}</Button>
                                <Box mx={1} />
                                <Button variant="contained" color="primary" disabled={processing} onClick={create}>{t('actions.create')}</Button>
                            </Box>
                        </Box>
                        {processing && <LinearProgress variant="indeterminate" />}
                    </Paper>
                </Grid>
            </Grid>
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}
