import {ChangeEvent, createElement, Fragment, useCallback, useRef, useState} from "react";
import {CircularProgress, Dialog, IconButton, InputAdornment, TextField} from "@mui/material";
import {isHttpUrl, isImgUrl} from "../tools";
import {FileUpload} from "../FileUpload";
import {AttachmentIcon, VisibilityIcon} from "../theme";

export interface UploadUrl {
    uploadUrl: string;
    publicUrl: string;
}

interface UrlOrUploadInputProps {
    value?: string;
    label: string;
    placeholder?: string;
    onChange: (value: string) => void;
    onEnter?: () => void;
    disabled?: boolean;
    error?: boolean;
    uploadUrlProvider?: (file: File) => Promise<UploadUrl>;
    preview?: boolean;
    accept?: string;
}

export function UrlOrUploadInput({value, label, placeholder, onChange, onEnter, disabled, error, uploadUrlProvider, preview, accept}: UrlOrUploadInputProps) {
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadError, setUploadError] = useState<string>('');
    const [showPreview, setShowPreview] = useState<boolean>(false);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleChange = useCallback((strValue: string) => {
        onChange(strValue);
    }, [value]);

    const handleCheckEnter = useCallback((key: string) => {
        if(key === 'Enter' && onEnter) {
            onEnter();
        }
    }, [onEnter]);

    const showFileSelect = () => {
        if(fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFilesSelected = (e: ChangeEvent<HTMLInputElement>) => {
        const files: FileList | null = e.target.files;
        if(files) {
            for(let i=0; i<files.length; i++) {
                const f = files.item(i);
                if(f && uploadUrlProvider) {
                    setUploading(true);
                    uploadUrlProvider(f)
                        .then((url) => {
                            const fileUpload = new FileUpload(url.uploadUrl, f);
                            fileUpload.upload()
                                .then(() => {
                                    handleChange(url.publicUrl);
                                })
                                .catch((e) => {
                                    console.log(e);
                                    setUploadError(e.toString());
                                })
                                .finally(() => {
                                    setUploading(false);
                                });
                        })
                        .catch((e) => {
                            console.log(e);
                            setUploading(false);
                            setUploadError(e.toString());
                        });
                }
            }
            if(fileInputRef && fileInputRef.current) {
                e.target.value = '';
            }
        }
    };

    const startAdornment = (
        <InputAdornment position="end">
            <IconButton onClick={() => setShowPreview(true)} disabled={uploading || !value || !isImgUrl(value)}><VisibilityIcon /></IconButton>
        </InputAdornment>
    );

    const endAdornment = (
        <InputAdornment position="start">
            {!uploading &&<IconButton onClick={showFileSelect}><AttachmentIcon /></IconButton>}
            {uploading && <CircularProgress size={20} />}
        </InputAdornment>
    );

    return (
        <Fragment>
            <div style={{ display: 'none' }}>
                <input type="file" accept={accept} ref={fileInputRef} onChange={handleFilesSelected} />
            </div>
            <TextField label={label} placeholder={placeholder} variant="outlined" fullWidth
                       value={value} onChange={(e) => handleChange(e.target.value)}
                       onKeyPress={(e) => handleCheckEnter(e.key)}
                       error={error || Boolean(value && value.length > 0 && !isHttpUrl(value)) || Boolean(uploadError)} disabled={disabled || uploading}
                       InputProps={{
                           startAdornment: preview ? startAdornment : undefined,
                           endAdornment: uploadUrlProvider ? endAdornment : undefined
                       }}
            />
            <Dialog open={showPreview} onClose={() => setShowPreview(false)} maxWidth="lg">
                <img src={value} alt={value} onClick={() => setShowPreview(false)} />
            </Dialog>
        </Fragment>
    );
}
