import {MenuItem} from "@mui/material";
import {createElement} from "react";
import {PushButtonWithMenu} from "../../components/buttons/PushButtonWithMenu";
import {useLocalization} from "../../i18n";

type CategoryActions = {
	onEditCategory: () => void;
	onDeleteCategory: () => void;
};

export const CategoryActions = ({onEditCategory, onDeleteCategory}: CategoryActions) => {
	const {t} = useLocalization();
	return (
		<PushButtonWithMenu label={t("categories.edit.title")} onClick={onEditCategory}>
			<MenuItem key="editModel" onClick={onDeleteCategory}>{t("categories.delete.title")}</MenuItem>
		</PushButtonWithMenu>
	);
};
