import {Page, Paging, VCThemeProviderProps} from "@variocube/app-ui";
import {Api} from "../Api";
import {ChangeUserPasswordRequest, CreateUserRequest, ImportUserRequest, User, UserDetails} from "./types";

export function me(tenantId: string) {
    return Api.GET<User>(`tenants/${tenantId}/users/current-user`);
}

export function createUser(tenantId: string, request: CreateUserRequest) {
    return Api.POST<User>(`tenants/${tenantId}/users`, request);
}

export function refreshMyAccessCode(tenantId: string) {
    return Api.POST<User>(`tenants/${tenantId}/users/current-user-refresh-access-code`, {});
}

export function changePassword(tenantId: string, request: ChangeUserPasswordRequest) {
    return Api.POST<void>(`tenants/${tenantId}/users/current-user-change-password`, request);
}

export function listTenantUsers(tenantId: string, paging?: Paging) {
    return Api.GET<Page<User>>(`tenants/${tenantId}/users` + (paging ? paging.toQueryString() : '?unpaged=1'), { 'Accept': 'application/vnd.users.paged+json' });
}

export function getUser(tenantId: string, uuid: string): Promise<User> {
    return Api.GET<User>(`tenants/${tenantId}/users/${uuid}`);
}

export function updateUser(tenantId: string, uuid: string, request: UserDetails) {
    return Api.PUT<User>(`tenants/${tenantId}/users/${uuid}`, request);
}

export function updateUserAccessCode(tenantId: string, uuid: string) {
	return Api.POST<User>(`tenants/${tenantId}/users/${uuid}/access-code`, {});
}

export function deleteUser(tenantId: string, uuid: string) {
    return Api.DELETE<void>(`tenants/${tenantId}/users/${uuid}`);
}

export function deleteUserRequest(tenantId: string, reason: string) {
    return Api.DELETE<void>(`tenants/${tenantId}/users/deleteme`, {reason});
}


export function approveUser(tenantId: string, uuid: string): Promise<User> {
    return Api.POST<User>(`tenants/${tenantId}/users/${uuid}/approve`, {});
}

export function importUser(tenantId: string, request: ImportUserRequest) {
    return Api.POST<User>(`tenants/${tenantId}/users/import`, request);
}

export function getBranding(tenantId: string) {
    return Api.GET<VCThemeProviderProps["branding"]>(`tenants/${tenantId}/branding`);
}

export class UserWrapper {

    readonly user: User;

    constructor(user: User) {
        this.user = user;
    }

    hasRole(role: string): boolean {
        return (this.user.role === role);
    }

    get displayName(): string {
        let name: string;
        if(this.user.lastName) {
            name = this.user.lastName;
            if(this.user.firstName) {
                name += `, ${this.user.firstName}`;
            }
        }else{
            name = this.user.email;
        }
        return name;
    }
}
