import {createElement, useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import Chart from "react-apexcharts";

type ColumnChartProps = {
    title?: string,
    hint?: string,
    width?: string,
    height?: string,
    options?: any,
    data: any
}

export const ColumnChart = (props: ColumnChartProps) => {
    const [data, setData] = useState<any>([]);
    const [options, setOptions] = useState<any>({});

    useEffect(() => {
        setData(props.data);
        setOptions({
            // colors: StatisticColors,
            plotOptions: {
                bar: {
                    dataLabels: {
                        position: "top" // top, center, bottom
                    }
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    colors: ['#444444']
                }
            },
            chart: {
                toolbar: {
                    show: false
                }
            },
            ...props.options
        });
    }, [props]);

    return (
        <div>
            {props.title && <Typography variant="h6">{props.title}</Typography>}
            {props.hint && <Typography variant="body2" color="textSecondary">{props.hint}</Typography>}
            <Box my={2}/>
            <Chart type="bar"
                   width={props.width || '500px'}
                   height={props.height || '380px'}
                   options={options}
                   series={data}
            />
        </div>
    )
};
