import {createElement} from "react";
import {Chip} from "@mui/material";
import {useLocalization} from "../../i18n";
import {TicketType} from "../../data/types";

export function TicketTypeDisplay({type}: { type: TicketType }) {
	const {e} = useLocalization();
    return (
		<Chip
			label={e('tickets.types', type)}
			color="primary"
		/>
	);
}
