import {tenantUserStore} from "../../store/TenantUserStore";
import {createElement, useState} from "react";
import {Button, CardContent, Divider, Grid, IconButton, Stack, Typography} from "@mui/material";
import {formLabels, useLocalization} from "../../i18n";
import {Checkbox, EditForm, NumberField, TextField} from "@variocube/app-ui";
import {PeriodInputComponent} from "../../components/PeriodInputComponent";
import {IntegerNumberFormat} from "@variocube/app-ui/esm/Input/NumberField";
import {CategoryMultiSelector} from "../categories";
import {SmsSettingsComponent} from "../../components/SmsSettingsComponent";
import DeleteIcon from '@mui/icons-material/Delete';
import {useAsyncCallback} from "react-async-hook";
import {ChangeTenantRequest} from "../../data/types";
import {saveTenantSettings} from "../../data/tenants";

export function TenantSettings() {
	const {t} = useLocalization();
	const tenant = tenantUserStore.tenant;

	const [requireManuallyApproval, setRequireManuallyApproval] = useState(tenant?.requireManuallyApproval ?? false);
	const [reducedMenuOptions, setReducedMenuOptions] = useState(tenant?.reducedMenuOptions ?? false);
	const [enabledCostCenter, setEnabledCostCenter] = useState(tenant?.enabledCostCenter ?? false);
	const [rentalEndsAfterReturn, setRentalEndsAfterReturn] = useState(tenant?.rentalEndsAfterReturn ?? false);
	const [enabledTicketOnCube, setEnabledTicketOnCube] = useState(tenant?.enabledTicketOnCube ?? false);
	const [receiveEmails, setReceiveEmails] = useState(tenant?.receiveEmails ?? false);
	const [displayBorrowerData, setDisplayBorrowerData] = useState(tenant?.displayBorrowerData ?? false);
	const [whitelist, setWhitelist] = useState(tenant?.whitelist ?? []);

	const [maxRentPeriod, setMaxRentPeriod] = useState(tenant?.maxRentPeriod ?? null);
	const [overdueReminderHours, setOverdueReminderHours] = useState(tenant?.overdueReminderHours ?? null);
	const [maxRentals, setMaxRentals] = useState(tenant?.maxRentals ?? null);

	const [rentalInfoDe, setRentalInfoDe] = useState(tenant?.rentalInfo?.de ?? '');
	const [rentalInfoEn, setRentalInfoEn] = useState(tenant?.rentalInfo?.en ?? '');
	const [email, setEmail] = useState(tenant?.email ?? '');
	const [phone, setPhone] = useState(tenant?.phone ?? '');

	const [sendSmsOnRental, setSendSmsOnRental] = useState(tenant?.sendSmsOnRental ?? false);
	const [smsCountryCode, setSmsCountryCode] = useState(tenant?.smsCountryCode ?? '');
	const [smsTemplate, setSmsTemplate] = useState(tenant?.smsTemplate ?? '');

	const [requiredEmailVerificationForSignUp, setRequiredEmailVerificationForSignUp] = useState(tenant?.requiredEmailVerificationForSignUp ?? false);
	const [requiredPhoneForSignUp, setRequiredPhoneForSignUp] = useState(tenant?.requiredPhoneForSignUp ?? false);
	const [requiredPhoneVerificationForSignUp, setRequiredPhoneVerificationForSignUp] = useState(tenant?.requiredPhoneVerificationForSignUp ?? false);
	const [requiredAddressForSignUp, setRequiredAddressForSignUp] = useState(tenant?.requiredAddressForSignUp ?? false);
	const [requiredNameForSignUp, setRequiredNameForSignUp] = useState(tenant?.requiredNameForSignUp ?? false);

	const [emailDomains, setEmailDomains] = useState(tenant?.emailDomains ?? []);
	const [signUpDomains, setSignUpDomains] = useState(tenant?.signUpDomains ?? []);

	function handleEmailDomainChange(index: number, value: string) {
		setEmailDomains(prev => prev.map((v, i) =>
			i === index ? value : v
		));
	}

	function addEmailDomain() {
		setEmailDomains(prev => prev.concat(''));
	}

	function removeEmailDomain(index: number) {
		setEmailDomains(prev => prev.filter((_, i) => i !== index));
	}

	function handleSignUpDomainChange(index: number, value: string) {
		setSignUpDomains(prev => prev.map((v, i) =>
			i === index ? value : v
		));
	}

	function addSignUpDomain() {
		setSignUpDomains(prev => prev.concat(''));
	}

	function removeSignUpDomain(index: number) {
		setSignUpDomains(prev => prev.filter((_, i) => i !== index));
	}

	const {loading, execute: processTenantSettings} = useAsyncCallback(async (request: ChangeTenantRequest) => saveTenantSettings(tenantUserStore.getTenantId(), request));

	async function handleSave() {
		const modified = await processTenantSettings({
			requireManuallyApproval,
			reducedMenuOptions,
			enabledCostCenter,
			rentalEndsAfterReturn,
			enabledTicketOnCube,
			receiveEmails,
			displayBorrowerData,
			whitelist,
			maxRentPeriod,
			overdueReminderHours,
			maxRentals,
			rentalInfo: {
				de: rentalInfoDe,
				en: rentalInfoEn,
			},
			email,
			phone,
			sendSmsOnRental,
			smsCountryCode,
			smsTemplate,
			requiredEmailVerificationForSignUp,
			requiredPhoneForSignUp,
			requiredPhoneVerificationForSignUp,
			requiredAddressForSignUp,
			requiredNameForSignUp,
			emailDomains,
			signUpDomains,
		});
		tenantUserStore.setTenant(modified);
	}

	return (
		<EditForm
			loading={loading}
			onSave={handleSave}
			labels={formLabels()}
		>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="overline">{t('rentals.plural')}</Typography>
					</Grid>
					<Grid item sm={6} xs={12}>
						<Stack
							direction="column"
							gap={2}
						>
							<div>
								<Checkbox
									label={t('settings.requireManuallyApproved.label')}
									value={requireManuallyApproval}
									onChange={setRequireManuallyApproval}
								/>
								<Typography variant="body2" color="textSecondary">{t('settings.requireManuallyApproved.helperText')}</Typography>
							</div>

							<div>
								<Checkbox
									label={t('settings.reducedMenuOptions.label')}
									value={reducedMenuOptions}
									onChange={setReducedMenuOptions}
								/>
								<Typography variant="body2" color="textSecondary">{t('settings.reducedMenuOptions.helperText')}</Typography>
							</div>

							<div>
								<Checkbox
									label={t('settings.enableCostCenter.label')}
									value={enabledCostCenter}
									onChange={setEnabledCostCenter}
								/>
								<Typography variant="body2" color="textSecondary">{t('settings.enableCostCenter.helperText')}</Typography>
							</div>

							<div>
								<Checkbox
									label={t('settings.rentalEndsAfterReturn.label')}
									value={rentalEndsAfterReturn}
									onChange={setRentalEndsAfterReturn}
								/>
								<Typography variant="body2" color="textSecondary">{t('settings.rentalEndsAfterReturn.helperText')}</Typography>
							</div>

							<div>
								<Checkbox
									label={t('settings.enabledTicketOnCube.label')}
									value={enabledTicketOnCube}
									onChange={setEnabledTicketOnCube}
								/>
								<Typography variant="body2" color="textSecondary">{t('settings.enabledTicketOnCube.helperText')}</Typography>
							</div>

							<div>
								<Checkbox
									label={t('settings.receiveEmails.label')}
									value={receiveEmails}
									onChange={setReceiveEmails}
								/>
								<Typography variant="body2" color="textSecondary">{t('settings.receiveEmails.helperText')}</Typography>
							</div>

							<div>
								<Checkbox
									label={t('settings.displayBorrowerData.label')}
									value={displayBorrowerData}
									onChange={setDisplayBorrowerData}
								/>
								<Typography variant="body2" color="textSecondary">{t('settings.displayBorrowerData.helperText')}</Typography>
							</div>
						</Stack>
					</Grid>
					<Grid item sm={6} xs={12}>
						<Stack
							direction="column"
							gap={3}
						>
							<div>
								<PeriodInputComponent
									label={t('rentals.maxRentPeriod')}
									hours={maxRentPeriod}
									onPeriodChange={setMaxRentPeriod}
								/>
							</div>
							<div>
								<PeriodInputComponent
									label={t('tenants.overdueReminderHours')}
									hours={overdueReminderHours}
									onPeriodChange={setOverdueReminderHours}
								/>
							</div>
							<div>
								<NumberField
									fullWidth
									label={t('rentals.maxRentals.title')}
									numberFormat={IntegerNumberFormat}
									numberValue={maxRentals}
									onChangeNumber={setMaxRentals}
									sx={{
										mb: 1
									}}
								/>
								<Typography variant="body2"
											color="textSecondary">{t('rentals.maxRentals.helperText')}</Typography>
							</div>

							<div>
								<CategoryMultiSelector
									label={t('settings.whitelist')}
									helperText={t('settings.whitelistsHint')}
									value={whitelist}
									onChange={setWhitelist}
								/>
							</div>
						</Stack>
					</Grid>
					<Grid item sm={6} xs={12}>
						<TextField
							fullWidth
							multiline rows={2}
							label={t('settings.rentalAdditionalInfoGerman')}
							value={rentalInfoDe}
							onChange={setRentalInfoDe}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<TextField
							fullWidth
							multiline rows={2}
							label={t('settings.rentalAdditionalInfoEnglish')}
							value={rentalInfoEn}
							onChange={setRentalInfoEn}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<TextField
							fullWidth
							label={t('settings.supportEmail')}
							value={email}
							onChange={setEmail}
							type="email"
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<TextField
							fullWidth
							label={t('settings.supportPhone')}
							value={phone}
							onChange={setPhone}
						/>
					</Grid>
				</Grid>
			</CardContent>
			<Divider />
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="overline">SMS</Typography>
					</Grid>
					<Grid item sm={6} xs={12}>
						<SmsSettingsComponent
							sendSmsOnRental={sendSmsOnRental}
							smsCountryCode={smsCountryCode}
							smsTemplate={smsTemplate}
							onSendSmsOnRentalChange={setSendSmsOnRental}
							onSmsCountryCodeChange={setSmsCountryCode}
							onSmsTemplateChange={setSmsTemplate}
						/>
					</Grid>
				</Grid>
			</CardContent>
			<Divider />
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="overline">{t('users.actions.signup')}</Typography>
					</Grid>
					<Grid item sm={6} xs={12}>
						<Stack
							direction="column"
							gap={2}
						>
							<div>
								<Checkbox
									label={t('settings.signUpRequireEmailVerification')}
									value={requiredEmailVerificationForSignUp}
									onChange={setRequiredEmailVerificationForSignUp}
								/>
							</div>

							<div>
								<Checkbox
									label={t('settings.signUpRequirePhone')}
									value={requiredPhoneForSignUp}
									onChange={setRequiredPhoneForSignUp}
								/>
							</div>

							<div>
								<Checkbox
									label={t('settings.signUpRequirePhoneVerification')}
									value={requiredPhoneVerificationForSignUp}
									onChange={setRequiredPhoneVerificationForSignUp}
								/>
							</div>

							<div>
								<Checkbox
									label={t('settings.signUpRequireAddress')}
									value={requiredAddressForSignUp}
									onChange={setRequiredAddressForSignUp}
								/>
							</div>

							<div>
								<Checkbox
									label={t('settings.signUpRequireName')}
									value={requiredNameForSignUp}
									onChange={setRequiredNameForSignUp}
								/>
							</div>
						</Stack>
					</Grid>
					<Grid item sm={6} xs={12}>
						<Stack
							direction="column"
							gap={3}
						>
							<div>
								<Typography variant="subtitle1" gutterBottom><strong>{t('settings.emailDomains')}</strong></Typography>
								{emailDomains.map((d, i) => (
									<TextField
										key={'email-' + i}
										fullWidth
										size="small"
										value={d}
										onChange={v => handleEmailDomainChange(i, v)}
										InputProps={{
											endAdornment: <IconButton size="small" onClick={() => removeEmailDomain(i)}><DeleteIcon /></IconButton>
										}}
										sx={{
											mb: 1
										}}
									/>
								))}
								<Button
									sx={{ mt: 1 }}
									size="small"
									variant="outlined"
									onClick={addEmailDomain}
								>
									{t('actions.add')}
								</Button>
							</div>

							<div>
								<Typography variant="subtitle1" gutterBottom><strong>{t('settings.signUpDomains')}</strong></Typography>
								{signUpDomains.map((d, i) => (
									<TextField
										key={'domain-' + i}
										fullWidth
										size="small"
										value={d}
										onChange={v => handleSignUpDomainChange(i, v)}
										InputProps={{
											endAdornment: <IconButton size="small" onClick={() => removeSignUpDomain(i)}><DeleteIcon /></IconButton>
										}}
										sx={{
											mb: 1
										}}
									/>
								))}
								<Button
									sx={{ mt: 1 }}
									size="small"
									variant="outlined"
									onClick={addSignUpDomain}
								>
									{t('actions.add')}
								</Button>
							</div>
						</Stack>
					</Grid>
				</Grid>
			</CardContent>
		</EditForm>
	)
}
