import {createElement, PropsWithChildren, ReactElement, useMemo} from "react";
import {AppShell, ThemeModeSwitcher} from "@variocube/app-ui";
import {
	Box,
	Button, Grid,
	Hidden,
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
	Link as MuiLink
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {useLocalization} from "./i18n";
import {
    CategoryIcon,
    CostCenterIcon, CubeIcon, ItemIcon, LogoutIcon, ModelIcon, RentalIcon, SettingIcon, StatisticIcon, TicketIcon, UserIcon } from "./theme";
import {tenantUserStore} from "./store/TenantUserStore";
import {AuthCallbackProcessingPath, AuthLoginPath, AuthLogoutPath} from "./Auth";
import {listTenants} from "./data/tenants";
import {useAsync} from "react-async-hook";

export function RentalAppLayout({children}: PropsWithChildren<{}>) {
    const enableNav = useMemo(() => {
		console.log('path', location.pathname);
		if(location.pathname == "/") {
			return false;
		}
        const notAllowPaths = [
            "tenants",
			"signup",
            AuthLogoutPath,
            AuthLoginPath,
            AuthCallbackProcessingPath
        ];
        return notAllowPaths.filter(p => location.pathname.indexOf(p) > -1).length === 0;
    }, [location.pathname])

    return (
        <AppShell
            appName="rental"
            sideNav={enableNav ? <SideNav /> : undefined}
            topNav={enableNav ? <TopNav /> : undefined}
			footer={enableNav ? <Footer /> : undefined}
        >
            {children}
        </AppShell>
    )
}

function SideNav() {
    const {t} = useLocalization();
	const reducedMenuOptions = tenantUserStore.tenant?.reducedMenuOptions ?? false;

    return (
        <List>
			{!reducedMenuOptions &&
				<MainMenuItem
					label={t('rentals.plural')}
					icon={<RentalIcon />}
					path="/rentals"
				/>
			}
			{!reducedMenuOptions &&
				<MainMenuItem
					label={t('tickets.plural')}
					icon={<TicketIcon />}
					path="/tickets"
				/>
			}
            {(!reducedMenuOptions && tenantUserStore.staff) && (
                <MainMenuItem
                    label={t('categories.plural')}
                    icon={<CategoryIcon />}
                    path="/categories"
                />
            )}
            <MainMenuItem
                label={t('models.plural')}
                icon={<ModelIcon />}
                path="/models"
            />
            <MainMenuItem
                label={t('items.plural')}
                icon={<ItemIcon />}
                path="/items"
            />
            {tenantUserStore.staff && (
                <MainMenuItem
                    label={t('cubes.plural')}
                    icon={<CubeIcon />}
                    path="/cubes"
                />
            )}
            {(!reducedMenuOptions && tenantUserStore.staff) && (
                <MainMenuItem
                    label={t('costCenters.plural')}
                    icon={<CostCenterIcon />}
                    path="/cost-centers"
                />
            )}
            {tenantUserStore.admin && (
                <MainMenuItem
                    label={t('users.plural')}
                    icon={<UserIcon />}
                    path="/users"
                />
            )}
            {(!reducedMenuOptions && tenantUserStore.admin) && (
                <MainMenuItem
                    label={t('statistics.title')}
                    icon={<StatisticIcon />}
                    path="/statistics"
                />
            )}
            {tenantUserStore.staff && (
                <MainMenuItem
                    label={t('settings.title')}
                    icon={<SettingIcon />}
                    path="/settings"
                />
            )}
        </List>
    )
}

function TopNav() {
    const navigate = useNavigate();
    const {t} = useLocalization();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                displayPrint: 'none'
            }}
        >
            <Hidden smDown>
				{(tenantUserStore.tenant && !tenantUserStore.tenant.reducedMenuOptions) &&
					<Button size="small" variant="contained" component={Link} to="/rentals/create">
						{t("rentals.create.title")}
					</Button>
				}
                <Box mx={1} />
            </Hidden>
			<ThemeModeSwitcher />
            <IconButton color="inherit" onClick={() => navigate(AuthLogoutPath)}><LogoutIcon /></IconButton>
        </Box>
    )
}

function Footer() {
	const {t} = useLocalization();

	const {result: hasMultipleTenants} = useAsync(async () => {
		const tenants = await listTenants()
		return tenants.length > 1;
	}, [])

	return (
		<Box
			sx={{
				px: 1,
				py: 0.5
			}}
		>
			<Grid container spacing={3}>
				<Grid item sx={{ flexGrow: 1 }}>
					<Typography variant="overline" color="textSecondary">© Variocube GmbH</Typography>
				</Grid>
				{hasMultipleTenants && (
					<Grid item>
						<Typography variant="overline">
							<MuiLink
								component={Link}
								to="/tenants"
								color="secondary"
								underline="none"
							>
								{t('footerBar.switchTenant')}
							</MuiLink>
						</Typography>
					</Grid>
				)}
				<Grid item>
					<Typography variant="overline">
						<MuiLink
							href="/api/docs"
							target="_blank"
							color="secondary"
							underline="none"
						>
							{t('footerBar.api')}
						</MuiLink>
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="overline">
						<MuiLink
							href="https://www.variocube.com/kontakt/"
							target="_blank"
							color="secondary"
							underline="none"
						>
							{t('footerBar.imprint')}
						</MuiLink>
					</Typography>
				</Grid>
			</Grid>
		</Box>
	)
}

interface MainMenuItemProps {
    label: string;
    exact?: boolean;
    path?: string;
    icon: ReactElement;
    onClick?: () => void;
}

function MainMenuItem({label, path, icon, exact, onClick}: MainMenuItemProps) {
    const match = !!path && location.pathname.includes(path);

    const otherProps = path ? {component: Link, to: path} : {onClick};

    return (
        <ListItemButton selected={match} {...otherProps}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText primary={label}/>
        </ListItemButton>
    );
}
