import {
	Box,
	Chip,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import {TemporalRangeFormat} from "@variocube/app-ui";
import {createElement, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ApiError} from "../../Api";
import {TreeSwingGraphic} from "../../assets/svg";
import {FormattedAlert} from "../../components/FormattedAlert";
import {Loading} from "../../components/Loading";
import {listCubesBoxes} from "../../data/cubes";
import {getItem} from "../../data/items";
import {getRental} from "../../data/rentals";
import {Item, Rental} from "../../data/types";
import {Box as CubeBox, Cube, Occupancy} from "../../data/types";
import {useLocalization} from "../../i18n";
import {tenantUserStore} from "../../store/TenantUserStore";
import {RentalBorrowerName} from "../rentals";
import {CubeBoxLockStateComponent} from "./CubeBoxLockStateComponent";

export const BoxesList = (props: { cube: Cube }) => {
	const [boxes, setBoxes] = useState<CubeBox[] | undefined>();
	const [error, setError] = useState<ApiError | undefined>(undefined);

	useEffect(() => {
		const tenant = tenantUserStore.tenant;
		if (!tenant) {
			throw new Error("No tenant selected");
		}
		listCubesBoxes(tenant.centerId, props.cube.cubeId)
			.then(setBoxes)
			.catch(toggleError);
	}, [setBoxes]);

	const toggleError = (error?: ApiError) => {
		setError(error);
	};

	return (
		<div>
			<Paper>
				{!boxes && (
					<Box p={5}>
						<Loading />
					</Box>
				)}
				{boxes && <BoxTable list={boxes} cube={props.cube} />}
			</Paper>
			{error && <FormattedAlert title={`${error.code}`} info={`${error.message}`} />}
		</div>
	);
};

type sortKeys = "number" | "types" | "lockStatus" | "disabled" | "occupancies";

type BoxTableProps = {
	cube: Cube;
	list: CubeBox[];
};

export function BoxTable({cube, list}: BoxTableProps) {
	const [orderAsc, setOrderAsc] = useState(false);
	const [orderBy, setOrderBy] = useState<sortKeys>("number");

	function descendingComparator(a: CubeBox, b: CubeBox, asc: boolean, orderBy: sortKeys): number {
		let aValue, bValue;
		switch (orderBy) {
			case "number":
				aValue = a.number;
				bValue = b.number;
				try {
					const aInt = parseInt(a.number);
					const bInt = parseInt(b.number);
					if (asc) {
						return aInt - bInt;
					} else {
						return bInt - aInt;
					}
				} catch (e) {
				}
				break;
			case "types":
				aValue = a.types.sort((x: any, y: any) => x.localeCompare(y)).join(",");
				bValue = b.types.sort((x: any, y: any) => x.localeCompare(y)).join(",");
				break;
			case "lockStatus":
				aValue = a.lockStatus ? a.lockStatus : "0";
				bValue = b.lockStatus ? b.lockStatus : "0";
				break;
			case "disabled":
				aValue = a.disabled ? "1" : "0";
				bValue = b.disabled ? "1" : "0";
				break;
			case "occupancies":
				aValue = a.occupancies.length.toString();
				bValue = b.occupancies.length.toString();
				break;
			default:
				throw new Error("You did not select a valid comparison field");
		}
		const v = aValue.localeCompare(bValue);
		if (asc) {
			return v;
		}
		return v * -1;
	}

	function onSort(sortKey: sortKeys) {
		if (orderBy === sortKey) {
			setOrderAsc(!orderAsc);
		} else {
			setOrderBy(sortKey);
		}
	}

	function getSorted() {
		return list ? list.sort((a, b) => descendingComparator(b, a, orderAsc, orderBy)) : undefined;
	}

	const {t} = useLocalization();
	const sorted = getSorted();
	return (
		<Table size="small">
			<TableHead>
				<TableRow>
					<TableCell>
						<TableSortLabel
							active={orderBy === "number"}
							direction={orderAsc ? "asc" : "desc"}
							onClick={() => onSort("number")}
						>
							{t("boxes.singular")}
						</TableSortLabel>
					</TableCell>
					<TableCell>
						<TableSortLabel
							active={orderBy === "types"}
							direction={orderAsc ? "asc" : "desc"}
							onClick={() => onSort("types")}
						>
							{t("boxes.boxType")}
						</TableSortLabel>
					</TableCell>
					<TableCell>
						<TableSortLabel
							active={orderBy === "lockStatus"}
							direction={orderAsc ? "asc" : "desc"}
							onClick={() => onSort("lockStatus")}
						>
							{t("boxes.lockState.label")}
						</TableSortLabel>
					</TableCell>
					<TableCell style={{flexGrow: 1}}>
						<TableSortLabel
							active={orderBy === "occupancies"}
							direction={orderAsc ? "asc" : "desc"}
							onClick={() => onSort("occupancies")}
						>
							{t("boxes.occupancy")}
						</TableSortLabel>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{sorted && sorted.length > 0 && sorted.map((box) => <BoxRow cube={cube} box={box} key={box.number} />)}
				{sorted && sorted.length == 0 && (
					<TableRow>
						<TableCell colSpan={5}>
							<Box p={6}>
								<Typography variant="h6" align="center">{t("boxes.noBoxes")}</Typography>
							</Box>
							<TreeSwingGraphic />
							<Box p={6} />
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	);
}

const BoxRow = (props: { cube: Cube; box: CubeBox }) => {
	const navigate = useNavigate();

	const {cube, box} = props;
	return (
		<TableRow
			style={{cursor: "pointer", backgroundColor: box.disabled ? "#efefef" : undefined}}
			hover
			onClick={() => navigate(`/cubes/${cube.cubeId}/boxes/${box.number}`)}
		>
			<TableCell style={{whiteSpace: "nowrap"}}>{box.description?box.description:box.number}</TableCell>
			<TableCell>
				<BoxTypes boxTypes={box.types} />
			</TableCell>
			<TableCell>
				<CubeBoxLockStateComponent lockState={box.lockStatus} />
			</TableCell>
			<TableCell>
				<BoxOccupancies occupancies={box.occupancies} />
			</TableCell>
		</TableRow>
	);
};

const BoxTypes = (props: { boxTypes: string[] }) => {
	const {boxTypes} = props;
	return (
		<div>
			{boxTypes.map((boxType) => <Chip key={boxType} label={boxType} />)}
		</div>
	);
};

const BoxOccupancies = ({occupancies}: { occupancies: Occupancy[] }) => {
	return (
		<div>
			{occupancies.map(o => <BoxOccupancy key={o.uuid} occupancy={o} />)}
		</div>
	);
};

const BoxOccupancy = ({occupancy}: { occupancy: Occupancy }) => {
	const [item, setItem] = useState<Item | undefined>();
	const [rental, setRental] = useState<Rental | undefined>();
	const [loading, setLoading] = useState<boolean>(false);

	const getTenantId = () => {
		const tenant = tenantUserStore.tenant;
		if (!tenant) {
			throw new Error("No tenant selected");
		}
		return tenant.centerId;
	};

	useEffect(() => {
		setLoading(true);
		if (occupancy.itemUuid) {
			getItem(getTenantId(), occupancy.itemUuid).then(setItem).finally(() => setLoading(false));
		}
		if (occupancy.rentalUuid) {
			getRental(getTenantId(), occupancy.rentalUuid).then(setRental);
		}
	}, [occupancy]);

	if (loading) {
		return <span>....</span>;
	}

	if (item && !rental) {
		return <ItemDisplay item={item} />;
	}

	if (item && rental) {
		return (
			<Grid container spacing={3}>
				<Grid item>
					<ItemDisplay item={item} />
				</Grid>
				<Grid item>
					<TemporalRangeFormat
						from={rental.from?.toJSDate()}
						until={rental.until?.toJSDate()}
						dateStyle="short"
						timeStyle="short"
					/>
				</Grid>
				<Grid item>
					<RentalBorrowerName borrower={rental.borrower} />
				</Grid>
			</Grid>
		);
	}

	return <span>{occupancy.uuid}</span>;
};

interface ItemDisplayProps {
	item: Item;
}

function ItemDisplay({item}: ItemDisplayProps) {
	const label = useMemo<string>(() => {
		if (item.foreignId) {
			return `${item.name}: ${item.foreignId}`;
		}
		if (item.name) {
			return item.name;
		}
		return "Unnamed item";
	}, [item]);

	return <span>{label}</span>;
}
