import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {Box, Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography, Alert, Autocomplete} from "@mui/material";
import {ApiError} from "../../Api";
import {CostCenter, CreateRentalBorrower, Item, User, UserRole} from "../../data/types";
import {verifyItemUserAccess} from "../../data/rentals";
import {listCostCenters} from "../../data/costcenters";
import {listTenantUsers} from "../../data/users";
import {tenantUserStore} from "../../store/TenantUserStore";
import {formatUserName} from "../../utils/formatter";
import {ErrorDialog} from "../../components/ErrorDialog";
import {useLocalization} from "../../i18n";

type CreateWizardBorrowerProps = {
    item: Item,
    borrower: CreateRentalBorrower,
    borrowerUuid: string|null,
    costCenterUuid: string|null,
    onBorrowerChange: (borrower: CreateRentalBorrower, borrowerUuid: string|null) => void,
    onCostCenterUuidChange: (costCenterUuid: string|null) => void
}

export const CreateWizardBorrower = (props: CreateWizardBorrowerProps) => {
    const [users, setUsers] = useState<User[]>();
    const [tenantCostCenters, setTenantCostCenters] = useState<CostCenter[]>([]);
    const [costCenters, setCostCenters] = useState<CostCenter[]>();
    const [itemAccessible, setItemAccessible] = useState(false);
    const [borrowerUuid, setBorrowerUuid] = useState(props.borrowerUuid);
    const [costCenterUuid, setCostCenterUuid] = useState(props.costCenterUuid);
    const [error, setError] = useState<ApiError>();

    const fetch = useCallback(async () => {
        try {
            const {content: costCenters} = await listCostCenters(tenantUserStore.getTenantId());
            setTenantCostCenters(costCenters);

            const {content: users} = await listTenantUsers(tenantUserStore.getTenantId());
            setUsers(users);

            if (props.borrowerUuid) {
                const user = users.find(u => u.uuid === props.borrowerUuid) || null;
                if (user) {
                    if (user.costCenters.length > 0) {
                        setCostCenters(user.costCenters);
                    } else {
                        setCostCenters(costCenters);
                    }
                    await verifyUserAccessOnItem(props.item.uuid, user.uuid);
                }
            } else {
                setCostCenters(costCenters);
            }
        } catch (error) {
            setError(error as any);
        }
    }, [])

    useEffect(() => {
        fetch().then()
    }, []);

    const verifyUserAccessOnItem = async (itemUuid: string, borrowerUuid: string) => {
        try {
            const {blacklisted} = await verifyItemUserAccess(tenantUserStore.getTenantId(), { itemUuid, borrowerUuid });
            setItemAccessible(!blacklisted);
        } catch (error) {
            console.error(error);
        }
    }

    const borrowerChange = async (user: User|null) => {
        costCenterChange(null);
        if (user) {
            if (user.costCenters.length > 0) {
                setCostCenters(user.costCenters);
            } else {
                setCostCenters(tenantCostCenters);
            }
            await verifyUserAccessOnItem(props.item.uuid, user.uuid);
            let name = user.email;
            if(user.firstName && user.lastName) {
                name = user.firstName + ' ' + user.lastName;
            } else if(user.lastName) {
                name = user.lastName;
            }
            props.onBorrowerChange({
                ...props.borrower,
                name: name,
                email: user.email,
                phone: null,
                accessKey: user.accessKey,
				nfcToken: user.nfcToken,
            }, user.uuid);
            setBorrowerUuid((user.role === UserRole.Borrower) ? user.uuid : null);
        } else {
            setCostCenters(tenantCostCenters);
            props.onBorrowerChange({
                ...props.borrower,
                name: null,
                email: null,
                phone: null
            }, null);
            setBorrowerUuid(null);
        }
    };

    const costCenterChange = (value: string|null) => {
        setCostCenterUuid(value);
        props.onCostCenterUuidChange(value);
    };

    const borrowerInputChange = (key: keyof CreateRentalBorrower, value: any) => {
        if(key !== 'phone'  || value.match('^[0-9 /+]*$')) {
            props.onBorrowerChange({
                ...props.borrower,
                [key]: value
            }, props.borrowerUuid);
        }
    };

    const rentalEndsAutomaticallyAfterReturn = (value: string) => {
        value = value.trim();
        if (value) {
            try {
                const numeric = parseInt(value);
                borrowerInputChange('rentalEndsAutomaticallyAfterReturn', numeric);
            }catch(e) { console.error(e); }
        } else {
            borrowerInputChange('rentalEndsAutomaticallyAfterReturn', undefined);
        }
    };

	const {t} = useLocalization();
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6">{t('rentals.create.borrowerQuestion', { itemName: props.item.name })}</Typography>
                    <Box p={1} />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {users && (
                        <Grid item xs={12}>
                            <Autocomplete fullWidth
                                          options={users}
                                          getOptionLabel={user => formatUserName(user)}
                                          value={users.find(u => u.uuid === props.borrowerUuid)}
                                          renderInput={(params) => <TextField {...params} label={t('rentals.borrower.label')} variant="outlined" />}
                                          onChange={(e, v) => borrowerChange(v)}
                            />
                            {borrowerUuid && !itemAccessible && (
                                <Fragment>
                                    <Box my={2} />
                                    <Alert severity="warning">
                                        {t('rentals.borrower.itemBlacklisted')}
                                    </Alert>
                                </Fragment>
                            )}
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Box my={1}/>
            {tenantUserStore.tenant && tenantUserStore.tenant.enabledCostCenter && (
                <Grid container spacing={3}>
                    {costCenters && (
                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth select label={t('costCenters.singular')}
                                       value={costCenterUuid || ''}
                                       onChange={ev => costCenterChange(ev.target.value)}>
                                {costCenters.map(cc => (
                                    <MenuItem key={cc.uuid} value={cc.uuid}>{[cc.name, cc.description].filter(t => !!t).join(': ')}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}
                </Grid>
            )}
			<Box my={1}/>
            <Grid container spacing={3}>
				{!borrowerUuid && (
					<Fragment>
						<Grid item xs={12}>
							<TextField variant="outlined" fullWidth label={t('common.name')}
									   value={props.borrower.name}
									   onChange={e => borrowerInputChange('name', e.target.value)} />
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={12}>
							<TextField variant="outlined" fullWidth label={t('common.email')}
									   value={props.borrower.email}
									   onChange={e => borrowerInputChange('email', e.target.value)} />
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={12}>
							<TextField variant="outlined" fullWidth label={t('common.phone')}
									   value={props.borrower.phone}
									   onChange={e => borrowerInputChange('phone', e.target.value)} />
						</Grid>
					</Fragment>
				)}
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth label={t('users.accessKey')}
                               value={props.borrower.accessKey}
                               onChange={e => borrowerInputChange('accessKey', e.target.value)} />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox
                        checked={props.borrower.rentalEndsAfterReturn}
                        onChange={(e) => borrowerInputChange('rentalEndsAfterReturn', e.target.checked)}
                        color="primary"
                    />} label={t('rentals.borrower.rentalEndsAfterReturn')} />
                </Grid>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth label={t('rentals.borrower.rentalEndsAutomaticallyAfterReturn')}
                               value={props.borrower.rentalEndsAutomaticallyAfterReturn ? `${props.borrower.rentalEndsAutomaticallyAfterReturn}` : ''}
                               onChange={e => rentalEndsAutomaticallyAfterReturn(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox
                        checked={props.borrower.notifyOnCreation}
                        onChange={(e) => borrowerInputChange('notifyOnCreation', e.target.checked)}
                        color="primary"
                    />} label={t('rentals.borrower.notifyOnCreation')} />
                </Grid>
            </Grid>
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </div>
    );
}
