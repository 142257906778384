import {createElement, forwardRef, useContext} from "react";
import {Button} from "@mui/material";
import {useLocalization} from "../../i18n";
import {WizardContext} from "./context";

export enum WizardNavigationStep {
	EmailStep = 0,
	PhoneStep = 1,
	DetailsStep = 2,
	PasswordStep = 3,
}

interface WizardBackButtonProps {
	disabled: boolean;
	onBack: () => void;
}

export const WizardBackButton = forwardRef<HTMLButtonElement, WizardBackButtonProps>(({disabled, onBack}, ref) => {
	const {t} = useLocalization();

	return (
		<Button
			ref={ref}
			disabled={disabled}
			color="primary"
			disableElevation
			onClick={onBack}
		>
			{t('actions.back')}
		</Button>
	)
})

export const WizardNextButton = forwardRef<HTMLButtonElement, { disabled: boolean }>(({disabled}, ref) => {
	const {t} = useLocalization();
	const {step} = useContext(WizardContext);

	return (
		<Button
			ref={ref}
			disabled={disabled}
			type="submit"
			color="primary"
			variant={step === WizardNavigationStep.PasswordStep ? 'contained' : 'text'}
			disableElevation
		>
			{step === WizardNavigationStep.PasswordStep
				? t('actions.submit')
				: t('actions.next')
			}
		</Button>
	)
})
