import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	Grid,
	Tooltip,
	Typography,
} from "@mui/material";
import * as H from "history";
import {DateTime} from "luxon";
import {createElement, Fragment, useEffect, useState} from "react";
import {searchItems} from "../../data/items";
import {Availability, Item, Model} from "../../data/types";
import {useLocalization} from "../../i18n";
import {tenantUserStore} from "../../store/TenantUserStore";
import {InfoIcon} from "../../theme";
import {ItemAvailabilityDisplay} from "../items/ItemAvailabillityDisplay";
import "../../assets/css/model.css";
import {useNavigate} from "react-router-dom";
import {listModelItems} from "../../data/models";

type ModelCardProps = {
	model: Model;
};
export const ModelCard = ({model}: ModelCardProps) => {
	const {t, e} = useLocalization();
	const navigate = useNavigate();
	// const [items, setItems] = useState<Item[]>([]);
	const [availableItems, setAvailableItems] = useState<Item[]>([]);
	const [firstAvailableItem, setFirstAvailableItem] = useState<Item>();
	const [availability, setAvailability] = useState<Availability | undefined>();

	const renderCost = (model: Model) => {
		if (model && model.amount && model.calculationCase) {
			return (
				<Tooltip title={t("items.inheritFromModel")} placement="top">
					<Box display="flex" flexDirection="row">
						€ {model.amount.gross} / {e("calculationCase", model.calculationCase)}
						<Box mx={0.5} />
						<InfoIcon fontSize="small" />
					</Box>
				</Tooltip>
			);
		}
		return <span>--</span>;
	};

	useEffect(() => {
		var tenantId = tenantUserStore.getTenantId();
		var from = (new Date()).getTime();
		var until = from
			+ 60 * 60 * 1000 * (model.maxRentPeriod !== undefined && model.maxRentPeriod > 0 ? model.maxRentPeriod : 1);

		/*listModelItems(tenantUserStore.getTenantId(), model.uuid)
        .then(setItems);*/

		searchItems(tenantId, "", DateTime.fromMillis(from), DateTime.fromMillis(until), model.uuid)
			.then(data => setAvailableItems(data.content));
	}, [model]);

	useEffect(() => {
		if (availableItems && availableItems.length > 0) {
			setFirstAvailableItem(availableItems[0]);
			setAvailability(Availability.Available);
		} else {
			setAvailability(Availability.Rented);
		}
	}, [availableItems]);

	return (
		<Grid item xs={12} sm={6} md={4}>
			<Card>
				<CardHeader title={model.name} subheader={renderCost(model)} />
				{model.imageUrl && (
					<CardMedia
						component="img"
						height="150"
						image={model.imageUrl}
						alt={model.name}
						className="card-media"
					/>
				)}
				<CardContent>
					<Grid container>
						<Grid item xs={12}>
							<Typography
								gutterBottom
								variant="body2"
								color="textSecondary"
								component="div"
								className="product-description"
							>
								{model.description} in {model.category?.name}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							{availability && <ItemAvailabilityDisplay availability={availability} />}
						</Grid>
					</Grid>
				</CardContent>
				<CardActions>
					{tenantUserStore.staff && (
						<Button onClick={() => navigate(`/models/${model.uuid}`)}>{t("models.edit.title")}</Button>
					)}
					{firstAvailableItem
						&& (
							<Button
								onClick={() => {
									var from = (new Date()).getTime();
									var until = from
										+ 60 * 60 * 1000 * (model.maxRentPeriod !== undefined && model.maxRentPeriod > 0
												? model.maxRentPeriod
												: 1);
									var fromISO = new Date(from).toISOString();
									var untilISO = new Date(until).toISOString();
									var directRentLink =
										`/rentals/create/${firstAvailableItem.uuid}?itemUuid=${firstAvailableItem.uuid}&from=${fromISO}&until=${untilISO}`;
									navigate(directRentLink);
								}}
							>
								{t("rentals.start.title")}
							</Button>
						)}
					{!firstAvailableItem && <Fragment></Fragment>}
				</CardActions>
			</Card>
		</Grid>
	);
};
