import {createElement} from "react";
import {RentalServiceItem} from "../../data/types";

interface RentalItemNameProps {
    itemName: string;
    serviceItem?: RentalServiceItem;
}

export function RentalItemName(props: RentalItemNameProps) {
    const {itemName, serviceItem} = props;
    if(serviceItem && serviceItem.name) {
        return (<span>{`${itemName} / ${serviceItem.name}`}</span>);
    }
    return (<span>{itemName}</span>);
}
