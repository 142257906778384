import {PhonePrefixSelect, TextField, useFlag} from "@variocube/app-ui";
import {createElement, Fragment, useCallback, useContext, useEffect, useState} from "react";
import {Grid, IconButton, InputAdornment} from "@mui/material";
import {useLocalization} from "../../i18n";
import {WizardContext} from "./context";
import {Verification} from "./verification";
import EditIcon from "@mui/icons-material/Edit";
import {PhoneNumber} from "./types";

interface StepPhoneProps {
	centerId: string;
	requiredPhone: boolean;
	requireVerification: boolean;
	onNext: (phone?: PhoneNumber) => void;
	defaultPrefix?: string;
	phone?: PhoneNumber;
}

export function StepPhone({centerId, requiredPhone, requireVerification, onNext, defaultPrefix, phone: pPhone}: StepPhoneProps) {
	const {t} = useLocalization();
	const {nextBtnRef, loading} = useContext(WizardContext);

	const [prefix, setPrefix] = useState<string>('43');
	const [number, setNumber] = useState('');
	const [showVerificationInput, setShowVerificationInput, clearShowVerificationInput] = useFlag(false);

	const phone = '+' + prefix + number;

	useEffect(() => {
		if (pPhone) {
			setPrefix(pPhone.prefix);
			setNumber(pPhone.number);
			return;
		}
		if (defaultPrefix) {
			setPrefix(defaultPrefix.replace(/\D/g, ''));
		}
	}, [pPhone, defaultPrefix]);

	const handleNext = useCallback(async () => {
		if (!requiredPhone && !number) {
			onNext();
			return;
		}
		if (!number) {
			console.error('Invalid phone provided.');
			return;
		}
		if (!requireVerification) {
			onNext({
				prefix,
				number,
				normalized: '+' + prefix + number
			});
			return;
		}

		if (!showVerificationInput) {
			console.log('show verification input')
			setShowVerificationInput();
			return;
		}
	}, [requiredPhone, requireVerification, showVerificationInput, onNext, prefix, number])

	useEffect(() => {
		if (nextBtnRef && nextBtnRef.current) {
			console.info('Bind nextBtn on click in StepPhone');
			nextBtnRef.current.onclick = handleNext;
		}
	}, [nextBtnRef, handleNext]);

	function handleNumberChange(value: string) {
		setNumber(parseInt(value.replace(/\D/g, '')).toString());
	}

	function handleValidationSuccess() {
		onNext({
			prefix,
			number,
			normalized: phone
		});
	}

	return (
		<Grid
			container
			spacing={3}
		>
			{!showVerificationInput && (
				<Fragment>
					<Grid item sm={5} xs={12}>
						<PhonePrefixSelect
							label="Prefix"
							value={prefix}
							onChange={v => setPrefix(v ?? '43')}

						/>
					</Grid>
					<Grid item sm={7} xs={12}>
						<TextField
							fullWidth
							required={requiredPhone}
							label={t('common.phone')}
							value={number}
							onChange={handleNumberChange}
						/>
					</Grid>
				</Fragment>
			)}
			{showVerificationInput && (
				<Fragment>
					<Grid item xs={12}>
						<TextField
							disabled
							fullWidth
							required={requiredPhone}
							label={t('common.phone')}
							value={phone}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											disabled={loading}
											onClick={clearShowVerificationInput}
										>
											<EditIcon fontSize="inherit" />
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Verification
							{...{
								centerId,
								onValid: handleValidationSuccess,
								phone
							}}
						/>
					</Grid>
				</Fragment>
			)}
		</Grid>
	)
}
