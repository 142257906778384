import {createElement} from "react";
import {Link} from "@mui/material";

interface PhoneDisplayProps {
    phone: string;
    label?: string;
}

export function PhoneDisplay(props: PhoneDisplayProps) {
    const {phone} = props;
    const label = props.label || phone;
    return (<Link href={`tel:${phone}`}>{label}</Link>);
}
