import {createElement, useEffect, useState} from "react";
import {Button, Stack} from "@mui/material";
import {useLocalization} from "../i18n";
import {Checkbox, PhonePrefixSelect, TextField} from "@variocube/app-ui";

type SmsSettingsComponentProps = {
	sendSmsOnRental: boolean;
	smsCountryCode: string;
	smsTemplate: string;
    onSendSmsOnRentalChange: (sendSmsOnRental: boolean) => void;
	onSmsCountryCodeChange: (smsCountryCode: string) => void;
	onSmsTemplateChange: (smsTemplate: string) => void;
}

export function SmsSettingsComponent(props: SmsSettingsComponentProps) {
	const {
		sendSmsOnRental,
		smsCountryCode,
		smsTemplate,
		onSendSmsOnRentalChange,
		onSmsCountryCodeChange,
		onSmsTemplateChange,
	} = props;
	const {t} = useLocalization();

	function setStandardSmsText() {
		onSmsTemplateChange(t('settings.sendSmsOnRentalToBorrowerStdTemplate', {
			tenant: {
				name: '{{tenant.name}}'
			},
			rental: {
				itemName: '{{rental.itemName}}',
				borrower: {
					name: '{{rental.borrower.name}}',
					accessCode: '{{rental.borrower.accessCode}}'
				}
			},
			from: '{{from}}',
			until: '{{until}}'
		}));
	}

	return (
		<Stack
			direction="column"
			gap={2}
		>
			<div>
				<Checkbox
					label={t('settings.sendSmsOnRentalToBorrower.label')}
					value={sendSmsOnRental}
					onChange={onSendSmsOnRentalChange}
				/>
			</div>

			<div>
				<PhonePrefixSelect
					label={t('settings.smsCountryCode')}
					value={smsCountryCode ?? ''}
					onChange={v => onSmsCountryCodeChange(v ?? '')}
					disabled={!sendSmsOnRental}
				/>
			</div>

			<div>
				<TextField
					fullWidth
					multiline
					label={t('settings.smsText')}
					value={smsTemplate}
					onChange={onSmsTemplateChange}
					disabled={!sendSmsOnRental}
					inputProps={{
						style: {
							fontFamily: 'monospace',
							fontSize: 'smaller',
							lineHeight: '1.2em'
						}
					}}
				/>
			</div>

			<div>
				<Button
					size="small"
					variant="outlined"
					disabled={!sendSmsOnRental}
					onClick={setStandardSmsText}
				>
					{t('settings.standardSmsText')}
				</Button>
			</div>
		</Stack>
	)
}
