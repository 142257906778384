import {createApiFetcher} from "@variocube/app-ui";
import {authStorage} from "../Auth";

export const BaseUrl = "/api/v1";
export const BaseUrlV2 = "/api/v2";

export const api = createApiFetcher(BaseUrl, {
	"X-Requested-With": "XMLHttpRequest",
	"Authorization": `Bearer ${authStorage.accessToken}`,
});

export const apiV2 = createApiFetcher(BaseUrlV2, {
	"X-Requested-With": "XMLHttpRequest",
	"Authorization": `Bearer ${authStorage.accessToken}`,
});
