import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {DateTime} from "luxon";
import {
    Box,
    Button, Checkbox,
    Dialog, DialogActions, DialogContent,
    DialogTitle, FormControl, FormHelperText,
    Grid, InputLabel, ListItemText,
    MenuItem,
    Paper, Select,
    TextField,
    Typography,
    Alert
} from "@mui/material";
import {
    BreadcrumbItem,

    Breadcrumbs,
    Page,
    PagingSettings,
    PlainDate,
    PlainDatePicker
} from "@variocube/app-ui";
import {ApiError} from "../../Api";
import {getRentalTriggerUrl, getRentalTriggerUrlWithDateRange} from "./trigger";
import {exportRental, listOverdueRentals, listRentals, listRentalsCalendar} from "../../data/rentals";
import {listCostCenters} from "../../data/costcenters";
import {CostCenter, Rental} from "../../data/types";
import {overdueRentalPaging, rentalsPaging} from "../../data/pagings";
import {tenantUserStore} from "../../store/TenantUserStore";
import {TableSearchInput} from "../../components/TableSearchInput";
import {ErrorDialog} from "../../components/ErrorDialog";
import {Loading} from "../../components/Loading";
import {AddFab} from "../../components/AddFab";
import {RentalsCalendar} from "./RentalsCalendar";
import {RentalTable} from "./RentalTable";
import {useLocalization} from "../../i18n";
import {AlarmIcon, CheckBoardIcon, DownloadIcon, KeyboardArrowLeftIcon} from "../../theme";
import {RentalAppContainer} from "../../RentalAppContainer";
import {luxonDate, plainDateTimeNow} from "../../tools";
import { CrumbLink } from "../../components/CrumbLink";
import {useNavigate} from "react-router-dom";

enum RentalsListView {
    List = "List",
    Calendar = "Calendar"
}

class RentalsListViewLocalStorage {

    static StorageKey = 'RentalsListView';

    get view(): RentalsListView {
        const value = localStorage.getItem(RentalsListViewLocalStorage.StorageKey);
        if(value) {
            return value as RentalsListView;
        }
        return RentalsListView.Calendar;
    }

    set view(selected: RentalsListView) {
        localStorage.setItem(RentalsListViewLocalStorage.StorageKey, selected);
    }

}

const rentalsListViewLocalStorage = new RentalsListViewLocalStorage();

export const RentalList = () => {
	const navigate = useNavigate();

    const [page, setPage] = useState<Page<Rental>>();
    const [rentals, setRentals] = useState<Rental[]>();
    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
    const [view, setView] = useState<RentalsListView>(rentalsListViewLocalStorage.view);
    const [inProgress, setInProgress] = useState(true);
    const [exportMode, setExportMode] = useState(false);

    const [exportFrom, setExportFrom] = useState(plainDateTimeNow().subtract({ days: 7 }).toPlainDate());
    const [exportUntil, setExportUntil] = useState(plainDateTimeNow().toPlainDate());
    const [costCenterUuids, setCostCenterUuids] = useState<string[]>([]);

    const [error, setError] = useState<ApiError>();

    const loadRentals = useCallback((from: DateTime, until: DateTime) => {
        listRentalsCalendar(tenantUserStore.getTenantId(), from, until)
            .then(setRentals)
            .catch(setError);
    }, [setRentals, setError]);

    const loadCostCenters = useCallback(() => {
        listCostCenters(tenantUserStore.getTenantId())
            .then(p => setCostCenters(p.content.sort((c1, c2) => c1.name.localeCompare(c2.name))))
            .catch(setError);
    }, [setCostCenters, setError])

    const loadPage = useCallback((needle?: string) => {
        setInProgress(true);
        listRentals(tenantUserStore.getTenantId(), rentalsPaging, needle)
            .then(p => {
                setInProgress(false);
                setPage(p)
            })
            .catch(e => {
                rentalsPaging.resetSettings();
                setInProgress(false);
                setError(e);
            })
    }, [setPage, setError, setInProgress]);

    useEffect(() => {
        const now = DateTime.now();
        const from = now.set({ day: 1 });
        const until = now.set({ day: now.daysInMonth });
        loadRentals(from, until);
        loadPage();
        loadCostCenters();
    }, []);

    const handleSearchByForeignKey = (needle?: string) => {
        rentalsPaging.updateSettings({
            ...rentalsPaging.getSettings(),
            pageNumber: 0
        });
        loadPage(needle);
        handleRentalListViewChanged(RentalsListView.List);
    }

    const handleRentalListViewChanged = (view: RentalsListView) => {
        rentalsListViewLocalStorage.view = view;
        setView(view);
    };

    const handleSelectRentalByUuid = (rentalUuid: string) => {
        if (rentals && rentals.length > 0) {
            const rental = rentals.find(r => r.uuid === rentalUuid);
            if (rental) {
                navigate(`/rentals/${rental.uuid}`);
            }
        }
    }

    const requestExportation = async () => {
        setInProgress(true);
        try {
            await exportRental(tenantUserStore.getTenantId(), {
                from: luxonDate(exportFrom).toJSDate(),
                until: luxonDate(exportUntil).toJSDate(),
                costCenterUuids
            });
        } catch (error) {
            console.error('ERROR', error);
        }
        setInProgress(false);
    }

    const handlePageChange = (settings: PagingSettings<any>) => {
        rentalsPaging.updateSettings({
            ...rentalsPaging.getSettings(),
            ...settings
        });
        loadPage();
    }

    const {t} = useLocalization();
    return (
        <RentalAppContainer title={t('rentals.plural')}>
            <Grid container spacing={3} sx={{ justifyContent: 'flex-end' }}>
                <Grid item style={{ flexGrow: 1 }}>
                    <Breadcrumbs>
                        <BreadcrumbItem>{t('rentals.plural')}</BreadcrumbItem>
                    </Breadcrumbs>
                    <Box my={1} />
                    <Typography variant="h4">{t('rentals.plural')}</Typography>
                </Grid>
                <Grid item>
                    <TableSearchInput label={t('rentals.foreignId')} onSubmit={handleSearchByForeignKey} />
                </Grid>
                <Grid item>
                    <TextField variant="outlined" fullWidth select label={t('rentals.listView.label')}
                               value={view} size="small"
                               onChange={(event) => handleRentalListViewChanged(event.target.value as any)}>
                        <MenuItem key={RentalsListView.Calendar} value={RentalsListView.Calendar}>{t('rentals.listView.Calendar')}</MenuItem>
                        <MenuItem key={RentalsListView.List} value={RentalsListView.List}>{t('rentals.listView.List')}</MenuItem>
                    </TextField>
                </Grid>
                <Grid item>
                    <Button variant="outlined" style={{ paddingTop: 7, paddingBottom: 7 }}
                            onClick={() => navigate("/requests")} startIcon={<CheckBoardIcon/>}>
                        {t('rentals.requests.plural')}
                    </Button>
                </Grid>
                {tenantUserStore.staff && (
                    <Fragment>
                        <Grid item>
                            <Button variant="outlined" style={{ paddingTop: 7, paddingBottom: 7 }}
                                    onClick={() => navigate("/rentals/overdue")} startIcon={<AlarmIcon/>}>
                                {t('rentals.overdue')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" style={{ paddingTop: 7, paddingBottom: 7 }}
                                    onClick={() => setExportMode(true)} startIcon={<DownloadIcon/>}>
                                {t('actions.export')}
                            </Button>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
            <Box my={3} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        {rentals && view == RentalsListView.Calendar && (
                            <Box p={2}>
                                <RentalsCalendar rentals={rentals}
                                                 onRangeChanged={loadRentals}
                                                 onSelectRental={handleSelectRentalByUuid}
                                                 onSelectSlot={(from, until) => {
                                                     navigate(getRentalTriggerUrlWithDateRange(from, until))
                                                 }} />
                            </Box>
                        )}
                        {page != undefined && view == RentalsListView.List && (
                            <RentalTable page={page} pageable={rentalsPaging.getSettings()}
                                         inProgress={inProgress} onPagingChange={handlePageChange} />
                        )}
                    </Paper>
                </Grid>
            </Grid>
            <Dialog open={exportMode} fullWidth maxWidth="sm">
                <DialogTitle>{t('actions.export')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <PlainDatePicker
                                fullWidth label={t('common.from')}
                                value={exportFrom}
                                onChange={d => setExportFrom(d as PlainDate)}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <PlainDatePicker
                                fullWidth label={t('common.until')}
                                value={exportUntil}
                                onChange={d => setExportUntil(d as PlainDate)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>{t('costCenters.plural')}</InputLabel>
                                <Select
                                    multiple label={t('costCenters.plural')}
                                    value={costCenterUuids}
                                    onChange={({target}) => setCostCenterUuids(target.value as any)}
                                    renderValue={(v: any) => v.length + ' ' + t('costCenters.plural')}
                                    MenuProps={{ anchorEl: null }}
                                >
                                    {costCenters.map(c => (
                                        <MenuItem key={c.uuid} value={c.uuid}>
                                            <Checkbox checked={costCenterUuids.indexOf(c.uuid) > -1} />
                                            <ListItemText primary={c.name} secondary={c.description} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{t('rentals.export.costCenters.hint')}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={inProgress} variant="outlined" onClick={() => setExportMode(false)}>{t('actions.cancel')}</Button>
                    <Button disabled={inProgress} variant="contained" color="primary" onClick={requestExportation}>{t('actions.export')}</Button>
                </DialogActions>
            </Dialog>
			<AddFab onClick={() => navigate(getRentalTriggerUrl())} />
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}

export const OverdueRentalList = () => {
	const navigate = useNavigate();

    const [page, setPage] = useState<Page<Rental>>();
    const [inProgress, setInProgress] = useState(true);
    const [error, setError] = useState<ApiError>();

    const loadPage = useCallback(() => {
        setInProgress(true);
        listOverdueRentals(tenantUserStore.getTenantId(), overdueRentalPaging)
            .then(p => {
                setPage(p);
                setInProgress(false);
            })
            .catch(e => {
                overdueRentalPaging.resetSettings();
                setInProgress(false);
                setError(e);
            });
    }, [setPage, setError, setInProgress]);

    useEffect(() => {
        loadPage()
    }, []);

    const handlePageChange = (settings: PagingSettings<any>) => {
        overdueRentalPaging.updateSettings({
            ...overdueRentalPaging.getSettings(),
            ...settings
        });
        loadPage();
    }

    const {t} = useLocalization();
    return (
        <RentalAppContainer title={t('rentals.overdue')}>
            <Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{ flexGrow: 1 }}>
                    <Breadcrumbs>
                        <CrumbLink href='/rentals'>{t('rentals.plural')}</CrumbLink>
                        <BreadcrumbItem>{t('rentals.overdue')}</BreadcrumbItem>
                    </Breadcrumbs>
                    <Box my={1} />
                    <Typography variant="h4" gutterBottom>{t('rentals.overdue')}</Typography>
                </Grid>
                <Grid item>
                    <Button variant="outlined" style={{ padding: '7px 15px' }}
                            onClick={() => navigate("/rentals")} startIcon={<KeyboardArrowLeftIcon/>}>
                        {t('rentals.backButton')}
                    </Button>
                </Grid>
            </Grid>
            <Box my={3} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        {!page && (
                            <Box p={5}>
                                <Loading />
                            </Box>
                        )}
                        {page && (
                            <RentalTable
                                page={page} pageable={overdueRentalPaging.getSettings()}
                                inProgress={inProgress} onPagingChange={handlePageChange}
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}
