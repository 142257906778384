import {Box, Button, Grid, Paper, TableCell, TableRow, Typography} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs, ColumnType, ContentTable, Page, PagingSettings, Selector} from "@variocube/app-ui";
import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ApiError} from "../../Api";
import {TreeSwingGraphic} from "../../assets/svg";
import {AddFab} from "../../components/AddFab";
import {ErrorDialog} from "../../components/ErrorDialog";
import {TableSearchInput} from "../../components/TableSearchInput";
import {searchItems} from "../../data/items";
import {listModels} from "../../data/models";
import {modelsPaging} from "../../data/pagings";
import {Item, Model} from "../../data/types";
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer";
import {tenantUserStore} from "../../store/TenantUserStore";
import {ModelCard} from "./ModelCard";

export const ModelList = () => {
	const {t} = useLocalization();
	const navigate = useNavigate();

	useEffect(() => {
		setColumns({
			"name": {show: true, name: t("models.singular")},
			"category": {show: true, name: t("categories.singular")},
			"description": {show: true, name: t("common.description")},
		});
	}, [t]);

	const [page, setPage] = useState<Page<Model>>();
	const [inProgress, setInProgress] = useState(true);
	const [columns, setColumns] = useState<ColumnType>({});
	const [error, setError] = useState<ApiError>();
	const [needle, setNeedle] = useState<string>("");
	const [layout, setLayout] = useState<"table" | "cards">("cards");

	const [allItems, setAllItems] = useState<Item[]>([]);

	function initAllItems() {
		setInProgress(true);
		searchItems(tenantUserStore.getTenantId(), "", undefined, undefined)
			.then(page => {
				let enabledItems = page.content.filter(i => !i.disabled);
				setAllItems(enabledItems);
				console.log("Items ", enabledItems.length);
				setInProgress(false);
			});
	}

	const loadPage = useCallback(async (n?: string) => {
		n = n || needle;
		setInProgress(true);
		try {
			const page = await listModels(tenantUserStore.getTenantId(), modelsPaging, n);
			setPage(page);
		} catch (error) {
			modelsPaging.resetSettings();
			setError(error as any);
		}
		setInProgress(false);
		initAllItems();
	}, [setInProgress, setPage, setError]);

	useEffect(() => {
		loadPage().then();
	}, []);

	const handleSearch = (needle?: string) => {
		modelsPaging.updateSettings({
			...modelsPaging.getSettings(),
			pageNumber: 0,
		});
		setNeedle(needle || "");
		loadPage(needle).then();
	};

	const handlePageChange = (settings: PagingSettings<any>) => {
		modelsPaging.updateSettings({
			...modelsPaging.getSettings(),
			...settings,
		});
		loadPage(needle).then();
	};

	const showCell = (column: keyof typeof columns) => columns[column] && columns[column].show;

	function handleLayoutChange(value: any): any {
		setLayout(value);
	}

	function hasItem(modelUuid: string) {
		// return true;
		return allItems && allItems.filter(i => i.model && i.model.uuid == modelUuid).length > 0;
	}

	return (
		<RentalAppContainer title={t("models.plural")}>
			<Grid container spacing={3} style={{justifyContent: "flex-end"}}>
				<Grid item style={{flexGrow: 1}}>
					<Breadcrumbs>
						<BreadcrumbItem>{t("models.plural")}</BreadcrumbItem>
					</Breadcrumbs>
					<Box my={1} />
					<Typography variant="h4">{t("models.plural")}</Typography>
				</Grid>
				<Grid item style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
					<TableSearchInput label={t("actions.search")} onSubmit={handleSearch} />
					<Box mx={1} />

					{tenantUserStore.staff && (
						<Selector
							label={t("rentals.listView.label")}
							size="small"
							value={layout}
							onChange={v => handleLayoutChange(v as any)}
							options={[
								{label: t("rentals.listView.Cards"), value: "cards"},
								{label: t("rentals.listView.Table"), value: "table"},
							]}
						/>
					)}
					<Box mx={1} />
					{tenantUserStore.staff && (
						<Button
							style={{minWidth: 250}}
							variant="outlined"
							onClick={() => navigate("/models/import")}
						>
							{t("models.import.title")}
						</Button>
					)}
				</Grid>
			</Grid>
			<Box my={3} />
			<Grid container spacing={3}>
				<Grid item xs={12}>
					{layout === "table" && (
						<Paper>
							<ContentTable
								page={page}
								pageable={modelsPaging.getSettings()}
								onPageableChange={handlePageChange}
								inProgress={inProgress}
								columns={columns}
								onColumnsChange={c => setColumns(c)}
								renderTableBody={
									<Fragment>
										{page && page.content.map(m => (
											<TableRow
												key={"model-" + m.uuid}
												onClick={() => navigate("/models/" + m.uuid)}
											>
												{showCell("name") && <TableCell>{m.name}</TableCell>}
												{showCell("category") && <TableCell>{m.category?.name}</TableCell>}
												{showCell("description") && <TableCell>{m.description}</TableCell>}
											</TableRow>
										))}
									</Fragment>
								}
								renderEmptyContent={
									<Fragment>
										<TableRow>
											<TableCell colSpan={3}>
												<Box p={6}>
													<Typography variant="h6" align="center">
														{t("models.noModels")}
													</Typography>
												</Box>
												<TreeSwingGraphic />
												<Box p={6} />
											</TableCell>
										</TableRow>
									</Fragment>
								}
							/>
						</Paper>
					)}
					{layout === "cards" && (
						<Fragment>
							<Grid container spacing={3}>
								{page
									&& page.content.filter(model => hasItem(model.uuid)).map(model => (
										<ModelCard key={model.uuid} model={model} />
									))}
							</Grid>
						</Fragment>
					)}
				</Grid>
			</Grid>
			{tenantUserStore.staff && <AddFab onClick={() => navigate("/models/create")} />}
			{error && <ErrorDialog error={error} onClose={() => setError(undefined)} />}
		</RentalAppContainer>
	);
};
