import {createElement} from "react";
import {Chip} from "@mui/material";
import {TicketState} from "../../data/types";
import {useLocalization} from "../../i18n";

export function TicketStateDisplay({state}: { state: TicketState }) {
    const color = state == TicketState.Open ? '#f44336' : '#1b5e20';
	const {e} = useLocalization();
    return (
		<Chip
			label={e('tickets.states', state)}
			style={{ color: 'white', backgroundColor: color }}
		/>
	);
}
