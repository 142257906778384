import {Tenant} from "../data/types";
import {RentalAppContainer} from "../RentalAppContainer";
import {createElement} from "react";
import {Button, Card, CardActions, CardContent, CardHeader, Grid, Typography} from "@mui/material";
import {useLocalization} from "../i18n";
import {AuthLoginPath} from "../Auth";
import {useNavigate} from "react-router-dom";


interface WelcomeScreenProps {
	tenant: Tenant;
}

export function WelcomeScreen({tenant}: WelcomeScreenProps) {
	const {t, language} = useLocalization();

	const navigate = useNavigate();

	return (
		<RentalAppContainer title={tenant.name}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h1">{tenant.name}</Typography>
				</Grid>
				<Grid item xs={12} sm={6} md={8}>
					<Grid container spacing={3}>
						{language == "en" &&
							<Grid item xs={12}>
								<Typography variant="body1" sx={{whiteSpace: "pre-wrap"}}>{tenant.rentalInfo.en}</Typography>
							</Grid>
						}
						{language == "de" &&
							<Grid item xs={12}>
								<Typography variant="body1" sx={{whiteSpace: "pre-wrap"}}>{tenant.rentalInfo.de}</Typography>
							</Grid>
						}
					</Grid>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<Card>
						<CardHeader title={t("home.welcome")} />
						<CardContent>
							<Typography variant="body1">{t("home.info")}</Typography>
						</CardContent>
						<CardActions>
							<Button onClick={() => navigate(AuthLoginPath)}>{t("home.login")}</Button>
							<Button onClick={() => navigate("/signup")}>{t("home.signup")}</Button>
						</CardActions>
					</Card>
				</Grid>
			</Grid>
		</RentalAppContainer>
	);
}
