import {Temporal} from "@js-temporal/polyfill";
import {Alert, Box, Button, Grid, List, ListItem, ListItemText, Paper, Typography} from "@mui/material";
import {
	getStartOfAccessible,
	getEndOfAccessible,
	PlainDateTime,
	PlainDateTimePicker,
	toZonedDateTime,
	toZonedDateTimeGeneral,
	getEndOfAccessibleToday,
	SiteAccessibility, isInAccesssibleTime, Now
} from "@variocube/app-ui";
import {DateTime} from "luxon";
import {createElement, useEffect, useMemo, useState} from "react";
import {DateComponent, Display} from "../../components/DateComponent";
import {computeAvailability} from "../../data/rentals";
import {Item, RentalAlternativeTimeframe, RentalAvailabilityCheckResponse} from "../../data/types";
import {useLocalization} from "../../i18n";
import {tenantUserStore} from "../../store/TenantUserStore";
import {instantToPlainDatetime, luxonDate} from "../../tools";
import {CubeAccessibilityComponent} from "../cubes/CubeAccessibilitycomponent";

type CreateWizardDateRangeProps = {
	item: Item;
	from: DateTime;
	until: DateTime;
	onDateTimeChange: (from: DateTime, until: DateTime) => void;
	onAvailabilityChange: (availability?: RentalAvailabilityCheckResponse) => void;
	onAlternativeItemSelect: (item: Item) => void;
	onAlternativeTimeframeChange: (timeframe: RentalAlternativeTimeframe) => void;
};


export const CreateWizardDateRange = (props: CreateWizardDateRangeProps) => {
	const item = props.item;

	const [from, setFrom] = useState(instantToPlainDatetime(props.from));
	const [until, setUntil] = useState(instantToPlainDatetime(props.until));
	const [availability, setAvailability] = useState<RentalAvailabilityCheckResponse | undefined>(undefined);
	const [siteAccessibility, setSiteAccessibility] = useState<SiteAccessibility>();

	useEffect(() => {
		if (item) {
			checkAvailability(item.uuid, instantToPlainDatetime(props.from), instantToPlainDatetime(props.until));
		}
	}, [item]);

	const validTimeframe = useMemo(() => {
		// check for max rent period
		let maxUntil = until;
		if (item) {
			if (item.maxRentPeriod) {
				maxUntil = from.add({
					hours: item.maxRentPeriod,
				});
			} else if (item.model && item.model.maxRentPeriod) {
				maxUntil = from.add({
					hours: item.model.maxRentPeriod,
				});
			}
		}
		return PlainDateTime.compare(from, until) < 1 && PlainDateTime.compare(until, maxUntil) < 1;
	}, [from, until, item]);

	const checkAvailability = (itemUuid: string, from: PlainDateTime, until: PlainDateTime) => {
		computeAvailability(tenantUserStore.getTenantId(), {itemUuid, from: luxonDate(from), until: luxonDate(until)})
			.then(response => {
				setAvailability(response);
				props.onAvailabilityChange(response);
			})
			.catch(console.error);
	};

	const dateTimeChange = (key: "from" | "until", plainDateTime: PlainDateTime) => {
		switch (key) {
			case "from":
				plainDateTime = correctTimeToFixAccessibilityStart(plainDateTime);
				setFrom(plainDateTime);
				if (item && PlainDateTime.compare(plainDateTime, until) < 1) {
					checkAvailability(item.uuid, plainDateTime, until);
				}
				props.onDateTimeChange(luxonDate(plainDateTime), luxonDate(until));
				break;
			case "until":
				plainDateTime = correctTimeToFixAccessibilityUntil(plainDateTime);
				setUntil(plainDateTime);
				if (item && PlainDateTime.compare(from, plainDateTime) < 1) {
					checkAvailability(item.uuid, from, plainDateTime);
				}
				props.onDateTimeChange(luxonDate(from), luxonDate(plainDateTime));
				break;
		}
	};

	const itemChange = (item: Item) => {
		checkAvailability(item.uuid, from, until);
		props.onAlternativeItemSelect(item);
	};

	const timeframeChange = (timeframe: RentalAlternativeTimeframe) => {
		const from = instantToPlainDatetime(timeframe.from);
		const until = instantToPlainDatetime(timeframe.until);
		setFrom(from);
		setUntil(until);
		checkAvailability(item.uuid, from, until);
		props.onAlternativeTimeframeChange(timeframe);
		console.debug("timeframeChanged", timeframeChange);
	};

	const {t} = useLocalization();

	const onAccessibilityLoaded = (siteAccessibility: SiteAccessibility) => {
		setSiteAccessibility(siteAccessibility);
		const fromZDT = Now.zonedDateTimeISO();
		const untilZDT = toZonedDateTime(getEndOfAccessibleToday(siteAccessibility));
		setFrom(fromZDT.toPlainDateTime());
		setUntil(untilZDT.toPlainDateTime());
		props.onAlternativeTimeframeChange({
			from: luxonDate(fromZDT.toPlainDateTime()),
			until: luxonDate(untilZDT.toPlainDateTime())
		});
	};

	const correctTimeToFixAccessibilityStart = (plainDateTime: Temporal.PlainDateTime) => {
		if (siteAccessibility) {
			const plainDateTimeAsDate = new Date(Date.parse(plainDateTime.toString()));
			if (!isInAccesssibleTime(siteAccessibility, plainDateTime.toZonedDateTime("Europe/Vienna"))) {
				const fromZDT = toZonedDateTimeGeneral(getStartOfAccessible(siteAccessibility, plainDateTimeAsDate), plainDateTime);
				return fromZDT.toPlainDateTime();
			}
		}
		return plainDateTime;
	};

	const correctTimeToFixAccessibilityUntil = (plainDateTime: Temporal.PlainDateTime) => {
		if (siteAccessibility) {
			const plainDateTimeAsDate = new Date(Date.parse(plainDateTime.toString()));
			if (!isInAccesssibleTime(siteAccessibility, plainDateTime.toZonedDateTime("Europe/Vienna"))) {
				const untilZDT = toZonedDateTimeGeneral(getEndOfAccessible(siteAccessibility, plainDateTimeAsDate), plainDateTime);
				return untilZDT.toPlainDateTime();
			}
		}
		return plainDateTime;
	};

	return (
		<div>
			<Grid container spacing={3}>
				{item
					&& (
						<Grid item xs={12}>
							<Typography variant="h6">
								{t("rentals.create.dateRangeQuestion", {itemName: item.name ?? "Unknown Item"})}
							</Typography>
							<Box p={1} />
						</Grid>
					)}
				<Grid item lg={6} md={6} sm={6} xs={12}>
					<PlainDateTimePicker
						fullWidth
						label={t("common.from")}
						value={from}
						onChange={dt => dateTimeChange("from", dt as PlainDateTime)}
					/>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={12}>
					<PlainDateTimePicker
						fullWidth
						label={t("common.until")}
						value={until}
						onChange={dt => dateTimeChange("until", dt as PlainDateTime)}
					/>
				</Grid>
				{!validTimeframe && (
					<Grid item xs={12}>
						<Alert severity="error">
							{t("rentals.create.invalidTimeframe")}
						</Alert>
					</Grid>
				)}
				{item?.stored
					&& (
						<Grid item xs={12}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Typography variant="body2">
										<CubeAccessibilityComponent
											cubeId={item.stored.cubeId}
											onAccessibilityLoaded={onAccessibilityLoaded}
										/>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					)}

				{availability && validTimeframe && (
					<Grid item xs={12}>
						{!availability.available && (
							<Alert severity="warning">{t("rentals.create.conflictTimeframe")}</Alert>
						)}
						{availability.available && (
							<Alert severity="success">{t("rentals.create.validTimeframe")}</Alert>
						)}
					</Grid>
				)}
				{(availability?.currentBorrower) && (
					<Grid item xs={12}>
						<Alert severity="warning">
							{t("rentals.create.currentBorrower")}: {availability.currentBorrower}
						</Alert>
					</Grid>
				)}
				{availability && (availability.alternativeItem || availability.alternativeTimeframes.length > 0) && (
					<Grid item xs={12}>
						<Box my={1} />
						{availability.alternativeItem && (
							<Paper variant="outlined">
								<Box p={2}>
									<Typography variant="subtitle1">
										<strong>{t("rentals.create.alternative.item")}</strong>
									</Typography>
									<Grid container spacing={3}>
										<Grid item style={{flexGrow: 1}}>
											<Typography variant="subtitle1">
												{availability.alternativeItem.name}
											</Typography>
											<Typography variant="body2" color="textSecondary">
												{availability.alternativeItem.description}
											</Typography>
										</Grid>
										<Grid item>
											<Button
												variant="outlined"
												onClick={() => itemChange(availability.alternativeItem as Item)}
											>
												{t("rentals.create.changeItem")}
											</Button>
										</Grid>
									</Grid>
								</Box>
							</Paper>
						)}
						<Box my={1} />
						{availability.alternativeTimeframes && availability.alternativeTimeframes.length > 0 && (
							<Paper variant="outlined">
								<Box p={2}>
									<Typography variant="subtitle1">
										<strong>{t("rentals.create.alternative.timeframes")}</strong>
									</Typography>
								</Box>
								<List>
									{availability.alternativeTimeframes.map((tf, i) => (
										<ListItem
											key={"alt-timeframe-" + i}
											button
											onClick={() => timeframeChange(tf)}
										>
											<ListItemText
												primary={
													<div>
														From:{" "}
														<DateComponent date={tf.from} display={Display.Timestamp} />
														<br />
														Until:{" "}
														<DateComponent date={tf.until} display={Display.Timestamp} />
													</div>
												}
											/>
										</ListItem>
									))}
								</List>
							</Paper>
						)}
					</Grid>
				)}
			</Grid>
		</div>
	);
};
