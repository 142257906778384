import {createElement, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useLocalization} from "../../i18n";
import {Alert, IconButton, InputAdornment, Stack, TextField} from "@mui/material";
import {WizardContext} from "./context";
import {useFlag} from "@variocube/app-ui";
import EditIcon from "@mui/icons-material/Edit";
import {Verification} from "./verification";
import {verifyEmail} from "../../data/tenants";

interface StepEmailProps {
	centerId: string;
	requiredVerification: boolean;
	onNext: (email: string) => void;
	email?: string;
}

export function StepEmail({centerId, requiredVerification, onNext, email: pEmail}: StepEmailProps) {
	const {t} = useLocalization();
	const {nextBtnRef, loading} = useContext(WizardContext);

	const inputRef = useRef<HTMLInputElement|undefined>();

	const [email, setEmail] = useState(pEmail ?? '');
	const [existing, setExisting, clearExisting] = useFlag(false);
	const [showVerificationInput, setShowVerificationInput, clearShowVerificationInput] = useFlag(false);

	const handleNext = useCallback(async () => {
		if (!inputRef.current?.validity.valid) {
			console.error('Invalid email provided.');
			return;
		}

		clearExisting();

		const email = inputRef.current?.value ?? '';
		try {
			const {existed} = await verifyEmail(centerId, email);
			if (existed) {
				setExisting();
				return;
			}
		} catch (err) {
			console.error('Failed to check email existing', err);
			return;
		}

		if (!requiredVerification) {
			onNext(email);
			return;
		}

		if (!showVerificationInput) {
			setShowVerificationInput();
			return;
		}
	}, [centerId, inputRef, email, requiredVerification, showVerificationInput, onNext])

	useEffect(() => {
		if (nextBtnRef && nextBtnRef.current) {
			console.info('Bind nextBtn on click in StepEmail');
			nextBtnRef.current.onclick = handleNext;
		}
	}, [nextBtnRef, handleNext]);

	function handleValidationSuccess() {
		onNext(email);
	}

	function handleInputRef(ref: any) {
		const element = ref instanceof HTMLInputElement ? ref : ref?.node;
		if (element) {
			inputRef.current = element;
		}
	}

	return (
		<Stack
			sx={{ width: '100%' }}
			direction="column"
			spacing={3}
		>
			<TextField
				fullWidth
				required
				label={t('common.email')}
				type="email"
				inputRef={handleInputRef}
				value={email}
				onChange={ev => setEmail(ev.target.value)}
				disabled={showVerificationInput}
				InputProps={{
					endAdornment: showVerificationInput
						? (
							<InputAdornment position="end">
								<IconButton
									disabled={loading}
									onClick={clearShowVerificationInput}
								>
									<EditIcon fontSize="inherit" />
								</IconButton>
							</InputAdornment>
						)
						: undefined
				}}
			/>

			{existing && (
				<Alert severity="warning">
					{t('signup.emailExisted')}
				</Alert>
			)}

			{showVerificationInput && (
				<Verification
					{...{
						centerId,
						onValid: handleValidationSuccess,
						email
					}}
				/>
			)}
		</Stack>
	)
}
