import {DateLocalizer, DateLocalizerSpec, FormatInput, Culture, Formats} from "react-big-calendar";
import {luxonDate} from "../tools";
import { DateTime } from "luxon";

class LuxonLocalizerSpec implements DateLocalizerSpec {

    formats: Formats;

    constructor() {
        this.formats = {
            dateFormat: 'dd',
            dayFormat: 'dd EEE',
            weekdayFormat: 'ccc',

            timeGutterFormat: 't',

            monthHeaderFormat: 'MM/yyyy',
            dayHeaderFormat: 'dd.MM.yyyy',

            agendaDateFormat: 'EEE MMM dd',
            agendaTimeFormat: 't'
        };
    }

    format(value: FormatInput, format: string, _culture: Culture): string {
        const tmp = value as any;
        if(tmp.start || tmp.end) {
            const fullDateFormat = 'dd.MM.yyyy HH:mm';
            const begin = luxonDate(tmp.start).toFormat(fullDateFormat);
            const end = luxonDate(tmp.end).toFormat(fullDateFormat);
            return `${begin} - ${end}`;
        }
        const parsed = luxonDate(value);
        return parsed.toFormat(format);
    }

    firstOfWeek(culture: Culture): number {
        return 1;
    }

}

export function luxonLocalizer(): DateLocalizer {
    return new DateLocalizer(new LuxonLocalizerSpec());
}

export function tryParseDateTime(myVar: any) {
    if (typeof myVar === 'string' || myVar instanceof String)
        return DateTime.fromISO(myVar as string);
    else
        return myVar;
}
