import {
    Alert, Box, Button, DialogActions, DialogContent, Typography
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import { createElement } from "react";
import { useLocalization } from "../../i18n";
import { Item } from "../../data/types";
import { tenantUserStore } from "../../store/TenantUserStore";
import {getBox, removeItem} from "../../data/cubes";
import { BoxOperations } from "../cubes/BoxOperations";
import { Loading } from "../../components/Loading";
import {useAsync, useAsyncCallback} from "react-async-hook";

export type ItemRemoveDialogProps = {
    item: Item;
    handleClose: () => void;
    handleRemove: () => void;
}

export const ItemRemoveDialog = ({item, handleClose, handleRemove}: ItemRemoveDialogProps) => {
	const {t} = useLocalization();

	const {result: box, loading, error} = useAsync(async () => {
		if (item.stored) {
			return getBox(tenantUserStore.getTenantId(), item.stored.cubeId, item.stored.boxNumber);
		}
	}, [item])

	const {loading: removeLoading, error: removeError, execute: remove} = useAsyncCallback(async () => {
		console.debug('handleRemove', item);
		if (tenantUserStore.getTenantId() && item.stored) {
			await removeItem(tenantUserStore.getTenantId(), item.stored.cubeId, item.uuid);
			handleRemove();
		}
	});

    return (
		<Dialog open={true}>
			<DialogTitle>{t('items.remove.title')}</DialogTitle>
			<DialogContent>
				<Typography>{t('items.remove.message', { itemName: item.name })}</Typography>

				<div
					dangerouslySetInnerHTML={{ __html: t('items.remove.instructions') }}
				/>

				<Box my={1} />
				<Alert severity="info">
					<Typography>{t('items.remove.info')}</Typography>
				</Alert>


				<hr />

				{box && item.stored && <BoxOperations cubeId={item.stored.cubeId} boxNumber={box.number} showBreadcrumb={false}></BoxOperations>}
				{(error || removeError) && <Alert severity="error">{error?.message ?? removeError?.message ?? 'Failed to remove.'}</Alert>}
				{(loading || removeLoading) && <Loading />}
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" onClick={handleClose}>{t('actions.cancel')}</Button>
				{item.stored && <Button variant="contained" color="primary" onClick={remove}>{t('items.remove.title')}</Button>}
			</DialogActions>
		</Dialog>
	)
}
