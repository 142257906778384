import {createElement, useCallback, useEffect, useState} from "react";
import {CalculationCase, ItemDetails, Model, TaxedAmount} from "../../data/types";
import {
    Box, Button,
    Checkbox,
    FormControlLabel,
    Grid, IconButton,
    Switch,
    Typography,
	TextField,
	Alert
} from "@mui/material";
import {Selector} from "@variocube/app-ui";
import {listModels} from "../../data/models";
import {uploadUrl} from "../../data/upload-url";
import {tenantUserStore} from "../../store/TenantUserStore";
import {PeriodInputComponent} from "../../components/PeriodInputComponent";
import {TaxedAmountComponent} from "../../components/TaxedAmountComponent";
import {UrlOrUploadInput} from "../../components/UrlOrUploadInput";
import {CategorySelector} from "../categories";
import {useLocalization} from "../../i18n";
import { DeleteIcon } from "../../theme";

type ItemFormProps = {
    onChange: (details: ItemDetails) => void,
    details?: ItemDetails,
    modelUuid?: string
}

export const ItemForm = ({details, modelUuid, onChange}: ItemFormProps) => {
    const [form, setForm] = useState<ItemDetails>(details || {
        foreignId: '',
        name: '',
        description: '',
        images: [],
        modelUuid,
        maxRentPeriod: null,
        amount: { net: 0, gross: 0, tax: 0, taxRate: 20 },
        calculationCase: CalculationCase.PerUsage,
        disabled: false,
        needInspection: false
    });
    const [models, setModels] = useState<Model[]>([]);

    useEffect(() => {
        listModels(tenantUserStore.getTenantId())
            .then(page => setModels(page.content))
    }, []);

    useEffect(() => {
        if (modelUuid) handleInputChange(modelUuid, 'modelUuid');
    }, [modelUuid]);

    const updateForm = (data: ItemDetails) => {
        setForm(data);
        onChange(data);
    }

    const handleInputChange = (value: any, name?: string) => {
        if (name) {
            const categoryUuid = (name === 'modelUuid' && value) ? undefined : form.categoryUuid;
            updateForm({
                ...form,
                categoryUuid,
                [name]: value
            });
        }
    }

    const handlePeriodChange = (hours: number) => {
        updateForm({
            ...form,
            maxRentPeriod: hours
        });
    };

    const handleAmountChange = (amount: TaxedAmount) => {
        updateForm({
            ...form,
            amount
        })
    };

    const handleImageChange = (url: string, i: number) => {
        updateForm({
            ...form,
            images: form.images.map((p,pi) => (pi === i) ? url : p)
        });
    };

    const toggleAmount = () => {
        updateForm({
            ...form,
            amount: (form.amount) ? null : { net: 0, gross: 0, tax: 0, taxRate: 20 },
            calculationCase: (form.amount) ? null : CalculationCase.PerUsage
        });
    };

    const addImage = () => {
        updateForm({
            ...form,
            images: form.images.concat('')
        });
    };

	const {t, e} = useLocalization();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                        {form.modelUuid && (
                            <Typography variant="body2" color="textSecondary">{t('items.categoryHint')}</Typography>
                        )}
                        {!form.modelUuid && (
                            <CategorySelector value={details?.categoryUuid}
                                              onChange={v => handleInputChange(v, 'categoryUuid')}
                            />
                        )}
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Selector label={t('models.singular')}
                                  value={form.modelUuid} onChange={v => handleInputChange(v, 'modelUuid')}
                                  options={[
                                      { label: t('common.none'), value: '' },
                                      ...models.map(m => ({ label: m.name, value: m.uuid }))
                                  ]}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField variant="outlined" fullWidth
								   label={t('common.name')}
                                   name="name" value={form.name ?? ''}
                                   onChange={ev => handleInputChange(ev.target.value, 'name')}
                                   error={!form.name}
                                   helperText={!form.name ? t('error.input.requiredField') : undefined}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField variant="outlined" fullWidth
						   label={tenantUserStore.tenant?.centerId == 'hkl-baumaschinen' ? 'HKL-ID' : t('items.foreignId')}
                           name="foreignId" value={form.foreignId ?? ''}
                           onChange={ev => handleInputChange(ev.target.value, 'foreignId')} />
            </Grid>
            <Grid item xs={12}>
                <TextField variant="outlined" fullWidth
						   label={t('common.description')}
                           name="description" multiline rows={3}
                           value={form.description ?? ''}
                           onChange={ev => handleInputChange(ev.target.value, 'description')} />
            </Grid>
            <Grid item xs={12}>
                <PeriodInputComponent label={t('rentals.maxRentPeriod')} hours={form.maxRentPeriod} onPeriodChange={handlePeriodChange}/>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={form.disabled} onChange={e => handleInputChange(e.target.checked as any, 'disabled')} />}
                    label={t('items.disable.label')}
                />
                {form.disabled && (
                    <Alert severity="warning">{t('items.disable.hint')}</Alert>
                )}
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={form.needInspection} onChange={e => handleInputChange(e.target.checked as any, 'needInspection')} />}
                    label={t('items.needInspection.title')} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6"><strong>{t('items.cost.title')}</strong></Typography>
                <FormControlLabel
                    control={<Switch checked={form.amount != null} onChange={toggleAmount} />}
                    label={t('items.cost.label')}
                />
                <Box my={2}/>
                {form.amount && (
                    <Grid container spacing={3}>
                        <Grid item md={8}>
                            <TaxedAmountComponent autoUpdate
                                                  amount={form.amount}
                                                  onAmountChange={handleAmountChange}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <Selector label={t('calculationCase.label')}
                                      value={form.calculationCase}
                                      onChange={v => handleInputChange(v, 'calculationCase')}
                                      options={Object.values(CalculationCase).map(c => ({ label: e('calculationCase', c), value: c }))}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">{t('common.imageUrl')}</Typography>
                {form.images.map((p, i) => (
                    <Box key={'image-' + i} display="flex" alignContent="center" my={2}>
                        <UrlOrUploadInput label="URL" preview value={p} onChange={(url) => handleImageChange(url, i)}
                                          accept="image/png, image/jpeg, image/svg+xml"
                                          uploadUrlProvider={(file) => uploadUrl(tenantUserStore.getTenantId(), file)} />
                        <IconButton onClick={() => handleInputChange(form.images.filter((url, index) => index !== i), 'images')}><DeleteIcon /></IconButton>
                    </Box>
                ))}
                <Box mt={1} />
                <Button variant="outlined" color="primary" onClick={addImage}>{t('actions.addImage')}</Button>
            </Grid>
        </Grid>
    )
}
