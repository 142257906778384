import {MenuItem} from "@mui/material";
import {createElement} from "react";
import {PushButtonWithMenu} from "../../components/buttons/PushButtonWithMenu";
import {useLocalization} from "../../i18n";

type TicketActionsProps = {
	onSetState: () => void;
	onDeleteTicket: () => void;
};

export const TicketActions = (props: TicketActionsProps) => {
	const {onSetState, onDeleteTicket} = props;
	const {t} = useLocalization();
	return (
		<PushButtonWithMenu label={t("tickets.setState.title")} onClick={onSetState}>
			<MenuItem key="deleteTicket" onClick={onDeleteTicket}>{t("tickets.delete.title")}</MenuItem>
		</PushButtonWithMenu>
	);
};
