import {createElement,useEffect, useState} from "react";
import {WebAuth} from "auth0-js";

export const AUTH0_CLIENT_ID = 'U3AZMRtppUtLNrg0fKOVhgbZxjQjS9EE';
export const REDIRECT_URI = `${location.protocol}//${location.host}/auth`;
export const RETURN_URI = `${location.protocol}//${location.host}/`;

export const auth0 = new WebAuth({
    domain: 'variocube.eu.auth0.com',
    clientID: AUTH0_CLIENT_ID,
    redirectUri: REDIRECT_URI,
    audience: 'https://rental.variocube.com/',
    responseType: 'token id_token',
    scope: 'openid'
});


const StorageKey = 'auth';

type StoredAuth = {
    accessToken: string,
    idToken: string,
    expiresAt?: number
}

class AuthStorage {

    store(accessToken:string, idToken:string, expiresIn?:number) {
        // compute expiresAt
        const expiresAt = expiresIn && (expiresIn * 1000) + new Date().getTime();
        // store to localStorage
        localStorage.setItem(StorageKey, JSON.stringify({
            accessToken,
            idToken,
            expiresAt
        } as StoredAuth));
    }

    clear() {
        localStorage.removeItem(StorageKey);
    }

    private static readAuth() {
        const item = localStorage.getItem(StorageKey);
        return item && JSON.parse(item) as StoredAuth;
    }

    private static isNotExpired(auth: StoredAuth) {
        return !auth.expiresAt || new Date().getTime() < auth.expiresAt;
    }

    get accessToken() {
        const auth = AuthStorage.readAuth();
        if (auth && AuthStorage.isNotExpired(auth)) {
            return auth.accessToken;
        }
        else {
            return undefined;
        }
    }

    get idToken() {
        const auth = AuthStorage.readAuth();
        if (auth && AuthStorage.isNotExpired(auth)) {
            return auth.idToken;
        }
        else {
            return undefined;
        }
    }

    get authenticated() {
        const auth = AuthStorage.readAuth();
        if (auth) {
            return AuthStorage.isNotExpired(auth);
        }
        else {
            return false;
        }
    }
}

export const authStorage = new AuthStorage();


export const AuthLoginPath = '/login';
export const AuthLogoutPath = '/logout';
export const AuthCallbackProcessingPath = '/auth';

export const AuthCallbackRoute = () => {
    const [error, setError] = useState('');

    useEffect(() => {
        auth0.parseHash((error, authResult) => {
            if (error) {
                console.log('Could not process authentication callback', error);
                setError(error.description || 'Unknown error');
                return;
            }
            console.log('Got auth result', authResult);
            if (authResult && authResult.accessToken && authResult.idToken) {
                console.log('Processing auth result');
                authStorage.store(authResult.accessToken, authResult.idToken, authResult.expiresIn);
                setTimeout(() => {
                    window.location.replace('/');
                }, 1000);
            }
        });
    }, []);

    return (
        <div>
            {!error && (
                <span>processing login data...</span>
            )}
            {error && (
                <span>display error</span>
            )}
        </div>
    );
}

export const AuthLogin = () => {
    useEffect(() => {
        auth0.authorize();
    }, []);

    return (
        <div>
            logging in...
        </div>
    )
}

export const AuthLogout = () => {

    const clearCookies = () => {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    useEffect(() => {
        localStorage.clear();
        authStorage.clear();
        clearCookies();
        auth0.logout({
            clientID: AUTH0_CLIENT_ID,
            returnTo: RETURN_URI
        });
    }, []);

    return (
        <div>
            logging out...
        </div>
    )
}
