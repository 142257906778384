import {DateTime} from "luxon";
import {Page, Paging} from "@variocube/app-ui";
import {Api} from "../Api";
import {
    CreateRentalRequest,
    Rental,
    RentalAvailabilityCheckRequest,
    RentalAvailabilityCheckResponse,
    RentalExportRequest,
    RentalLog, RentalProlongResponse,
    RentalRequest,
    RentalRequestStatus,
    RentalState,
    Ticket,
    UserItemAccessRequest
} from "./types";
import {luxonDate} from "../tools";
import {authStorage} from "../Auth";
import {tenantUserStore} from "../store/TenantUserStore";
import {useLocalization} from "../i18n";

export function listRentalsCalendar(tenantId: string, from: DateTime, until: DateTime, modelUuid?: string, itemUuid?: string) {
    let filterParams = 'from=' + from.toJSDate().toISOString() + '&until=' + until.toJSDate().toISOString() + '&';
    if (modelUuid) filterParams += 'modelUuid=' + modelUuid + '&';
    if (itemUuid) filterParams += 'itemUuid=' + itemUuid + '&';
    if (filterParams) filterParams = '?' + filterParams.slice(0, -1);
    return Api.GET<Rental[]>(`tenants/${tenantId}/rentals/calendar${filterParams}`)
        .then(response => response.map(mapResponse));
}

export function listRentals(tenantId: string, paging: Paging, foreignId?: string, modelUuid?: string, itemUuid?: string, categoryUuid?: string, ) {
	const params = new URLSearchParams(paging.toQueryString());
	if (foreignId) {
		params.set("foreignId", foreignId);
	}
	if (modelUuid) {
		params.set("modelUuid", modelUuid);
	}
    if (categoryUuid) {
		params.set("categoryUuid", categoryUuid);
	}
	if (itemUuid) {
		params.set("itemUuid", itemUuid);
	}
    return Api.GET<Page<Rental>>(`tenants/${tenantId}/rentals?${params}`, { 'Accept': 'application/vnd.rentals.paged+json' })
        .then(response => ({
            ...response,
            content: response.content.map(mapResponse)
        }));
}

export function listOverdueRentals(tenantId: string, paging: Paging) {
    return Api.GET<Page<Rental>>(`tenants/${tenantId}/rentals/overdue` + paging.toQueryString(), { 'Accept': 'application/vnd.rentals.paged+json' })
        .then(response => ({
            ...response,
            content: response.content.map(mapResponse)
        }));
}

export function listRentalRequests(tenantId: string, paging: Paging, status?: RentalRequestStatus) {
    const params = paging.toQueryString(status ? '?status=' + status : undefined);
    return Api.GET<Page<RentalRequest>>(`tenants/${tenantId}/rentals/requests${params}`)
        .then(response => ({
            ...response,
            content: response.content.map(r => ({
                ...r,
                from: luxonDate(r.from),
                until: luxonDate(r.until),
                created: luxonDate(r.created)
            }))
        }));
}

export function computeAvailability(tenantId: string, request: RentalAvailabilityCheckRequest) {
    const convertedRequest = {
        ...request,
        from: request.from.toJSDate().toISOString(),
        until: request.until.toJSDate().toISOString()
    };
    return Api.POST<RentalAvailabilityCheckResponse>(`tenants/${tenantId}/rentals/availability`, convertedRequest)
        .then(response => ({
            ...response,
            alternativeTimeframes: response.alternativeTimeframes ? response.alternativeTimeframes.map((timeframe: any) => ({
                from: luxonDate(timeframe.from),
                until: luxonDate(timeframe.until)
            })) : []
        }));
}

export function verifyItemUserAccess(tenantId: string, request: UserItemAccessRequest) {
    return Api.POST<{ blacklisted: boolean }>(`tenants/${tenantId}/rentals/item-user-access`, request);
}

export function getRental(tenantId: string, uuid: string) {
    return Api.GET<Rental>(`tenants/${tenantId}/rentals/${uuid}`).then(mapResponse);
}

export function getRentalRequest(tenantId: string, uuid: string) {
    return Api.GET<RentalRequest>(`tenants/${tenantId}/rentals/requests/${uuid}`)
        .then(r => ({
            ...r,
            from: luxonDate(r.from),
            until: luxonDate(r.until),
            created: luxonDate(r.created)
        }))
}

export function prolongRental(tenantId: string, uuid: string, until: DateTime) {
	console.log('pl until', until.toString())
    return Api.PUT<RentalProlongResponse>(`tenants/${tenantId}/rentals/${uuid}/prolong`, { until: until.toJSDate().toISOString() });
}

export function endRental(tenantId: string, uuid: string) {
    return Api.PUT<Rental>(`tenants/${tenantId}/rentals/${uuid}/end`, {}).then(mapResponse);
}

export function simulateRentalEvent(tenantId: string, rentalUuid: string, simulatedEvent: string) {
    return Api.PUT<Rental>(`tenants/${tenantId}/rentals/${rentalUuid}/simulate-event?simulatedEvent=${simulatedEvent}`, {}).then(mapResponse);
}

export function getRentalTicket(tenantId: string, uuid: string) {
    return Api.GET<Ticket>(`tenants/${tenantId}/rentals/${uuid}/ticket`)
        .catch(() => null);
}

export function createRental(tenantId: string, request: CreateRentalRequest) {
    const convertedRequest = {
        ...request,
        from: request.from.toJSDate().toISOString(),
        until: request.until.toJSDate().toISOString(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    return Api.POST<Rental>(`tenants/${tenantId}/rentals`, convertedRequest).then(mapResponse);
}

export function createRentalRequest(tenantId: string, request: CreateRentalRequest) {
    const convertedRequest = {
        ...request,
        from: request.from.toJSDate().toISOString(),
        until: request.until.toJSDate().toISOString(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    return Api.POST<RentalRequest>(`tenants/${tenantId}/rentals/requests`, convertedRequest)
        .then(r => ({
            ...r,
            from: luxonDate(r.from),
            until: luxonDate(r.until),
            created: luxonDate(r.created)
        }));
}

export function approveRentalRequest(tenantId: string, uuid: string) {
    return Api.PUT<RentalRequest>(`/tenants/${tenantId}/rentals/requests/${uuid}/approve`, {})
        .then(r => ({
            ...r,
            from: luxonDate(r.from),
            until: luxonDate(r.until),
            created: luxonDate(r.created)
        }));
}

export function rejectRentalRequest(tenantId: string, uuid: string, reason: string) {
    return Api.PUT<RentalRequest>(`/tenants/${tenantId}/rentals/requests/${uuid}/reject`, { reason })
        .then(r => ({
            ...r,
            from: luxonDate(r.from),
            until: luxonDate(r.until),
            created: luxonDate(r.created)
        }));
}

export function deleteRentalRequest(tenantId: string, uuid: string, reason: string) {
    return Api.DELETE(`/tenants/${tenantId}/rentals/requests/${uuid}`, { reason });
}

export function deleteRental(tenantId: string, uuid: string) {
    return Api.DELETE<void>(`tenants/${tenantId}/rentals/${uuid}`);
}

export function lookupColorCodeForRental(rental: Rental): { backgroundColor: string, color: string} {
    switch (rental.state) {
        case RentalState.Created:
            return { backgroundColor: '#9E9E9E', color: '#FFFFFF' };
        case RentalState.BorrowerPickedUp:
        case RentalState.LenderPickedUp:
        case RentalState.LenderReturned:
            return { backgroundColor: '#2B87C4', color: '#FFFFFF' };
        case RentalState.BorrowerReturned:
            return { backgroundColor: '#0F5102', color: '#FFFFFF' };
        default:
            const canAccess = tenantUserStore.canAccessRental(rental);
            if(canAccess) {
                return { backgroundColor: '#9E9E9E', color: '#FFFFFF' };
            }else {
                return { backgroundColor: '#DBDBDB', color: '#000000' };
            }
    }
}

export function lookupLabelForRentalState(rentalState: RentalState): string {
	const {t} = useLocalization();
	return t('rentals.states.' + rentalState as any);
}

export async function exportRental(tenantId: string, request: RentalExportRequest) {
    const response = await fetch(`/api/v1/tenants/${tenantId}/rentals/export?timestamp=` + new Date().getTime(), {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authStorage.accessToken}`,
            'X-Requested-With': 'XMLHttpRequest'
        },
        body: JSON.stringify(request)
    });
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = "rental-export.xlsx";
    document.body.appendChild(a);
    a.click();
    a.remove();
}

function mapResponse(response: any): Rental {
    return {
        ...response,
        from: luxonDate(response.from),
        until: luxonDate(response.until),
        created: luxonDate(response.created),
        updated: luxonDate(response.updated),
        logs: response.logs ? response.logs.map((l: any) => mapRentalLog(l)) : []
    };
}

function mapRentalLog(log: any): RentalLog {
    return {
        ...log,
        createdAt: luxonDate(log.createdAt)
    };
}

export class RentalWrapper {

    readonly rental: Rental;

    constructor(rental: Rental) {
        this.rental = rental;
    }

    inTimeSpan(ts: DateTime) {
        return ts >= this.rental.from && ts <= this.rental.until;
    }

    canProlong(dateTime: DateTime) {
        switch (this.rental.state) {
            case RentalState.Created:
            case RentalState.BorrowerPickedUp:
                return true;
            default:
                return false;
        }
    }
}
