import {useLocalization} from "../../i18n";
import {createElement, useCallback, useContext, useEffect, useState} from "react";
import {WizardContext} from "./context";
import {Divider, Grid} from "@mui/material";
import {CountrySelect, TextField} from "@variocube/app-ui";
import {StepDetailsData} from "./types";

interface StepDetailsProps {
	requiredName: boolean;
	requiredAddress: boolean;
	onNext: (data: StepDetailsData) => void;
	data?: StepDetailsData;
}

export function StepDetails({requiredName, requiredAddress, onNext, data: pData}: StepDetailsProps) {
	const {t} = useLocalization();
	const {nextBtnRef} = useContext(WizardContext);

	const [firstName, setFirstName] = useState(pData?.firstName ?? '');
	const [lastName, setLastName] = useState(pData?.lastName ?? '');
	const [addressLine1, setAddressLine1] = useState(pData?.address.addressLine1 ?? '');
	const [addressLine2, setAddressLine2] = useState(pData?.address.addressLine2 ?? '');
	const [zipcode, setZipcode] = useState(pData?.address.zipcode ?? '');
	const [city, setCity] = useState(pData?.address.city ?? '');
	const [country, setCountry] = useState(pData?.address.country || 'AT');

	const handleNext = useCallback(() => {
		if (requiredName && (!firstName || !lastName)) {
			console.error('Invalid name details.');
			return;
		}

		if (requiredAddress && (!addressLine1 || !zipcode || !city || !country)) {
			console.error('Invalid address details.');
			return;
		}

		onNext({
			firstName,
			lastName,
			address: {
				addressLine1,
				addressLine2,
				zipcode,
				city,
				country
			}
		});
	}, [firstName, lastName, addressLine1, addressLine2, zipcode, city, country, onNext])


	useEffect(() => {
		if (nextBtnRef && nextBtnRef.current) {
			console.info('Bind nextBtn on click in StepDetails');
			nextBtnRef.current.onclick = handleNext;
		}
	}, [nextBtnRef, handleNext]);

	return (
		<Grid
			container
			spacing={3}
		>
			<Grid item sm={6} xs={12}>
				<TextField
					fullWidth
					required={requiredName}
					label={t('common.firstName')}
					value={firstName}
					onChange={setFirstName}
				/>
			</Grid>
			<Grid item sm={6} xs={12}>
				<TextField
					fullWidth
					required={requiredName}
					label={t('common.lastName')}
					value={lastName}
					onChange={setLastName}
				/>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					required={requiredAddress}
					label={t('common.address.addressLine1')}
					value={addressLine1}
					onChange={setAddressLine1}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					label={t('common.address.addressLine2')}
					value={addressLine2}
					onChange={setAddressLine2}
				/>
			</Grid>
			<Grid item sm={4} xs={12}>
				<TextField
					fullWidth
					required={requiredAddress}
					label={t('common.address.zipcode')}
					value={zipcode}
					onChange={setZipcode}
				/>
			</Grid>
			<Grid item sm={8} xs={12}>
				<TextField
					fullWidth
					required={requiredAddress}
					label={t('common.address.city')}
					value={city}
					onChange={setCity}
				/>
			</Grid>
			<Grid item xs={12}>
				<CountrySelect
					label={t('common.address.country')}
					value={country}
					onChange={v => setCountry(v ?? 'AT')}
				/>
			</Grid>
		</Grid>
	)
}
