import {createLocalizationContext, createSimpleTFunc} from "@variocube/app-ui";

export const {StorageLocalizationProvider, useLocalization} = createLocalizationContext<typeof import("./en.json")>({
    load: lang => import(`./${lang}.json`),
    missing: location.hostname == "localhost" ? key => `??${key}??` : "fallback",
    fallback: "en"
});

export const SUPPORTED_LANGUAGES = [
    { language: 'de', displayName: 'Deutsch' },
    { language: 'en', displayName: 'English' },
] as const;

export const columnSettingsLabels = () => {
	const {t} = useLocalization();
	return createSimpleTFunc({
		title: t('dataTable.title'),
		close: t('dataTable.close'),
		add: t('dataTable.add'),
		remove: t('dataTable.remove'),
		moveUp: t('dataTable.moveUp'),
		moveDown: t('dataTable.moveDown'),
	})
}

export const filterLabels = () => {
	const {t} = useLocalization();
	return createSimpleTFunc({
		none: t('filter.none'),
		search: t('filter.search'),
		reset: t('filter.reset'),
	})
}

export const formLabels = () => {
	const {t} = useLocalization();
	return createSimpleTFunc({
		save: t('form.save'),
		cancel: t('form.cancel'),
	})
}
