import {createElement,PropsWithChildren} from "react";
import {Container} from "@mui/material";
import {Helmet} from "react-helmet";
import {useLocalization} from "./i18n";

type RentalAppContainerProps = {
    title: string
}

export function RentalAppContainer({children, title}: PropsWithChildren<RentalAppContainerProps>) {
    const {t} = useLocalization();
    return (
        <Container>
            <Helmet>
                <title>{`${title} | ${t('appName')}`}</title>
            </Helmet>
            {children}
        </Container>
    )
}
