import {createElement, Fragment, PropsWithChildren, SyntheticEvent, useRef, useState} from "react";
import {
	Alert,
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	LinearProgress,
	Stack,
	Typography
} from "@mui/material";
import {SignupStepper} from "./stepper";
import {useFlag, VCLogo} from "@variocube/app-ui";
import {PhoneNumber, StepDetailsData} from "./types";
import {StepEmail} from "./step-email";
import {WizardNextButton, WizardNavigationStep, WizardBackButton} from "./navigation";
import {WizardContext} from "./context";
import {StepPhone} from "./step-phone";
import {StepDetails} from "./step-details";
import {StepPassword} from "./step-password";
import {useAsync} from "react-async-hook";
import {isTenantSignupDomain} from "../../data/tenants";
import {SmallLoading} from "../../components/Loading";
import {signup} from "../../data/signups";
import {useNavigate} from "react-router-dom";
import {useLocalization} from "../../i18n";

export function SignupWizard() {
	const {t} = useLocalization();
	const navigate = useNavigate();
	const {result: tenant, loading: tenantLoading, error: fetchTenantError} = useAsync(() => isTenantSignupDomain(location.hostname), [])

	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState<PhoneNumber>();
	const [detailsData, setDetailsData] = useState<StepDetailsData>({
		firstName: '',
		lastName: '',
		address: {
			addressLine1: '',
			addressLine2: '',
			zipcode: '',
			city: '',
			country: ''
		}
	});

	const [step, setStep] = useState(WizardNavigationStep.EmailStep);
	const [loading, setLoading] = useState(false);
	const nextBtnRef = useRef<HTMLButtonElement>(null);

	const [success, setSuccess] = useFlag(false);
	const [error, setError] = useState<Error>();

	function handleFormSubmit(ev: SyntheticEvent) {
		ev.preventDefault();
	}

	function handleStepEmail(email: string) {
		setEmail(email);
		setStep(1);
	}

	function handleStepPhone(phone?: PhoneNumber) {
		setPhone(phone);
		setStep(2);
	}

	function handleStepDetails(data: StepDetailsData) {
		setDetailsData(data);
		setStep(3);
	}

	async function handleStepPassword(password: string) {
		setError(undefined);
		setLoading(true);

		try {
			await signup({
				email,
				phone: phone?.normalized ?? '',
				password,
				...detailsData,
				signUpDomain: location.hostname,
				locale: navigator.language
			});
			setSuccess();
			setTimeout(() => navigate('/login', { replace: true }), 5000);
		} catch (err) {
			console.error('Failed to sign up', err);
			setError(err as any);
		}

		setLoading(false);
	}

	function handleBack() {
		setStep(step => step !== 0 ? step - 1 : 0);
	}

	const canNavigate = !loading && !success;
	return (
		<WizardContext.Provider
			value={{
				step, setStep,
				loading, setLoading,
				nextBtnRef
			}}
		>
			<Dialog
				fullWidth
				maxWidth="sm"
				open={true}
			>
				<form onSubmit={handleFormSubmit}>
					<DialogContent
						sx={{padding: 0}}
					>
						<StackContent>
							<VCLogo style={{ maxWidth: '100%' }} />
							<Box my={1}/>
							<Typography variant="h3" align="center">{t('signup.title')}</Typography>
						</StackContent>

						<Divider/>

						{tenantLoading && (
							<StackContent>
								<SmallLoading/>
							</StackContent>
						)}

						{fetchTenantError && (
							<StackContent>
								<Alert severity="error">
									Failed to fetch tenant.
								</Alert>
							</StackContent>
						)}

						{!tenantLoading && !tenant && (
							<StackContent>
								<Alert severity="warning">
									{t('signup.notSupported')}
								</Alert>
							</StackContent>
						)}

						{tenant && (
							<Fragment>
								<StackContent>
									<SignupStepper/>
								</StackContent>

								<Divider/>

								{!success && (
									<StackContent>
										{step === 0 && (
											<StepEmail
												centerId={tenant.centerId}
												requiredVerification={tenant.requiredEmailVerificationForSignUp}
												onNext={handleStepEmail}
												email={email}
											/>
										)}
										{step === 1 && (
											<StepPhone
												centerId={tenant.centerId}
												requiredPhone={tenant.requiredPhoneForSignUp}
												requireVerification={tenant.requiredPhoneVerificationForSignUp}
												onNext={handleStepPhone}
												phone={phone}
												defaultPrefix={tenant.smsCountryCode}
											/>
										)}
										{step === 2 && (
											<StepDetails
												requiredName={tenant.requiredNameForSignUp}
												requiredAddress={tenant.requiredAddressForSignUp}
												onNext={handleStepDetails}
												data={detailsData}
											/>
										)}
										{step === 3 && (
											<StepPassword
												tenantName={tenant.name}
												onNext={handleStepPassword}
											/>
										)}
									</StackContent>
								)}


								{error && (
									<StackContent>
										<Alert severity="error">
											{t('signup.error')}
										</Alert>
									</StackContent>
								)}

								{success && (
									<StackContent>
										<Alert severity="success">
											{tenant.requireManuallyApproval
												? t('signup.success.requireApproval')
												: t('signup.success.automaticallyApproval')
											}
										</Alert>
									</StackContent>
								)}

								{loading && (
									<LinearProgress/>
								)}
							</Fragment>
						)}
					</DialogContent>

					{tenant && (
						<DialogActions>
							{step !== 0 && (
								<WizardBackButton
									disabled={!canNavigate}
									onBack={handleBack}
								/>
							)}
							<Box flexGrow={1}/>
							<WizardNextButton
								ref={nextBtnRef}
								disabled={!canNavigate}
							/>
						</DialogActions>
					)}
				</form>
			</Dialog>
		</WizardContext.Provider>
	);
}

function StackContent({children}: PropsWithChildren<{}>) {
	return (
		<Stack
			direction="column"
			alignItems="center"
			justifyContent="center"
			p={3}
		>
			{children}
		</Stack>
	)
}
