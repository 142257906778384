import {createElement, useEffect, useState} from "react";
import {Box, Grid, Typography} from "@mui/material";
import {CostCenter, CreateRentalRequest, Item} from "../../data/types";
import {RentalItemSummary} from "./RentalItemSummary"; 
import {getCostCenter} from "../../data/costcenters";
import {tenantUserStore} from "../../store/TenantUserStore";
import {useLocalization} from "../../i18n";
import {MailIcon, PhoneIcon} from "../../theme";
import { TemporalRangeFormat } from "@variocube/app-ui";

type CreateWizardSummaryProps = {
    item: Item,
    itemAccess: boolean,
    rentalRequest: CreateRentalRequest
}

export const CreateWizardSummary = ({item, itemAccess, rentalRequest}: CreateWizardSummaryProps) => {
    const [costCenter, setCostCenter] = useState<CostCenter | undefined>();

    useEffect(() => {
        if(rentalRequest.costCenterUuid) {
            getCostCenter(tenantUserStore.getTenantId(), rentalRequest.costCenterUuid).then(setCostCenter);
        }else {
            setCostCenter(undefined);
        }
    }, [rentalRequest]);

	const {t} = useLocalization();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h6">{t('rentals.create.summaryQuestion')}</Typography>
                <Box p={1} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="overline">{t('items.singular')}</Typography>
                <RentalItemSummary item={item} itemAccess={itemAccess} small />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography variant="overline">{t('common.timeframe')}</Typography>
                <Typography variant="h6" component="p">
                    <TemporalRangeFormat from={rentalRequest.from?.toJSDate()} until={rentalRequest.until?.toJSDate()} dateStyle="short" timeStyle="short" />
                </Typography>
            </Grid>
            {rentalRequest.costCenterUuid &&
            <Grid item xs={12} sm={4}>
                <Typography variant="overline">{t('costCenters.singular')}</Typography>
                <Typography variant="h6" component="p">
                    {costCenter ? `${costCenter.name}: ${costCenter.description}` : rentalRequest.costCenterUuid}
                </Typography>
            </Grid>
            }
            <Grid item xs={12} sm={4}>
                <Typography variant="overline">{t('common.name')}</Typography>
                <Typography variant="h6" component="p">{rentalRequest.borrower.name}</Typography>
                {rentalRequest.borrower.email &&
                <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item><MailIcon/></Grid>
                    <Grid item>
                        <Typography variant="body1" component="p" style={{fontWeight: 'lighter'}}>
                            {rentalRequest.borrower.email}
                        </Typography>
                    </Grid>
                </Grid>
                }
                {rentalRequest.borrower.phone &&
                <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item><PhoneIcon/></Grid>
                    <Grid item>
                        <Typography variant="body1" component="p" style={{fontWeight: 'lighter'}}>
                            {rentalRequest.borrower.phone}
                        </Typography>
                    </Grid>
                </Grid>
                }
            </Grid>
            {rentalRequest.serviceItem &&
            <Grid item xs={12}>
                <Typography variant="overline">{t('rentals.serviceItem.name')}</Typography>
                <Typography variant="h6" component="p">{rentalRequest.serviceItem.name}</Typography>
                {rentalRequest.serviceItem.description &&
                <Typography variant="body1" component="p">{rentalRequest.serviceItem.description}</Typography>
                }
            </Grid>
            }
        </Grid>
    )
};
