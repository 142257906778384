import {createElement} from "react";
import {Box, Paper, Typography} from "@mui/material";

interface CopyUrlProps {
    url?: string;
    label: string;
}

export function CopyUrl(props: CopyUrlProps) {
    const {url, label} = props;
    return (
        <Paper elevation={0} style={{background: 'rgb(245, 245, 245)', cursor: url ? 'pointer' : undefined}} onClick={(_) => { if(url) { window.open(url, url); }}}>
            <Box p={1}>
                <Typography variant="caption" style={{wordBreak: 'break-word', overflowWrap: 'break-word'}}>{label}</Typography>
            </Box>
        </Paper>
    );
}
