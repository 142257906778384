import {createElement} from "react";
import {Chip} from "@mui/material";
import {BlockIcon, CheckCircleIcon} from "../../theme";
import {useLocalization} from "../../i18n";

export function CubeBoxAvailableComponent({available}: { available: boolean }) {
	const {t} = useLocalization();
    const icon = available ? <CheckCircleIcon /> : <BlockIcon />;
    const label = available ? t('common.enabled') : t('common.disabled');
    const color = available ? '#4caf50' : '#f44336';
    return (
        <Chip label={label} style={{color: '#ffffff', backgroundColor: color}} icon={icon} />
    );
}
