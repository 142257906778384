import {Box, Button, ButtonBase, TextField, useTheme} from "@mui/material";
import {ChangeEvent, createElement, useRef, useState} from "react";
import {useLocalization} from "../../i18n";

type Props = {
    onChange?: (file: File) => void,
    label?: string,
    disabled?: boolean,
}

export const FileInput = ({label, onChange, disabled}: Props) => {
    const ref = useRef(null);
    const [file, setFile] = useState<File>();

    function handleChange(ev: ChangeEvent<HTMLInputElement>) {
        const files = ev.target.files;
        if (files && files.length > 0) {
            const [file] = files;
            setFile(file);
            if (!!onChange) {
                onChange(file);
            }
        }
    }

	const theme = useTheme();
	const {t} = useLocalization();
    return (
        <Box position="relative" height={40} color={theme.palette.background.paper} borderBottom={4} style={{ opacity: disabled ? 0.5 : 1 }}>
            <Box position="absolute" top={0} bottom={0} left={0} right={0}>
                <TextField
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    label={label}
                    value={file?.name ?? '-'}
                    disabled={disabled}
                />
            </Box>
            <ButtonBase component="label" style={{ width: "100%", height: "100%", overflow: "hidden", zIndex: 10 }}>
                <input
                    ref={ref}
                    type="file"
                    accept="text/csv"
                    hidden
                    onChange={handleChange}
                    disabled={disabled}
                />
            </ButtonBase>
            <Button variant="contained" size="small" disableElevation style={{ position: 'absolute', zIndex: 5, right: 0 }}>
                {t('file.browse')}
            </Button>
        </Box>
    )
}
