import {createElement, Fragment, useEffect, useState} from "react";
import {RentalRequest, RentalRequestStatus} from "../../data/types";
import {
    approveRentalRequest,
    computeAvailability,
    deleteRentalRequest,
    getRentalRequest,
    rejectRentalRequest
} from "../../data/rentals";
import {tenantUserStore} from "../../store/TenantUserStore";
import {ApiError} from "../../Api";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    Paper,
    TextField,
    Typography,
	Alert, AlertTitle
} from "@mui/material";
import {Loading} from "../../components/Loading";
import {LabeledData} from "../../components/LabeledData";
import {CompactItemDisplay} from "../items";
import {ErrorDialog} from "../../components/ErrorDialog";
import {useLocalization} from "../../i18n";
import {green, red} from "@mui/material/colors";
import {CheckIcon, CLoseIcon} from "../../theme";
import {RentalAppContainer} from "../../RentalAppContainer";
import {BreadcrumbItem,  Breadcrumbs, TemporalRangeFormat} from "@variocube/app-ui";
import { CrumbLink } from "../../components/CrumbLink";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";

const approveBtnSx = {
	background: green[500],
	color: '#FFF',
	'&:hover': {
		background: green[800]
	}
};
const rejectBtnSx = {
	background: red[500],
	color: '#FFF',
	'&:hover': {
		background: red[800]
	}
};

export const RentalRequestView = () => {
	const navigate = useNavigate();
	const {uuid = ''} = useParams<{ uuid: string }>();

    const [request, setRequest] = useState<RentalRequest>();
    const [rejectReason, setRejectReason] = useState('');
    const [approveMode, setApproveMode] = useState(false);
    const [rejectMode, setRejectMode] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deleteMode, setDeleteMode] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [itemAvailable, setItemAvailable] = useState(false);
    const [error, setError] = useState<ApiError>();

    useEffect(() => {
        getRentalRequest(tenantUserStore.getTenantId(), uuid)
            .then(request => {
                setRequest(request);
                if (request.status === RentalRequestStatus.Pending) {
                    computeAvailability(tenantUserStore.getTenantId(), {
                        itemUuid: request.item.uuid,
                        from: request.from,
                        until: request.until
                    })
                        .then(a => {
                            setItemAvailable(a.available);
                        })
                }
            })
            .catch(setError);
    }, [uuid])

    const handleApprove = () => {
        if (request) {
            setInProgress(true);
            approveRentalRequest(tenantUserStore.getTenantId(), request.uuid)
                .then(setRequest)
                .catch(setError)
                .finally(() => {
                    setInProgress(false);
                    setApproveMode(false);
                })
        }
    }

    const handleReject = () => {
        if (request && rejectReason) {
            setInProgress(true);
            rejectRentalRequest(tenantUserStore.getTenantId(), request.uuid, rejectReason)
                .then(setRequest)
                .catch(setError)
                .finally(() => {
                    setInProgress(false);
                    setRejectMode(false);
                })
        }
    }

    const handleDelete = () => {
        if (request && deleteReason) {
            setInProgress(true);
            deleteRentalRequest(tenantUserStore.getTenantId(), request.uuid, deleteReason)
                .then(() => navigate('/requests'))
                .catch(setError)
        }
    }

    const crumb = (r: RentalRequest) => {
        return (!r.borrower.firstName && !r.borrower.lastName) ?
            r.item.name :
            `${[r.borrower.firstName, r.borrower.lastName].filter(s => !!s).join(' ')} - ${r.item.name}`;
    };

    const borrowerName = (r: RentalRequest) => {
        return (!r.borrower.firstName && !r.borrower.lastName) ?
            r.borrower.email :
            [r.borrower.firstName, r.borrower.lastName].filter(s => !!s).join(' ');
    }

    const {t, e} = useLocalization();
    return (
        <RentalAppContainer title={request ? crumb(request) : t('rentals.requests.singular')}>
            {!request && (
                <Box p={5}>
                    <Loading />
                </Box>
            )}
            {request && (
                <Fragment>
					<Breadcrumbs>
						<CrumbLink href='/rentals'>{t('rentals.plural')}</CrumbLink>
						<CrumbLink href='/requests/'>{t('rentals.requests.plural')}</CrumbLink>
						<BreadcrumbItem>{crumb(request)}</BreadcrumbItem>
					</Breadcrumbs>
					<Box my={1} />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item style={{ flexGrow: 1 }}>
                                    <Typography variant="h4" color="secondary" gutterBottom>
                                        {t('rentals.requests.singular')}: {request.item.name}
                                    </Typography>
                                </Grid>
                                {tenantUserStore.staff && request.status === RentalRequestStatus.Pending && (
                                    <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <Button variant="contained" sx={approveBtnSx} startIcon={<CheckIcon />} disabled={!itemAvailable} onClick={() => setApproveMode(true)}>{t('actions.approve')}</Button>
                                        <Box mx={1} />
                                        <Button variant="contained" sx={rejectBtnSx} startIcon={<CLoseIcon />} onClick={() => setRejectMode(true)}>{t('actions.reject')}</Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={2}>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <LabeledData label={t('rentals.borrower.label')}>
                                                {borrowerName(request)}
                                            </LabeledData>
                                        </Grid>
                                        <Grid item>
                                            <LabeledData label={t('items.singular')}>
                                                <Link style={{ cursor: 'pointer' }} onClick={() => navigate(`/items/${request.item.uuid}`)}>{request.item.name}</Link>
                                            </LabeledData>
                                        </Grid>
                                        <Grid item>
                                            <LabeledData label={t('common.timeframe')}>
                                                <TemporalRangeFormat from={request.from?.toJSDate()} until={request.until?.toJSDate()} dateStyle="short" />
                                            </LabeledData>
                                            {request.status === RentalRequestStatus.Pending && (
                                                <Fragment>
                                                    <Box my={1} />
                                                    {!itemAvailable && (
                                                        <Alert severity="warning">{t('rentals.create.conflictTimeframe')}</Alert>
                                                    )}
                                                    {itemAvailable && (
                                                        <Alert severity="success">{t('rentals.create.validTimeframe')}</Alert>
                                                    )}
                                                </Fragment>
                                            )}
                                        </Grid>
                                        <Grid item style={{flexGrow: 1}}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{t('common.status')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={2}>
                                            <Typography variant="overline">{t('common.status')}</Typography>
                                            <Typography>{e('rentals.requests.statuses', request.status)}</Typography>
                                            <Box my={2} />
                                            {RentalRequestStatus.Rejected === request.status && (
                                                <Fragment>
                                                    <Typography variant="overline">{t('common.reason')}</Typography>
                                                    <Typography>{request.rejectReason ?? '-'}</Typography>
                                                </Fragment>
                                            )}
                                            {RentalRequestStatus.Approved === request.status && request.rentalUuid && (
                                                <Fragment>
                                                    <Typography variant="overline">{t('rentals.singular')}</Typography>
                                                    <Box />
                                                    <Button variant="outlined" size="small" color="primary" onClick={() => navigate('/rentals/' + request.rentalUuid)}>{t('rentals.view')}</Button>
                                                </Fragment>
                                            )}
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{t('items.singular')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={2}>
                                            <CompactItemDisplay item={request.item} />
                                        </Box>
                                    </Paper>
                                </Grid>
                                {request.serviceItem &&
                                    <Fragment>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">{t('rentals.serviceItem.name')}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper>
                                                <Box p={2}>
                                                    <Typography variant="body1">{request.serviceItem.name}</Typography>
                                                    <Typography variant="body2">{request.serviceItem.description}</Typography>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                    </Fragment>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box my={3} />
                    {tenantUserStore.staff && request.status === RentalRequestStatus.Pending && (
                        <Alert severity="error" action={<Button onClick={() => setDeleteMode(true)}>{t('actions.delete')}</Button>}>
                            <AlertTitle>{t('rentals.requests.delete.title')}</AlertTitle>
                            {t('rentals.requests.delete.message')}
                        </Alert>
                    )}

                    <Dialog open={approveMode} fullWidth maxWidth="sm">
                        <DialogTitle>{t('rentals.requests.approval.title')}</DialogTitle>
                        <DialogContent>
                            <Typography>{t('rentals.requests.approval.text')}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="primary" onClick={handleApprove} disabled={inProgress}>{t('actions.approve')}</Button>
                            <Button variant="contained" onClick={() => setApproveMode(false)}>{t('actions.cancel')}</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={rejectMode} fullWidth maxWidth="sm">
                        <DialogTitle>{t('rentals.requests.rejection.title')}</DialogTitle>
                        <DialogContent>
                            <Typography>{t('rentals.requests.rejection.reasonPrompt')}</Typography>
                            <Box my={1} />
                            <TextField fullWidth multiline variant="outlined" rows={3} value={rejectReason} onChange={ev => setRejectReason(ev.target.value)} />
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="primary" onClick={handleReject} disabled={!rejectReason || inProgress}>{t('actions.reject')}</Button>
                            <Button variant="contained" onClick={() => setRejectMode(false)}>{t('actions.cancel')}</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={deleteMode} fullWidth maxWidth="sm">
                        <DialogTitle>{t('rentals.requests.delete.title')}</DialogTitle>
                        <DialogContent>
                            <Typography>{t('rentals.requests.delete.reasonPrompt')}</Typography>
                            <Box my={1} />
                            <TextField fullWidth multiline variant="outlined" rows={3} value={deleteReason} onChange={ev => setDeleteReason(ev.target.value)} />
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="primary" onClick={handleDelete} disabled={!deleteReason || inProgress}>{t('actions.delete')}</Button>
                            <Button variant="contained" onClick={() => setDeleteMode(false)}>{t('actions.cancel')}</Button>
                        </DialogActions>
                    </Dialog>
                </Fragment>
            )}
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}
