import {createElement, Fragment, useEffect, useState} from "react";
import {
    Box,
    Button,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography,
	Alert
} from "@mui/material";
import {ApiError} from "../../Api";
import {tenantUserStore} from "../../store/TenantUserStore";
import {CostCenter, CostCenterDetails} from "../../data/types";
import {deleteCostCenter, getCostCenter, putCostCenter} from "../../data/costcenters";
import {Loading} from "../../components/Loading";
import {LabeledData} from "../../components/LabeledData";
import {ErrorDialog} from "../../components/ErrorDialog";
import {ConfirmDialog} from "../../components/ConfirmDialog";
import {CostCenterForm} from "./CostCenterForm";
import {useLocalization} from "../../i18n";
import { EditIcon, PersonIcon } from "../../theme";
import {RentalAppContainer} from "../../RentalAppContainer";
import {Breadcrumbs, BreadcrumbItem} from "@variocube/app-ui";
import {CrumbLink} from "../../components/CrumbLink";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";

export const CostCenterView = () => {
	const navigate = useNavigate();
	const {uuid = ''} = useParams<{ uuid: string }>();

    const [costCenter, setCostCenter] = useState<CostCenter>();
    const [details, setDetails] = useState<CostCenterDetails>();
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [error, setError] = useState<ApiError>();

    const applyDetails = (costCenter: CostCenter) => {
        return JSON.parse(JSON.stringify({
            name: costCenter.name,
            description: costCenter.description,
            userUuids: costCenter.managers ? costCenter.managers.map(m => m.uuid) : []
        }))
    };

    useEffect(() => {
        getCostCenter(tenantUserStore.getTenantId(), uuid)
            .then(c => {
                setCostCenter(c);
                setDetails(applyDetails(c));
            })
            .catch(setError)
    }, []);

    const toggleEditMode = () => {
        setEditMode(!editMode);
        if (costCenter) {
            setDetails(applyDetails(costCenter));
        }
    };

    const toggleDeleteMode = () => {
        setDeleteMode(!deleteMode);
    };

    const update = () => {
        if (costCenter && details) {
            putCostCenter(tenantUserStore.getTenantId(), costCenter.uuid, details)
                .then(costCenter => {
                    setCostCenter(costCenter);
                    toggleEditMode();
                })
                .catch(setError);
        }
    };

    const handleDelete = () => {
        if (costCenter) {
            deleteCostCenter(tenantUserStore.getTenantId(), costCenter.uuid)
                .then(() => navigate('/cost-centers'))
                .catch(setError);
        }
    };

	const {t} = useLocalization();
    return (
        <RentalAppContainer title={costCenter?.name ?? t('costCenters.singular')}>
            {!costCenter && (
                <Box p={5}>
                    <Loading />
                </Box>
            )}
            {costCenter && details && (
                <Fragment>
					<Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                        <Grid item style={{flexGrow: 1}}>
							<Breadcrumbs>
								<CrumbLink href='/cost-centers'>{t('costCenters.plural')}</CrumbLink>
								<BreadcrumbItem>{costCenter.name}</BreadcrumbItem>
							</Breadcrumbs>
							<Box my={1} />
                            <Typography variant="h4">
                                {`${t('costCenters.singular')}: ${costCenter.name}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined"
                                    startIcon={<EditIcon/>}
                                    onClick={toggleEditMode}>{t('costCenters.edit.title')}</Button>
                        </Grid>
                    </Grid>
					<Box my={3} />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {!editMode && (
                                <Grid container spacing={3}>
                                    <Grid item md={6} sm={12}>
                                        <Paper>
                                            <Box p={3}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <LabeledData label={t('common.name')}>
                                                            {costCenter.name}
                                                        </LabeledData>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <LabeledData label={t('common.description')}>
                                                            {costCenter.description || '--'}
                                                        </LabeledData>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <Paper>
                                            <Box p={3}>
                                                <Typography variant="overline">{t('costCenters.managers')}</Typography>
                                                {costCenter.managers && costCenter.managers.length > 0 && (
                                                    <List dense>
                                                        {costCenter.managers.map(user => (
                                                            <ListItem key={user.uuid}>
                                                                <ListItemIcon>
                                                                    <PersonIcon />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={(user.firstName || user.lastName) ? `${user.firstName} ${user.lastName}` : '-'}
                                                                    secondary={user.email}
                                                                />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                )}
                                                {costCenter.managers && costCenter.managers.length == 0 && (
                                                    <Typography variant="body1">{t('costCenters.noManagers')}</Typography>
                                                )}
                                            </Box>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            )}
                            {editMode && (
                                <div>
                                    <Paper>
                                        <Box p={3}>
                                            <CostCenterForm onChange={setDetails} details={details} />
                                            <Box my={3}/>
                                            <Box display="flex" justifyContent="flex-end">
                                                <Button variant="contained" onClick={toggleEditMode}>{t('actions.cancel')}</Button>
                                                <Box mx={1}/>
                                                <Button variant="contained" color="primary" onClick={update}>{t('actions.save')}</Button>
                                            </Box>
                                        </Box>
                                    </Paper>
                                    <Box my={3}/>
                                    <Paper>
                                        <Alert severity="error">
                                            <Typography variant="subtitle1">{t('costCenters.delete.title')}</Typography>
                                            <Typography variant="body1">{t('costCenters.delete.hint')}</Typography>
                                            <Box my={1}/>
                                            <Button variant="outlined" onClick={toggleDeleteMode}>{t('actions.delete')}</Button>
                                        </Alert>
                                    </Paper>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Fragment>
            )}
            {deleteMode && (
                <ConfirmDialog title={t('costCenters.delete.title')} message={t('costCenters.delete.prompt')} resolve={handleDelete} reject={toggleDeleteMode}/>
            )}
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}
