import {AuthLogoutPath, authStorage} from "./Auth";

const BaseHeaders = {
    'Content-Type': 'application/json',
};

export class ApiError extends Error {

    constructor(private _code: number, private _message: string|undefined, private _path: string) {
        super(_message);
    }

    get code() {
        return this._code;
    }

    get message() {
        return this._message || `An error occurred with code (${this.code}) on path (${this.path}).`;
    }

    get path() {
        return this._path;
    }
}

export class Api {

    static SERVICE_URL = '/api/v1';

    private static buildHeaders(headers?: {[key: string]: string}) {
        if(authStorage.authenticated) {
            return {
                ...BaseHeaders,
                ...(headers || {}),
                'Authorization': `Bearer ${authStorage.accessToken}`,
                'X-Requested-With': 'XMLHttpRequest'
            }
        }
        return BaseHeaders;
    }

    private static buildUrl(path: string) {
        if(!path.startsWith('/')) {
            path = '/' + path;
        }
        return `${Api.SERVICE_URL}${path}`;
    }

    private static fetchData<T>(path: string, requestBody: RequestInit) {
        return new Promise(async (resolve: (value: T) => void, reject: (error: ApiError) => void) => {
            try {
                const response = await fetch(path, requestBody);
                // if (response.status == 401) {
                //     console.error('Unauthorized Request, trying to send user back to login.');
                //     reject(new ApiError(response.status, "Unauthorized", path));
                //     // location.replace(AuthLogoutPath);
                //     return;
                // }
                if (!response.ok) {
                    const message = await response.text();
                    console.error(`An error occurred, status code ${response.status} with message ${message} on path ${path}.`);
                    reject(new ApiError(response.status, message, path));
                    return;
                }
                try {
                    resolve(await response.json() as T);
                } catch (error) {
                    console.warn('Body is empty. Return nothing.');
                    resolve('' as any);
                }
            } catch (e) {
                console.error("Failed to fetch. Please contact support.");
                reject(e as any);
            }
        });
    }

    static GET<T>(path: string, headers?: {[key:string]:string}) {
        return Api.fetchData<T>(Api.buildUrl(path), {
            method: 'GET',
            headers: Api.buildHeaders(headers)
        });
    }

    static PUT<T>(path: string, object: any) {
        return Api.fetchData<T>(Api.buildUrl(path), {
            method: 'PUT',
            body: JSON.stringify(object),
            headers: Api.buildHeaders()
        });
    }

    static POST<T>(path: string, object: any) {
        return Api.fetchData<T>(Api.buildUrl(path), {
            method: 'POST',
            body: JSON.stringify(object),
            headers: Api.buildHeaders()
        });
    }

    static DELETE<T>(path: string, object?: any) {
        return Api.fetchData<T>(Api.buildUrl(path), {
            method: 'DELETE',
            body: JSON.stringify(object || {}),
            headers: Api.buildHeaders()
        });
    }

    static POST_FORM_DATA<T>(path: string, data: FormData) {
        return Api.fetchData<T>(Api.buildUrl(path), {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': `Bearer ${authStorage.accessToken}`,
                'X-Requested-With': 'XMLHttpRequest'
            }

        });
    }
}