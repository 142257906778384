import {useAsync, useAsyncCallback} from "react-async-hook";
import {tenantUserStore} from "../../store/TenantUserStore";
import {createElement, FormEvent, Fragment, useMemo, useState} from "react";
import {Alert, Button, Card, CardContent, CircularProgress, Divider, Grid, Paper, Typography} from "@mui/material";
import {changePassword, deleteUserRequest, me, refreshMyAccessCode} from "../../data/users";
import {ConfirmButton, TextField} from "@variocube/app-ui";
import {useLocalization} from "../../i18n";
import {useNavigate} from "react-router-dom";

export function AccountSettings() {
	const {t} = useLocalization();
	const navigate = useNavigate();

	const {result: user, error, loading} = useAsync(async () => me(tenantUserStore.getTenantId()), []);
	const {error: passwordError, loading: passwordLoading, execute: processChangePassword} = useAsyncCallback(async () => changePassword(tenantUserStore.getTenantId(), { password, confirmPassword }));
	const {error: accessCodeError, loading: accessCodeLoading, execute: processAccessCode} = useAsyncCallback(async () => refreshMyAccessCode(tenantUserStore.getTenantId()));
	const {error: deletionError, loading: deletionLoading, execute: processDeleteAccount} = useAsyncCallback(async () => deleteUserRequest(tenantUserStore.getTenantId(), "Manually request from user."));

	const [accessCode, setAccessCode] = useState(user?.accessCode);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	function handleChangePassword(ev: FormEvent) {
		ev.preventDefault();

		if (password.length > 0 && confirmPassword.length > 0 && password === confirmPassword) {
			processChangePassword()
				.then(() => {
					setPassword('');
					setConfirmPassword('');
				})
				.catch(console.error);
		}
	}

	function handleRefreshAccessCode() {
		processAccessCode()
			.then(u => setAccessCode(u.accessCode))
			.catch(console.error);
	}

	async function handleDeleteAccount() {
		try {
			await processDeleteAccount();
			navigate('/logout');
		} catch (err) {
			console.error('err', err);
		}
	}

	const passwordMismatch = useMemo(() => {
		return (password.length > 0 && confirmPassword.length > 0 && password !== confirmPassword);
	}, [password, confirmPassword])

	return (
		<Fragment>
			{loading && <CircularProgress />}
			{error && <Alert severity="error">{error.message ?? 'Error'}</Alert>}
			{user && (
				<Card>
					{!!user.uuid && (
						<Fragment>
							<CardContent>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Typography variant="overline">{t('settings.accessCode')}</Typography>
									</Grid>
									<Grid item sm={2} xs={12}>
										<Typography variant="h5">
											{accessCode ?? '-'}
										</Typography>
									</Grid>
									<Grid item sm={4} xs={12}>
										<Button
											variant="contained"
											onClick={handleRefreshAccessCode}
											disabled={accessCodeLoading}
										>
											{t('settings.refreshAccessCode')}
										</Button>
									</Grid>
									{accessCodeError && (
										<Grid item xs={12}>
											<Alert severity="error">{t('settings.refreshAccessCodeError')}</Alert>
										</Grid>
									)}
								</Grid>
							</CardContent>
							<Divider />
							<CardContent
								component="form"
								onSubmit={handleChangePassword}
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Typography variant="overline">{t('settings.changePassword')}</Typography>
									</Grid>
									<Grid item sm={4} xs={12}>
										<TextField
											fullWidth required
											disabled={passwordLoading}
											size="small"
											label={t('settings.password')}
											value={password}
											onChange={setPassword}
											type="password"
										/>
									</Grid>
									<Grid item sm={4} xs={12}>
										<TextField
											fullWidth required
											disabled={passwordLoading}
											size="small"
											label={t('settings.confirmPassword')}
											value={confirmPassword}
											onChange={setConfirmPassword}
											type="password"
											error={passwordMismatch}
											helperText={passwordMismatch ? 'Password mismatch' : undefined}
										/>
									</Grid>
									<Grid item sm={4} xs={12}>
										<Button
											disabled={passwordLoading}
											variant="contained"
											type="submit"
										>
											{t('settings.changePassword')}
										</Button>
									</Grid>
									{passwordError && (
										<Grid item xs={12}>
											<Alert severity="error">{t('settings.invalidPassword')}</Alert>
										</Grid>
									)}
								</Grid>
							</CardContent>
							<Divider />
							<CardContent>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Typography variant="overline">{t('settings.delete.button')}</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography>{t('settings.delete.description')}</Typography>
									</Grid>
									<Grid item sm={4} xs={12}>
										<ConfirmButton
											title={t('settings.delete.button')}
											confirmTitle={t('settings.delete.button')}
											cancel={t('actions.cancel')}
											onConfirm={handleDeleteAccount}
											variant="contained"
											color="error"
											disabled={deletionLoading}
										>
											<Typography>{t('settings.delete.description')}</Typography>
										</ConfirmButton>
									</Grid>
									{deletionError && (
										<Grid item xs={12}>
											<Alert severity="error">{t('settings.delete.error')}</Alert>
										</Grid>
									)}
								</Grid>
							</CardContent>
						</Fragment>
					)}
					{!user.uuid && (
						<CardContent>
							<Typography>Settings not available for current user. User UUID is missing.</Typography>
						</CardContent>
					)}
				</Card>
			)}
		</Fragment>
	)
}
