import {createElement, Fragment, useCallback, useContext, useEffect, useState} from "react";
import {Alert, InputAdornment, TextField} from "@mui/material";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import {useFlag} from "@variocube/app-ui";
import {WizardContext} from "./context";
import {addSignupVerification, verifySignupCode} from "../../data/tenants";
import {useAsync} from "react-async-hook";
import {useLocalization} from "../../i18n";

interface VerificationProps {
	centerId: string;
	onValid: () => void;
	email?: string;
	phone?: string;
}

export function Verification({centerId, onValid, email, phone}: VerificationProps) {
	const {t} = useLocalization();
	const {nextBtnRef, loading, setLoading} = useContext(WizardContext);

	const [verificationCode, setVerificationCode] = useState('');
	const [verified, setVerified] = useState<boolean>();
	const [verificationError, setVerificationError, clearVerificationError] = useFlag(false);

	const {error: addVerificationError, loading: addVerificationLoading} = useAsync(() => addSignupVerification(centerId, { email, phone }), [email, phone]);

	useEffect(() => {
		setLoading(addVerificationLoading);
	}, [addVerificationLoading]);

	const handleNext = useCallback(async () => {
		if (!verificationCode) {
			console.error('Verification code is missing.');
			return;
		}

		setLoading(true);

		let valid = false;
		try {
			const type = email ? 'Email' : 'Phone';
			const result = await verifySignupCode(type, verificationCode, (email || phone) ?? '');
			setVerified(result.valid);
			valid = result.valid;
		} catch (err) {
			setVerificationError();
		}

		setLoading(false);
		if (valid) {
			onValid();
		}

	}, [email, phone, verificationCode, onValid]);

	useEffect(() => {
		if (nextBtnRef && nextBtnRef.current) {
			console.info('Bind nextBtn on click in Verification');
			nextBtnRef.current.onclick = handleNext;
		}
	}, [nextBtnRef, handleNext]);

	return (
		<Fragment>
			{addVerificationError && (
				<Alert severity="error">
					{t('signup.verificationAddError')}
				</Alert>
			)}

			{verificationError && (
				<Alert severity="error">
					{t('signup.verificationError')}
				</Alert>
			)}

			{(!addVerificationLoading && !addVerificationError) && (
				<TextField
					fullWidth
					label="Verification code"
					value={verificationCode}
					onChange={ev => setVerificationCode(ev.target.value)}
					error={verified === false}
					helperText={verified === false ? t('signup.verificationInvalid') : undefined}
					disabled={verified || loading}
					InputProps={{
						endAdornment: verified
							? (
								<InputAdornment position="end">
									<CheckIcon color="success" />
								</InputAdornment>
							)
							: undefined
					}}
				/>
			)}
		</Fragment>
	)
}
