import {createElement} from "react";
import {Chip} from "@mui/material";
import {AppLockStatus} from "../../data/types";
import {ErrorIcon, LockIcon, LockOpenIcon, NotificationImportantIcon} from "../../theme";
import {useLocalization} from "../../i18n";

interface CubeBoxLockStateComponentProps {
    lockState?: AppLockStatus;
}

export function CubeBoxLockStateComponent(props: CubeBoxLockStateComponentProps) {
    const {lockState} = props;

    return(<Chip label={getLockStatusLabel(lockState)} icon={getLockStatusIcon(lockState)}/>);
}

function getLockStatusIcon(state?: AppLockStatus) {
    switch (state) {
        case AppLockStatus.Open:
            return <LockOpenIcon />;
        case AppLockStatus.Closed:
            return <LockIcon />;
        case AppLockStatus.Blocked:
            return <ErrorIcon />;
        case AppLockStatus.Breakin:
            return <NotificationImportantIcon />;
        default:
            return undefined;
    }
}

function getLockStatusLabel(state?: AppLockStatus): string {
	const {t, e} = useLocalization();
    if(state) {
        return e('boxes.lockState', state);
    }
    return t('boxes.lockState.unknown');
}
