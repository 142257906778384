import {createElement} from "react";
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from "@mui/material";

interface SimpleCheckBoxProps {
    label: string;
    checked?: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
    helperText?: string;
}

export function SimpleCheckBox({label, checked, onChange, disabled, helperText}: SimpleCheckBoxProps) {
    return (
        <FormControl>
            <FormControlLabel control={
                <Checkbox checked={checked} onChange={(event) => onChange(event.target.checked)} disabled={disabled} color="primary" />
            } label={label} />
            {helperText &&
            <FormHelperText>{helperText}</FormHelperText>
            }
        </FormControl>
    );
}
