import {createElement, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {DateTime} from "luxon";
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, Tab,
    Table, TableBody, TableCell, TableHead, TableRow, Tabs,
    Typography
} from "@mui/material";
import {
	BreadcrumbItem,
	Breadcrumbs,
	Page,
	PagingImpl,
	PagingSettings,
	Selector,
    TemporalRangeFormat
} from "@variocube/app-ui";
import {ApiError} from "../../Api";
import {Item, Model, ModelDetails, Rental} from "../../data/types";
import {listRentals, listRentalsCalendar} from "../../data/rentals";
import {deleteModel, getModel, listModelItems, updateModel} from "../../data/models";
import {tenantUserStore} from "../../store/TenantUserStore";
import {Loading} from "../../components/Loading";
import {ErrorDialog} from "../../components/ErrorDialog";
import {LabeledData} from "../../components/LabeledData";
import {ConfirmDialog} from "../../components/ConfirmDialog";
import {ImageWithPopover} from "../../components/ImageWithPopover";
import {CategoryLink} from "../categories";
import {ItemStateDisplay} from "../items";
import {RentalTable, RentalsCalendar} from "../rentals";
import {getRentalTriggerUrlWithDateRangeAndItem} from "../rentals/trigger";
import {ModelForm} from "./ModelForm";
import {ModelActions} from "./ModelActions";
import {useLocalization} from "../../i18n";
import {grey} from "@mui/material/colors";
import {RentalAppContainer} from "../../RentalAppContainer";
import { CrumbLink } from "../../components/CrumbLink";
import {CubeDescriptionDisplay} from "../../components/CubeDescriptionDisplay";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";

type CreateRentalForm = {
    from: DateTime,
    until: DateTime,
    itemUuid: string
}

const paging = new PagingImpl('mrentals_paging');

export const ModelView = () => {
	const navigate = useNavigate();
	const {uuid = ''} = useParams<{ uuid: string }>();

    const [model, setModel] = useState<Model>();
    const [details, setDetails] = useState<ModelDetails>();
    const [rentals, setRentals] = useState<Rental[]>();
    const [rentalPage, setRentalPage] = useState<Page<Rental>>();
    const [items, setItems] = useState<Item[]>([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [rentalMode, setRentalMode] = useState<'calendar'|'list'>('calendar');
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [rentalForm, setRentalForm] = useState<CreateRentalForm>();
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState<ApiError>();

    const canUpdate = useMemo(() => (details && details.name && !processing), [details, processing])

    const applyDetails = (model: Model) => {
        return JSON.parse(JSON.stringify({
            name: model.name,
            description: model.description,
            imageUrl: model.imageUrl,
            maxRentPeriod: model.maxRentPeriod,
            amount: model.amount ? JSON.parse(JSON.stringify(model.amount)) : null,
            calculationCase: model.calculationCase,
            categoryUuid: model.category?.uuid
        }));
    }

    const loadRentals = useCallback((from?: DateTime, until?: DateTime) => {
        const now = DateTime.now();
        if (!from) from = now.set({ day: 1 });
        if (!until) until = now.set({ day: now.daysInMonth });
        listRentalsCalendar(tenantUserStore.getTenantId(), from, until, uuid)
            .then(setRentals)
            .catch(setError);
    }, [setRentals, setError]);

    const loadRentalPage = useCallback(() => {
        listRentals(tenantUserStore.getTenantId(), paging, undefined, uuid)
            .then(setRentalPage)
            .catch(setError);
    }, [setRentalPage, setError])

    const fetch = useCallback(() => {
        getModel(tenantUserStore.getTenantId(), uuid)
            .then(m => {
                setModel(m);
                setDetails(applyDetails(m));
                listModelItems(tenantUserStore.getTenantId(), uuid)
                    .then(setItems)
                    .catch(setError);
            })
            .catch(setError);
    }, [setModel, setDetails, setItems, setError]);

    useEffect(() => {
        fetch();
        loadRentals();
    }, []);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    }

    const toggleDeleteMode = () => {
        setDeleteMode(!deleteMode);
    }

    const handleRentalModeChange = (mode: 'calendar'|'list') => {
        setRentalMode(mode);
        switch (mode) {
            case 'calendar':
                loadRentals();
                break;
            case 'list':
                loadRentalPage();
                break;
        }
    }

    const update = async () => {
        if (model && details) {
            setProcessing(true);
            try {
                const updated = await updateModel(tenantUserStore.getTenantId(), model.uuid, details);
                setModel(updated);
                setEditMode(false);
            } catch (error) {
                toggleError(error as any);
            }
            setProcessing(false);
        }
    }

    const handleDeleteModel = () => {
        if (model) {
            deleteModel(tenantUserStore.getTenantId(), model.uuid)
                .then(() => navigate('/models', { replace: true }))
                .catch(setError);
        }
    }

    const handleSelectRentalByUuid = (rentalUuid: string) => {
        if (rentals && rentals.length > 0) {
            const rental = rentals.find(r => r.uuid === rentalUuid);
            if (rental) {
                navigate(`/rentals/${rental.uuid}`);
            }
        }
    }

    const handleSelectSlot = (from: DateTime, until: DateTime) => {
        setRentalForm({
            from,
            until,
            itemUuid: ''
        });
    }

    const triggerCreateRentalUrl = () => {
        if (rentalForm && rentalForm.itemUuid) {
            navigate(getRentalTriggerUrlWithDateRangeAndItem(rentalForm.from, rentalForm.until, rentalForm.itemUuid));
        }
    }

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    const handleRentalPageChange = (settings: PagingSettings<any>) => {
        paging.updateSettings({
            ...paging.getSettings(),
            ...settings
        });
        loadRentalPage();
    }

	const {t, e} = useLocalization();
    return (
        <RentalAppContainer title={model?.name ?? t('models.singular')}>
            {!model && (
                <Box p={5}>
                    <Loading/>
                </Box>
            )}
            {(model && details) && (
                <Fragment>
                    <Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                        <Grid item style={{flexGrow: 1}}>
							<Breadcrumbs>
								<CrumbLink href='/models'>{t('models.plural')}</CrumbLink>
								<BreadcrumbItem>{model.name}</BreadcrumbItem>
							</Breadcrumbs>
							<Box my={1} />
                            <Typography variant="h4">{t('models.singular')}: {model.name}</Typography>
                        </Grid>
                        {tenantUserStore.staff && (
                            <Grid item>
                                <ModelActions
									model={model}
									onCreateItem={() => navigate('/items/create?modelUuid=' + model.uuid)}
									onEditModel={toggleEditMode} onDeleteModel={toggleDeleteMode}
								/>
                            </Grid>
                        )}
                    </Grid>
					<Box my={3} />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    {!editMode && (
                                        <Grid container spacing={3}>
                                            <Grid item md={8}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={3}>
                                                            <Grid item>
                                                                <LabeledData label={t('categories.singular')}>
                                                                    {model.category && <CategoryLink category={model.category} />}
                                                                </LabeledData>
                                                            </Grid>
                                                            <Grid item>
                                                                <LabeledData label={t('common.name')}>
                                                                    {model.name}
                                                                </LabeledData>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <LabeledData label={t('common.description')}>
                                                            {model.description}
                                                        </LabeledData>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={3}>
                                                            <Grid item>
                                                                <LabeledData label={t('rentals.maxRentPeriod')}>
                                                                    {model.maxRentPeriod ? model.maxRentPeriod + ' ' + (model.maxRentPeriod > 1 ? t('period.plural.Hours') : t('period.singular.Hours')) : '-'}
                                                                </LabeledData>
                                                            </Grid>
                                                            <Grid item>
                                                                <LabeledData label={t('models.cost.title')}>
                                                                    {(model.amount != null && model.calculationCase != null) ?
                                                                        `€ ${model.amount.gross} / ${e('calculationCase', model.calculationCase)}` :
                                                                        '-'
                                                                    }
                                                                </LabeledData>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={4}>
                                                {!model.imageUrl && (
                                                    <Box p={3} style={{ background: grey[100], width: '100%' }}>
                                                        <Typography variant="overline">No image</Typography>
                                                    </Box>
                                                )}
                                                {model.imageUrl && (
                                                    <ImageWithPopover src={model.imageUrl} />
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                    {editMode && (
                                        <div>
                                            <ModelForm details={details} onChange={setDetails} />
                                            <Box my={3}/>
                                            <Box display="flex" justifyContent="flex-end">
                                                <Button variant="contained" onClick={toggleEditMode}>{t('actions.cancel')}</Button>
                                                <Box mx={1} />
                                                <Button variant="contained" color="primary" disabled={!canUpdate} onClick={update}>{t('actions.save')}</Button>
                                            </Box>
                                        </div>
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                        {!editMode && (
                            <Grid item xs={12}>
                                <Paper>
                                    <Tabs value={tabIndex} onChange={(e,v) => setTabIndex(v)}>
                                        <Tab label={t('rentals.plural')} />
                                        <Tab label={t('items.plural')} />
                                    </Tabs>
                                    <Divider />
                                    {(tabIndex === 0 && rentals) && (
                                        <Box p={2}>
                                            <Grid container>
                                                <Grid item xs={12} sm={3}>
                                                    <Selector label={t('rentals.listView.label')} size="small"
                                                              value={rentalMode} onChange={v => handleRentalModeChange(v as any)}
                                                              options={[
                                                                  {label: t('rentals.listView.Calendar'), value: 'calendar'},
                                                                  {label: t('rentals.listView.List'), value: 'list'}
                                                              ]}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box my={2} />
                                            {rentalMode === 'calendar' && (
                                                <RentalsCalendar rentals={rentals}
                                                                 onRangeChanged={loadRentals}
                                                                 onSelectRental={handleSelectRentalByUuid}
                                                                 onSelectSlot={handleSelectSlot} />
                                            )}
                                            {rentalMode === 'list' && rentalPage !== undefined && (
                                                <RentalTable page={rentalPage} pageable={paging.getSettings()}
                                                             onPagingChange={handleRentalPageChange} />
                                            )}
                                        </Box>
                                    )}
                                    {tabIndex === 1 && (
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{width: '30%'}}>{t('items.singular')}</TableCell>
                                                    <TableCell style={{width: '20%'}}>{t('items.foreignId')}</TableCell>
                                                    <TableCell>{t('cubes.singular')} / {t('boxes.singular')}</TableCell>
                                                    <TableCell>{t('common.state')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {items.map(i => (
                                                    <TableRow key={i.uuid} hover style={{ cursor: 'pointer' }} onClick={() => navigate(`/items/${i.uuid}`)}>
                                                        <TableCell>{i.name}</TableCell>
                                                        <TableCell>{i.foreignId || ''}</TableCell>
                                                        <TableCell><CubeDescriptionDisplay cubeId={i.stored ? i.stored.cubeId : ""} /> {i.stored ? ' / ' + i.stored.boxNumber : '--'}</TableCell>
                                                        <TableCell><ItemStateDisplay item={i} /></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    )}
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                    {deleteMode && (
                        <ConfirmDialog title={t('models.delete.title')}
                                       message={t('models.delete.message', { modelName: model.name })}
                                       resolve={handleDeleteModel}
                                       reject={toggleDeleteMode}
                        />
                    )}
                    {rentalForm && (
                        <Dialog open={true} fullWidth maxWidth="sm">
                            <DialogTitle style={{ paddingBottom: 0 }}>{t('rentals.create.title')}</DialogTitle>
                            <DialogContent>
                                <LabeledData label={t('common.timeframe')}>
                                    <TemporalRangeFormat from={rentalForm.from?.toJSDate()} until={rentalForm.until?.toJSDate()} dateStyle="short" timeStyle="short" />
                                </LabeledData>
                                <Box my={3} />
                                <Typography>{t('models.selectItem')}</Typography>
                                <Box my={2} />
                                <Selector label={t('items.singular')} value={rentalForm.itemUuid}
                                          onChange={v => setRentalForm({ ...rentalForm, itemUuid: v })}
                                          options={items.filter((i) => (!i.disabled)).map(i => ({ label: i.name, value: i.uuid }))}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" onClick={() => setRentalForm(undefined)}>{t('actions.cancel')}</Button>
                                <Button variant="contained" color="primary" disabled={!rentalForm.itemUuid} onClick={triggerCreateRentalUrl}>{t('actions.next')}</Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Fragment>
            )}
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}
