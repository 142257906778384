import {Page, Paging} from "@variocube/app-ui";
import {Api} from "../Api";
import {Category, CategoryDetails, ImportRequest, CategoryTree, ImportResult, Item, Model} from "./types";
import {makeCsvImportFormData} from "./form";
import {api, apiV2} from "./api";
import {V2Model} from "./models";

export function createCategory(tenantId: string, details: CategoryDetails) {
    return Api.POST<Category>(`tenants/${tenantId}/categories`, details);
}

export function listCategories(tenantId: string, paging?: Paging, needle?: string) {
    return Api.GET<Page<Category>>(`tenants/${tenantId}/categories${paging ? paging.toQueryString() : '?unpaged=1'}${needle ? '&needle=' + needle : ''}`);
}

export function listCategoryTrees(tenantId: string) {
    return Api.GET<CategoryTree[]>(`tenants/${tenantId}/categories/trees`);
}

export function getCategory(tenantId: string, uuid: string) {
    return Api.GET<CategoryTree>(`tenants/${tenantId}/categories/${uuid}`);
}

export function updateCategory(tenantId: string, uuid: string, details: CategoryDetails) {
    return Api.PUT<Category>(`tenants/${tenantId}/categories/${uuid}`, details);
}

export function listCategoryModels(tenantId: string, uuid: string) {
    return Api.GET<Model[]>(`tenants/${tenantId}/categories/${uuid}/models`);
}

export function listCategoryItems(tenantId: string, uuid: string) {
    return Api.GET<Item[]>(`tenants/${tenantId}/categories/${uuid}/items`);
}

export function putCategoryModels(tenantId: string, uuid: string, modelUuids: string[]) {
    return Api.PUT<Model[]>(`tenants/${tenantId}/categories/${uuid}/models`, modelUuids);
}

export function putCategoryItems(tenantId: string, uuid: string, itemUuids: string[]) {
    return Api.PUT<Item[]>(`tenants/${tenantId}/categories/${uuid}/items`, itemUuids);
}

export async function deleteCategory(tenantId: string, uuid: string) {
    await Api.DELETE(`tenants/${tenantId}/categories/${uuid}`);
}

export async function importCategories(tenantId: string, importRequest: ImportRequest) {
    return Api.POST_FORM_DATA<ImportResult>(`tenants/${tenantId}/categories/import`, makeCsvImportFormData(importRequest));
}

export interface V2Category {
	uuid: string;
	name: string;
	description?: string;
	centerId: string;
	children: V2Category[];
}

export type CategoryOption = Pick<V2Category, 'uuid'|'name'|'children'>;

export const categoryApi = {
	listOptions(centerId: string) {
		return apiV2.get<CategoryOption[]>(`/tenants/${centerId}/categories/options`)
	},
	get(centerId: string, categoryUuid: string) {
		return apiV2.get<V2Category>(`/tenants/${centerId}/categories/${categoryUuid}`)
	},
}
