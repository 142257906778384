import {useLocalization} from "../../i18n";
import {createElement, useCallback, useContext, useEffect, useState} from "react";
import {WizardContext} from "./context";
import {Alert, Stack} from "@mui/material";
import {TextField, Checkbox} from "@variocube/app-ui";

interface StepPasswordProps {
	tenantName: string;
	onNext: (password: string) => void;
}

export function StepPassword({tenantName, onNext}: StepPasswordProps) {
	const {t} = useLocalization();
	const {nextBtnRef, loading} = useContext(WizardContext);

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [tos, setTos] = useState(false);

	const [strongPassword, setStrongPassword] = useState<boolean>();
	const [matched, setMatched] = useState<boolean>();

	const handleNext = useCallback(() => {
		if (!tos) {
			console.error('ToS must be confirmed.');
			return;
		}

		// - min length of 8 letters
		// - at least 1 uppercase character
		// - at least 1 special character (!@#$%^&*)
		// - at least 1 digit
		const test = /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g;
		const strongPassword = test.test(password);

		const matched = password === confirmPassword;

		setStrongPassword(strongPassword);
		setMatched(matched);

		if (strongPassword && matched) {
			onNext(password);
			return;
		}
	}, [password, confirmPassword, tos, strongPassword, matched]);

	useEffect(() => {
		if (nextBtnRef && nextBtnRef.current) {
			console.info('Bind nextBtn on click in StepPassword');
			nextBtnRef.current.onclick = handleNext;
		}
	}, [nextBtnRef, handleNext]);

	return (
		<Stack
			sx={{ width: '100%' }}
			direction="column"
			spacing={3}
		>
			<TextField
				fullWidth
				required
				disabled={loading}
				type="password"
				label={t('signup.password')}
				value={password}
				onChange={setPassword}
			/>

			<TextField
				fullWidth
				required
				disabled={loading}
				type="password"
				label={t('signup.confirmPassword')}
				value={confirmPassword}
				onChange={setConfirmPassword}
			/>

			<Checkbox
				required
				label={t('signup.termAgreementHint', { tenantName })}
				checked={tos}
				onChange={setTos}
			/>

			{strongPassword === false && (
				<Alert severity="error">
					{t('signup.passwordPolicyError')}
				</Alert>
			)}

			{matched === false && (
				<Alert severity="error">
					{t('signup.passwordNotMatch')}
				</Alert>
			)}
		</Stack>
	)
}
