import {User} from "../data/types";

export function formatUserName(user: User) {
	let name = '';
	if (user.firstName) {
		name += user.firstName + ' ';
	}
	if (user.lastName) {
		name += user.lastName + ' ';
	}
	return user.email + (name ? ' - ' + name.trim() : '') + (' - ' + user.role);
}
