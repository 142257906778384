import {Button, MenuItem} from "@mui/material";
import {DateTime} from "luxon";
import {createElement, Fragment, useMemo, useState} from "react";
import {PushButtonWithMenu} from "../../components/buttons/PushButtonWithMenu";
import {RentalWrapper} from "../../data/rentals";
import {Item, Rental} from "../../data/types";
import {useLocalization} from "../../i18n";
import {tenantUserStore} from "../../store/TenantUserStore";

type RentalActionsProps = {
	rental: Rental;
	item?: Item;
	onCreateTicket: () => void;
	onDeactivateItem: () => void;
	onDeleteRental: () => void;
	onProlongRental: () => void;
	onEndRental: () => void;
	onSimulateEvent: () => void;
};

export const RentalActions = (
	{rental, item, onCreateTicket, onDeactivateItem, onProlongRental, onDeleteRental, onEndRental, onSimulateEvent}:
		RentalActionsProps,
) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

	const wrapper = new RentalWrapper(rental);

	const canDeactivate = useMemo(() => {
		return tenantUserStore.staff && item && !item.disabled;
	}, [item]);

	const canProlong = useMemo(() => {
		return tenantUserStore.staff && wrapper.canProlong(DateTime.local());
	}, [item, wrapper]);

	const canDelete = useMemo(() => {
		return tenantUserStore.admin;
	}, [item, wrapper]);

	const canEnd = useMemo(() => {
		return tenantUserStore.admin;
	}, [rental]);

	const canSimulateEvent = useMemo<boolean>(() => Boolean(tenantUserStore.root), []);

	const {t} = useLocalization();
	return (
		<Fragment>
			{tenantUserStore.staff && (
				<PushButtonWithMenu label={t("tickets.create.title")} onClick={onCreateTicket}>
					<MenuItem key="deactivateItem" disabled={!canDeactivate} onClick={onDeactivateItem}>
						{t("items.disable.title")}
					</MenuItem>
					<MenuItem key="prolongRental" disabled={!canProlong} onClick={onProlongRental}>
						{t("rentals.prolong.title")}
					</MenuItem>
					<MenuItem key="endRental" disabled={!canEnd} onClick={onEndRental}>
						{t("rentals.end.title")}
					</MenuItem>
					<MenuItem key="deleteRental" disabled={!canDelete} onClick={onDeleteRental}>
						{t("rentals.delete.title")}
					</MenuItem>
					{canSimulateEvent
						&& (
							<MenuItem key="simulateEvent" onClick={onSimulateEvent}>
								{t("rentals.simulateEvent.title")}
							</MenuItem>
						)}
				</PushButtonWithMenu>
			)}
			{!tenantUserStore.staff && <Button onClick={onCreateTicket}>{t("tickets.create.title")}</Button>}
		</Fragment>
	);
};
