import {createElement} from "react";
import {Chip} from "@mui/material";
import {UserRole} from "../../data/types";
import {useLocalization} from "../../i18n";

type UserRoleDisplayProps = {
    role: UserRole,
    size?: 'small'|'medium'
}

export function UserRoleDisplay({role, size}: UserRoleDisplayProps) {
	const {e} = useLocalization();
    const label = e('users.roles', role);
    return (<Chip size={size || 'small'} label={label} />);
}
