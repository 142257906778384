import {createElement, useEffect} from "react";
import {Button, Grid} from "@mui/material";
import {useState} from "react";
import {Comment, Ticket} from "../../data/types";
import {getTicketComments} from "../../data/tickets";
import {tenantUserStore} from "../../store/TenantUserStore";
import {LabeledData} from "../../components/LabeledData";
import {TicketStateDisplay} from "./TicketStateDisplay";
import {TicketTypeDisplay} from "./TicketTypeDisplay";
import {DateComponent} from "../../components/DateComponent";
import {CompactCommentDisplay} from "./CompactCommentDisplay";
import {useLocalization} from "../../i18n";
import {useNavigate} from "react-router-dom";

interface CompactTicketDisplayProps {
    ticket: Ticket;
}

export function CompactTicketDisplay(props: CompactTicketDisplayProps) {
    const {ticket} = props;
	const navigate = useNavigate();

    const [comments, setComments] = useState<Comment[]>([]);

    useEffect(() => {
        getTicketComments(tenantUserStore.getTenantId(), ticket.uuid)
            .then(comments => {
                setComments(comments);
            });
    }, [setComments])

	const {t} = useLocalization();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item>
                        <LabeledData label={t('common.state')}>
                            <TicketStateDisplay state={ticket.state} />
                        </LabeledData>
                    </Grid>
                    <Grid item>
                        <LabeledData label={t('common.type')}>
                            <TicketTypeDisplay type={ticket.type} />
                        </LabeledData>
                    </Grid>
                    <Grid item style={{flexGrow: 1}}>
                        <LabeledData label={t('common.created')}>
                            <DateComponent date={ticket.createdAt} />
                        </LabeledData>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" color="primary" onClick={() => navigate(`/tickets/${ticket.uuid}`)}>{t('actions.view')}</Button>
                    </Grid>
                </Grid>
            </Grid>
            {comments.length > 0 &&
            <Grid item xs={12}>
                <LabeledData label={t('tickets.initialReport')}>
                    <CompactCommentDisplay comment={comments[0]}/>
                </LabeledData>
            </Grid>
            }
            {comments.length > 1 &&
            <Grid item xs={12}>
                <LabeledData label={t('tickets.lastComment')}>
                    <CompactCommentDisplay comment={comments[comments.length -1]} />
                </LabeledData>
            </Grid>
            }
        </Grid>
    );
}
