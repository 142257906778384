import {createElement, PropsWithChildren} from "react";
import {Box, Typography} from "@mui/material";

export function LabeledData({label, children}: PropsWithChildren<{ label: string }>) {
	return (
		<Box>
			<Typography variant="overline">{label}</Typography>
			<Typography variant="body1" component="div">{children}</Typography>
		</Box>
	)
}
