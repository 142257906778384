import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {Box, Breadcrumbs, Button, Grid, Paper, TableCell, TableRow, Typography} from "@mui/material";
import {BreadcrumbItem,  ColumnType, ContentTable, Page, PagingSettings} from "@variocube/app-ui";
import {ApiError} from "../../Api";
import {Ticket} from "../../data/types";
import {listTickets} from "../../data/tickets";
import {ticketsPaging} from "../../data/pagings";
import {tenantUserStore} from "../../store/TenantUserStore";
import {ErrorDialog} from "../../components/ErrorDialog";
import {DateComponent} from "../../components/DateComponent";
import {TicketTypeDisplay} from "./TicketTypeDisplay";
import {TicketStateDisplay} from "./TicketStateDisplay";
import {TicketRentalDisplay} from "./TicketRentalDisplay";
import {useLocalization} from "../../i18n";
import {ElectionDayGraphic} from "../../assets/svg";
import {RentalAppContainer} from "../../RentalAppContainer";
import {useNavigate} from "react-router-dom";

export const TicketList = () => {
    const {t} = useLocalization();
	const navigate = useNavigate();

    useEffect(() => {
        setColumns({
            'state': { show: true, name: t('common.state') },
            'type': { show: true, name: t('common.type') },
            'item': { show: true, name: t('items.singular') },
            'rental': { show: true, name: t('rentals.singular') },
            'createdAt': { show: true, name: t('common.created') }
        })
    }, [t])

    const [page, setPage] = useState<Page<Ticket>>();
    const [inProgress, setInProgress] = useState(true);
    const [columns, setColumns] = useState<ColumnType>({});
    const [error, setError] = useState<ApiError>();

    const loadPage = useCallback(async () => {
        setInProgress(true);
        try {
            const page = await listTickets(tenantUserStore.getTenantId(), ticketsPaging);
            setPage(page);
        } catch (error) {
            ticketsPaging.resetSettings();
            setError(error as any);
        }
        setInProgress(false);
    }, [setPage, setError, setInProgress]);

    useEffect(() => {
        loadPage().then();
    }, []);

    const handlePageChange = (settings: PagingSettings<any>) => {
        ticketsPaging.updateSettings({
            ...ticketsPaging.getSettings(),
            ...settings
        });
        loadPage().then();
    }

    const showCell = (column: keyof typeof columns) => columns[column] && columns[column].show;

    return (
        <RentalAppContainer title={t('tickets.plural')}>
            <Breadcrumbs>
                <BreadcrumbItem>{t('tickets.plural')}</BreadcrumbItem>
            </Breadcrumbs>
            <Box my={1} />
            <Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{ flexGrow: 1 }}>
                    <Typography variant="h4">{t('tickets.plural')}</Typography>
                </Grid>
            </Grid>
            <Box my={3} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        <ContentTable
                            page={page} pageable={ticketsPaging.getSettings()}
                            onPageableChange={handlePageChange}
                            inProgress={inProgress} columns={columns}
                            onColumnsChange={c => setColumns(c)}
                            renderTableBody={(
                                <Fragment>
                                    {page && page.content.map(t => (
                                        <TableRow key={'ticket-' + t.uuid} onClick={() => navigate('/tickets/' + t.uuid)}>
                                            {showCell('state') && (<TableCell><TicketStateDisplay state={t.state} /></TableCell>)}
                                            {showCell('type') && (<TableCell><TicketTypeDisplay type={t.type} /></TableCell>)}
                                            {showCell('item') && (<TableCell>{t.item.name}</TableCell>)}
                                            {showCell('rental') && (<TableCell><TicketRentalDisplay rental={t.rental}/></TableCell>)}
                                            {showCell('createdAt') && (<TableCell><DateComponent date={t.createdAt} /></TableCell>)}
                                        </TableRow>
                                    ))}
                                </Fragment>
                            )}
                            renderEmptyContent={<NoTicketsRow />}
                        />
                    </Paper>
                </Grid>
            </Grid>
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}

const NoTicketsRow = () => {
	const navigate = useNavigate();
    const {t} = useLocalization();
    return (
        <TableRow>
            <TableCell colSpan={6}>
                <Box p={6}>
                    <ElectionDayGraphic />
                </Box>
                <Typography variant="h6" align="center" style={{fontWeight: 'lighter'}}>{t('tickets.noTickets.info')}</Typography>
                <Box p={6}>
                    <Grid container spacing={3} sx={{ justify: 'center' }}>
                        <Grid item>
                            <Button variant="outlined" onClick={() => navigate('/rentals')}>{t('tickets.noTickets.goRentals')}</Button>
                        </Grid>
                        {tenantUserStore.staff &&
                            <Grid item>
                                <Button variant="outlined" onClick={() => navigate('/items')}>{t('tickets.noTickets.goItems')}</Button>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </TableCell>
        </TableRow>
    );
}
