import {PagingImpl} from "@variocube/app-ui";

export const rentalsPaging = new PagingImpl('rentals_paging');
export const overdueRentalPaging = new PagingImpl('overdue_rental_paging');
export const rentalRequestsPaging = new PagingImpl('rental_requests_paging');
export const modelsPaging = new PagingImpl('models_paging');
export const itemsPaging = new PagingImpl('items_paging');
export const ticketsPaging = new PagingImpl('tickets_paging');
export const cubesPaging = new PagingImpl('cubes_paging');
export const usersPaging = new PagingImpl('users_paging');
export const costCentersPaging = new PagingImpl('costcenters_paging');
export const categoriesPaging = new PagingImpl('categories_paging');