import {Api} from "../Api";
import {ChangeTenantRequest, Tenant, TenantSignUpInformation} from "./types";
import {createQueryString} from "@variocube/app-ui";

export function listTenants(): Promise<Tenant[]> {
    return Api.GET<Tenant[]>('tenants');
}

export function getTenant(tenantId: string): Promise<Tenant> {
    return Api.GET<Tenant>('tenants/' + tenantId);
}

export function saveTenantSettings(tenantId: string, request: ChangeTenantRequest) {
    return Api.PUT<Tenant>(`tenants/${tenantId}`, request);
}

export function getTenantSignUpInformation(tenantId: string) {
    return Api.GET<TenantSignUpInformation>(`tenants/${tenantId}/signup`);
}

export function isTenantSignupDomain(domain: string): Promise<Tenant> {
	return Api.GET<Tenant>(`public/signup-domain?domain=${domain}`);
}

interface VerificationRequest {
	email?: string;
	phone?: string;
}

export function verifyEmail(centerId: string, email: string) {
	return Api.GET<{ email: string, existed: boolean }>(`public/${centerId}/verify-email?${createQueryString({ email })}`);
}

export function addSignupVerification(centerId: string, request: VerificationRequest) {
	return Api.POST<void>(`public/${centerId}/add-signup-verification`, request);
}

export function verifySignupCode(type: 'Email'|'Phone', code: string, value: string) {
	return Api.POST<{ code: string, valid: boolean }>(`/public/verify-signup-code`, { type, code, value });
}
