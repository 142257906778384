import {ImportRequest} from "./types";

export function makeCsvImportFormData(importRequest: ImportRequest) {
    const data = new FormData();
    const blob = new Blob([importRequest.csvFile], { type: importRequest.csvFile.type });
    data.append('csvFile', blob);
    data.append('headerAvailable', importRequest.headerAvailable ? '1' : '0');
    data.append('characterEncoding', importRequest.characterEncoding);
    data.append('columnMapping', JSON.stringify(importRequest.columnMapping));
    return data;
}