import { VCThemeProviderProps } from "@variocube/app-ui";
import {Rental, Tenant, TenantFeature, User, UserRole} from "../data/types";
import {getBranding, me, refreshMyAccessCode, UserWrapper} from "../data/users";

const USER_KEY = 'user';
const TENANT_KEY = 'tenant';

class TenantUserStore {

    setTenant(tenant: Tenant) {
        localStorage.setItem(TENANT_KEY, JSON.stringify(tenant));
    }

    setUser(user: User) {
        localStorage.setItem(USER_KEY, JSON.stringify(user));
    }

    get tenant() {
        const data = localStorage.getItem(TENANT_KEY);
        return data ? JSON.parse(data) as Tenant : undefined;
    }

    get user() {
        const data = localStorage.getItem(USER_KEY);
        return data ? JSON.parse(data) as User : undefined;
    }

    get root() {
        return this.user != undefined && this.user.root;
    }

    get admin() {
        return this.hasRole(UserRole.TenantAdmin) || this.root;
    }

    get staff() {
        return this.hasRole(UserRole.TenantUser) || this.admin;
    }

    get borrower() {
        return this.hasRole(UserRole.Borrower);
    }

    hasRole(userRole: UserRole): boolean {
        if(this.user) {
            const wrapper = new UserWrapper(this.user);
            return wrapper.hasRole(userRole);
        }
        return false;
    }

	hasFeature(feature: TenantFeature) {
		return this.tenant?.features?.includes(feature);
	}

    assertTenantIsSet(): Tenant {
        if(!this.tenant) {
            if (window.location.pathname.indexOf('tenants') === -1) {
                window.location.replace('/tenants');
            }
            throw new Error('No tenant set in userTenantStore, redirecting to tenant selection.');
        }
        return this.tenant;
    }

    getTenantId() {
        const tenant = this.assertTenantIsSet();
        return tenant.centerId || (tenant as any).id;
    }

    load(): Promise<User> {
        const tenant = this.assertTenantIsSet();
        return me(tenant.centerId).then((user) => {
            this.setUser(user)
            return user;
        });
    }

    getBranding(): Promise<VCThemeProviderProps["branding"]> {
        const tenant = this.assertTenantIsSet();
        return getBranding(tenant.centerId);
    }

    refreshAccessCode(): Promise<User> {
        const tenant = this.assertTenantIsSet();
        return refreshMyAccessCode(tenant.centerId).then((user) => {
            this.setUser(user);
            return user;
        });
    }

    canAccessRental(rental: Rental): boolean {
        if(this.staff) {
            return true;
        }
        const user = this.user
        if(user && rental.user) {
            return user.uuid == rental.user.uuid || !!tenantUserStore.tenant?.displayBorrowerData;
        }
        return false;
    }

    canControlRental(rental: Rental) {
        if (this.staff) return true;
        const user = this.user
        if(user && rental.user) return user.uuid == rental.user.uuid;
        return false;
    }

}

export const tenantUserStore = new TenantUserStore();
