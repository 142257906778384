import {Rental, StatisticDetails, StatisticRequest} from "./types";
import {Api} from "../Api";
import {luxonDate} from "../tools";

export function computeStatistic(tenantId: string, request: StatisticRequest) {
    return Api.POST<StatisticDetails>(`tenants/${tenantId}/statistics`, {
        ...request,
        from: request.from ? luxonDate(request.from).toISODate() : undefined,
        until: request.until ? luxonDate(request.until).toISODate() : undefined
    })
        .then(d => ({
            ...d,
            highestCostRental: d.highestCostRental ? parseDateTime(d.highestCostRental) : null,
            lowestCostRental: d.lowestCostRental ? parseDateTime(d.lowestCostRental) : null,
            longestRental: d.longestRental ? parseDateTime(d.longestRental) : null,
            shortestRental: d.shortestRental ? parseDateTime(d.shortestRental) : null
        }) as StatisticDetails);
}

function parseDateTime(rental: Rental) {
    return {
        ...rental,
        from: luxonDate(rental.from),
        until: luxonDate(rental.until)
    }
}