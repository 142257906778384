import {createElement, useMemo, useState} from "react";
import {ModelDetails} from "../../data/types";
import {ApiError} from "../../Api";
import {
    Box, Button,
    Grid, LinearProgress, Paper, Typography
} from "@mui/material";
import {createModel} from "../../data/models";
import {tenantUserStore} from "../../store/TenantUserStore";
import {ErrorDialog} from "../../components/ErrorDialog";
import {ModelForm} from "./ModelForm";
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer";
import {Breadcrumbs, BreadcrumbItem} from "@variocube/app-ui";
import { CrumbLink } from "../../components/CrumbLink";
import {useNavigate} from "react-router-dom";

export const ModelCreate = () => {
	const navigate = useNavigate();

    const [details, setDetails] = useState<ModelDetails>();
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState<ApiError>();

    const canCreate = useMemo(() => (details && details.name && !processing), [details, processing])

    const create = async () => {
        if (details) {
            setProcessing(true);
            try {
                const model = await createModel(tenantUserStore.getTenantId(), details);
                navigate(`/models/${model.uuid}`);
                return;
            } catch (error) {
                setError(error as any);
            }
            setProcessing(false);
        }
    }

	const {t} = useLocalization();
    return (
        <RentalAppContainer title={t('models.create.title')}>
			<Breadcrumbs>
				<CrumbLink href='/models'>{t('models.plural')}</CrumbLink>
				<BreadcrumbItem>{t('models.create.title')}</BreadcrumbItem>
			</Breadcrumbs>
			<Box my={1} />
			<Typography variant="h4">{t('models.create.title')}</Typography>
			<Box my={3} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        <Box p={3}>
                            <ModelForm onChange={setDetails} />
                            <Box my={3}/>
                            <Box display="flex" justifyContent="flex-end">
                                <Button variant="contained" onClick={() => navigate('/models')}>{t('actions.cancel')}</Button>
                                <Box mx={1} />
                                <Button variant="contained" color="primary" disabled={!canCreate} onClick={create}>{t('actions.create')}</Button>
                            </Box>
                        </Box>
                        {processing && <LinearProgress variant="indeterminate" />}
                    </Paper>
                </Grid>
            </Grid>
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}
