import {createElement, Fragment, useMemo, useState} from "react";
import {tenantUserStore} from "../store/TenantUserStore";
import { getCube } from "../data/cubes";
import { Cube } from "../data/types";
 
interface CubeDescriptionDisplayProps {
	cubeId: string;
}
export function CubeDescriptionDisplay( {cubeId}: CubeDescriptionDisplayProps ) {

	const tenantId = tenantUserStore.getTenantId();
	const [cube, setCube] = useState<Cube>(); 
	useMemo(() => {
		if (tenantId && cubeId)
	    	getCube(tenantId, cubeId)?.then(setCube)
	}, [cubeId, tenantId]);

	return(<Fragment>{cube?.description}</Fragment>);
}
