import {MenuItem} from "@mui/material";
import {createElement} from "react";
import {PushButtonWithMenu} from "../../components/buttons/PushButtonWithMenu";
import {Model} from "../../data/types";
import {useLocalization} from "../../i18n";

type ModelActionsProps = {
	model: Model;
	onCreateItem: () => void;
	onEditModel: () => void;
	onDeleteModel: () => void;
};

export const ModelActions = ({model, onCreateItem, onEditModel, onDeleteModel}: ModelActionsProps) => {
	const {t} = useLocalization();
	return (
		<PushButtonWithMenu label={t("items.create.title")} onClick={onCreateItem}>
			<MenuItem key="editModel" onClick={onEditModel}>{t("models.edit.title")}</MenuItem>
			<MenuItem key="deleteModel" onClick={onDeleteModel}>
				{t("models.delete.title", {modelName: model.name})}
			</MenuItem>
		</PushButtonWithMenu>
	);
};
