import {createElement, useState} from "react";
import {Box, Grid, Stack, Typography} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs, Tabs} from "@variocube/app-ui";
import {AccountSettings} from "./account";
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer";
import {TenantSettings} from "./tenant";
import {CustomFieldsSettings} from "./custom-fields";
import {tenantUserStore} from "../../store/TenantUserStore";
import {TenantFeature} from "../../data/types";

export function Settings() {
    const {t} = useLocalization();

	const [tab, setTab] = useState(0);

    return (
        <RentalAppContainer title={t('settings.title')}>
			<Grid
				container
				spacing={3}
				justifyContent="flex-end"
			>
				<Grid
					item
					flexGrow={1}
				>
					<Stack
						direction="column"
						gap={1}
					>
						<Breadcrumbs>
							<BreadcrumbItem>{t("settings.title")}</BreadcrumbItem>
						</Breadcrumbs>
						<Typography variant="h4">
							{tab == 0 && t("settings.accountTitle")}
							{tab == 1 && t("settings.tenantTitle")}
							{tab == 2 && t("settings.fieldsTitle")}
						</Typography>
					</Stack>
				</Grid>
			</Grid>

			<Box my={3} />

			<Tabs
				value={tab}
				onChange={(_, tab) => setTab(tab)}
				items={[
					{ label: t('settings.accountTitle'), /*icon: <TabIcon/>*/ },
					{ label: t('settings.tenantTitle'), /*icon: <TabIcon/>*/ },
					...(tenantUserStore.hasFeature(TenantFeature.TrackUsageData)
						? [{ label: t('settings.fieldsTitle'), /*icon: <TabIcon/>*/ }]
						: []
					)
				]}
			/>

			<Box my={3} />

			{tab === 0 && <AccountSettings />}
			{tab === 1 && <TenantSettings />}
			{tab === 2 && <CustomFieldsSettings />}
{/*/!**/}
{/*            <Breadcrumbs>*/}
{/*                <BreadcrumbItem>{t('settings.title')}</BreadcrumbItem>*/}
{/*            </Breadcrumbs>*/}
{/*            <Box my={1} />*/}
{/*            <MySettings />*/}
{/*            <Box my={3} />*/}
{/*            {tenantUserStore.admin && (*/}
{/*                <Fragment>*/}
{/*                    <TenantSettings />*/}
{/*                </Fragment>*/}
{/*            )}*!/*/}
        </RentalAppContainer>
    )
}
