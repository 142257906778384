import {createElement, Fragment} from "react";
import {Typography} from "@mui/material";
import {Comment} from "../../data/types";

export function CompactCommentDisplay({comment}: { comment: Comment }) {
    return (
        <Fragment>
            <Typography variant="overline">{new Date(comment.createdAt).toLocaleString()}</Typography>
            <Typography variant="subtitle1"><strong>{comment.user ? comment.user.email : 'Anonymous'}</strong></Typography>
            <Typography variant="body1">{comment.comment}</Typography>
        </Fragment>
    );
}
