import {createElement, Fragment, KeyboardEvent, useState} from "react";
import {IconButton, TextField} from "@mui/material";
import {useLocalization} from "../i18n";
import {ClearIcon, SearchIcon} from "../theme";

type TableSearchInput = {
    onSubmit: (needle?: string) => void,
    label?: string,
    needle?: string,
}

export const TableSearchInput = ({onSubmit, label, needle: n}: TableSearchInput) => {
    const [needle, setNeedle] = useState(n);

	function handleReset() {
		setNeedle(undefined);
		onSubmit(undefined);
	}

	function handleKeyUp(ev: KeyboardEvent) {
		if (ev.code === 'Enter') onSubmit(needle);
	}

	const {t} = useLocalization();
    return (
        <TextField
			variant="outlined" fullWidth size="small"
			label={label ?? t('actions.search')}
			InputProps={{
				endAdornment: (
					<Fragment>
						{needle && <IconButton size="small" onClick={handleReset}><ClearIcon fontSize="inherit" /></IconButton>}
						<IconButton size="small" onClick={() => onSubmit(needle)}><SearchIcon fontSize="inherit" /></IconButton>
					</Fragment>
				)
			}}
			onKeyUp={handleKeyUp}
			value={needle ?? ''}
			onChange={ev => setNeedle(ev.target.value)}
        />
    )
}
