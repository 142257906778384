import {createElement, Fragment, useMemo} from "react";
import {Link} from "@mui/material";
import {Category} from "../../data/types";

function getParentUuid(category: Category): string|null {
    let uuid;
    if (category.parentCategory) {
        uuid = getParentUuid(category.parentCategory);
    } else {
        uuid = category.uuid;
    }
    return uuid;
}

export function getCategoryUrl(category: Category) {
    const parentUuid = getParentUuid(category);
    if (parentUuid && parentUuid !== category.uuid) {
        return `/categories/${parentUuid}?uuid=${category.uuid}`;
    }
    return `/categories/${category.uuid}`;
}

export const CategoryLink = ({category}: { category: Category }) => {
    const url = useMemo(() => {
        return getCategoryUrl(category);
    }, [category]);
    return (
        <Fragment>
            <Link href={url}>{category.name}</Link>
        </Fragment>
    )
}
