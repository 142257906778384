import {createElement, SVGProps} from "react";

export function TreeSwingGraphic(props: SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 855.31595 676.00569" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M769.658,696.8505h-2V478.00284a5.97951,5.97951,0,0,0-2.21289-4.65332l1.26368-1.55078a7.97289,7.97289,0,0,1,2.94921,6.2041Z" transform="translate(-172.34203 -111.99716)" fill="#3f3d56"/>
			<path d="M565.922,135.23a17.53713,17.53713,0,0,1,1.15,3.68C566.51207,136.98,566.07207,135.67,565.922,135.23Z" transform="translate(-172.34203 -111.99716)" fill="#3f3d56"/>
			<path d="M583.662,196.37s0,1.38-1.49,6.36Z" transform="translate(-172.34203 -111.99716)" fill="#3f3d56"/>
			<path d="M766.25206,404.68c-39.33,20.5-90.78,38.2-106.19,43.33,11.75-3.06,45.95-7.18,98.94,25.88,66.58,41.53,137.77,13.84,137.77,13.84s-15.82,30.33-49.44,32.3c-33.62006,1.98-121.29-53.39-150.95-37.57-29.67,15.82-43.51-3.96-55.38,35.6-11.86,39.55-33.61,147.65-8.56,208.3L657.482,787H515.102s51.42-137.11,23.07-221.48c-15.87-47.25-38.15-81.47-46.46-98.86-4.17-8.71-4.83-13.19.57-12.97,3.07.13,8.1,1.78,15.57,5.04,0,0-34.28-33.62-45.48-33.62-11.21,0-63.95-5.27-81.08-23.07,0,0-24.39-17.14-67.9,25.05s-32.96,42.19-71.85,38.89c-38.28-3.25-68.27-21.82-69.2-22.4,1.11.45,45.82,18.32,73.15,9.22,27.69-9.23,51.42-44.17,75.81-56.03,24.39-11.87,38.89-27.69,71.85-10.55l-21.72-51.21-5.95-14.03-.01-.02s-7.26-96.24-21.1-102.83c-13.84-6.6-5.27-23.73-30.98-27.03,0,0,31.64-3.96,37.57,17.8,5.94,21.75,46.81006,139.09,46.81006,139.09s25.04,50.09,52.73,38.23c27.69-11.87-10.55-78.44-10.55-78.44l-47.46-21.76s35.6-1.32,57.35,19.12c21.75,20.43,23.07,86.35,23.07,86.35s54.71,7.91,67.9,37.58c0,0,8.57-26.37-7.91-42.19-4.12-3.96-8.44995-10.84-12.62-18.93a167.21656,167.21656,0,0,1-16.34-100.72c2.45-16.86,3.96-33.35,1.27-37.24-5.93-8.57-30.98-61.3-32.96-75.15-1.97-13.84,1.98-42.84,1.98-42.84s.66,35.59,8.57,46.8,30.32,66.58,30.32,66.58,0,69.87,18.46,104.15,54.05,72.51,52.07995,97.56v14.5s39.55-37.57,36.91-50.1c-2.64-12.52-5.93-42.19-30.98-59.98-25.05-17.8-41.53-46.15-23.73-87.02,10.01-22.99,14.39-35.34,16.31-41.75l-10.38,44.39s-6.59,45.48,11.87,61.3c.05.04.09.08.14.12,18.43,15.84,42.7,44.71,42.7,44.71l-.07-1.27-5.2-93.65,1.14,2.5,8.22,18.02,7.78,17.05s36.91-47.46,80.42-63.28c43.5-15.82,70.53-71.19,70.53-71.19l36.92-29.01-30.33,36.26s-9.23,42.84-61.3,73.17c-52.08,30.32-85.69,75.14-87.67,102.83s-3.96,52.08-3.96,52.08,97.56-3.3,119.31-46.15c21.76-42.84,39.56-44.82,39.56-44.82l-12.53,36.91-13.84,31.64s56.69,1.32,86.35,34.94c29.67,33.62,103.49,43.51,103.49,43.51l-41.52,11.2s-39.56-13.18-77.13-37.57S813.05205,380.29,766.25206,404.68Z" transform="translate(-172.34203 -111.99716)" fill="#3f3d56"/>
			<path d="M787.27205,314.74c-4.17,18.46-9.08,36.29-15.98,49.88-21.75,42.85-119.31,46.15-119.31,46.15s.46-5.66,1.16-14.59c24.77-2.04,92.48-10.75,110.15-45.56,1.62-3.18,3.12-6.6,4.54-10.2Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M635.50206,367.26s-24.39-29.01-42.84-44.83c-4.05-3.46-6.88995-8.36-8.86-13.88-.16-.44-.31-.89-.46-1.34.43.42.86.83,1.32,1.22,16.15,13.85,36.86005,37.81,41.77,43.56.69.82,1.07,1.27,1.07,1.27l-5.13-92.42,8.22,18.02Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M649.62056,284.02743c-.14392,16.22967-2.25036,25.88245-2.25036,25.88245s36.89892-47.441,80.39331-63.27155c2.57055-1.62622,5.17846-3.22736,7.84765-4.78162,52.07569-30.32257,61.30433-73.16961,61.30433-73.16961l30.3225-36.25519-36.91442,29.00415s-27.02662,55.37158-70.53272,71.192C690.13509,243.412,663.547,268.8934,649.62056,284.02743Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M592.65779,441.08779v14.50208s39.55115-37.57355,36.91431-50.09809c-2.01636-9.5783-4.43066-29.17681-16.65332-45.82989,5.5094,12.585,7.18628,24.8609,8.65332,31.82989,1.89087,8.98127-17.91138,30.842-29.26758,42.493A30.06054,30.06054,0,0,1,592.65779,441.08779Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M539.6036,341.567c.31323.66809.63623,1.3255.97851,1.9613,10.1084,18.77271,25.348,38.72785,36.75025,57.0578C568.117,382.43374,552.07967,361.67617,539.6036,341.567Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M760.00106,473.89c-21.68445-13.52594-40.219-20.82514-55.35962-24.5232-15.88233,5.978-28.9541,10.43525-35.5315,12.62384,11.79615-3.05352,45.97168-7.1098,98.89112,25.89936,41.5205,25.8988,84.833,24.87793,111.5559,20.44134a69.06583,69.06583,0,0,0,18.21387-20.59851S826.57882,515.4186,760.00106,473.89Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M571.74044,313.5032c-5.31568-13.02454-6.19153-28.52258-.0846-46.68335a142.46131,142.46131,0,0,1,1.1366-19.68964L583.172,202.729c-1.91369,6.40307-6.29223,18.75567-16.31225,41.76446C554.17488,273.62173,558.909,296.383,571.74044,313.5032Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M862.83406,409.28976c-20.57165-23.31452-54.13623-31.09253-72.782-33.67139l-5.57129,12.73456s56.68994,1.31842,86.35327,34.93683c16.44959,18.64294,46.47706,29.98529,69.96827,36.39361l25.5238-6.88733S892.49739,442.90823,862.83406,409.28976Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M459.50228,400.21829c27.68579-11.86536-10.54688-78.44306-10.54688-78.44306l-1.0459-.47937c9.53162,20.11048,23.35535,56.45276,3.59278,64.92243-18.49243,7.92535-35.806-11.79218-45.26685-25.762.34351.988.53211,1.5293.53211,1.5293S431.81649,412.08364,459.50228,400.21829Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M549.81075,443.06533s8.34241-25.71472-7.32971-41.59143c3.84314,13.67755-.67029,27.59143-.67029,27.59143-10.19861-22.94671-45.24243-32.87408-60.59216-36.19415.59533,7.63971.696,12.6206.696,12.6206S536.627,413.402,549.81075,443.06533Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M583.172,202.729c1.48548-4.9704,1.48584-6.35608,1.48584-6.35608Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M254.09566,466.922c.13294-.04279.26795-.08191.4-.12592,27.68579-9.22863,51.4165-44.16546,75.80639-56.03076s38.89185-27.68579,71.85108-10.547c0,0-5.76612-23.60236-16.94-39.10883a153.78662,153.78662,0,0,1,8.94,25.10883c-32.95923-17.1388-47.46131-1.31836-71.85108,10.547s-48.1206,46.80213-75.80639,56.03076-73.16956-9.22863-73.16956-9.22863,30.32251,19.11639,69.21448,22.41229C246.98958,466.35671,250.78572,466.68942,254.09566,466.922Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M374.472,334.96s-1.08.02-3.04.05l-5.95-14.03c.65-.01.99-.02.99-.02s-4.58-60.69995-13.4-88.99c.11.05.2.11.3.16C367.212,238.72,374.472,334.96,374.472,334.96Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M528.91805,787.003c12.07812-37.02557,41.125-139.41113,18.256-207.48632-9.8761-29.39881-19.03027-48.14557-26.76892-60.10535,5.78723,11.32153,12.1145,26.29688,18.76892,46.10535C567.519,649.89242,516.1025,787.003,516.1025,787.003Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M516.852,472.73a73.3005,73.3005,0,0,0-25.14-6.07c-4.17-8.71-4.83-13.19.57-12.97,1.5.27,3.01.59,4.51.96C507.39207,463.45,516.852,472.73,516.852,472.73Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M338.39717,211.72677a17.244,17.244,0,0,0,2.71082,3.46558A19.45432,19.45432,0,0,0,338.39717,211.72677Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M458.8431,319.13851a32.09551,32.09551,0,0,1,2.85779,3.08112c-2.88758-6.94153-6.4535-12.94391-10.85779-17.08112-21.753-20.43475-57.34912-19.11639-57.34912-19.11639l44.64111,20.46051A63.74971,63.74971,0,0,1,458.8431,319.13851Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M506.21468,229.99136c2.69019,3.88568,1.17871,20.37591-1.2771,37.241a167.20277,167.20277,0,0,0,16.3457,100.71418,117.30214,117.30214,0,0,0,7.02381,12.05719,167.36175,167.36175,0,0,1-15.36951-98.77137c2.45581-16.865,3.96729-33.35528,1.2771-37.241-5.93262-8.5694-30.98169-61.30426-32.95923-75.14716a95.93134,95.93134,0,0,1-.413-16.17493c-5.12049-14.5285-5.6095-40.67211-5.6095-40.67211s-3.95508,29.00421-1.97754,42.847S500.28206,221.422,506.21468,229.99136Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M568.05635,138.86141a18.863,18.863,0,0,0-1.19665-3.79272S567.36629,136.49136,568.05635,138.86141Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M335.22933,205.25c5.80957.71118,12.49219,2.49366,17.5896,6.62751-.33252-1.12464-.62256-2.13282-.85352-2.98-5.93274-21.75311-37.57361-17.798-37.57361-17.798C328.69075,192.93264,332.38411,199.04855,335.22933,205.25Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M956.85641,753.13486c-9.831,15.07625-7.13727,34.2513-7.13727,34.2513s18.63284-5.26838,28.46381-20.34463,7.13727-34.2513,7.13727-34.2513S966.68738,738.05861,956.85641,753.13486Z" transform="translate(-172.34203 -111.99716)" fill="#3f3d56"/>
			<path d="M964.24105,755.80038c-.77706,17.98159-14.12518,32.00893-14.12518,32.00893s-12.08824-15.12656-11.31118-33.10814,14.12518-32.00893,14.12518-32.00893S965.01812,737.8188,964.24105,755.80038Z" transform="translate(-172.34203 -111.99716)" fill="#ff6a00"/>
			<path d="M791.0877,642.00747s11.312-11.86732,15.94175-9.47984-13.3119,12.99944-13.3119,12.99944Z" transform="translate(-172.34203 -111.99716)" fill="#ffb9b9"/>
			<path d="M791.0877,642.00747s11.312-11.86732,15.94175-9.47984-13.3119,12.99944-13.3119,12.99944Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M756.57389,616.10619s-4.15828,7.45338-3.07015,15.48817,11.03942,33.81741,14.42249,33.35925,28.44317-18.92439,28.44317-18.92439l-4.77955-6.67359-18.30725,11.0921S770.49377,610.77593,756.57389,616.10619Z" transform="translate(-172.34203 -111.99716)" fill="#d0cde1"/>
			<path d="M824.45569,634.25051l-28.53941,15.67632a1.35917,1.35917,0,0,1-1.99837-.5895l-6.99131-14.61354a1.82416,1.82416,0,0,1,.935-2.19057l23.69489-12.21336a1.38345,1.38345,0,0,1,1.813.3466L825.20537,631.817A1.75765,1.75765,0,0,1,824.45569,634.25051Z" transform="translate(-172.34203 -111.99716)" fill="#2f2e41"/>
			<polygon points="650.209 520.094 623.454 535.387 616.334 522.555 639.286 510.073 650.209 520.094" fill="#ff6a00"/>
			<ellipse cx="799.84505" cy="627.45637" rx="0.39386" ry="0.33582" transform="translate(-366.70393 727.6057) rotate(-50.15848)" fill="#f2f2f2"/>
			<ellipse cx="809.77894" cy="640.58483" rx="0.26258" ry="0.22388" transform="translate(-373.21463 739.95063) rotate(-50.15848)" fill="#f2f2f2"/>
			<path d="M756.57389,616.10619s-4.15828,7.45338-3.07015,15.48817,11.03942,33.81741,14.42249,33.35925,28.44317-18.92439,28.44317-18.92439l-4.77955-6.67359-18.30725,11.0921S770.49377,610.77593,756.57389,616.10619Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M783.44,644.27509s12.80232-10.24186,17.06976-7.25465-14.936,11.09535-14.936,11.09535Z" transform="translate(-172.34203 -111.99716)" fill="#ffb9b9"/>
			<circle cx="594.02819" cy="478.93493" r="12.37558" fill="#ffb9b9"/>
			<path d="M809.04462,677.13438s13.22907,1.707,12.80232,9.81512-10.24185,41.82092-10.24185,41.82092l-.85349,13.22906s.85349,22.1907-1.707,21.764-8.53488-15.78953-8.53488-15.78953-7.25465-4.69418-3.8407-8.96162,5.97442-3.8407,5.12093-8.53489-.42674-24.32441,2.13372-30.29883,4.05243-9.26959,4.05243-9.26959l-8.31987-.54552Z" transform="translate(-172.34203 -111.99716)" fill="#ffb9b9"/>
			<path d="M809.04462,677.13438s13.22907,1.707,12.80232,9.81512-10.24185,41.82092-10.24185,41.82092l-.85349,13.22906s.85349,22.1907-1.707,21.764-8.53488-15.78953-8.53488-15.78953-7.25465-4.69418-3.8407-8.96162,5.97442-3.8407,5.12093-8.53489-.42674-24.32441,2.13372-30.29883,4.05243-9.26959,4.05243-9.26959l-8.31987-.54552Z" transform="translate(-172.34203 -111.99716)" opacity="0.1"/>
			<path d="M809.89811,679.26811s12.80232,2.9872,14.08255,11.52209,7.6814,33.71278,7.6814,33.71278l6.8279,15.78953s10.6686,19.20348,5.97442,18.77674-11.09535-9.81512-13.22907-9.81512-8.53488-2.56046-8.10813-4.26744,3.414-6.8279,3.414-6.8279l-10.32454-17.98468a43.68247,43.68247,0,0,1-4.47815-11.08309c-1.06057-4.21218-2.26707-9.37191-2.26707-11.04618,0-2.9872-8.53489-4.26744-8.53489-4.26744Z" transform="translate(-172.34203 -111.99716)" fill="#ffb9b9"/>
			<path d="M768.07719,651.95648l5.97442,17.06977s9.38837,2.56046,16.21627,4.69418,19.63023-.85349,20.91046,4.26744-2.56046,14.08256-6.40116,16.21628-5.97442,4.26744-18.35.85349-41.82092-4.26744-41.82092-4.26744a21.39177,21.39177,0,0,1-9.38837,2.9872c-5.54768.42675-6.82791.85349-8.53488-.42674s.85348-1.28023,1.707-4.69419,1.28023-2.13372,5.12093-5.97441,6.82791-15.36279,8.96163-19.20349,3.414-11.52209,3.414-11.52209Z" transform="translate(-172.34203 -111.99716)" fill="#2f2e41"/>
			<path d="M767.2237,601.17394a12.49559,12.49559,0,0,0-5.12093,9.81511c0,6.40116-12.80232-6.8279-12.80232-6.8279s6.82791-8.53488,6.82791-11.09535S767.2237,601.17394,767.2237,601.17394Z" transform="translate(-172.34203 -111.99716)" fill="#ffb9b9"/>
			<path d="M751.00743,598.61348s7.68139,2.9872,9.38837,7.25464,8.10813,13.22907,8.10813,13.22907,8.96163,9.38837,5.97442,19.63023-5.54767,12.80232-5.54767,14.5093-4.26744,4.69418-7.68139,4.26744-16.21628-2.13372-17.06977-3.8407-5.97441-26.03139-2.56046-34.993A207.06676,207.06676,0,0,1,751.00743,598.61348Z" transform="translate(-172.34203 -111.99716)" fill="#d0cde1"/>
			<path d="M769.95962,591.49521c2.7694.65727,5.48713-1.10722,7.75636-2.85664,1.28856-.99339,2.70672-2.41,2.31191-4.0088-.35025-1.41836-2.02116-2.22231-2.26434-3.66384-.169-1.00169.41653-1.97623.464-2.99152a4.22577,4.22577,0,0,0-2.48441-3.62394c-1.3493-.72658-2.87961-1.0295-4.26575-1.67973-2.11673-.993-3.87592-2.78292-6.13659-3.35629-3.0884-.78332-6.23312.88448-9.40831,1.10039-1.92153.13066-3.8676-.27452-5.76848.0407a8.23441,8.23441,0,0,0-6.597,7.77339c-.02449,1.35819.24692,2.87349-.56352,3.95064a5.97766,5.97766,0,0,1-1.08592.98314c-2.82372,2.2835-4.10575,6.09851-4.25151,9.77387-.07174,1.80894.30717,3.95783,1.91207,4.72676a7.08811,7.08811,0,0,0,2.67525.37168,2.79426,2.79426,0,0,1,2.36206,1.12773c.731,1.28961-.36425,3.19439.592,4.31981.7183.84535,2.11935.56459,2.95821-.15572s1.362-1.75625,2.15718-2.52648c1.54685-1.49827,3.84134-1.76986,5.96156-1.99763a3.024,3.024,0,0,0,1.74865-.57341,2.70264,2.70264,0,0,0,.71719-1.47043,9.1133,9.1133,0,0,0,.01057-3.8609,2.14631,2.14631,0,0,1-.04885-1.22235,1.73436,1.73436,0,0,1,.62589-.701c1.31411-.98713,3.6162-2.4244,5.324-2.15253C766.557,589.12377,768.04793,591.04151,769.95962,591.49521Z" transform="translate(-172.34203 -111.99716)" fill="#2f2e41"/>
			<path d="M752.7144,613.97626s-5.12092,6.82791-5.12092,14.936,6.40116,34.993,9.81511,34.993,30.72557-14.936,30.72557-14.936l-3.84069-7.25465-19.63023,8.53488S767.2237,610.56231,752.7144,613.97626Z" transform="translate(-172.34203 -111.99716)" fill="#d0cde1"/>
			<rect x="540.31577" y="579.00586" width="98" height="19" fill="#ff6a00"/>
			<path d="M761.658,711.00284a8.00916,8.00916,0,0,1-8-8v-225a8.0001,8.0001,0,0,1,13.05079-6.2041l-1.26368,1.55078a5.99957,5.99957,0,0,0-9.78711,4.65332v225a6.00656,6.00656,0,0,0,6,6Z" transform="translate(-172.34203 -111.99716)" fill="#3f3d56"/>
			<rect x="49.31595" y="674.00569" width="806" height="2" fill="#3f3d56"/>
			<ellipse cx="744.65779" cy="583.00301" rx="1" ry="3" transform="translate(-373.34287 482.73093) rotate(-38.76825)" fill="#ff6a00"/>
			<path d="M660.85641,753.13486c-9.831,15.07625-7.13727,34.2513-7.13727,34.2513s18.63284-5.26838,28.46381-20.34463,7.13727-34.2513,7.13727-34.2513S670.68738,738.05861,660.85641,753.13486Z" transform="translate(-172.34203 -111.99716)" fill="#3f3d56"/>
			<path d="M668.24105,755.80038c-.77706,17.98159-14.12518,32.00893-14.12518,32.00893s-12.08824-15.12656-11.31118-33.10814,14.12518-32.00893,14.12518-32.00893S669.01812,737.8188,668.24105,755.80038Z" transform="translate(-172.34203 -111.99716)" fill="#ff6a00"/>
			<path d="M499.06742,758.83791c4.7,17.37387,20.79838,28.134,20.79838,28.134s8.47829-17.40854,3.77833-34.78242-20.79839-28.134-20.79839-28.134S494.36746,741.464,499.06742,758.83791Z" transform="translate(-172.34203 -111.99716)" fill="#3f3d56"/>
			<path d="M505.97368,755.10407c12.89676,12.55448,14.472,31.85363,14.472,31.85363s-19.33453-1.05568-32.23129-13.61015-14.472-31.85363-14.472-31.85363S493.07692,742.5496,505.97368,755.10407Z" transform="translate(-172.34203 -111.99716)" fill="#ff6a00"/>
			<path d="M463.2282,284.04377c-1.12118,17.96341-14.7354,31.73264-14.7354,31.73264s-11.79643-15.35522-10.67525-33.31863,14.73539-31.73264,14.73539-31.73264S464.34938,266.08036,463.2282,284.04377Z" transform="translate(-172.34203 -111.99716)" fill="#ff6a00"/>
			<path d="M321.312,170.48223c15.2251,9.59884,20.77833,28.14878,20.77833,28.14878s-19.13154,2.98715-34.35663-6.61169-20.77832-28.14878-20.77832-28.14878S306.08693,160.88339,321.312,170.48223Z" transform="translate(-172.34203 -111.99716)" fill="#ff6a00"/>
			<path d="M287.312,389.48223c15.2251,9.59884,20.77833,28.14878,20.77833,28.14878s-19.13154,2.98715-34.35663-6.61169-20.77832-28.14878-20.77832-28.14878S272.08693,379.88339,287.312,389.48223Z" transform="translate(-172.34203 -111.99716)" fill="#ff6a00"/>
			<path d="M596.83173,202.49827c-4.59147,17.40286-20.62244,28.26318-20.62244,28.26318s-8.58674-17.35531-3.99527-34.75817,20.62244-28.26318,20.62244-28.26318S601.42319,185.09541,596.83173,202.49827Z" transform="translate(-172.34203 -111.99716)" fill="#ff6a00"/>
			<path d="M741.66722,216.0677c-5.39655,17.17028-21.91567,27.27261-21.91567,27.27261s-7.76958-17.73618-2.373-34.90646,21.91567-27.27261,21.91567-27.27261S747.06377,198.89742,741.66722,216.0677Z" transform="translate(-172.34203 -111.99716)" fill="#ff6a00"/>
			<path d="M898.21162,407.16487c-9.77548,15.11228-28.38884,20.44911-28.38884,20.44911s-2.76415-19.165,7.01134-34.2773S905.223,372.88757,905.223,372.88757,907.98711,392.05259,898.21162,407.16487Z" transform="translate(-172.34203 -111.99716)" fill="#ff6a00"/>
			<path d="M866,206" transform="translate(-172.34203 -111.99716)" fill="#3f3d56"/>
		</svg>
	)
}
