import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {Box, Button, Grid, Paper, Typography} from "@mui/material";
import {Breadcrumbs, BreadcrumbItem, PlainDate, PlainDatePicker, Selector} from "@variocube/app-ui";
import {ApiError} from "../../Api";
import {tenantUserStore} from "../../store/TenantUserStore";
import {
    CostCenter,
    Item,
    Model,
    Rental,
    StatisticDetails,
    StatisticRequest,
    StatisticTimeframe
} from "../../data/types";
import {listItems} from "../../data/items";
import {computeStatistic} from "../../data/statistic";
import {listCostCenters} from "../../data/costcenters";
import {listModelItems, listModels} from "../../data/models";
import {ColumnChart} from "./ColumnChart";
import {useLocalization} from "../../i18n";
import {luxonDate, plainDateTimeNow} from "../../tools";
import {RentalAppContainer} from "../../RentalAppContainer";

export const StatisticView = () => {
	const {t, e} = useLocalization();
	const [from, setFrom] = useState(plainDateTimeNow().toPlainDate());
	const [until, setUntil] = useState(plainDateTimeNow().toPlainDate());
    const [form, setForm] = useState<StatisticRequest>();
    const [models, setModels] = useState<Model[]>([]);
    const [items, setItems] = useState<Item[]>([]);
    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
    const [statistic, setStatistic] = useState<StatisticDetails>();
    const [error, setError] = useState<ApiError>();

    const fetchModels = useCallback(() => {
        listModels(tenantUserStore.getTenantId())
            .then(p => setModels(p.content.sort((a, b) => a.name.localeCompare(b.name))))
            .catch(setError);
    }, []);

    const fetchItems = useCallback(async (modelUuid?: string) => {
        try {
            let items: Item[];
            if (modelUuid) {
                items = await listModelItems(tenantUserStore.getTenantId(), modelUuid);
            } else {
                items = (await listItems(tenantUserStore.getTenantId())).content;
            }
            setItems(items.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            setError(error as any);
        }
    }, []);

    const fetchCostCenters = useCallback(() => {
        listCostCenters(tenantUserStore.getTenantId())
            .then(p => setCostCenters(p.content.sort((c1, c2) => c1.name.localeCompare(c2.name))))
            .catch(setError);
    }, []);

    useEffect(() => {
        // TODO: SUPPORT TO PARSE REQUEST FROM QUERY STRING
        fetchModels();
        fetchItems().then();
        fetchCostCenters();
        setForm({
            timeframe: StatisticTimeframe.Today
        });
    }, []);

    const handleModelChange = (modelUuid: string) => {
        if (form) {
            setForm({
                ...form,
                modelUuid,
                itemUuid: ''
            });
            fetchItems(modelUuid).then();
        }
    }

    const handleTimeframeChange = (timeframe: StatisticTimeframe) => {
        if (form) {
			setForm({ ...form, timeframe });
		}
    }

    const handleReset = () => {
        if (form) {
            const resetForm = {
                ...form,
                modelUuid: '',
                itemUuid: '',
                costCenterUuid: ''
            };
            fetchItems().then();
            setForm(resetForm);
            setStatistic(undefined);
            computeStatistic(tenantUserStore.getTenantId(), resetForm)
                .then(setStatistic)
                .catch(setError);
        }
    }

    const handleSubmit = () => {
        if (form) {
            setStatistic(undefined);
            computeStatistic(tenantUserStore.getTenantId(), {
				...form,
				from: luxonDate(from).toJSDate(),
				until: luxonDate(until).toJSDate(),
			})
                .then(setStatistic)
                .catch(setError);
        }
    }

    const renderDuration = (rental: Rental) => {
        let {days, hours, minutes} = rental.until.diff(rental.from, ['days', 'hours', 'minutes']).toObject();
        const dayLabel = days && days > 1 ? t('period.plural.Days') : t('period.singular.Days');
        const hourLabel = hours && hours > 1 ? t('period.plural.Hours') : t('period.singular.Hours');
        const minuteLabel = minutes && minutes > 1 ? t('period.plural.Minutes') : t('period.singular.Minutes');
        return `${days || 0} ${dayLabel} ${hours || 0} ${hourLabel} ${Math.ceil(minutes || 0)} ${minuteLabel}`;
    }

    return (
        <RentalAppContainer title={t('statistics.title')}>
			<Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{ flexGrow: 1 }}>
					<Breadcrumbs>
						<BreadcrumbItem>{t('statistics.title')}</BreadcrumbItem>
					</Breadcrumbs>
					<Box my={1} />
                    <Typography variant="h4">
                        {t('statistics.title')}
                    </Typography>
                </Grid>
            </Grid>
			<Box my={3} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {form && (
                        <Paper>
                            <Box p={2}>
                                <Grid container spacing={3}>
                                    <Grid item sm={4} xs={12}>
                                        <Selector label={t('common.timeframe')} value={form.timeframe}
                                                  options={Object.values(StatisticTimeframe).map(t => ({ label: e('statistics.timeframe', t), value: t }))}
                                                  onChange={v => handleTimeframeChange(v as any)}
                                        />
                                    </Grid>
                                    {form.timeframe === StatisticTimeframe.Custom && (
                                        <Fragment>
                                            <Grid item sm={4} xs={12}>
												<PlainDatePicker
													fullWidth label={t('common.from')}
													value={from}
													onChange={d => setFrom(d as PlainDate)}
												/>
                                            </Grid>
                                            <Grid item sm={4} xs={12}>
												<PlainDatePicker
													fullWidth label={t('common.until')}
													value={until}
													onChange={d => setUntil(d as PlainDate)}
												/>
                                            </Grid>
                                        </Fragment>
                                    )}
                                </Grid>
                                <Box my={1}/>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <Selector label={t('costCenters.singular')}
                                                  value={form.costCenterUuid}
                                                  onChange={v => setForm({ ...form, costCenterUuid: v })}
                                                  options={[
                                                      {label: t('common.all'), value: '' },
                                                      ...costCenters.map(c => ({ label: [c.name, c.description].filter(t => !!t).join(' - '), value: c.uuid }))
                                                  ]} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Selector label={t('models.singular')}
                                                  value={form.modelUuid}
                                                  onChange={handleModelChange}
                                                  options={[
                                                      {label: t('common.all'), value: '' },
                                                      ...models.map(m => ({ label: m.name, value: m.uuid }))
                                                  ]} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Selector label={t('items.singular')}
                                                  value={form.itemUuid}
                                                  onChange={v => setForm({ ...form, itemUuid: v})}
                                                  options={[
                                                      {label: t('common.all'), value: '' },
                                                      ...items.map(i => ({ label: i.name, value: i.uuid }))
                                                  ]} />
                                    </Grid>
                                </Grid>
                                <Box my={2} />
                                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                    <Button variant="contained" onClick={handleReset}>{t('actions.reset')}</Button>
                                    <Box mx={1} />
                                    <Button variant="contained" color="primary" onClick={handleSubmit}>{t('actions.submit')}</Button>
                                </Box>
                            </Box>
                        </Paper>
                    )}
                    <Box my={3} />
                    {(statistic && form) && (
                        <Paper>
                            <Box p={2}>
                                {(form.timeframe !== StatisticTimeframe.Today && form.timeframe !== StatisticTimeframe.Yesterday) && (
                                    <RentalPerDayChart dateCounters={statistic.dateCounters} />
                                )}
                                <Typography><strong>{t('statistics.totalRentals')}:</strong> {statistic.totalRentals}</Typography>
                                <Typography><strong>{t('statistics.totalCost')}:</strong> € {statistic.totalCost}</Typography>
                                <Typography><strong>{t('statistics.highestCost')}:</strong> € {statistic.highestCostRental ? statistic.highestCostRental.amount?.gross + ` (${statistic.highestCostRental.itemName})`: 0 }</Typography>
                                <Typography><strong>{t('statistics.lowestCost')}:</strong> € {statistic.lowestCostRental ? statistic.lowestCostRental.amount?.gross + ` (${statistic.lowestCostRental.itemName})`: 0 }</Typography>
                                <Typography><strong>{t('statistics.longestRental')}:</strong> {statistic.longestRental ? renderDuration(statistic.longestRental) + ` (${statistic.longestRental.itemName})` : t('common.none')}</Typography>
                                <Typography><strong>{t('statistics.shortestRental')}:</strong> {statistic.shortestRental ? renderDuration(statistic.shortestRental) + ` (${statistic.shortestRental.itemName})` : t('common.none')}</Typography>
                            </Box>
                        </Paper>
                    )}
                </Grid>
            </Grid>
        </RentalAppContainer>
    )
}

const RentalPerDayChart = ({dateCounters}: {dateCounters: {[date: string]: number}}) => {
	const {t} = useLocalization();
    const [chartData, setChartData] = useState<any>([]);
    const [options, setOptions] = useState<any>({});

    useEffect(() => {
        const data: any = [];
        const cols: any = [];
        for (let date of Object.keys(dateCounters).sort((a, b) => a.localeCompare(b))) {
            if (dateCounters.hasOwnProperty(date)) {
                cols.push(date);
                data.push(dateCounters[date]);
            }
        }
        setOptions({
            xaxis: {
                categories: cols,
                title: {
                    text: t('common.date')
                }
            },
            yaxis: {
                title: {
                    text: t('rentals.plural')
                }
            }
        });
        setChartData([
            {
                name: t('rentals.plural'),
                data
            }
        ]);
    }, []);

    return (
        <ColumnChart title={t('statistics.rentalsPerDay.title')}
                     hint={t('statistics.rentalsPerDay.hint')}
                     width="100%"
                     data={chartData}
                     options={options}
        />
    )
}
