export class FileUpload {

    readonly uploadUrl: string;
    readonly file: File;

    uploading: boolean;

    progress: number;

    private uploadPromiseResolver?: (value: (PromiseLike<FileUpload> | FileUpload)) => void;
    private uploadPromiseRejector?: (reason?: any) => void;

    constructor(uploadUrl: string, file: File) {
        this.uploadUrl = uploadUrl;
        this.file = file;
        this.uploading = true;
        this.progress = 0;
    }

    cancel() {
        this.uploading = false;
        if(this.uploadPromiseRejector) {
            this.uploadPromiseRejector('Cancelled by user');
        }
    }

    private updateProgress(event: ProgressEvent) {
        if(event.lengthComputable) {
            this.progress = (event.loaded / event.total * 100 | 0);
        }
    }

    private uploadFinished(event: any) {
        this.uploading = false;
        if(this.uploadPromiseResolver) {
            this.uploadPromiseResolver(this);
        }
    }

    private uploadFailed(event: ProgressEvent) {
        this.uploading = false;
        if(this.uploadPromiseRejector) {
            this.uploadPromiseRejector('Upload Failed');
        }
    }

    upload(): Promise<FileUpload> {
        const xhr = new XMLHttpRequest();
        xhr.onload = (event) => { this.uploadFinished(event); };
        xhr.onabort = (event) => { this.uploadFailed(event); };
        if(xhr.upload) {
            xhr.upload.onprogress = (event) => { this.updateProgress(event); };
        }
        xhr.open('PUT', this.uploadUrl);
        xhr.setRequestHeader('Content-Type', this.file.type);
        xhr.send(this.file);
        return new Promise<FileUpload>((resolve, reject)  => {
            this.uploadPromiseResolver = resolve;
            this.uploadPromiseRejector = reject;
        });
    }

}
