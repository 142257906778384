import {createElement, Fragment, useEffect, useState} from "react"
import {CategoryDetails, CategoryTree} from "../../data/types";
import {listCategoryTrees} from "../../data/categories";
import {tenantUserStore} from "../../store/TenantUserStore";
import {
	Checkbox,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	TextField, useTheme
} from "@mui/material";
import {useLocalization} from "../../i18n";

type CategoryForm = {
    category?: CategoryTree,
    onChange: (details: CategoryDetails) => void
}

export const CategoryForm = ({category, onChange}: CategoryForm) => {
    const [form, setForm] = useState<CategoryDetails>({
        uuid: category?.uuid || '',
        name: category?.name || '',
        description: category?.description || '',
        parentCategoryUuid: category?.parentCategoryUuid || ''
    });

    const onInputChange = (name: string, value: any) => {
        const changed = {
            ...form,
            [name]: value
        };
        setForm(changed);
        onChange(changed);
    }

	const {t} = useLocalization();
    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth
							   label={t('common.name')}
                               value={form.name}
                               onChange={ev => onInputChange('name', ev.target.value)}
                               error={!form.name}
                               helperText={!form.name ? t('error.input.requiredField') : ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth
							   label={t('common.description')}
                               value={form.description} multiline rows={3}
                               onChange={ev => onInputChange('description', ev.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CategorySelector label={t('categories.parentCategory')}
                                      category={category}
                                      onChange={uuid => onInputChange('parentCategoryUuid', uuid)} />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export const CategorySelector = ({category, value, label, onChange}: {category?: CategoryTree, value?: string, label?: string, onChange: (uuid: string) => void}) => {
    const theme = useTheme();
	const [trees, setTrees] = useState<CategoryTree[]>([]);
	const {t} = useLocalization();

    useEffect(() => {
        listCategoryTrees(tenantUserStore.getTenantId())
            .then(setTrees);
    }, []);

    const renderTreeItems = (tree: CategoryTree, padding: number, currentCategory?: CategoryTree) => {
        const items: any = [];
        if (tree.uuid !== currentCategory?.uuid) {
            items.push(
                <MenuItem key={tree.uuid} value={tree.uuid}>
                    <div style={{ paddingLeft: theme.spacing(padding) }}>
                        {tree.name}
                    </div>
                </MenuItem>
            );
        }
        const children = currentCategory ? listChildCategory(currentCategory) : [];
        if (tree.children.length > 0) {
            for (let c of tree.children) {
                if (children.indexOf(c.uuid) === -1) {
                    items.push(...renderTreeItems(c, padding + 1, currentCategory));
                }
            }
        }
        return items;
    }

    const listChildCategory = (category: CategoryTree) => {
        const children: string[] = [];
        if (category.children) {
            for (let c of category.children) {
                children.push(c.uuid);
                if (c.children) {
                    children.push(...listChildCategory(c));
                }
            }
        }
        return children;
    }


    return (
        <TextField select variant="outlined" fullWidth
                   label={label ?? t('categories.singular')}
                   value={category?.parentCategoryUuid || value}
                   onChange={ev => onChange(ev.target.value)}
        >
            <MenuItem value={''}>{t('common.none')}</MenuItem>
            {trees.map(t => renderTreeItems(t, 0, category))}
        </TextField>
    )
}

export const CategoryMultiSelector = ({label, helperText, value, onChange}: {label?: string, helperText?: string, value: string[], onChange: (uuids: string[]) => void}) => {
    const theme = useTheme();
	const [trees, setTrees] = useState<CategoryTree[]>([]);
    const [selected, setSelected] = useState<string[]>(value);
	const {t} = useLocalization();

    useEffect(() => {
        listCategoryTrees(tenantUserStore.getTenantId())
            .then(setTrees);
    }, []);

    const renderTreeItems = (tree: CategoryTree, padding: number, currentCategory?: CategoryTree) => {
        const items: any = [];
        if (tree.uuid !== currentCategory?.uuid) {
            items.push(
                <MenuItem key={tree.uuid} value={tree.uuid} style={{ paddingLeft: theme.spacing(padding) }} >
                    <Checkbox checked={selected.indexOf(tree.uuid) > -1} />
                    <ListItemText primary={tree.name} />
                </MenuItem>
            );
        }
        const children = currentCategory ? listChildCategory(currentCategory) : [];
        if (tree.children.length > 0) {
            for (let c of tree.children) {
                if (children.indexOf(c.uuid) === -1) {
                    items.push(...renderTreeItems(c, padding + 3, currentCategory));
                }
            }
        }
        return items;
    }

    const listChildCategory = (category: CategoryTree) => {
        const children: string[] = [];
        if (category.children) {
            for (let c of category.children) {
                children.push(c.uuid);
                if (c.children) {
                    children.push(...listChildCategory(c));
                }
            }
        }
        return children;
    }

    const handleChange = (value: string[]) => {
        setSelected(value);
        onChange(value);
    }

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel>{label ?? t('categories.plural')}</InputLabel>
            <Select multiple label={label ?? t('categories.plural')}
                    value={selected}
                    onChange={({target}) => handleChange(target.value as any)}
                    renderValue={(v: any) => v.length + ' ' + t('users.categoriesSelectCount')}
                    MenuProps={{ anchorEl: null }}
            >
                {trees.map(t => renderTreeItems(t, 0))}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
}
