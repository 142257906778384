import {createElement} from "react";
import {RentalBorrower} from "../../data/types";
import {useLocalization} from "../../i18n";

export function RentalBorrowerName({borrower}: {borrower?: RentalBorrower}) {
    if (borrower) {
        if(borrower.name) {
            return (<span>{borrower.name}</span>);
        }
        if(borrower.email) {
            return (<span>{borrower.email}</span>);
        }
        if(borrower.phone) {
            return (<span>{borrower.phone}</span>);
        }
    }
	const {t} = useLocalization();
    return (<span>{t('rentals.borrower.unknownBorrower')}</span>);
}
