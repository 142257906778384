import {Api} from "../Api";

export interface UploadUrlRequest {
    filename: string;
    size: number;
    lastModified: number;
    type: string;
}

export interface UploadUrlResponse {
    uploadUrl: string;
    publicUrl: string;
}

export function uploadUrl(tenantId: string, file: File): Promise<UploadUrlResponse> {
    const request: UploadUrlRequest = {
        filename: file.name,
        size: file.size,
        lastModified: file.lastModified,
        type: file.type
    };
    return Api.POST<UploadUrlResponse>(`tenants/${tenantId}/upload-url`, request).then(res => res as UploadUrlResponse);
}
