import {createElement, useState} from "react";
import {
    Box, Button,
    Grid,
    Paper,
    Typography
} from "@mui/material";
import {ApiError} from "../../Api";
import {CostCenterDetails} from "../../data/types";
import {createCostCenter} from "../../data/costcenters";
import {tenantUserStore} from "../../store/TenantUserStore";
import {ErrorDialog} from "../../components/ErrorDialog";
import {CostCenterForm} from "./CostCenterForm";
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer";
import {Breadcrumbs, BreadcrumbItem} from "@variocube/app-ui";
import {CrumbLink} from "../../components/CrumbLink";
import {useNavigate} from "react-router-dom";

export const CostCenterCreate = () => {
	const navigate = useNavigate();
    const [details, setDetails] = useState<CostCenterDetails>();
    const [error, setError] = useState<ApiError>();

    const create = () => {
        if (details) {
            createCostCenter(tenantUserStore.getTenantId(), details)
                .then(cc => navigate('/cost-centers/' + cc.uuid))
                .catch(setError);
        }
    }

	const {t} = useLocalization();
    return (
        <RentalAppContainer title={t('costCenters.create.title')}>
			<Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{ flexGrow: 1 }}>
					<Breadcrumbs>
						<CrumbLink href='/cost-centers'>{t('costCenters.plural')}</CrumbLink>
						<BreadcrumbItem>{t('costCenters.create.title')}</BreadcrumbItem>
					</Breadcrumbs>
					<Box my={1} />
                    <Typography variant="h4" gutterBottom>{t('costCenters.create.title')}</Typography>
                </Grid>
            </Grid>
			<Box my={3} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        <Box p={3}>
                            <CostCenterForm onChange={setDetails} />
                            <Box my={3}/>
                            <Box display="flex" justifyContent="flex-end">
                                <Button variant="contained" onClick={() => navigate("/cost-centers")}>{t('actions.cancel')}</Button>
                                <Box mx={1} />
                                <Button variant="contained" color="primary" disabled={!details || !details.name} onClick={create}>{t('actions.create')}</Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}
