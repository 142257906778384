import {
	AccountTree,
	BarChart,
	Category,
	Event,
	ExitToApp,
	FolderCopy,
	LocalOffer,
	Paid,
	People,
	Settings,
	ViewColumn,
	Clear,
	Search,
	Add,
	AssignmentTurnedIn,
	ExpandMore,
	Alarm,
	GetApp,
	Mail,
	Phone,
	KeyboardArrowLeft,
	KeyboardArrowRight,
	ArrowDropDown,
	BrokenImage,
	Help,
	Build,
	Assistant,
	Check,
	Close,
	Attachment,
	Visibility,
	CheckCircle,
	Info,
	Delete,
	LockOpen,
	Error,
	NotificationImportant,
	Lock,
	Cloud,
	CloudOff,
	Block,
	Person,
	Edit,
	AccountBalance,
	PostAdd, PersonAdd, Folder, FolderSpecial, OpenInNew
} from '@mui/icons-material';

export const RentalIcon = Event;
export const LogoutIcon = ExitToApp;
export const TicketIcon = LocalOffer;
export const CategoryIcon = FolderCopy;
export const ModelIcon = AccountTree;
export const ItemIcon = Category;
export const CubeIcon = ViewColumn;
export const CostCenterIcon = Paid;
export const UserIcon = People;
export const StatisticIcon = BarChart;
export const SettingIcon = Settings;
export const ClearIcon = Clear;
export const SearchIcon = Search;
export const AddIcon = Add;
export const ExpandIcon = ExpandMore;
export const CheckBoardIcon = AssignmentTurnedIn;
export const AlarmIcon = Alarm;
export const DownloadIcon = GetApp;
export const KeyboardArrowLeftIcon = KeyboardArrowLeft;
export const KeyboardArrowRightIcon = KeyboardArrowRight;
export const MailIcon = Mail;
export const PhoneIcon = Phone;
export const ArrowDropDownIcon = ArrowDropDown;
export const BrokenImageIcon = BrokenImage;
export const HelpIcon = Help;
export const BuildIcon = Build;
export const AssistantIcon = Assistant;
export const CheckIcon = Check;
export const CLoseIcon = Close;
export const AttachmentIcon = Attachment;
export const VisibilityIcon = Visibility;
export const CheckCircleIcon = CheckCircle;
export const InfoIcon = Info;
export const DeleteIcon = Delete;
export const LockOpenIcon = LockOpen;
export const LockIcon = Lock;
export const ErrorIcon = Error;
export const NotificationImportantIcon = NotificationImportant;
export const CloudIcon = Cloud;
export const CloudOffIcon = CloudOff;
export const BlockIcon = Block;
export const PersonIcon = Person;
export const EditIcon = Edit;
export const AccountBalanceIcon = AccountBalance;
export const ExitToAppIcon = ExitToApp;
export const PostAddIcon = PostAdd;
export const PersonAddIcon = PersonAdd;
export const CategoryTreeIcon = AccountTree;
export const FolderIcon = Folder;
export const FolderStarIcon = FolderSpecial;
export const OpenNewIcon = OpenInNew;

