import {createElement} from "react";
import {Chip} from "@mui/material";
import {Rental} from "../../data/types";
import {lookupColorCodeForRental, lookupLabelForRentalState} from "../../data/rentals";

interface RentalStateProps {
    rental: Rental;
    size?: 'small' | 'medium';
}

export function RentalStateComponent(props: RentalStateProps) {
    const {rental, size} = props;
    const label = lookupLabelForRentalState(rental.state);
    const color = lookupColorCodeForRental(rental);
    return (
        <Chip style={color} size={size} label={label} />
    );
}

