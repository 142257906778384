import {createElement, useEffect} from "react";
import {Tenant} from "../../data/types";
import {tenantUserStore} from "../../store/TenantUserStore";
import {listTenants} from "../../data/tenants";
import {FormattedAlert} from "../../components/FormattedAlert";
import {Loading} from "../../components/Loading";
import {Grid, List, ListItemButton, ListItemText, Paper, Typography} from "@mui/material";
import {RentalAppContainer} from "../../RentalAppContainer";
import {useLocalization} from "../../i18n";
import {useAsync} from "react-async-hook";

export const TenantSelector = ({onTenantSelected}: { onTenantSelected: () => void }) => {
	const {result: tenants} = useAsync(listTenants, []);

	useEffect(() => {
		if (tenants && tenants.length === 1) {
			console.log('There is only 1 tenant available, auto-select tenant ', tenants[0]);
			handleTenantSelect(tenants[0]);
		}
	}, [tenants]);

	function handleTenantSelect(tenant: Tenant) {
		tenantUserStore.setTenant(tenant);
		onTenantSelected();
	}

	const {t} = useLocalization();
    return (
		<RentalAppContainer title={t('tenants.plural')}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h4" gutterBottom>{t('tenants.plural')}</Typography>
					<Typography variant="body1">{t('tenants.select')}</Typography>
				</Grid>
				<Grid item xs={12}>
					{tenants == undefined && <Loading />}
					{tenants && tenants.length > 0 && (
						<Paper>
							<List>
								{tenants.map(t =>
									<ListItemButton key={t.centerId} onClick={() => handleTenantSelect(t)} >
										<ListItemText primary={t.name || t.centerId} />
									</ListItemButton>
								)}
							</List>
						</Paper>
					)}
					{tenants && tenants.length == 0 && (
						<FormattedAlert severity="error" title={t('tenants.noTenant.title')} info={t('tenants.noTenant.info')} />
					)}
				</Grid>
			</Grid>
		</RentalAppContainer>
    )
}
