import {createElement, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {tenantUserStore} from "../../store/TenantUserStore";
import {
    deleteCategory,
    getCategory,
    listCategoryItems,
    listCategoryModels,
    putCategoryItems,
    putCategoryModels, updateCategory
} from "../../data/categories";
import {CategoryDetails, CategoryTree, Item, Model} from "../../data/types";
import {ApiError} from "../../Api";
import {
	Box,
	Button, Dialog, DialogActions, DialogContent, DialogTitle,
	Divider,
	Grid, IconButton,
	List,
	ListItem, ListItemButton,
	ListItemIcon, ListItemSecondaryAction,
	ListItemText,
	Paper, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Tooltip,
	Typography, useTheme
} from "@mui/material";
import {Loading} from "../../components/Loading";
import {LabeledData} from "../../components/LabeledData";
import {
    AddIcon,
    CategoryTreeIcon,
    DeleteIcon,
    EditIcon,
    FolderIcon,
    FolderStarIcon,
    OpenNewIcon
} from "../../theme";
import {listModels} from "../../data/models";
import {listItems} from "../../data/items";
import {Breadcrumbs, BreadcrumbItem,  Selector} from "@variocube/app-ui";
import {CategoryForm} from "./CategoryForm";
import {CategoryActions} from "./CategoryActions";
import {AddFab} from "../../components/AddFab";
import {ErrorDialog} from "../../components/ErrorDialog";
import {getQueryValue} from "../../tools";
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer";
import {CrumbLink} from "../../components/CrumbLink";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";

export const CategoryView = () => {
	const navigate = useNavigate();
	const {uuid = ''} = useParams<{ uuid: string }>();

    const [mainCategory, setMainCategory] = useState<CategoryTree>();
    const [category, setCategory] = useState<CategoryTree>();
    const [categoryModels, setCategoryModels] = useState<Model[]>([]);
    const [categoryItems, setCategoryItems] = useState<Item[]>([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [error, setError] = useState<ApiError>();

    const [models, setModels] = useState<Model[]>([]);
    const [items, setItems] = useState<Item[]>([]);
    const [editModelList, setEditModelList] = useState<Model[]>([]);
    const [editItemList, setEditItemList] = useState<Item[]>([]);
    const [editListUuid, setEditListUuid] = useState<string>('');
    const [editListMode, setEditListMode] = useState(false);

    const [categoryEditMode, setCategoryEditMode] = useState(false);
    const [categoryDeleteMode, setCategoryDeleteMode] = useState(false);

    const fetch = useCallback(async (uuid: string, subCategory?: boolean) => {
        try {
            const c = await getCategory(tenantUserStore.getTenantId(), uuid);
            setCategory(c);
            const models = await listCategoryModels(tenantUserStore.getTenantId(), uuid);
            setCategoryModels(models);
            const items = await listCategoryItems(tenantUserStore.getTenantId(), uuid);
            setCategoryItems(items);
            if (!mainCategory && !subCategory) {
                setMainCategory(c);
                const {content: ml} = await listModels(tenantUserStore.getTenantId());
                const {content: il} = await listItems(tenantUserStore.getTenantId());
                setModels(ml);
                setItems(il);
            }
        } catch (error) {
            setError(error as any);
        }
    }, [mainCategory])

    const refreshMainCategory = async () => {
        if (mainCategory) {
            try {
                const c = await getCategory(tenantUserStore.getTenantId(), mainCategory.uuid);
                setMainCategory(c);
            } catch (error) {
                setError(error as any);
            }
        }
    }

    useEffect(() => {
        const targetCategory = getQueryValue('uuid');
        fetch(uuid)
            .then(() => {
                handleTabChange(0).then();
                if (targetCategory) fetch(targetCategory, true).then();
            });
    }, []);

    const handleTabChange = async (index: number) => {
        if (category) {
            try {
                switch (index) {
                    case 0:
                        const models = await listCategoryModels(tenantUserStore.getTenantId(), category.uuid);
                        setCategoryModels(models);
                        break;
                    case 1:
                        const items = await listCategoryItems(tenantUserStore.getTenantId(), category.uuid);
                        setCategoryItems(items);
                        break;
                }
            } catch (error) {
                setError(error as any);
            }
        }
        setTabIndex(index);
    }

    const openListEditor = async () => {
        setEditListMode(true);
        try {
            if (tabIndex === 0) {
                setEditModelList([...categoryModels]);
            } else if (tabIndex === 1) {
                setEditItemList([...categoryItems]);
            }
        } catch (error) {
            setError(error as any);
        }
    }

    const handleAddListItem = () => {
        if (tabIndex === 0) {
            const model = models.find(m => m.uuid === editListUuid);
            if (model) setEditModelList([...editModelList, model])
        } else if (tabIndex === 1) {
            const item = items.find(i => i.uuid === editListUuid);
            if (item) setEditItemList([...editItemList, item])
        }
        setEditListUuid('');
    }

    const handleSaveListItem = async () => {
        if (category) {
            try {
                if (tabIndex === 0) {
                    const models = await putCategoryModels(tenantUserStore.getTenantId(), category.uuid, editModelList.map(m => m.uuid));
                    setCategoryModels(models);
                } else if (tabIndex === 1) {
                    await putCategoryItems(tenantUserStore.getTenantId(), category.uuid, editItemList.filter(i => !i.model).map(i => i.uuid));
                    const items = await listCategoryItems(tenantUserStore.getTenantId(), category.uuid);
                    setCategoryItems(items);
                }
                await refreshMainCategory();
            } catch (error) {
                setError(error as any);
            }
            setEditListMode(false);
        }
    }

    const handleCategoryUpdated = () => {
        setCategoryEditMode(false);
        if (category) {
            fetch(category.uuid).then();
            refreshMainCategory().then();
        }
    }

    const handleDeleteCategory = async () => {
        if (mainCategory && category) {
            try {
                await deleteCategory(tenantUserStore.getTenantId(), category.uuid)
            } catch (error) {
                setError(error as any);
            }
            if (category.uuid === mainCategory.uuid) {
                navigate('/categories', { replace: true });
                return;
            }
            fetch(mainCategory.uuid).then();
            refreshMainCategory().then();
            setCategoryDeleteMode(false);
        }
    }

    const {t} = useLocalization();
    return (
        <RentalAppContainer title={category ? category.name : t('categories.singular')}>
            {!category && (
                <Box p={5}>
                    <Loading />
                </Box>
            )}
            {category && (
                <Fragment>
                    <Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                        <Grid item style={{ flexGrow: 1 }}>
							<Breadcrumbs>
								<CrumbLink href='/categories'>{t('categories.plural')}</CrumbLink>
								<BreadcrumbItem>{category.name}</BreadcrumbItem>
							</Breadcrumbs>
                    		<Box my={1} />
                            <Typography variant="h4">
                                {t('categories.singular')}: {category.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {tenantUserStore.admin && (
                                <CategoryActions onEditCategory={() => setCategoryEditMode(true)}
                                                 onDeleteCategory={() => setCategoryDeleteMode(true)}
                                />
                            )}
                        </Grid>
                    </Grid>
					<Box my={3} />
                    <Paper>
                        <Box p={3}>
                            <LabeledData label={t('common.name')}>
                                {category.name}
                            </LabeledData>
                            <Box my={2} />
                            <LabeledData label={t('common.description')}>
                                {category.description}
                            </LabeledData>
                        </Box>
                    </Paper>
                    <Box my={2} />
                    <Paper>
                        <Tabs value={tabIndex} onChange={(e,v) => handleTabChange(v)}>
                            <Tab label={t('models.plural')} />
                            <Tab label={t('items.plural')} />
                        </Tabs>
                        <Divider />
                        <Box m={2}>
                            <Button variant="outlined" size="small" color="primary"
                                    startIcon={<EditIcon />}
                                    onClick={openListEditor}
                            >
                                {tabIndex === 0 ? t('categories.editModelsList.title') : t('categories.editItemsList.title')}
                            </Button>
                        </Box>
                        {tabIndex === 0 && (
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('models.singular')}</TableCell>
                                        <TableCell>{t('common.description')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {categoryModels.map(m => (
                                        <TableRow key={m.uuid} sx={{ cursor: 'pointer' }} hover onClick={() => open('/models/' + m.uuid, '_blank')}>
                                            <TableCell>{m.name} <OpenNewIcon fontSize="inherit"/></TableCell>
                                            <TableCell>{m.description}</TableCell>
                                        </TableRow>
                                    ))}
                                    {categoryModels.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <Box p={2} textAlign="center">
                                                    <Typography>{t('common.emptyList')}</Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        )}
                        {tabIndex === 1 && (
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('items.singular')}</TableCell>
                                        <TableCell>{t('models.singular')}</TableCell>
                                        <TableCell>{t('common.description')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {categoryItems.sort((a, b) => a.name.localeCompare(b.name)).map(i => (
                                        <TableRow key={i.uuid} sx={{ cursor: 'pointer' }} hover onClick={() => open('/items/' + i.uuid, '_blank')}>
                                            <TableCell>{i.name} <OpenNewIcon fontSize="inherit"/></TableCell>
                                            <TableCell>{i.model ? i.model.name : '-'}</TableCell>
                                            <TableCell>{i.description}</TableCell>
                                        </TableRow>
                                    ))}
                                    {categoryItems.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={3}>
                                                <Box p={2} textAlign="center">
                                                    <Typography>{t('common.emptyList')}</Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        )}
                    </Paper>

                    <Dialog open={editListMode} fullWidth maxWidth="md">
                        <DialogTitle>{tabIndex === 0 ? t('categories.editModelsList.title') : t('categories.editItemsList.title')}</DialogTitle>
                        <DialogContent>
                            <LabeledData label={t('categories.singular')}>
                                {category.name}
                            </LabeledData>
                            <Box my={2}>
                                <Divider />
                            </Box>
                            <Grid container spacing={3}>
                                <Grid item sm={6} xs={12}>
                                    <Box display="flex" flexDirection="row" alignItems="flex-start">
                                        {tabIndex === 0 && (
                                            <Selector label={t('models.singular')}
                                                      options={models.filter(m => editModelList.map(cm => cm.uuid).indexOf(m.uuid) === -1).map(m => ({ label: m.name, value: m.uuid }))}
                                                      value={editListUuid}
                                                      onChange={v => setEditListUuid(v)}
                                                      size="small"
                                                      helperText={t('categories.editModelsList.hint')}
                                            />
                                        )}
                                        {tabIndex === 1 && (
                                            <Selector label={t('items.singular')}
                                                      options={items.filter(i => editItemList.map(ci => ci.uuid).indexOf(i.uuid) === -1 && !i.model).map(i => ({ label: i.name, value: i.uuid }))}
                                                      value={editListUuid}
                                                      onChange={v => setEditListUuid(v)}
                                                      size="small"
                                                      helperText={t('categories.editItemsList.hint')}
                                            />
                                        )}
                                        <Box mx={1} />
                                        <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleAddListItem}>{t('actions.add')}</Button>
                                    </Box>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Typography variant="h6">{tabIndex === 0 ? t('models.plural') : t('items.plural')}</Typography>
                                    <List dense>
                                        {tabIndex === 0 && editModelList.map(m => (
                                            <ListItem key={'cm-' + m.uuid}>
                                                <ListItemText primary={m.name} />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" onClick={() => setEditModelList(editModelList.filter(em => em.uuid !== m.uuid))}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                        {tabIndex === 1 && editItemList.map(i => (
                                            <ListItem key={'ci-' + i.uuid}>
                                                <ListItemText primary={i.name} />
                                                <ListItemSecondaryAction>
                                                    <Tooltip title={i.model != undefined ? t('categories.editItemsList.disabled') : ''} placement="top">
                                                        <IconButton edge="end" style={{ opacity: i.model ? 0.5 : 1 }} onClick={() => {
                                                            if (!i.model) setEditItemList(editItemList.filter(ei => ei.uuid !== i.uuid))
                                                        }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>

                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setEditListMode(false)}>{t('actions.cancel')}</Button>
                            <Button variant="contained" color="primary" onClick={handleSaveListItem}>{t('actions.save')}</Button>
                        </DialogActions>
                    </Dialog>
                    {categoryEditMode && (
                        <CategoryEditForm category={category}
                                          onClose={() => setCategoryEditMode(false)}
                                          onSave={handleCategoryUpdated} />
                    )}
                    <Dialog open={categoryDeleteMode}>
                        <DialogTitle>{t('categories.delete.title')}</DialogTitle>
                        <DialogContent>
                            <Typography>{t('categories.delete.message', { categoryName: category.name })}</Typography>
                            <Typography><strong>{t('categories.delete.warning')}</strong></Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={() => setCategoryDeleteMode(false)}>{t('actions.cancel')}</Button>
                            <Button variant="contained" color="primary" onClick={handleDeleteCategory}>{t('actions.delete')}</Button>
                        </DialogActions>
                    </Dialog>
                </Fragment>
            )}
            <AddFab onClick={() => navigate('/categories/create')} />
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}

const CategoryTreeComponent = ({mainCategory, onCategoryChange}: { mainCategory: CategoryTree, onCategoryChange: (uuid: string) => void}) => {
    const [active, setActive] = useState<string>(mainCategory.uuid);

    const isActive = (uuid: string) => (active === uuid);

    const handleCategoryChange = (uuid: string) => {
        setActive(uuid);
        onCategoryChange(uuid);
    }

    const theme = useTheme();
    const renderCategoryTree = (tree: CategoryTree, padding: number) => {
        return (
            <Fragment>
                {tree.children.length > 0 && (
                    <List dense disablePadding sx={{
						maxHeight: 450,
						overflow: 'auto'
					}}>
                        {tree.children.sort((a, b) => a.name.localeCompare(b.name)).map(t => (
                            <Fragment key={t.uuid}>
                                <ListItemButton
									sx={isActive(t.uuid) ? {
										background: `${theme.palette.primary.light} !important`,
										'& *': {
											color: '#fff !important'
										}
									} : undefined}
									style={{ paddingLeft: theme.spacing(padding) }}
									onClick={() => handleCategoryChange(t.uuid)}
								>
                                    <ListItemIcon>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t.name}/>
                                </ListItemButton>
                                <Divider />
                                {renderCategoryTree(t, padding + 1.5)}
                            </Fragment>
                        ))}
                    </List>
                )}
            </Fragment>
        )
    }

    return (
        <Paper>
            <Box p={2}>
                <Typography variant="h6" sx={{
					maxHeight: 450,
					overflow: 'auto'
				}}>
                    <CategoryTreeIcon/>
                    <Box mx={0.5} />
                    <strong>Tree</strong>
                </Typography>
                <Box my={2}/>
                <Paper
					variant="outlined"
					sx={isActive(mainCategory.uuid) ? {
						background: `${theme.palette.primary.light} !important`,
						'& *': {
							color: '#fff !important'
						}
					} : undefined}
				>
                    <ListItem button dense onClick={() => handleCategoryChange(mainCategory.uuid)}>
                        <ListItemIcon>
                            <FolderStarIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={mainCategory.name}
                            secondary={mainCategory.description?.substring(0, 30) ?? '-'}
                        />
                    </ListItem>
                </Paper>
            </Box>
            <Divider />
            {renderCategoryTree(mainCategory, 4)}
        </Paper>
    )
}

export const CategoryEditForm = ({category, onClose, onSave}: { category: CategoryTree, onSave: () => void, onClose: () => void }) => {
    const [details, setDetails] = useState<CategoryDetails>();
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState<ApiError>();

    const canSave = useMemo(() => (details && details.name && !processing), [details, processing]);

    const save = async () => {
        if (category && details) {
            setProcessing(true);
            try {
                await updateCategory(tenantUserStore.getTenantId(), category.uuid, details);
                onSave()
                return;
            } catch (error) {
                setError(error as any)
            }
            setProcessing(false);
        }
    }

	const {t} = useLocalization();
    return (
        <Dialog open={true} fullWidth maxWidth="sm">
            <DialogTitle>{t('categories.edit.title')}</DialogTitle>
            <DialogContent>
                <CategoryForm category={category} onChange={setDetails} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>{t('actions.cancel')}</Button>
                <Button variant="contained" color="primary" disabled={!canSave} onClick={save}>{t('actions.save')}</Button>
            </DialogActions>
        </Dialog>
    )
}
