import {createElement} from "react";
import {User} from "../../data/types";

export const UserLanguageDisplay = ({user}: { user: User }) => {
    switch (user.locale) {
        case 'en':
            return <span>English</span>;
        case 'de':
            return <span>Deutsch</span>;
        default:
            return <span>--</span>;
    }
};