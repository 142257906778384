import {createElement, useState} from "react";
import {Box, Typography, useTheme} from "@mui/material";
import {useLocalization} from "../i18n";

export const FileInput = ({accept, onChange}: { accept?: string, onChange?: (file: File) => void }) => {
    const [file, setFile] = useState<File>();

    const handleChange = (files: FileList|null) => {
        if (files && files.length > 0) {
            setFile(files[0]);
            if (onChange) onChange(files[0]);
        }
    }

	const theme = useTheme();
	const {t} = useLocalization();
    return (
        <Box
			sx={{
				position: 'relative',
				border: '1px solid #ddd',
				borderRadius: 1,
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center'
			}}
		>
            <Box
				sx={{
					position: 'relative',
					zIndex: 5,
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(2)}`
				}}
			>
                <Typography
					sx={{
						fontSize: '1rem',
						flexGrow: 2,
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						overflow: 'hidden'
					}}
				>
                    {!file && t('file.select')}
                    {file && file.name}
                </Typography>
                <Box
					sx={{
						background: theme.palette.primary.main,
						fontSize: '1rem',
						borderRadius: 1,
						padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
						color: '#fff'
					}}
				>
					{t('file.browse')}
				</Box>
            </Box>
            <input
				style={{
					position: 'absolute',
					opacity: 0,
					zIndex: 10,
					width: '100%',
					height: '100%',
					cursor: 'pointer'
				}}
				type="file"
				accept={accept}
				onChange={ev => handleChange(ev.target.files)}
			/>
        </Box>
    )
}
