import {createElement, Fragment, useCallback, useEffect, useState} from "react"
import {
	Box,
	Button,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography, useTheme
} from "@mui/material";
import {ApiError} from "../../Api";
import {tenantUserStore} from "../../store/TenantUserStore";
import {CategoryTree} from "../../data/types";
import {listCategoryTrees} from "../../data/categories";
import {AddFab} from "../../components/AddFab";
import { Loading } from "../../components/Loading";
import {ErrorDialog} from "../../components/ErrorDialog";
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer";
import {Breadcrumbs, BreadcrumbItem} from "@variocube/app-ui";
import {useNavigate} from "react-router-dom";

export const CategoryList = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const {t} = useLocalization();

    const [trees, setTrees] = useState<CategoryTree[]>();
    const [error, setError] = useState<ApiError>();

    const loadPage = useCallback(async (n?: string) => {
        try {
            const trees = await listCategoryTrees(tenantUserStore.getTenantId());
            setTrees(trees);
        } catch (error) {
            setError(error as any);
        }
    }, []);

    useEffect(() => {
        loadPage().then();
    }, []);

    function renderTreeRow(tree: CategoryTree, level: number) {
        return (
            <Fragment key={tree.uuid}>
                <TableRow hover style={{ cursor: 'pointer' }} onClick={() => navigate('/categories/' + tree.uuid)}>
                    <TableCell>
                        <span style={{ paddingLeft: theme.spacing(level * 1.5) }}></span>
                        {level > 0 && '|--'}
                        {level > 0 && <span style={{ paddingLeft: theme.spacing(1) }}></span>}
                        {tree.name}
                    </TableCell>
                    <TableCell>{tree.description}</TableCell>
                </TableRow>
                {tree.children && tree.children.length > 0 && tree.children.map(c => renderTreeRow(c, level + 1))}
            </Fragment>
        )
    }

    return (
        <RentalAppContainer title={t('categories.plural')}>
			<Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{ flexGrow: 1 }}>
					<Breadcrumbs>
						<BreadcrumbItem>{t('categories.plural')}</BreadcrumbItem>
					</Breadcrumbs>
					<Box my={1} />
                    <Typography variant="h4">{t('categories.plural')}</Typography>
                </Grid>
                <Grid item>
                    <Button variant="outlined" onClick={() => navigate('/categories/import')}>{t('categories.import.title')}</Button>
                </Grid>
            </Grid>
			<Box my={3} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        {!trees && (
                            <Box p={5}>
                                <Loading />
                            </Box>
                        )}
                        {trees && (
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('categories.singular')}</TableCell>
                                        <TableCell>{t('common.description')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {trees.map(t => renderTreeRow(t, 0))}
                                </TableBody>
                            </Table>
                        )}
                    </Paper>
                </Grid>
            </Grid>
            <AddFab onClick={() => navigate('/categories/create')} />
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}
