import {createElement, Fragment, useEffect, useState} from "react";
import {Box, Button, Grid, List, ListItem, ListItemText, Paper, Typography} from "@mui/material";
import {ApiError} from "../../Api";
import {TenantSignUpInformation} from "../../data/types";
import {getTenantSignUpInformation} from "../../data/tenants";
import {tenantUserStore} from "../../store/TenantUserStore";
import {Loading} from "../../components/Loading";
import {CopyUrl} from "../../components/CopyUrl";
import {ErrorDialog} from "../../components/ErrorDialog";
import {FormattedAlert} from "../../components/FormattedAlert";
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer";
import {BreadcrumbItem,  Breadcrumbs} from "@variocube/app-ui";
import { CrumbLink } from "../../components/CrumbLink";
import {useNavigate} from "react-router-dom";

export const UserSignUpSettings = () => {
	const navigate = useNavigate();

    const [signUpInformation, setSignUpInformation] = useState<TenantSignUpInformation>();
    const [error, setError] = useState<ApiError>();

    useEffect(() => {
        getTenantSignUpInformation(tenantUserStore.getTenantId())
            .then(setSignUpInformation)
            .catch(setError);
    }, []);

    const prepareUrl = (domain: string) => {
        const proto = window.location.protocol;
        let port = '';
        if(window.location.port != '80' && window.location.port != '443') {
            if(domain.indexOf(port) == -1) {
                port = `:${window.location.port}`;
            }
        }
        return `${proto}//${domain}${port}/signup`;
    };

	const {t} = useLocalization();
    return(
        <RentalAppContainer title={t('users.signupInformation.title')}>
			<Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{ flexGrow: 1 }}>
					<Breadcrumbs>
						<CrumbLink href="/users">{t('users.plural')}</CrumbLink>
						<BreadcrumbItem>{t('users.signupInformation.title')}</BreadcrumbItem>
					</Breadcrumbs>
					<Box my={1} />
                    <Typography variant="h4">{t('users.signupInformation.title')}</Typography>
                </Grid>
            </Grid>
			<Box my={3} />
            <Grid container spacing={3}>
                {!signUpInformation && (
                    <Grid item xs={12}>
                        <Loading />
                    </Grid>
                )}
                {signUpInformation && !signUpInformation.enabled && (
                    <Fragment>
                        <Grid item xs={12}>
                            <FormattedAlert severity="warning"
                                            title={t('users.signupInformation.notConfigured.title')}
                                            info={t('users.signupInformation.notConfigured.info')} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align="center" component="div">
                                <Button variant="outlined" onClick={(_) => navigate('/settings')}>
                                    {t('users.signupInformation.notConfigured.goSettings')}
                                </Button>
                            </Typography>
                        </Grid>
                    </Fragment>
                )}
                {signUpInformation && signUpInformation.signUpDomains.length > 0 && (
                    <Grid item xs={12}>
                        <Paper>
                            <Box p={2} pb={1}>
                                <Typography gutterBottom={false}>{t('users.signupInformation.infoDomains')}</Typography>
                            </Box>
                            <List dense>
                                {signUpInformation.signUpDomains
                                    .map((d) => prepareUrl(d))
                                    .map((url) =>
                                        <ListItem key={url}>
                                            <ListItemText primary={<CopyUrl label={url} url={url} />} />
                                        </ListItem>
                                    )}
                            </List>
                        </Paper>
                    </Grid>
                )}
                {signUpInformation && signUpInformation.emailDomains.length > 0 && (
                    <Grid item xs={12}>
                        <Paper>
                            <Box p={2} pb={1}>
                                <Typography gutterBottom={false}>{t('users.signupInformation.infoEmails')}</Typography>
                            </Box>
                            <List dense>
                                {signUpInformation.emailDomains.map((domain) =>
                                    <ListItem key={domain}>
                                        <ListItemText primary={<CopyUrl label={domain} />} />
                                    </ListItem>
                                )}
                            </List>
                        </Paper>
                    </Grid>
                )}
            </Grid>
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}

