import {Page, Paging} from "@variocube/app-ui";
import {Api} from "../Api";
import {Comment, CommentCreateRequest, Ticket, TicketCreateRequest, TicketSetStateRequest} from "./types";

export function createTicket(tenantId: string, request: TicketCreateRequest) {
    return Api.POST<Ticket>(`tenants/${tenantId}/tickets`, request);
}

export function listTickets(tenantId: string, paging: Paging) {
    return Api.GET<Page<Ticket>>(`tenants/${tenantId}/tickets` + paging.toQueryString(), { 'Accept': 'application/vnd.tickets.paged+json' });
}

export function getTicket(tenantId: string, uuid: string) {
    return Api.GET<Ticket>(`tenants/${tenantId}/tickets/${uuid}`).then(res => res as Ticket);
}

export function deleteTicket(tenantId: string, uuid: string) {
    return Api.DELETE<void>(`tenants/${tenantId}/tickets/${uuid}`);
}

export function setTicketState(tenantId: string, uuid: string, request: TicketSetStateRequest) {
    return Api.PUT<Ticket>(`tenants/${tenantId}/tickets/${uuid}/set-state`, request);
}

export function getTicketComments(tenantId: string, uuid: string) {
    return Api.GET<Comment[]>(`tenants/${tenantId}/tickets/${uuid}/comments`)
        .then(comments => comments.sort((a, b) => a.createdAt.localeCompare(b.createdAt)));
}

export function postTicketComment(tenantId: string, uuid: string, request: CommentCreateRequest) {
    return Api.POST<Comment>(`tenants/${tenantId}/tickets/${uuid}/comments`, request);
}