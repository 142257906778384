import {createElement} from "react";
import {Alert, AlertColor, Grid, Paper, Typography} from "@mui/material";

interface FormattedAlertProps {
    severity?: AlertColor;
    title: string;
    info: string;
}

export function FormattedAlert(props: FormattedAlertProps) {
    const {severity, title, info} = props;
    return (
        <Paper>
            <Alert severity={severity}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="body1" style={{fontWeight: 'bolder'}}>{title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">{info}</Typography>
                    </Grid>
                </Grid>
            </Alert>
        </Paper>
    );
}
