import {createElement, Fragment, useEffect, useState, useCallback} from "react";
import {DateTime, FixedOffsetZone} from 'luxon';
import {usePaletteMode} from "@variocube/app-ui";
import {Calendar} from "react-big-calendar";
import {Box} from "@mui/material";
import {initials, luxonDate} from "../../tools";
import {lookupColorCodeForRental} from "../../data/rentals";
import {Rental} from "../../data/types";
import {tenantUserStore} from "../../store/TenantUserStore";
import {luxonLocalizer} from "../../utils/luxonLocalizer";
import {Loading} from "../../components/Loading";
import {useLocalization} from "../../i18n";

import "../../assets/css/react-big-calendar.css";

export interface MappedEvent {
    id: number;
    title: string;
    allDay?: boolean;
    start: Date;
    end: Date;
    color?: string;
    backgroundColor?: string;
    rental: Rental;
}

type RentalsCalendarProps = {
    rentals: Rental[],
    onSelectSlot: (from: DateTime, until: DateTime) => void,
    onSelectRental: (rentalUuid: string) => void,
    onRangeChanged: (from: DateTime, until: DateTime) => void
}

export const RentalsCalendar = ({rentals, onSelectSlot, onSelectRental, onRangeChanged}: RentalsCalendarProps) => {
	const {mode} = usePaletteMode();
    const [events, setEvents] = useState<MappedEvent[]>();

    useEffect(() => {
        setEvents(mapRentalsToEvents(rentals));
    }, [rentals]);

    const eventStyleGetter = (event: MappedEvent, start: Date | string, end: Date | string, selected: boolean) => {
        const style = {
            color: event.color || '#ffffff',
            backgroundColor: event.backgroundColor || '#05164d',
        };
        return {
            style: style
        };
    }

    const mapRentalsToEvents = (rentals: Rental[]) => {
        return rentals.map((rental, index) => {
            const color = lookupColorCodeForRental(rental);
            let title = rental.itemName;
            if(rental.borrower && rental.borrower.name) {
                title = `${initials(rental.borrower.name)}: ${rental.itemName}`;
            }
            return {
                id: index,
                title: title,
                start: rental.from.toJSDate(),
                end: rental.until.toJSDate(),
                color: color.color,
                backgroundColor: color.backgroundColor,
                rental: rental
            };
        });
    }

    function handleRentalSelected(rental: Rental) {
        if(tenantUserStore.canAccessRental(rental)) {
            onSelectRental(rental.uuid);
        }
    }

    const localizer = luxonLocalizer();
	const {t} = useLocalization();
    return (
        <Fragment>
			{!events && (
				<Box p={5}>
					<Loading />
				</Box>
			)}
			{events && (
				<Calendar
					selectable
					className={"dark" === mode ? 'dark-mode' : undefined}
					events={events}
					localizer={localizer}
					startAccessor="start"
					endAccessor="end"
					style={{ height: 800 }}
					step={60}
					views={{
						month: true, week: true, day: true
					}}
					onSelectSlot={(slotInfo) => {
						onSelectSlot(luxonDate(slotInfo.start), luxonDate(slotInfo.end).setZone(FixedOffsetZone.utcInstance).minus({ hour: 1 }));   // TODO: END TIME IS CONTRADICTED WITH DAY VIEW
					}}
					onSelectEvent={(event) => handleRentalSelected(event.rental)}
					eventPropGetter={(event, start, end, selected) => eventStyleGetter(event, start, end, selected)}
					onRangeChange={(range:any) => {
						if (Array.isArray(range)) {
							onRangeChanged(luxonDate(range.shift()), luxonDate(range.pop()))
						} else if(range.start && range.end) {
							onRangeChanged(luxonDate(range.start), luxonDate(range.end));
						}
					}}
				/>
			)}
		</Fragment>
    )
}
