import {createElement} from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import {useLocalization} from "../i18n";

type ConfirmDialogProps = {
    title: string;
    message: string;
    confirmLabel?: string;
    resolve?: () => void;
    reject?: () => void;
    noConfirmButton?: boolean;
}

export function ConfirmDialog(props: ConfirmDialogProps) {
	const {
		title,
		message,
		confirmLabel,
		resolve,
		reject,
		noConfirmButton
	} = props;

	function handleConfirm() {
		if (resolve) resolve();
	}

	function handleCancel() {
		if (reject) reject();
	}

	const {t} = useLocalization();
	return (
		<Dialog
			open={resolve != undefined}
			onClose={handleCancel}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<Typography variant="body1">{message}</Typography>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleCancel}>{t('actions.cancel')}</Button>
				{!noConfirmButton && <Button color="primary" variant="contained" onClick={handleConfirm}>{confirmLabel ?? t('actions.confirm')}</Button> }
			</DialogActions>
		</Dialog>
	)
}
