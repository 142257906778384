import {createElement} from "react";
import {Box, Checkbox, Collapse, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import {CreateRentalServiceItem} from "../../data/types";
import {useLocalization} from "../../i18n";

type CreateWizardLenderAndServiceItemProps = {
    serviceItem: CreateRentalServiceItem|null,
    onServiceItemChange: (item: CreateRentalServiceItem|null) => void
};

export const CreateWizardLenderAndServiceItem = ({serviceItem, onServiceItemChange}: CreateWizardLenderAndServiceItemProps) => {

    const toggleServiceItem = (event: any) => {
        const item = (event.target.checked) ? { name: '', description: '' } : null;
        onServiceItemChange(item);
    };

    const serviceItemInputChange = (event: any) => {
        if (serviceItem) {
            const {name, value} = event.target;
            const item = {
                ...serviceItem,
                [name]: value
            };
            onServiceItemChange(item);
        }
    };

	const {t} = useLocalization();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h6">{t('rentals.create.serviceOrderQuestion')}</Typography>
                <Box p={1} />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel control={<Checkbox
                    checked={serviceItem !== null}
                    onChange={toggleServiceItem}
                    color="primary"
                />} label={t('rentals.create.serviceOrderCheckbox')} />
            </Grid>
            <Grid item xs={12}>
                <Collapse in={serviceItem !== null}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth label={t('rentals.serviceItem.itemName')}
                                       name='name' value={serviceItem?.name || ''}
                                       onChange={serviceItemInputChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth multiline rows={3} label={t('rentals.serviceItem.itemDescription')}
                                       name='description' value={serviceItem?.description || ''}
                                       onChange={serviceItemInputChange} />
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    )
};
