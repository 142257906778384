import {createElement, useCallback} from "react";
import {Chip} from "@mui/material";
import {green, red} from "@mui/material/colors";
import {useLocalization} from "../../i18n";
import {Availability} from "../../data/types";
import {useNavigate} from "react-router-dom";

type ItemAvailabilityProps = {
    availability: Availability;
    rentalUuid?: string;
};

export function ItemAvailabilityDisplay(props: ItemAvailabilityProps) {
    const {availability, rentalUuid} = props;
    const navigate = useNavigate();

    const handleOpenRental = useCallback(() => navigate(`/rentals/${rentalUuid}`), [rentalUuid]);

	const {t} = useLocalization();
    return (
        availability == Availability.Rented ?
            <Chip label={t('items.availability.Rented')} style={{ color: '#fff', background: red[500], cursor: 'pointer' }} onClick={() => rentalUuid?handleOpenRental():null} /> :
            <Chip label={t('items.availability.Available')} style={{ color: '#fff', background: green[500] }}/>
    );
}
