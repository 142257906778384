import {createElement,  useEffect, useState} from "react";
import {CostCenterDetails, User, UserRole} from "../../data/types";
import {ApiError} from "../../Api";
import {
    FormControl,
    Grid, IconButton,
    Input,
    List,
    ListItem,
    ListItemIcon, ListItemSecondaryAction, ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {listTenantUsers} from "../../data/users";
import {tenantUserStore} from "../../store/TenantUserStore";
import {useLocalization} from "../../i18n";
import {DeleteIcon, PersonIcon} from "../../theme";

type CostCenterFormProps = {
    details?: CostCenterDetails,
    onChange: (details: CostCenterDetails) => void
}

export const CostCenterForm = ({details, onChange}: CostCenterFormProps) => {
    const [form, setForm] = useState<CostCenterDetails>(details || {
        name: '',
        description: '',
        userUuids: []
    });
    const [users, setUsers] = useState<User[]>([]);
    const [error, setError] = useState<ApiError>();

    useEffect(() => {
        listTenantUsers(tenantUserStore.getTenantId())
            .then(p => {
                setUsers(p.content.filter(u => u.role !== UserRole.TenantAdmin));
            })
            .catch(toggleError);
    }, []);

    const onInputChange = (value: string, name?: string) => {
        if (name) {
            handleChange({
                ...form,
                [name]: value
            });
        }
    }

    const handleRemoveManager = (uuid: string) => {
        handleChange({
            ...form,
            userUuids: form.userUuids.filter(id => id !== uuid)
        })
    }

    const handleChange = (data: CostCenterDetails) => {
        setForm(data);
        onChange(data);
    }

    const toggleError = (error?: ApiError) => {
        setError(error);
    }

	const {t} = useLocalization();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField variant="outlined" fullWidth
						   label={t('common.name')}
                           value={form.name}
                           onChange={ev => onInputChange(ev.target.value, 'name')}
                           error={!form.name} helperText={!form.name ? t('error.input.requiredField') : ''}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField variant="outlined" fullWidth
						   label={t('common.description')}
                           value={form.description} multiline rows={3}
                           onChange={ev => onInputChange(ev.target.value, 'description')}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <Typography variant="h6">{t('costCenters.selectManagers')}</Typography>
                        <FormControl variant="outlined" fullWidth>
                            <Select multiple
                                    value={form.userUuids}
                                    renderValue={(v: any) => v.length + ' ' + t('costCenters.managers')}
                                    onChange={ev => onInputChange(ev.target.value as any, 'userUuids')}
                                    input={<Input />}>
                                {users.map(u => (
                                    <MenuItem key={u.uuid} value={u.uuid}>
                                        {(u.firstName || u.lastName) && `${u.firstName} ${u.lastName} - `} {u.email}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant="h6">{t('costCenters.managers')}</Typography>
                        <List dense>
                            {form.userUuids.map(id => {
                                const user = users.find(u => u.uuid === id) || {} as any;
                                return (
                                    <ListItem key={'selected-' + id}>
                                        <ListItemIcon>
                                            <PersonIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={(user.firstName || user.lastName) ? `${user.firstName} ${user.lastName}` : '-'}
                                            secondary={user.email}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" onClick={() => handleRemoveManager(id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
