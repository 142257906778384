import {createElement} from "react";
import {Fab, useTheme} from "@mui/material";
import {AddIcon} from "../theme";

export function AddFab({onClick}: {onClick: () => void}) {
	const theme = useTheme();
	return (
		<Fab
			color="primary" aria-label="add"
			style={{
				position: 'fixed',
				bottom: theme.spacing(8),
				right: theme.spacing(3)
			}}
			onClick={onClick}
		>
			<AddIcon />
		</Fab>
	)
}
