import {createElement, useCallback, useState} from "react";
import {Button, Grid, Typography, Alert} from "@mui/material";
import {Item, UserItemAccessRequest} from "../../data/types";
import {tenantUserStore} from "../../store/TenantUserStore";
import {RentalItemSummary} from "./RentalItemSummary";
import {ItemSearchComponent} from "../items";
import {verifyItemUserAccess} from "../../data/rentals";
import {useLocalization} from "../../i18n";
import { DateTime } from "luxon/src/datetime";
import {useNavigate} from "react-router-dom";

type CreateWizardItemSelectorProps = {
    canCreateRental: boolean,
    borrowerUuid?: string,
    onItemSelect: (item?: Item) => void,
    item?: Item,
    from?: DateTime,
    until?: DateTime
}

export const CreateWizardItemSelector = ({canCreateRental, borrowerUuid, onItemSelect, item, from, until}: CreateWizardItemSelectorProps) => {
	const navigate = useNavigate();

    const [itemAccess, setItemAccess] = useState<boolean | undefined>();

    const handleSelectItem = useCallback((item: Item) => {
        onItemSelect(item);
        setItemAccess(undefined);
        const tenantId = tenantUserStore.getTenantId();
        if(tenantId && borrowerUuid) {
            const accessRequest: UserItemAccessRequest = {
                itemUuid: item.uuid,
                borrowerUuid: borrowerUuid
            };
            verifyItemUserAccess(tenantId, accessRequest)
                .then((result) => {
                    setItemAccess(!result.blacklisted);
                });
        }
    }, [onItemSelect, borrowerUuid]);

	const {t} = useLocalization();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h6">{t('rentals.create.itemQuestion')}</Typography>
            </Grid>
            {!canCreateRental && tenantUserStore.staff && (
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Alert severity="error">{t('rentals.create.noItems')}</Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary"
                                    onClick={() => navigate('/items')}>{t('rentals.create.createItem')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {canCreateRental && !item && (
                <Grid item xs={12}>
                    <ItemSearchComponent from={from} until={until} onItemSelected={handleSelectItem} />
                </Grid>
            )}
            {(item) && (
                <Grid item xs={12}>
                    <RentalItemSummary item={item} itemAccess={itemAccess != undefined ? itemAccess : true} onAction={() => onItemSelect(undefined)}
                                       actionLabel={t('rentals.create.changeItem')} />
                </Grid>
            )}
        </Grid>
    );
};
