import {createElement, Fragment, useState} from "react";
import {
	Box,
	FormControlLabel,
	Grid,
	Switch, TextField,
	Typography
} from "@mui/material";
import {Selector} from "@variocube/app-ui";
import {tenantUserStore} from "../../store/TenantUserStore";
import {CalculationCase, ModelDetails, TaxedAmount} from "../../data/types";
import {uploadUrl} from "../../data/upload-url";
import {PeriodInputComponent} from "../../components/PeriodInputComponent";
import {TaxedAmountComponent} from "../../components/TaxedAmountComponent";
import {UrlOrUploadInput} from "../../components/UrlOrUploadInput";
import {CategorySelector} from "../categories";
import {grey} from "@mui/material/colors";
import {useLocalization} from "../../i18n";

type ModelFormProps = {
    details?: ModelDetails,
    onChange: (details: ModelDetails) => void
}

export const ModelForm = ({details, onChange}: ModelFormProps) => {
    const [form, setForm] = useState<ModelDetails>(details || {
        name: '',
        description: '',
        imageUrl: '',
        maxRentPeriod: null,
        amount: { net: 0, gross: 0, tax: 0, taxRate: 20 },
        calculationCase: CalculationCase.PerUsage,
        categoryUuid: ''
    });

    const onInputChange = (name: string, value: any) => {
        handleChange({
            ...form,
            [name]: value
        });
    };

    const onPeriodChange = (hours: number) => {
        handleChange({
            ...form,
            maxRentPeriod: hours
        });
    };

    const onAmountChange = (amount: TaxedAmount) => {
        handleChange({
            ...form,
            amount
        })
    };

    const toggleAmount = () => {
        handleChange({
            ...form,
            amount: (form.amount) ? null : { net: 0, gross: 0, tax: 0, taxRate: 20 },
            calculationCase: (form.amount) ? null : CalculationCase.PerUsage
        });
    };

    const handleChange = (details: ModelDetails) => {
        setForm(details);
        onChange(details);
    }

	const {t, e} = useLocalization();
    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                    {!form.imageUrl && (
                        <Box p={3} style={{ background: grey[100], width: '100%' }}>
                            <Typography variant="overline">Place image url to get it render here.</Typography>
                        </Box>
                    )}
                    {form.imageUrl && (
                        <img src={form.imageUrl} alt="Model image" width="100%"/>
                    )}
                    <Box my={3} />
                    <UrlOrUploadInput label={t('common.imageUrl')} preview accept="image/png, image/jpeg, image/svg+xml"
                                      value={form.imageUrl} onChange={(url) => onInputChange('imageUrl', url)}
                                      uploadUrlProvider={(file) => uploadUrl(tenantUserStore.getTenantId(), file)} />
                </Grid>
                <Grid item md={8} xs={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={4} xs={12}>
                            <CategorySelector value={details?.categoryUuid}
                                              onChange={v => onInputChange('categoryUuid', v)}
                            />
                        </Grid>
                        <Grid item sm={8} xs={12}>
                            <TextField variant="outlined" fullWidth
									   label={t('common.name')}
                                       value={form.name}
                                       onChange={ev => onInputChange('name', ev.target.value)}
                                       error={!form.name}
                                       helperText={!form.name ? t('error.input.requiredField') : undefined}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth
									   label={t('common.description')}
                                       value={form.description} multiline rows={3}
                                       onChange={ev => onInputChange('description', ev.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PeriodInputComponent label={t('rentals.maxRentPeriod')} hours={form.maxRentPeriod} onPeriodChange={onPeriodChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1"><strong>{t('models.cost.title')}</strong></Typography>
                            <FormControlLabel
                                control={<Switch checked={form.amount != null} onChange={toggleAmount} />}
                                label={t('common.enabled')}
                            />
                            <Box my={2}/>
                            {form.amount && (
                                <Grid container spacing={3}>
                                    <Grid item md={8}>
                                        <TaxedAmountComponent autoUpdate
                                                              amount={form.amount}
                                                              onAmountChange={onAmountChange}
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <Selector label={t('calculationCase.label')} value={form.calculationCase}
                                                  options={Object.values(CalculationCase).map(c => ({ label: e('calculationCase', c), value: c }))}
                                                  onChange={v => onInputChange('calculationCase', v)}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/*<Box my={1}/>*/}
            {/*<Grid container spacing={1}>*/}
            {/*    <Grid item>*/}
            {/*        <Button variant="contained" color="primary" onClick={update}>{messages.actions.save}</Button>*/}
            {/*    </Grid>*/}
            {/*    <Grid item style={{ flexGrow: 1 }}>*/}
            {/*        <Button variant="contained" onClick={toggleEditMode}>{messages.actions.cancel}</Button>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
        </Fragment>
    )
}
