import {FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {createElement, useEffect, useState} from "react";
import {useLocalization} from "../i18n";

enum PeriodUnit {
    Hours = 'Hours',
    Days = 'Days',
    Weeks = 'Weeks'
}

type PeriodComponentProps = {
    label: string;
    hours: number|null;
    onPeriodChange: (hours: number) => void;
};

export const PeriodInputComponent = (props: PeriodComponentProps) => {
    const {label} = props;
    const [period, setPeriod] = useState<number|null>(null);
    const [unit, setUnit] = useState<PeriodUnit>(PeriodUnit.Hours);

    useEffect(() => {
        const {hours} = props;
        if (hours) {
            if (hours >= 168 && hours % 168 === 0) {
                setUnit(PeriodUnit.Weeks);
                setPeriod(hours / 168);
            } else if (hours >= 24 && hours % 24 === 0) {
                setUnit(PeriodUnit.Days);
                setPeriod(hours / 24);
            } else {
                setPeriod(hours);
            }
        }
    }, [setPeriod]);

    const pushPeriodChange = (period: number, unit: PeriodUnit) => {
        let value;
        switch (unit) {
            case PeriodUnit.Days:
                value = 24 * period;
                break;
            case PeriodUnit.Weeks:
                value = 7 * 24 * period;
                break;
            default:
                value = period;
                break;
        }
        props.onPeriodChange(value);
    };

    const handlePeriodChange = (value: any) => {
        value = value.replace(/\D/g, '');
        setPeriod(value);
        pushPeriodChange(value, unit);
    };

    const handleUnitChange = (value: PeriodUnit) => {
        setUnit(value);
        if (period) {
            pushPeriodChange(period, value);
        }
    };

	const {t, e} = useLocalization();
    return (
        <Grid container spacing={3}>
            <Grid item md={8}>
                <TextField variant="outlined" fullWidth label={label}
                           value={period || ''}
                           onChange={e => handlePeriodChange(e.target.value)} />
            </Grid>
            <Grid item md={4}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel>{t('period.label')}</InputLabel>
                    <Select value={unit || ''}
                            onChange={e => handleUnitChange(e.target.value as any)}
                            label={t('period.label')}
                    >
                        {Object.values(PeriodUnit).map(u => <MenuItem key={u} value={u}>{e('period.plural', u)}</MenuItem>)}
                    </Select >
                </FormControl>
            </Grid>
        </Grid>
    );
};
