import {createElement, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    TableCell,
    TableRow,
    Typography,
    Alert, TextField
} from "@mui/material";
import {CostCenter, CreateUserRequest, User, UserLocale, UserRole} from "../../data/types";
import {ApiError} from "../../Api";
import {createUser, listTenantUsers, UserWrapper} from "../../data/users";
import {tenantUserStore} from "../../store/TenantUserStore";
import {Loading} from "../../components/Loading";
import {AddFab} from "../../components/AddFab";
import {usersPaging} from "../../data/pagings";
import {
    BreadcrumbItem,
    Breadcrumbs,
    ColumnType,
    ContentTable,
    Page,
    PagingSettings,
    Selector
} from "@variocube/app-ui";
import {UserRoleDisplay} from "./UserRoleDisplay";
import {listCostCenters} from "../../data/costcenters";
import {ErrorDialog} from "../../components/ErrorDialog";
import {useLocalization} from "../../i18n";
import {ExitToAppIcon, PersonAddIcon, PostAddIcon} from "../../theme";
import {RentalAppContainer} from "../../RentalAppContainer";
import {useNavigate} from "react-router-dom";

function createUserLocale(): UserLocale {
    const allLocales = [UserLocale.German, UserLocale.English];
    const orConfigured = localStorage.getItem('variocube-language') || navigator.language?.split('-').shift();
    if(orConfigured && allLocales.indexOf(orConfigured as UserLocale) != -1) {
        return orConfigured as UserLocale;
    }
    return UserLocale.German;
}

export const UserList = () => {
	const navigate = useNavigate();
    const {t, e} = useLocalization();

    useEffect(() => {
        setColumns({
            'role': { show: true, name: t('users.role') },
            'lastName': { show: true, name: t('common.name') },
            'email': { show: true, name: t('common.email') }
        })
    }, [t])

    const [page, setPage] = useState<Page<User>>();
    const [inProgress, setInProgress] = useState(true);
    const [columns, setColumns] = useState<ColumnType>({});
    const [userForm, setUserForm] = useState<CreateUserRequest>({
        firstName: '', lastName: '', email: '',
        role: UserRole.Borrower,
        locale: createUserLocale(),
        costCenterUuids: [],
		nfcToken: '',
    });
    const [needle, setNeedle] = useState<string>();
    const [role, setRole] = useState<UserRole>();
    const [createDialog, setCreateDialog] = useState(false);
    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
    const [userExisted, setUserExisted] = useState(false);
    const [userCreatedExisted, setUserCreatedExisted] = useState(false);
    const [filterDialog, setFilterDialog] = useState(false);
    const [error, setError] = useState<ApiError>();

    const emailError = useMemo(() => {
        if (userExisted) {
            return t('users.emailExisted');
        }
        if (!userForm.email) {
            return t('error.input.requiredField');
        }
        return undefined;
    }, [userForm, userExisted]);

    const loadPage = useCallback(() => {
        setInProgress(true);
        listTenantUsers(tenantUserStore.getTenantId(), usersPaging)
            .then(p => {
                setInProgress(false);
                setPage(p);
            })
            .catch(e => {
                usersPaging.resetSettings();
                setInProgress(false);
                setError(e);
            })
    }, [setInProgress, setPage, setError]);

    useEffect(() => {
        loadPage();
        const filters = usersPaging.getSettings().filters;
        if (filters) {
            setNeedle(filters['needle']);
            setRole(filters['role']);
        }
        listCostCenters(tenantUserStore.getTenantId())
            .then(page => setCostCenters(page.content.sort((c1, c2) => c1.name.localeCompare(c2.name))))
            .catch(toggleError);
    }, []);

    const showCell = (column: keyof typeof columns) => columns[column] && columns[column].show;

    const toggleCreationDialog = () => {
        setCreateDialog(!createDialog);
    };

    const onInputChange = (value: string, name?: string) => {
        if (name) {
            setUserForm({
                ...userForm,
                [name]: value
            });
            if (name === 'email') {
                setUserExisted(false);
            }
        }
    };

    const handleFilter = () => {
        const settings = usersPaging.getSettings();
        if (!settings.filters) settings.filters = {};
        if (role) {
            if ((role as any) === 'ALL') delete settings.filters['role'];
            else settings.filters['role'] = role;
        }
        if (needle) settings.filters['needle'] = needle;
        usersPaging.updateSettings({
            ...settings
        });
        loadPage();
        setFilterDialog(false);
    }

    const clearFilter = () => {
        setNeedle('');
        setRole(undefined);
        usersPaging.updateSettings({
            ...usersPaging.getSettings(),
            filters: {}
        });
        loadPage();
        setFilterDialog(false);
    }

    const handleCreateUser = () => {
        setUserExisted(false);
        createUser(tenantUserStore.getTenantId(), userForm)
            .then(u => {
                if (u.existed) {
                    setUserCreatedExisted(true);
                    setTimeout(() => {
                        navigate('/users/' + u.uuid);
                    }, 1500);
                } else {
                    navigate('/users/' + u.uuid);
                }
            })
            .catch(toggleError);
    };

    const toggleError = (error?: ApiError) => {
        if (error && error.code === 409) {
            setUserExisted(true);
            return;
        }
        setError(error);
    };

    const handlePageChange = (settings: PagingSettings<any>) => {
        usersPaging.updateSettings({
            ...usersPaging.getSettings(),
            ...settings
        });
        loadPage();
    }

    return (
        <RentalAppContainer title={t('users.plural')}>
            <Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{ flexGrow: 1 }}>
                    <Breadcrumbs>
                        <BreadcrumbItem>{t('users.plural')}</BreadcrumbItem>
                    </Breadcrumbs>
                    <Box my={1} />
                    <Typography variant="h4">{t('users.plural')}</Typography>
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant="outlined"
                            onClick={() => navigate('/users/signup-settings')}
                            startIcon={<ExitToAppIcon/>}>
                        {t('users.actions.signup')}
                    </Button>
                    <Box mx={1}/>
                    <Button variant="outlined"
                            onClick={() => navigate('/users/import')}
                            startIcon={<PostAddIcon/>}>
                        {t('users.actions.import')}
                    </Button>
                    <Box mx={1}/>
                    <Button variant="outlined"
                            onClick={toggleCreationDialog} startIcon={<PersonAddIcon/>}>
                        {t('users.actions.add')}
                    </Button>
                </Grid>
            </Grid>
            <Box my={3} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        <ContentTable
                            page={page} pageable={usersPaging.getSettings()}
                            onPageableChange={handlePageChange}
                            inProgress={inProgress} columns={columns}
                            onColumnsChange={c => setColumns(c)}
                            onFilterClick={() => setFilterDialog(true)}
                            renderTableBody={(
                                <Fragment>
                                    {page && page.content.map(u => (
                                        <TableRow key={'user-' + u.uuid} onClick={() => navigate('/users/' + u.uuid)}>
                                            {showCell('role') && (<TableCell><UserRoleDisplay role={u.role} /></TableCell>)}
                                            {showCell('lastName') && (<TableCell>{new UserWrapper(u).displayName}</TableCell>)}
                                            {showCell('email') && (<TableCell>{u.email}</TableCell>)}
                                        </TableRow>
                                    ))}
                                </Fragment>
                            )}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <AddFab onClick={toggleCreationDialog}/>

            <Dialog open={filterDialog} fullWidth maxWidth="sm" onClose={() => setFilterDialog(false)}>
                <DialogTitle>{t('actions.filter')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth label={t('actions.search')} value={needle || ''} onChange={ev => setNeedle(ev.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <Selector label={t('users.role')} value={role || 'ALL'} onChange={v => setRole(v as any)} options={[
                                { value: 'ALL', label: t('common.all') },
                                ...Object.values(UserRole).map(v => ({ value: v, label: e('users.roles', v) }))
                            ]} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setFilterDialog(false)}>{t('actions.cancel')}</Button>
                    <Box mx={2} />
                    <Button variant="contained"  onClick={clearFilter}>{t('actions.reset')}</Button>
                    <Button variant="contained" color="primary" onClick={handleFilter}>
                        {t('actions.filter')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={createDialog} fullWidth maxWidth="sm">
                <DialogTitle>{t('users.actions.add')}</DialogTitle>
                <DialogContent>
                    {!userCreatedExisted && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Selector label={t('users.role')}
                                          value={userForm.role} onChange={v => onInputChange(v, 'role')}
                                          options={Object.values(UserRole).map(r => ({ label: e('users.roles', r), value: r }))}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField variant="outlined" fullWidth
                                           label={t('common.firstName')}
                                           value={userForm.firstName} onChange={ev => onInputChange(ev.target.value, 'firstName')}
                                           error={!userForm.firstName} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField variant="outlined" fullWidth
                                           label={t('common.lastName')}
                                           value={userForm.lastName} onChange={ev => onInputChange(ev.target.value, 'lastName')}
                                           error={!userForm.lastName} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField variant="outlined" fullWidth
                                           label={t('common.email')}
                                           value={userForm.email} onChange={ev => onInputChange(ev.target.value, 'email')}
                                           error={emailError !== undefined} helperText={emailError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Selector label={t('users.language')}
                                          value={userForm.locale} onChange={v => onInputChange(v, 'language')}
                                          options={[
                                              { label: 'English', value: UserLocale.English },
                                              { label: 'Deutsch', value: UserLocale.German }
                                          ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>{t('costCenters.plural')}</InputLabel>
                                    <Select multiple label={t('costCenters.plural')}
                                            value={userForm.costCenterUuids}
                                            onChange={({target}) => onInputChange(target.value as any, 'costCenterUuids')}
                                            renderValue={(v: any) => v.length + ' ' + t('users.costCenterSelectCount')}
                                    >
                                        {costCenters.map(cc => (
                                            <MenuItem key={cc.uuid} value={cc.uuid}>
                                                <Checkbox checked={userForm.costCenterUuids.indexOf(cc.uuid) > -1} />
                                                <ListItemText primary={cc.name} secondary={cc.description} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined" fullWidth
									label="NFC-Token"
									value={userForm.nfcToken} onChange={ev => onInputChange(ev.target.value, 'nfcToken')}
								/>
							</Grid>
                            <Grid item xs={12}>
                                {userExisted && (
                                    <Alert severity="error">
                                        {t('users.userExisted', { email: userForm.email })}
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    )}
                    {userCreatedExisted && (
                        <Loading message={t('users.userCreatedExisted', { email: userForm.email, tenant: tenantUserStore.tenant?.name ?? '' })} />
                    )}
                </DialogContent>
                {!userCreatedExisted && (
                    <DialogActions>
                        <Button onClick={toggleCreationDialog}>{t('actions.cancel')}</Button>
                        <Button variant="contained" color="primary"
                                disabled={!Boolean(userForm.email && userForm.firstName && userForm.lastName)}
                                onClick={handleCreateUser}>
                            {t('actions.create')}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    )
}
