import {createElement, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    LinearProgress,
    Typography,
    Alert
} from "@mui/material";
import {Rental, RentalProlongResponse} from "../../data/types";
import {prolongRental} from "../../data/rentals";
import {tenantUserStore} from "../../store/TenantUserStore";
import {useLocalization} from "../../i18n";
import {PlainDateTime, PlainDateTimePicker} from "@variocube/app-ui";
import {instantToPlainDatetime, luxonDate, plainDateTimeNow} from "../../tools";

interface ProlongRentalDialogProps {
    rental: Rental;
    open: boolean;
    onClose: () => void;
}

export function ProlongRentalDialog({rental, open, onClose}: ProlongRentalDialogProps) {
    const {from, until} = rental;

    const [prolongResult, setProlognResult] = useState<RentalProlongResponse>();
    const [prolongUntil, setProlongUntil] = useState(until ? instantToPlainDatetime(until) : null);
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState(false);

    const invalidProlongUntil = (!!prolongUntil && (PlainDateTime.compare(prolongUntil, instantToPlainDatetime(until)) === -1 || PlainDateTime.compare(prolongUntil, plainDateTimeNow()) === -1));

    async function handleProlong() {
        if (prolongUntil && !invalidProlongUntil) {
            setProcessing(true);
            try {
                const result = await prolongRental(tenantUserStore.getTenantId(), rental.uuid, luxonDate(prolongUntil));
                if (result.prolonged) {
                    onClose();
                    return;
                }
                setProlognResult(result);
            } catch (err) {
                console.error(err);
            }
            setError(true);
            setProcessing(false);
        }
    }

    const {t} = useLocalization();
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('rentals.prolong.title')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="body1">{t('rentals.prolong.body', { itemName: rental.itemName })}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <PlainDateTimePicker
                            fullWidth
                            value={prolongUntil}
                            onChange={dt => setProlongUntil(dt as PlainDateTime)}
                        />
                        {invalidProlongUntil && <Typography variant="body2" sx={{ pl: 2, pt: 1, color: 'red' }}>{t('rentals.prolong.invalidDateTime')}</Typography>}
                    </Grid>
                    {error && (
                        <Grid item xs={12}>
                            <Alert severity="error">{t('rentals.prolong.error')}</Alert>
                        </Grid>
                    )}
                    {prolongResult?.nextBorrower && (
                        <Grid item xs={12}>
                            <Alert severity="error">{t('rentals.prolong.nextBorrower')}: {prolongResult.nextBorrower}</Alert>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>{t('actions.cancel')}</Button>
                <Button variant="contained" color="primary" disabled={invalidProlongUntil} onClick={handleProlong}>{t('actions.save')}</Button>
            </DialogActions>
            {processing &&
                <LinearProgress variant="indeterminate" />
            }
        </Dialog>
    );
}
