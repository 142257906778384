import {createElement} from "react";
import {Chip} from "@mui/material";
import {useLocalization} from "../i18n";
import {green, red} from "@mui/material/colors";

type BooleanDisplayProps = {
    value: boolean;
    size?: 'small' | 'medium';
    reverseColors?: boolean;
}

export function BooleanDisplay({value, size, reverseColors}: BooleanDisplayProps) {
    const colorValue = reverseColors ? !value : value;
	const sx = colorValue ?
		{ backgroundColor: green[400] } :
		{ backgroundColor: red[500], color: '#fff' };
	const {t} = useLocalization();
    return (
        <Chip
			label={value ? t('yes') : t('no')}
			size={size}
			sx={sx}
		/>
    );
}
