import {createElement, useState} from "react";
import {Grid, InputAdornment, MenuItem, TextField} from "@mui/material";
import {TaxedAmount} from "../data/types";
import {useLocalization} from "../i18n";

type TaxedAmountComponentProps = {
    amount: TaxedAmount;
    onAmountChange: (amount: TaxedAmount) => void;
    autoUpdate?: boolean;
}

export const TaxedAmountComponent = (props: TaxedAmountComponentProps) => {
    const [amount, setAmount] = useState<TaxedAmount>(props.amount);

    const onInputChange = (event: any) => {
        const {name, value} = event.target;
        const data = {
            ...amount,
            [name]: value != '' ? parseFloat(value) : 0
        };
        if (props.autoUpdate) {
            switch (name) {
                case 'net':
                case 'taxRate':
                    data.tax = (data.net * data.taxRate) / 100;
                    data.gross = data.net + data.tax;
                    break;
                case 'gross':
                    data.tax = Math.floor(((data.taxRate * data.gross) / 120) * 100) / 100;
                    data.net = data.gross - data.tax;
                    break;
                case 'tax':
                    return;
            }
        }
        setAmount(data);
        props.onAmountChange(data);
    };

	const {t} = useLocalization();
    return (
        <Grid container spacing={3}>
            <Grid item md={3}>
                <TextField variant="outlined" fullWidth label={t('amount.net')}
                           name="net"
                           value={amount.net || ''}
                           onChange={onInputChange}
                           InputProps={{
                               endAdornment: <InputAdornment position="end">&euro;</InputAdornment>
                           }}
                />
            </Grid>
            <Grid item md={3}>
                <TextField variant="outlined" fullWidth label={t('amount.taxRate')}
                           name="taxRate" select
                           value={amount.taxRate || ''}
                           onChange={onInputChange}
                >
                    <MenuItem value={0}>0 %</MenuItem>
                    <MenuItem value={20}>20 %</MenuItem>
                    <MenuItem value={19}>19 %</MenuItem>
                </TextField>
            </Grid>
            <Grid item md={3}>
                <TextField variant="outlined" fullWidth label={t('amount.tax')}
                           name="tax"
                           value={amount.tax || ''}
                           onChange={onInputChange}
                           InputProps={{
                               endAdornment: <InputAdornment position="end">&euro;</InputAdornment>
                           }}
                />
            </Grid>
            <Grid item md={3}>
                <TextField variant="outlined" fullWidth label={t('amount.gross')}
                           name="gross"
                           value={amount.gross || ''}
                           onChange={onInputChange}
                           InputProps={{
                               endAdornment: <InputAdornment position="end">&euro;</InputAdornment>
                           }}
                />
            </Grid>
        </Grid>
    )
};
