import {createElement, PropsWithChildren} from "react";
import {BreadcrumbLink} from "@variocube/app-ui";
import {Link} from "react-router-dom";

export function CrumbLink({href, children}: PropsWithChildren<{ href: string }>) {
	return (
		<BreadcrumbLink component={Link} to={href}>
			{children}
		</BreadcrumbLink>
	)
}
