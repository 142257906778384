import { createElement, Fragment, useEffect, useState } from "react";
import {
    Box,
    Grid,
    IconButton,
    ListItemText,
    TextField,
    Typography,
    Alert
} from "@mui/material";

import { ApiError } from "../../Api";
import { Item } from "../../data/types";
import { searchItems } from "../../data/items";
import { tenantUserStore } from "../../store/TenantUserStore";
import { Loading } from "../../components/Loading";
import { FittingImageComponent } from "../../components/FittingImageComponent";
import { useLocalization } from "../../i18n";
import { ClearIcon } from "../../theme";
import MiniSearch from "minisearch";
import { DateTime } from "luxon";

type ItemSearchComponentProps = {
    onItemSelected: (item: Item) => void,
    onSearchResult?: (items?: Item[]) => void;
    from?: DateTime,
    until?: DateTime
}

export const ItemSearchComponent = ({ onItemSelected, onSearchResult, from, until }: ItemSearchComponentProps) => {
    const [needle, setNeedle] = useState('')
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState<Item[]>();
    const [error, setError] = useState<ApiError>();
    const [allItems, setAllItems] = useState<Item[]>([]);

    function initAllItems()  {
        console.log('initAllItems');
        setLoading(true);
        searchItems(tenantUserStore.getTenantId(), '', from as any, until as any)
            .then(page => {
                var enabledItems = page.content.filter(i=>i.disabled!==true);
                setAllItems(enabledItems);
                console.log('Items ', enabledItems.length); 
                setLoading(false);
            })
            .catch(toggleError);
    }

    useEffect(() => {
        console.log('Use Effect once');
        initAllItems();
    }, [from, until]);

    function search(needle: string) {
        setLoading(true);
        setItems(fizzySearch(needle) as any);
        setLoading(false);
        if (onSearchResult) onSearchResult(items);
    }

    function fizzySearch(needle: string) { 
        let miniSearch = new MiniSearch({
            fields: ['uuid', 'foreignId', 'name', 'description', 'modelName', 'categoryName'], // fields to index for full-text search
            storeFields: ['uuid', 'foreignId', 'name', 'description', 'category', 'model',  'images'] // fields to return with search results
        })

        console.debug('All Items ', allItems.length);
        allItems.forEach((i) => {
            (i as any).id= i.uuid;
            (i as any).categoryName = i.category?.name;
            (i as any).modelName = i.model?.name; 
        }); // add field uuid
        // Index all documents
        miniSearch.addAll(allItems)

        // Search with default options
        return miniSearch.search(needle, { prefix: true, fuzzy: true, boost: { name: 3, foreignId:4, modelName:2, categoryName: 1 } }) 
    }

    const clear = () => {
        setItems(undefined);
        setNeedle('');
        setLoading(false);
        if (onSearchResult) onSearchResult(undefined);
    }

    const handleNeedleChanged = (needle: string) => {
        setNeedle(needle);
        if (needle) {
            search(needle);
        } else {
            clear();
        }
    }

    const handleItemSelected = (item: Item) => {
        setNeedle('');
        setLoading(false);
        onItemSelected(item);
    }

    const toggleError = (error?: ApiError) => {
        setError(error);
    }

    const { t } = useLocalization();



    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth label={t('items.search.label')}
                        value={needle}
                        onChange={(e) => handleNeedleChanged(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => clear()} disabled={!needle} style={{ order: 1 }}>
                                    <ClearIcon color="disabled" fontSize="small" />
                                </IconButton>
                            )
                        }} />
                </Grid>
                {loading &&
                    <Grid item xs={12}>
                        <Loading message={t('items.search.loading', { needle })} />
                    </Grid>
                }
                {items &&
                    <Grid item xs={12}>
                        {items.length == 0 &&
                            <Alert severity="info">{t('items.search.noResult', { needle })}</Alert>
                        }
                        {items.length > 0 && items.map((item) =>
                            <Grid container spacing={3} key={item.uuid} style={{ cursor: 'pointer' }} onClick={() => handleItemSelected(item)} className="selectable-item">
                                <Grid item xs={2} style={{ paddingTop: 0 }}>
                                    {item.images && item.images.length > 0 &&
                                        <Box paddingRight={1}>
                                            <FittingImageComponent src={item.images[0]} />
                                        </Box>
                                    }
                                </Grid>
                                <Grid item xs={10} style={{ paddingTop: 0 }}>
                                    {item.model &&
                                        <Typography variant="overline">{item.model.name}</Typography>
                                    }
                                    {item.foreignId &&
                                        <Typography variant="overline">{item.foreignId}</Typography>
                                    }
                                    <ListItemText  primary={<Typography variant="body1" >{item.name} {item.category? ' in ' + item.category.name : '' }</Typography>} secondary={item.description} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                }
            </Grid>
            {/*{searchError && (*/}
            {/*    <ErrorPopup url={searchError.url} statusCode={searchError.code} onCloseHandler={() => this.toggleError(undefined)}/>*/}
            {/*)}*/}
        </Fragment>
    )
}
 