import {createElement, Fragment, useCallback, useState} from "react";
import {
    alpha,
    Box,
    Button,
    Checkbox, CircularProgress,
    FormControlLabel,
    Grid, Link,
    MenuItem,
    Paper, Table, TableBody, TableCell, TableHead, TableRow,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import Papa, {ParseResult} from "papaparse";
import {tenantUserStore} from "../../store/TenantUserStore";
import {importUser} from "../../data/users";
import {ImportUserRequest, ImportUserResult} from "../../data/types";
import {FileInput} from "../../components/FileInput";
import {useLocalization} from "../../i18n";
import {CheckCircleIcon, ErrorIcon} from "../../theme";
import {RentalAppContainer} from "../../RentalAppContainer";
import {BreadcrumbItem,  Breadcrumbs} from "@variocube/app-ui";
import {CrumbLink} from "../../components/CrumbLink";
import {useNavigate} from "react-router-dom";

enum CsvEncoding {
    UTF8 = 'UTF-8',
    ISO88591 = 'ISO-8859-1',
    MacRoman = 'MacRoman'
}

enum StateMachine {
    FileSelection = 'FileSelection',
    ColumnDefinition = 'ColumnDefinition',
    ImportResult = 'ImportResult'
}

export const UserImport = () => {
	const navigate = useNavigate();
	const {t} = useLocalization();

    const [state, setState] = useState(StateMachine.FileSelection);
    const [hasHeader, setHasHeader] = useState(false);
    const [encoding, setEncoding] = useState<CsvEncoding>(CsvEncoding.UTF8);
    const [file, setFile] = useState<File>();
    const [csv, setCsv] = useState<ParseResult<any>>();
    const [form, setForm] = useState<{ [columnOrder: number]: string }>({});
    const [results, setResults] = useState<ImportUserResult[]>([]);
    const [inProgress, setInProgress] = useState(false);

    const handleFileSelection = () => {
        if (file) {
            setState(StateMachine.ColumnDefinition);
            Papa.parse<any, File>(file, {
                encoding,
                error: (error: Error) => {

                },
                complete: (result: ParseResult<any>) => {
                    setCsv(result);
                    console.log('csv', result);
                    if (result.data.length > 0) {
                        const form: any = {};
                        for (let i = 0; i < result.data[0].length; i++) {
                            form[i] = 'ignore';
                        }
                        setForm(form);
                    }
                }
            } as any);
        }
    }

    const handleImportation = useCallback(async () => {
        if (csv) {
            setState(StateMachine.ImportResult);
            setInProgress(true);
            const results: ImportUserResult[] = [];

            for (let d of csv.data.slice(hasHeader ? 1 : 0)) {
                const request: ImportUserRequest = { email: '' };
                for (let i = 0; i < d.length; i++) {
                    if (form[i] && form[i] !== 'ignore') {
                        (request as any)[form[i]] = d[i];
                    }
                }
                let result: ImportUserResult = { email: request.email };
                try {
                    const user = await importUser(tenantUserStore.getTenantId(), request);
                    result.ok = true;
                    result.user = user;
                } catch (error: any) {
                    result.errorCode = error.code;
                }
                results.push(result);
            }

            setInProgress(false);
            setResults(results);
        }
    }, [csv, form, results, setResults])

    const canGoNext = () => {
        switch (state) {
            case StateMachine.FileSelection:
                return !!file;
            case StateMachine.ColumnDefinition:
                return (Object.values(form).filter(v => v === 'email').length === 1);
        }
        return true;
    }

    const goNext = () => {
        switch (state) {
            case StateMachine.FileSelection:
                handleFileSelection();
                break;
            case StateMachine.ColumnDefinition:
                handleImportation().then();
                break;
            case StateMachine.ImportResult:
                navigate('/users');
                break;
        }
    }

    const goBack = () => {
        switch (state) {
            case StateMachine.ColumnDefinition:
                setState(StateMachine.FileSelection);
                setForm({});
                break;
        }
    }

    const nextLabel = () => {
        switch (state) {
            case StateMachine.ColumnDefinition:
                return t('actions.import');
            case StateMachine.ImportResult:
                return t('actions.done');
        }
        return t('actions.continue');
    }

    const showOption = (order: number, value: keyof ImportUserRequest) => Object.entries(form).filter(([k, v]) => k !== order.toString() && v === value).length <= 0;

    const renderColumnSelect = (order: number) => {
        return (
            <TextField fullWidth select variant="outlined" size="small" value={form[order] || 'ignore'} onChange={ev => setForm({...form, [order]: ev.target.value})}>
                <MenuItem value="ignore">{t('users.import.ignoreColumn')}</MenuItem>
                {showOption(order, 'email') && <MenuItem value="email">{t('common.email')}</MenuItem>}
                {showOption(order, 'firstName') && <MenuItem value="firstName">{t('common.firstName')}</MenuItem>}
                {showOption(order, 'lastName') && <MenuItem value="lastName">{t('common.lastName')}</MenuItem>}
                {showOption(order, 'costCenterName') && <MenuItem value="costCenterName">{t('costCenters.singular')}</MenuItem>}
                {showOption(order, 'costCenterForeignId') && <MenuItem value="costCenterForeignId">{t('costCenters.foreignId')}</MenuItem>}
                {showOption(order, 'nfcToken') && <MenuItem value="nfcToken">NFC-Token</MenuItem>}
            </TextField>
        )
    }

    const renderImportStatus = (result: ImportUserResult) => {
        if (result.ok) return <CheckCircleIcon style={{ color: 'green' }} />;
        else if (result.errorCode === 400) return <Tooltip title="Invalid data. E-Mail is required."><ErrorIcon style={{ color: 'red' }} /></Tooltip>
        else if (result.errorCode === 409) return <Tooltip title="User already existed."><ErrorIcon style={{ color: 'red' }} /></Tooltip>
    }

    return (
        <RentalAppContainer title={t('users.actions.import')}>
            <Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{ flexGrow: 1 }}>
					<Breadcrumbs>
						<CrumbLink href="/users">{t('users.plural')}</CrumbLink>
						<BreadcrumbItem>{t('users.actions.import')}</BreadcrumbItem>
					</Breadcrumbs>
					<Box my={1} />
                    <Typography variant="h4">{t('users.actions.import')}</Typography>
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                </Grid>
            </Grid>
			<Box my={3} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        <Box p={3}>
                            {state === StateMachine.FileSelection && (
                                <Fragment>
                                    <FileInput accept="text/csv" onChange={setFile} />
                                    <Box my={2} />
                                    <FormControlLabel
                                        control={<Checkbox checked={hasHeader} onChange={ev => setHasHeader(ev.target.checked)} />}
                                        label={t('users.import.containHeader')}
                                    />
                                    <Box my={2} />
                                    <Grid container>
                                        <Grid item md={3} sm={6} xs={12}>
                                            <TextField fullWidth select label="Encoding" variant="outlined" value={encoding} onChange={ev => setEncoding(ev.target.value as any)}>
                                                <MenuItem value={CsvEncoding.UTF8}>{CsvEncoding.UTF8}</MenuItem>
                                                <MenuItem value={CsvEncoding.ISO88591}>{CsvEncoding.ISO88591}</MenuItem>
                                                <MenuItem value={CsvEncoding.MacRoman}>{CsvEncoding.MacRoman}</MenuItem>
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )}
                            {(state === StateMachine.ColumnDefinition && csv) && (
                                <div style={{ width: '100', overflowX: 'auto' }}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                {csv.data.length > 0 && csv.data[0].map((f: string, i: number) => (
                                                    <TableCell key={'columnDef-' + i}>
                                                        {renderColumnSelect(i)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {csv.data.length > 0 && csv.data.slice(0, 5).map((r, ri) => (
                                                <TableRow key={'row-' + ri} style={hasHeader && ri === 0 ? { background: alpha('#f00', 0.5) } : undefined}>
                                                    {r.map((c: string, ci: number) => (
                                                        <TableCell key={'cell-' + ri + '-' + ci} style={{ whiteSpace: 'nowrap' }}>{c}</TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            )}
                            {state === StateMachine.ImportResult && (
                                <Fragment>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t('common.email')}</TableCell>
                                                <TableCell>{t('users.singular')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {inProgress && (
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <CircularProgress />
                                                        <Typography>{t('users.import.inProgress')}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {results.map((r, i) => (
                                                <TableRow key={'import-result-' + i} hover>
                                                    <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                                                        {r.email}
                                                        <Box mx={1} />
                                                        {renderImportStatus(r)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {r.user && <Link href={'/users/' + r.user.uuid} target="_blank">{t('actions.view')}</Link>}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Fragment>
                            )}
                            <Box my={2} />
                            <Box display="flex" justifyContent="flex-end">
                                {state === StateMachine.ColumnDefinition && <Button variant="contained" disableElevation onClick={goBack}>{t('actions.back')}</Button>}
                                <Box mx={1} />
                                <Button variant="contained" color="primary" disabled={!canGoNext() || inProgress} onClick={goNext}>{nextLabel()}</Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </RentalAppContainer>
    )
}
